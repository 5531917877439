import React, { useState, useEffect } from "react";
import axios from "axios";
import { ip } from "../../../Api";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Table } from "reactstrap";
import moment from "moment-timezone";

const Uploadautomation = (props) => {
  const [fetchingclaims, setFetchingclaims] = useState(true);
  const [refreshtoken, setRefreshtoken] = useState(sessionStorage.getItem("refreshtoken"));
  const [accesstoken, setAccesstoken] = useState(sessionStorage.getItem("accesstoken"));
  const [client, setClient] = useState(sessionStorage.getItem("clientid"));
  const [followupdate, setFollowupdate] = useState([]);
  const [currentpage1, setCurrentpage1] = useState(0);
  const [perPage1] = useState(10);
  const [pageCount1, setPageCount1] = useState(0);

  const renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshtoken}`,
    };
    await axios
      .get(`${ip}/auth/renewAccessToken`, { headers: renewheaders })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
        setAccesstoken(renew.data.accessToken);
      })
      .catch((err) => {});
  };

  const handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage1;
    setCurrentpage1(selectedPage);
    getfollowupdate();
  };

  const getdownloadreport = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    let url;
    if (props.startdate && props.enddate) {
      url = `${ip}/helper/automatefollowupdateclaims?clientId=${client}&startdate=${props.startdate}&enddate=${props.enddate}`;
    } else {
      url = `${ip}/helper/automatefollowupdateclaims?clientId=${client}`;
    }
    fetch(url, { headers: headers }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", "Followupdate");
        a.click();
        URL.revokeObjectURL(a);
      });
    });
  };

  const getfollowupdate = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    let url;
    if (props.startdate && props.enddate) {
      url = `${ip}/helper/automatefollowupdateclaims?clientId=${client}&startdate=${props.startdate}&enddate=${props.enddate}&pageno=${currentpage1}`;
    } else {
      url = `${ip}/helper/automatefollowupdateclaims?clientId=${client}&pageno=${currentpage1}`;
    }
    axios
      .get(url, { headers: headers })
      .then((res) => {
        setPageCount1(Math.ceil(res.data.count / perPage1));
        setFollowupdate(res.data.data);
        setFetchingclaims(false);
      });
  };

  useEffect(() => {
    getfollowupdate();
  }, [currentpage1, props.startdate, props.enddate]); // Dependency array

  return fetchingclaims ? (
    <div>
      <p style={{ fontSize: "23px", marginTop: "10px", color: "#000" }}>
        <span>Loading...</span>
        <span>Please Wait...</span>
      </p>
      <div className="boxes body__loader dribbble">
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  ) : followupdate.length === 0 ? (
    <div className="bot-ui" style={{ marginTop: "6%" }}>
      <div className="bot-head">
        <div className="eyes-container">
          <div className="to-left eye"></div>
          <div className="to-right eye"></div>
        </div>
      </div>
      <div className="bot-body">
        <div className="analyzer"></div>
      </div>
      <p style={{ fontSize: "20px", fontStyle: "italic" }}>No Data Found</p>
    </div>
  ) : (
    <div>
      <br />
      <div>
        <button
          style={{
            boxShadow: "inset 0px 1px 0px 0px #E184F3",
            background: "black",
            backgroundColor: "black",
            borderRadius: "6px",
            border: "1px solid black",
            display: "inline-block",
            cursor: "pointer",
            color: "#FFFFFF",
            fontSize: "15px",
            fontWeight: "bold",
            padding: "6px 24px",
            textDecoration: "none",
            textShadow: "0px 1px 0px #9B14B3",
            float: "right",
          }}
          onClick={getdownloadreport}
        >
          Generate Report
        </button>
      </div>
      <br />
      <div style={{ width: "100%", overflowX: "scroll" }}>
        <Table
          className="StandardTable"
          bordered
          style={{
            marginLeft: "3%",
            width: "99%",
            marginTop: "4%",
          }}
        >
          <thead style={{ backgroundColor: "#041c3c", color: "white" }}>
            <tr align="center">
              <th>ClaimID</th>
              <th>Machine</th>
              <th>Billing Organization Name</th>
              <th>Physician</th>
              <th>Patient Name</th>
              <th>Patient DOB</th>
              <th>Plan Code</th>
              <th>Pri Payer</th>
              <th>Primary PolicyNo</th>
              <th>Trading Partner ServiceId</th>
              <th>Reason</th>
              <th>Claim Status</th>
              <th>Status Code</th>
              <th>Status Code Value</th>
              <th>Status Category Code</th>
              <th>Status Category Code Value</th>
              <th>Type</th>
              <th>Touch</th>
              <th>Created At</th>
              <th>Billed Amount</th>
              <th>DOS</th>
              <th>Followup Date</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {followupdate.map((data, index) => (
              <tr key={index}>
                <td>{data.ClaimID}</td>
                <td>{data.Machine}</td>
                <td style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                  {data.BillingOrganizationName}
                </td>
                <td>{data.Physician}</td>
                <td>{`${data.FirstName} ${data.LastName}`}</td>
                <td>{data.PatientDOB}</td>
                <td>{data.PlanCode}</td>
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>{data.Pripayer}</td>
                <td>{data.PrimaryPolicyNo}</td>
                <td>{data.tradingPartnerServiceId}</td>
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>{data.Reason}</td>
                <td>{data.ClaimStatus}</td>
                <td>{data.statusCode}</td>
                <td>{data.statusCodeValue}</td>
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>{data.statusCategoryCode}</td>
                <td style={{ whiteSpace: "normal", textAlign: "justify" }}>{data.statusCategoryCodeValue}</td>
                <td style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{data.type}</td>
                <td style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{data.Touch}</td>
                <td>{moment(data.CreatedAt).format("YYYY-MM-DD")}</td>
                <td>{data.TotalBilledAmount}</td>
                <td>{data.DateOfService}</td>
                <td>{moment(data.Followup_date).format("YYYY-MM-DD")}</td>
                <td style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{data.notes}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <br />
      <div className="pagination" style={{ float: "right", marginRight: "5%" }}>
        <ReactPaginate
          previousLabel={<IoIosArrowBack />}
          nextLabel={<IoIosArrowForward />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount1}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick1}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
      <br />
      <br />
    </div>
  );
};

export default Uploadautomation;
