import axios from 'axios';

// develop ip: http://164.52.216.40:3000
export const ip = "https://devapirevshield.imagnumhealthcare.com";
export const testUploadIp = "https://apidemorevshield.imagnumhealthcare.com";
export const secretkey = "aes_256_encryption_in_nestjs_sec";

export const restartStatus = async () => {
    try {
      const response = await axios.post(`${ip}/restart-status`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const checkHealth = async () => {
    try {
      const response = await axios.get(`${ip}/health`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

// qa ip
// export const ip = "https://apidemorevshield.imagnumhealthcare.com";
// export const secretkey = "aes_256_encryption_in_nestjs_sec"


// Replace with WebSocket server URL


