import React, { createContext, useState, useContext, useEffect } from 'react';
import history from '../Utilities/history';  // Ensure this import is correct

const UploadContext = createContext();

export const useUpload = () => useContext(UploadContext);

export const UploadProvider = ({ children }) => {
  const [progress, setProgress] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [notInserted, setNotInserted] = useState(0);
  const [inserted, setInserted] = useState(0);
  const [duplicate, setDuplicate] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [queuing, setQueuing] = useState(0);
  const [process_count, setProgresscount] = useState([0, 0]);
  const [duration, setDuration] = useState(0);
  const [status, setStatus] = useState('');
  const [filename, setFileName] = useState('');

  useEffect(() => {
    const unlisten = history.listen((location) => {
      console.log('Captured path:', location.pathname);

      if (location.pathname === '/data') {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  const toggleCard = () => {
    setIsVisible(!isVisible);
  };

  const value = {
    progress,
    setProgress,
    isVisible,
    toggleCard,
    notInserted,
    setNotInserted,
    inserted,
    setInserted,
    totalCount,
    setTotalCount,
    duration,
    setDuration,
    status,
    setStatus,
    filename,
    setFileName,
    duplicate,
    setDuplicate,
    queuing,
    setQueuing,
    process_count,
    setProgresscount,
  };

  return (
    <UploadContext.Provider value={value}>
      {children}
    </UploadContext.Provider>
  );
};
