import React, { useState, useEffect } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import axios from "axios";
import { ip, secretkey} from "../../Api";
import aes256 from "aes256";
import { Row, Col, Form, Label, Input, Button, FormGroup } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const NoteType = () => {
  const [addnewmodal, setAddnewmodal] = useState(false);
  const [client] = useState(sessionStorage.getItem("clientid"));
  const [accesstoken] = useState(sessionStorage.getItem("accesstoken"));
  const [notetypecode, setNotetypecode] = useState("");
  const [notetype, setNotetype] = useState("");
  const [noteTypearray, setNoteTypearray] = useState([]);
  const [offset, setOffset] = useState(0);
  const [currentpage, setCurrentpage] = useState(0);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accesstoken}`,
  };

  const addNoteType = () => {
    const value = {
      noteTypeCode: notetypecode,
      noteType: notetype,
      clientId: parseInt(client),
    };
    axios
      .post(`${ip}/notetypecodes/create`, value, { headers })
      .then((res) => {
        if (res) {
          setAddnewmodal(!addnewmodal);
          getNotetypeDetails();
          toast.success("Note Type created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          getNotetypeDetails();
          setAddnewmodal(!addnewmodal);
          const statusCode = err.response.data.statusCode;
          if (statusCode === 500) {
            toast.error("Server Error", { position: "top-right", autoClose: 5000 });
          } else if (statusCode === 401) {
            toast.error("Please reload and try again", { position: "top-right", autoClose: 5000 });
          } else {
            toast.error("An unknown error occurred", { position: "top-right", autoClose: 5000 });
          }
        }
      });
  };

  const addModal = () => {
    setAddnewmodal(!addnewmodal);
  };

  const getNotetypeDetails = () => {
    let url = client
      ? `${ip}/notetypecodes/all?pageno=${currentpage}&clientId=${client}`
      : `${ip}/notetypecodes/all?pageno=${currentpage}`;
    
    axios
      .get(url, { headers })
      .then((res) => {
        let noteTypeData;
        noteTypeData = aes256.decrypt(`${secretkey}`, res.data.notetypecodes);
        const decryptednoteTypedata = JSON.parse(noteTypeData); 
        setNoteTypearray(decryptednoteTypedata);
        setPageCount(Math.ceil(res.data.count / perPage));
      })
      .catch((err) => {});
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const newOffset = selectedPage * perPage;
    setCurrentpage(selectedPage);
    setOffset(newOffset);
    getNotetypeDetails();
  };

  const reset = () => {
    setNotetype("");
    setNotetypecode("");
  };

  useEffect(() => {
    getNotetypeDetails();
  }, [currentpage]);

  return (
    <div>
      <Newsidebar name="Note Types" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: "13px" }}></div>
          <div>
            <button
              style={{
                backgroundColor: "#E5E1DA",
                border: "1px solid #E5E1DA",
                width: "fit-content",
                padding: "8px",
                marginRight: "40px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                whiteSpace: "nowrap",
              }}
              onClick={addModal}
            >
              + New Note Type
            </button>
          </div>
        </div>
        <div className="align-column">
          <ToastContainer />
          <br />
          <div style={{ marginLeft: "30%", marginRight: "30%" }}>
            <Table bordered className="StandardTable">
              <thead>
                <tr>
                  <th>Note Type Code</th>
                  <th>Note Type</th>
                </tr>
              </thead>
              <tbody>
                {noteTypearray &&
                  noteTypearray.map((data, index) => (
                    <tr key={index}>
                      <td>{data.noteTypeCode}</td>
                      <td>{data.noteType}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <div>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
        <Modal
          show={addnewmodal}
          onHide={addModal}
          onExit={reset}
          centered
        >
          <Modal.Header closeButton closeLabel="">
            <Modal.Title>Add Note Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Label>Note Type Code</Label>
                  <Input
                    value={notetypecode}
                    onChange={(e) => setNotetypecode(e.target.value)}
                  />
                </Col>
                <Col>
                  <Label for="select">Note Type</Label>
                  <Input
                    value={notetype}
                    onChange={(e) => setNotetype(e.target.value)}
                  />
                </Col>
              </Row>
              <br />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                addModal();
                reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={addNoteType}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default NoteType;
