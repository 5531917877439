import React, { Component } from "react";
import axios from "axios";
import "../../Styles/configure.css";
import Newsidebar from "../Dashboard/Newsidebar";
import Twilio from "../Transaction/Twilio";
import ReactPaginate from "react-paginate";
import { AiOutlineSearch } from "react-icons/ai";
import { Card, Row, Col } from "react-bootstrap";
import nodata from "../../Images/no_result_found.png";
import searchresult from "../../Images/searchresult.jpg";
import loading from "../../Images/loader.gif";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ageOptions } from "./Agedata";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import moment from "moment-timezone";
import{Table} from "react-bootstrap";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { BiUpArrowAlt } from "react-icons/bi";
import { BiDownArrowAlt } from "react-icons/bi";
import { SiAcclaim } from "react-icons/si";
import "../../Styles/actionuser.css";
import { Button, Form, Input } from "reactstrap";
import ActionSimpleMenu from "./ActionsComponents/ActionMenuList";
import { ip } from ".././Api";
import SimpleMenu from "../Transaction/AllocationCardComponents/MenuList";
import Badge from "@mui/material/Badge";
import ActionViewClaim from "./ActionsComponents/ActionViewClaim";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Viewmoreeligibility from "../Eligibility/Viewmoreeligibility.js";
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

class Actions extends Component {
  constructor(props) {
    super(props);
    this.typeaheadRef = React.createRef();
    this.state = {
      spageCount:1,
      searchCompleted:false,
      optionsarray:[],
      modal: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      firstname: sessionStorage.getItem("firstname"),
      lastname: sessionStorage.getItem("lastname"),
      claimsdata: [],
      statuslist: [],
      searchtext: "",
      searchclaimdata: [],
      payercategories: [],
      cptdata: [],
      status: "",
      optionSelected: [],
      claimfield: "",
      claimvalue: "",
      claimtype: "",
      payervalue: [],
      cptvalue: [],
      query: [],
      pageCount: "",
      ids: [],
      offset: 0,
      currentpage: 0,
      currentpagesearch: 0,
      perPage: sessionStorage.getItem("Default_pagination"),
      sperPage: sessionStorage.getItem("Default_pagination"),
      allocated: 0,
      unallocated: 0,
      checkedAll: false,
      alldataresponse: [],
      searchdataresponse: [],
      user_id: sessionStorage.getItem("user_id"),
      role_type: sessionStorage.getItem("role_type"),
      client: sessionStorage.getItem("clientid"),
      css_name: "",
      formValues: [
        {
          claimfield1: "",
          claimvalue: "",
        },
      ],
      assignedclaims: "",
      assignedamt: "",
      inprogressamt: "",
      inprogressclaims: "",
      nottouchclaims: "",
      nottouchamt: "",
      completedamt: "",
      completedclaims: "",
      Needcallamt: "",
      Needcallcount: "",
      fetching: true,
      Location: "",
      locationslist: [],
      agegrplist: [
        "0-30days",
        "31-60days",
        "61-90days",
        "91-120days",
        "121-180days",
        "181-365days",
        "above 1year",
      ],
      agegrp: "",
      firstsearch1: false,
      sortstate: "ASC",
      openicon: false,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handlePageClick1 = this.handlePageClick1.bind(this);
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  handleChange = (selected) => {
    this.setState({
      optionSelected: selected,
    });
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getallocatedclaims();
      }
    );
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.sperPage;

    this.setState(
      {
        currentpagesearch: selectedPage,
        offset: offset,
        firstsearch1: false,
      },
      (e) => {
        this.filtersearch(e);
      }
    );
  };
  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  //api call to get all users
  getdashboardcount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let name = this.state.firstname + " " + this.state.lastname;
    const res = axios
      .get(
        `${ip}/claim/user/action/dashboard?userid=${name}&clientId=${this.state.client}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.needtocall.length !== 0) {
          this.setState({
            Needcallcount: res.data.needtocall[0].totalcount,
            Needcallamt: res.data.needtocall[0].totalamount,
          });
        } else {
          this.setState({ Needcallcount: 0, Needcallamt: 0 });
        }
        if (res.data.completedclaimscount.length !== 0) {
          this.setState({
            completedclaims: res.data.completedclaimscount[0].totalcount,
            completedamt: res.data.completedclaimscount[0].totalamount,
          });
        } else {
          this.setState({ completedclaims: 0, completedamt: 0 });
        }

        if (res.data.totalclaimscount.length !== 0) {
          this.setState({
            assignedclaims: res.data.totalclaimscount[0].totalcount,
            assignedamt: res.data.totalclaimscount[0].totalamount,
          });
        } else {
          this.setState({ assignedclaims: 0, assignedamt: 0 });
        }
        if (res.data.inprogressclaimscount.length !== 0) {
          this.setState({
            inprogressclaims: res.data.inprogressclaimscount[0].totalcount,
            inprogressamt: res.data.inprogressclaimscount[0].totalamount,
          });
        } else {
          this.setState({ inprogressclaims: 0, inprogressamt: 0 });
        }
        if (res.data.notworkedclaimscount.length !== 0) {
          this.setState({
            nottouchclaims: res.data.notworkedclaimscount[0].totalcount,
            nottouchamt: res.data.notworkedclaimscount[0].totalamount,
          });
        } else {
          this.setState({ nottouchclaims: 0, nottouchamt: 0 });
        }
      })
      .catch((err) => {});
  };
  //api call to get all cpt data
  getcpt = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/cptmaster/all`, { headers: headers })
      .then((res) => {
        this.setState({
          cptdata: res.data.cpt,
        });
      })
      .catch((err) => {});
  };
  //get location
  getLocation = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      url = `${ip}/location/all/list?clientId=${this.state.client}`;
    } else {
      url = `${ip}/location/all/list`;
    }
    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        let data = res.data.location;
        data = data.filter((item) => {
          return item.clientId === this.state.client;
        });
        data.sort((a, b) => {
          return a.Location < b.Location ? -1 : 1;
        });

        this.setState({ locationslist: data });
      })

      .catch((err) => {});
  };
  getpayercategory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(`${ip}/payercategory/all`, {
        headers: headers,
      })
      .then((res) => {
        let other = {
          category_name: "Others",
        };
        let data = res.data.payer;
        data.push(other);
        this.setState({ payercategories: data });
      })

      .catch((err) => {});
  };
  handleStatusChange = (selected) => {
    if (selected.length > 0) {
      this.setState({ status: selected[0] });
    } else {
      this.setState({ status: "" });
    }
  };

  getquery = () => {
    var query_o;
    let query = [];
    if (this.state.payervalue.length !== 0) {
      if (this.state.payervalue[0].category_name !== "") {
        query_o = `Payercategory=${this.state.payervalue[0].category_name}`;
        query.push(query_o);
      }
    }
    if (this.state.claimtype) {
      query_o = `type=${this.state.claimtype}`;
      query.push(query_o);
    }
    if (this.state.status !== "") {
      query_o = `ClaimStatus=${this.state.status}`;
      query.push(query_o);
    }
    if (this.state.optionSelected.length !== 0) {
      let agegrp = [];
      let temp;
      let agelist;
      this.state.optionSelected.map((ele) => {
        agegrp.push(ele.value);
      });
      agelist = agegrp.map((str) => '"' + str + '"');
      query_o = `AgeGrp=[${agelist}]`;
      query.push(query_o);
    }
    if (this.state.claimfield && this.state.claimvalue !== "") {
      query_o = `${this.state.claimfield}=${this.state.claimvalue}`;
      query.push(query_o);
    }
    if (query.length === 1) {
      this.state.query.push(`${query[0]}`);
    }
    if (query.length === 2) {
      this.state.query.push(`${query[0]}&${query[1]}`);
    }
    if (query.length === 3) {
      this.state.query.push(`${query[0]}&${query[1]}&${query[2]}`);
    }
    if (query.length === 4) {
      this.state.query.push(`${query[0]}&${query[1]}&${query[2]}&${query[3]}`);
    }
  };

  filtersearch = (e) => {
    if (this.state.firstsearch1) {
      e.preventDefault();
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.Location) {
      url = `${ip}/allocation/workqueue/advancedmultiplefilter?clientId=${this.state.client}&${this.state.query}&pageno=${this.state.currentpagesearch}&count=${this.state.sperPage}&Location=${this.state.Location}`;
    } else {
      url = `${ip}/allocation/workqueue/advancedmultiplefilter?clientId=${this.state.client}&${this.state.query}&pageno=${this.state.currentpagesearch}&count=${this.state.sperPage}`;
    }
    if ( this.state.role_type === "Admin") {
      if (this.state.query.length !== 0) {
        const res = axios
          .get({ url, headers: headers })
          .then((res) => {
            this.setState({ searchtext: 1 });
            this.setState({
              searchdataresponse: res.data.claims,
              spageCount: Math.ceil(res.data.claimcount / this.state.sperPage),
              searchclaimdata: res.data.claims,
            });
          })
          .catch((err) => {});
      }
    } 
    else if (this.state.searchCompleted=== true && this.state.role_type === "Team Member") {
      let name = this.state.firstname + " " + this.state.lastname;
          if (this.state.formValues.some(formValue => formValue.claimfield1 !== "" && formValue.claimvalue !== "")) {
            let url;
            let name = this.state.firstname + " " + this.state.lastname;
            url =`${ip}/userdashboard/advancedmultiplefilter?pageno=${this.state.currentpagesearch}&count=${this.state.perPage}&Claimunder=${name}&clientId=${this.state.client}`;
          const searchCriteria = {};
          this.state.formValues.forEach((formValue) => {
            if (formValue.claimfield1 && formValue.claimvalue) {
              searchCriteria[formValue.claimfield1] = formValue.claimvalue;
            }
          });
          this.state.formValues.forEach((formValue) => {
            if (
              formValue.claimfield1 === "type"
            ) {
              searchCriteria[formValue.claimfield1] = formValue.claimvalue;
            }
          });
          this.state.formValues.forEach((formValue) => {
            if (formValue.claimfield1 === "ClaimStatus") {
              if (Array.isArray(formValue.claimvalue)) {
                const userValues = formValue.claimvalue.map((option) => option.value);
                let statuslist;
                statuslist = userValues.map((str) => '"' + str + '"');
                searchCriteria[formValue.claimfield1] = `[${statuslist.join(", ")}]`;
              } else {
                console.error("formValue.claimvalue is not an array");
              }
            }
          });
          this.state.formValues.forEach((formValue) => {
            if (
              formValue.claimfield1 === "AgeGrp" &&
              formValue.claimvalue.length > 0
            ) {
              const userValues = formValue.claimvalue.map((option) => option.value);
              let agegrplist;
              agegrplist = userValues.map((str) => '"' + str + '"');
              searchCriteria[formValue.claimfield1] = `[${agegrplist.join(", ")}]`;
            }
          });
          const searchCriteriaQueryString = Object.keys(searchCriteria)
            .map((key) => `${key}=${searchCriteria[key]}`)
            .join("&");
    
          if (searchCriteriaQueryString) {
            url += `&${searchCriteriaQueryString}`;
          }
          const res = axios
            .get(url, {
              headers: headers,
            })
            .then((res) => {
              if (res && this.state.searchCompleted=== true && this.state.spageCount === 1) {
                toast.success("Searching completed!", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  progress: undefined,
                });
              }
              this.setState({ searchtext: 1 });
              this.setState({
                searchdataresponse: res.data.claims,
                spageCount: Math.ceil(res.data.claimcount / this.state.perPage),
                searchclaimdata: res.data.claims,
              });
            });
            this.setState({
              searchCompleted: true,
            });
          }else{
            alert("Select data to search")
          }
      }
  };
  addFormField() {
    this.setState({
      formValues: [
        ...this.state.formValues,
        { claimfield1: "", claimvalue: "" },
      ],
    });
  }
  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }

  //api call to get all users
  getallocatedclaims = async () => {
    // this.setState({ fetching: true });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    if (this.state.role_type === ("Admin" || "Team Manager")) {
      let url;
      if (this.state.Location) {
        url = `${ip}/allocation/workqueue?clientId=${this.state.client}&pageno=${this.state.currentpage}&Location=${this.state.Location}&count=${this.state.perPage}`;
      } else {
        url = `${ip}/allocation/workqueue?clientId=${this.state.client}&pageno=${this.state.currentpage}&count=${this.state.perPage}`;
      }
      const res = axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          this.setState({
            alldataresponse: res.data.claims,
            pageCount: Math.ceil(res.data.claimcount / this.state.perPage),
            claimsdata: res.data.claims,
            fetching: false,
            allocated: res.data.allocatedcount,
            unallocated: res.data.unallocatedcount,
          });
        })
        .catch((err) => {});
    } else if (this.state.role_type === "Team Member") {
      let name = this.state.firstname + " " + this.state.lastname;
      const res = axios
        .get(
          `${ip}/claim/user?userid=${name}&clientId=${this.state.client}&pageno=${this.state.currentpage}&count=${this.state.perPage}`,
          {
            headers: headers,
          }
        )
        .then((res) => {
          this.setState({
            pageCount: Math.ceil(res.data.count / this.state.perPage),
            claimsdata: res.data.Claims,
            fetching: false,
          });
        })
        .catch((err) => {});
    }
  };

  getcss = () => {
    if (this.state.role_type === "Team Member") {
      this.setState({ css_name: "align-col-actions" });
    } else {
      this.setState({ css_name: "align-column" });
    }
  };

  getClaimStatus = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    const res = await axios
      .get(`${ip}/claimstatus/all`, { headers: headers })
      .then((res) => {
        var data = res.data.claimstatus.map((e) => (
         {
          value: e.claimStatus,
          label: e.claimStatus,
        }));
        this.setState({
          statuslist: data,
        });
      })
      .catch((err) => {});
  };
  handleChangeLocation = (selectedOptions) => {
    let Location = selectedOptions.map((data) => {
      return data.Location;
    });
    this.setState(
      {
        Location: Location[0],
      },
      () => {
        this.getLocation();
        this.getallocatedclaims();
      }
    );
  };
  sorting = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.claimsdata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ claimsdata: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.claimsdata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ claimsdata: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };
  sortingfilter = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.searchdataresponse].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ searchdataresponse: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.searchdataresponse].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ searchdataresponse: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };
  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.setState({   firstsearch1: false , searchCompleted: true }, (e) => {
        this.filtersearch(e);
      });
    }
  };
  componentDidMount() {
    
    this.getallocatedclaims();
    // this.getcss();
    this.getdashboardcount();
    this.getcpt();
    this.getpayercategory();
    this.getClaimStatus();
    this.getLocation();
    const options = [
      { value: "Location", label: "Location" },
      { value: "ClaimID", label: "Claim ID" },
      { value: "patientAccountNumber", label: "Account Number" },
      { value: "MRN", label: "Claim No" },
      { value: "Physician", label: "Physician" },
      { value: "Age", label: "Age" },
      { value: "PrimaryPolicyNo", label: "Policy No" },
      { value: "ClaimStatus", label: "Claim Status"},
      { value: "PatientDOB", label: "DOB" },
      { value: "PlanCode", label: "Plan Code" },
      { value: "DateOfService", label: "DOS" },
      { value: "InsuranceName", label: "Insurance Name" },
      { value: "type", label: "Type" },
      { value: "AgeGrp", label: "AgeGroup" },
    ];
    this.setState({
      optionsarray: options
    })
    let storedSearchWorkqueueInput = localStorage.getItem("storedClaimWorkqueueSearch");
    let storedSearchvalue = localStorage.getItem("storedWorkqueueSearchvalue");
    let storedSearchkey = localStorage.getItem("storedWorkqueueSearchkey");

    let val = JSON.parse(storedSearchvalue)
    if (val === false) {
      this.setState({
      formValues: JSON.parse(storedSearchWorkqueueInput),
      searchCompleted: JSON.parse(storedSearchkey)
        }, (e) => {
          this.filtersearch(e);
        })
  }
    else{
      this.getallocatedclaims()
    }
  }
  componentWillUnmount() {
    let Workqueueinput = JSON.stringify(this.state.formValues);
    localStorage.setItem("storedClaimWorkqueueSearch", Workqueueinput);
    let Workqueueinput1 = JSON.stringify(this.state.firstsearch1);
    localStorage.setItem("storedWorkqueueSearchvalue", Workqueueinput1);
    let Workqueueinput2 = JSON.stringify(this.state.searchCompleted);
    localStorage.setItem("storedWorkqueueSearchkey", Workqueueinput2);
  }
  addsinglecheckboxid = (data, check) => {
    if (check) {
      this.setState({ ids: [...this.state.ids, data] }, () => {});
    } else {
      let arr = this.state.ids.slice();
      let val = arr.filter((id) => {
        return id !== data;
      });
      this.setState({ ids: val });
    }
  };

  checkBoxAll = () => {
    if (this.state.checkedAll === false) {
      let idvalue = this.state.alldataresponse.map((data) => data._id);
      this.setState({ checkedAll: !this.state.checkedAll, ids: idvalue });
    } else {
      this.setState({ checkedAll: !this.state.checkedAll, ids: [] }, () => {});
    }
  };
  resetcount = () => {
    this.setState(
      {
        perPage: 10,
      },
      () => this.getallocatedclaims()
    );
  };

  reset = (e) => {
    e.preventDefault();
    if (this.typeaheadRef.current) {
      this.typeaheadRef.current.clear(); // Use the ref to clear the Typeahead input
    }
    this.setState(
      {
        spageCount:1,
        searchCompleted:false,
        formValues: [],
        optionSelected: [],
        claimfield: "",
        claimvalue: "",
        status: "",
        payervalue: [],
        cptvalue: [],
        agegrp: "",
        searchdataresponse: [],
        searchclaimdata: [],
        searchtext: "",
        currentpage: 0,
        currentpagesearch: 0,
        allocated: 0,
        unallocated: 0,
        claimtype: "",
        sperPage: 10,
      },
      () => {
        this.addFormField()
        this.getallocatedclaims()
      }
    );
  };

  render() {
    const checkboxstyle = {
      marginTop: "39px",
    };
    return (
      <div>
        <Newsidebar name="Work queue" />
      <div className="align-row" style={{ overflow: "hidden" }}>

        <div className={this.state.css_name} >

          {this.state.role_type === "Team Member" ? (
            <Card id="team-dashboard" style={{ border: "none" }}>
              <br />
              {/* <Row className="m-lg-4">Dashboard</Row> */}
              <ToastContainer />
              {this.state.role_type === "Team Member" && (
                <div className="search__grid">
                <div className="search__grid1">
                  <Form>
                      {this.state.formValues.map((formValue, index) => (
                        <div key={index}>
                          <Row style={{
                            background:"whitesmoke",
                            padding:"6px",
                            borderRadius:"20px"
                          }}>
                            <Col md="5" className="d-flex justify-content-end">
                              <Typeahead
                                onKeyPress={this.handleKeyPress}
                                onKeyDown={this.handleKeyPress}
                                ref={this.typeaheadRef}
                                id={`typeahead${index}`}
                                style={{ width: "100%" }}
                                options={this.state.optionsarray}
                                value={formValue.claimfield1}
                                selected={this.state.optionsarray.filter(
                                  (option) => option.value === formValue.claimfield1
                                )}
                                labelKey={(option) => `${option.label}`}
                                placeholder="What are you looking for?..."
                                onChange={(selected) => {
                                  const updatedFormValues = [...this.state.formValues];
                                  if (selected && selected.length > 0 && selected[0] && selected[0].value) {
                                    updatedFormValues[index].claimfield1 = selected[0].value;
                                  } else {
                                    updatedFormValues[index].claimfield1 = "Default";
                                  }
                                  this.setState({ formValues: updatedFormValues });
                                }}
                              />
                            </Col>
                            <Col md="5">
                              {formValue.claimfield1 === "ClaimStatus" ? (
                                <ReactSelect
                                  onKeyPress={this.handleKeyPress}
                                  onKeyDown={this.handleKeyPress}
                                  options={this.state.statuslist}
                                  isMulti
                                  closeMenuOnSelect={false}
                                  hideSelectedOptions={false}
                                  components={{
                                    Option,
                                  }}
                                  onChange={(selected) => {
                                    const updatedFormValues = [...this.state.formValues];
                                    updatedFormValues[index].claimvalue = selected || [];
                                    this.setState({ formValues: updatedFormValues });
                                  }}
                                  allowSelectAll={true}
                                  value={formValue.claimvalue}
                                />
                              ) : formValue.claimfield1 === "AgeGrp" ? (
                                <ReactSelect
                                  onKeyPress={this.handleKeyPress}
                                  onKeyDown={this.handleKeyPress}
                                  options={ageOptions}
                                  isMulti
                                  closeMenuOnSelect={false}
                                  hideSelectedOptions={false}
                                  components={{
                                    Option,
                                  }}
                                  onChange={(selected) => {
                                    const updatedFormValues = [...this.state.formValues];
                                    updatedFormValues[index].claimvalue = selected || [];
                                    this.setState({ formValues: updatedFormValues });
                                  }}
                                  allowSelectAll={true}
                                  value={formValue.claimvalue}
                                />
                              ) : formValue.claimfield1 === "type" ? (
                                <Typeahead
                                  onKeyPress={this.handleKeyPress}
                                  onKeyDown={this.handleKeyPress}
                                  id="typeahed1"
                                  clearButton={true}
                                  options={["Web", "IVR", "Manual"]}
                                  placeholder="Select Type..."
                                  selected={formValue.claimvalue}
                                  onChange={(selected) => {
                                    const updatedFormValues = [...this.state.formValues];
                                    updatedFormValues[index].claimvalue = selected || [];
                                    this.setState({ formValues: updatedFormValues });
                                  }}
                                />
                              ) : (
                                <Input
                                  onKeyPress={this.handleKeyPress}
                                  onKeyDown={this.handleKeyPress}
                                  className="header__searchInput"
                                  type="text"
                                  placeholder="Enter text"
                                  value={formValue.claimvalue}
                                  onChange={(e) => {
                                    const updatedFormValues = [...this.state.formValues];
                                    updatedFormValues[index].claimvalue = e.target.value;
                                    this.setState({ formValues: updatedFormValues });
                                  }}
                                />
                              )}
                            </Col>
                            <Col md="2">
                              <div>
                                {index > 0 && (
                                  <Button
                                    className="btn btn-danger float-right labelfontsize"
                                    onClick={() => {
                                      this.removeFormFields(index);
                                    }}
                                  >
                                    <AiOutlineCloseCircle />
                                  </Button>
                                )}
                              </div>
                            </Col>
                          </Row>
                          <br />
                        </div>
                      ))}
                  </Form>
                </div>
                <div className="search__grid2">
                  <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "#89CFF0", color: "#fff" }}
                    onClick={() => {
                      this.setState(
                        { firstsearch1: false, searchCompleted: true },
                        (e) => {
                          this.filtersearch(e);
                        }
                      );
                    }}
                  >
                    <AiOutlineSearch />
                  </button>
                  <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "#7DD657", color: "#fff" }}
                    onClick={() => {
                      this.addFormField();
                    }}
                  >
                    <AiOutlinePlusCircle />
                  </button>
                  <button
                    style={{ backgroundColor: "#041c3c", color: "#fff" }}
                    type="button"
                    className="btn"
                    onClick={(e) => {
                      this.reset(e);
                    }}
                  >
                    <IoIosRefresh style={{ color: "white" }}></IoIosRefresh>
                  </button>
                </div>
                <div className="search__grid3" style={{ display: "flex" }}>
                <div className="select_drop">
                  <select
                    style={{ height: "35px" }}
                    value={this.state.perPage}
                    onChange={(e) =>
                      this.setState(
                        {
                          perPage: e.target.value,
                          currentpage: 0,
                          sperPage: e.target.value,
                          currentpagesearch: 0,
                        },
                        () => {
                          this.getallocatedclaims();
                        }
                      )
                    }
                  >
                    <option value="select" hidden selected>
                      Select page count
                    </option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                  </select>
                </div>
                <div>
                  <Button
                    style={{
                      border: "none",
                      color: "white",
                      background: "white",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      this.resetcount();
                    }}
                  >
                    <IoIosRefresh
                      style={{
                        color: "blue",
                        fontSize: "23px",
                  
                      }}
                    ></IoIosRefresh>
                  </Button>
                </div>
              </div>
              </div>
              )}
              <div class="page-wrapper">
              <ul class="infographic-cards">
                  <li class="color-1">
                      <i class="fa-solid fa-sitemap"></i>
                      <h6>Total Assigned Claims</h6>
              <Link to="/actions" style={{ textDecoration: "none" }}>
                      <Row className="action-card-row">
                        <Col className="action-claim">
                          Total Claims:
                          <span>
                            <div
                              style={{
                                color: "black",
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                            >
                              &nbsp;
                              {this.state.assignedclaims}
                            </div>
                          </span>
                        </Col>
                        <Col className="action-total">
                          Total Amount:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                            >
                              ${this.state.assignedamt.toLocaleString()}
                            </div>
                          </span>
                        </Col>
                      </Row>
                      </Link>
                  </li>
                  
                  <li class="color-2">
                      <i class="fa-solid fa-list"></i>
                      <h6>Total Completed Claims</h6>
                      <Link
                      to="/user-completed-page"
                      style={{ textDecoration: "none" }}
                    >
                      <Row className="action-card-row">
                      <Col className="action-claim">
                        Total Claims:
                        <span>
                          <div
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              fontSize: "14px",
                            }}
                          >
                            &nbsp;
                            {this.state.completedclaims}
                          </div>
                        </span>
                      </Col>
                      <Col className="action-total">
                        Total Amount:
                        <span>
                          {" "}
                          <div
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              fontSize: "14px",
                            }}
                          >
                            ${this.state.completedamt.toLocaleString()}
                          </div>
                        </span>
                      </Col>
                    </Row></Link>
                  </li>
                  
                  <li class="color-3">
                      <i class="fa-solid fa-list-check"></i>
                      <h6>Total Inprogress Claims</h6>
                      <Link
                    to="/user-inprogress-page"
                    style={{ textDecoration: "none" }}
                  >
                      <Row className="action-card-row">
                      <Col className="action-claim">
                        Total Claims:
                        <span>
                          <div
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              fontSize: "14px",
                            }}
                          >
                            &nbsp;
                            {this.state.inprogressclaims}
                          </div>
                        </span>
                      </Col>
                      <Col className="action-total">
                        Total Amount:
                        <span>
                          {" "}
                          <div
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              fontSize: "14px",
                            }}
                          >
                            ${this.state.inprogressamt.toLocaleString()}
                          </div>
                        </span>
                      </Col>
                    </Row></Link>
                  </li>
                  
                  <li class="color-4">
                      <i class="fa-solid fa-spinner"></i>
                      <h6>Pending</h6>
                      <Link
                      to="/user-nottouch-page"
                      style={{ textDecoration: "none" }}
                    >
                      <Row className="action-card-row">
                      <Col className="action-claim">
                        Total Claims:
                        <span>
                          <div
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              fontSize: "14px",
                            }}
                          >
                            &nbsp;
                            {this.state.nottouchclaims}
                          </div>
                        </span>
                      </Col>
                      <Col className="action-total">
                        Total Amount:
                        <span>
                          {" "}
                          <div
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              fontSize: "14px",
                            }}
                          >
                            ${this.state.nottouchamt.toLocaleString()}
                          </div>
                        </span>
                      </Col>
                    </Row></Link>
                  </li>
                  
                  <li class="color-5">
                  <i class="fa-solid fa-phone-volume"></i>
                  <h6>Need To Call</h6>
                  <Link
                    to="/user-needtocall-page"
                    style={{ textDecoration: "none" }}
                  >
                  <Row className="action-card-row">
                  <Col className="action-claim">
                    Total Claims:
                    <span>
                      <div
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        &nbsp;
                        {this.state.Needcallcount}
                      </div>
                    </span>
                  </Col>
                  <Col className="action-total">
                    Total Amount:
                    <span>
                      {" "}
                      <div
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        ${this.state.Needcallamt.toLocaleString()}
                      </div>
                    </span>
                  </Col>
                </Row></Link>
               
              </li>
              </ul>
             
          </div>
            </Card>
          ) : null}
          
          <div style={{ display: "flex", flexDirection: "row" , marginLeft:"3%", margin:"1%"}}>
            {this.state.client && this.state.role_type === "Admin" && (
              <Typeahead
                clearButton={true}
                onChange={this.handleChangeLocation}
                options={this.state.locationslist}
                labelKey={(option) => `${option.Location}`}
                placeholder="All Location"
                selected={this.state.selected}
              />
            )}
            {this.state.role_type === "Admin" && (
              <div style={{ display: "flex" }}>
                <div class="select_drop">
                  <select
                    style={{
                      float: "left",
                      marginLeft: "130px",
                      height: "35px",
                    }}
                    value={this.state.perPage}
                    onChange={(e) =>
                      this.setState(
                        {
                          perPage: e.target.value,
                          currentpage: 0,
                          sperPage: e.target.value,
                          currentpagesearch: 0,
                          firstsearch1: true,
                        },
                        () => {
                          this.getallocatedclaims();
                        }
                      )
                    }
                  >
                    <option value="select" hidden selected>
                      Select page count
                    </option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                  </select>
                </div>

                <div>
                  <Button
                    style={{
                      border: "none",
                      color: "white",
                      background: "white",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      this.resetcount();
                    }}
                  >
                    <IoIosRefresh
                      style={{
                        color: "blue",
                        fontSize: "23px",
                      }}
                    ></IoIosRefresh>
                  </Button>
                </div>
              </div>
            )}
          </div>

          {this.state.searchtext === "" && this.state.role_type === "Admin" && (
            <div style={{  marginLeft: "50%" }}>
              <Badge
                badgeContent={this.state.allocated}
                color="primary"
                showZero
                max={this.state.pageCount * this.state.perPage}
              >
                <Button
                  style={{
                    backgroundColor: "#2e5a88",
                    color: "white",
                  }}
                >
                  Allocated
                </Button>
              </Badge>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Badge
                badgeContent={this.state.unallocated}
                color="primary"
                showZero
                max={this.state.pageCount * this.state.perPage}
              >
                <Button>Unallocated</Button>
              </Badge>
            </div>
          )}
          <div>
            {this.state.fetching ? (
              <img src={loading} />
            ) : this.state.claimsdata.length === 0 ? (
              <div>
                <img src={nodata} alt="" style={{width:"25%", height:"25%"}} />
              </div>
            ) : (
              <div>
                {this.state.searchtext === "" ? (
                  <div>
                    <div style={{ marginLeft: "3%" }}>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          forcePage={this.state.currentpage}
                        />
                      </div>
                      <div style={{marginLeft:"3%", marginRight:"3%"}}>
                        <Table
                        bordered
                        className="StandardTable"
                        responsive
                        >
                          <thead className="sticky-header">
                            <tr className="sticky-table">
                              <th className="sticky-table">Action</th>
                              <th
                              
                                onClick={() => this.sorting("ClaimID")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Claim ID{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sorting("Location")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Location{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() =>
                                  this.sorting("patientAccountNumber")
                                }
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Account No{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sorting("MRN")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                              Claim No
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sorting("DateOfService")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                DOS{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sorting("PatientFirstName")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Patient Name{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sorting("PatientDOB")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Patient DOB{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sorting("PlanCode")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                PlanCode{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sorting("Pripayer")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Insurance Name{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sorting("Pripayerphone")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Insurance No
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sorting("PrimaryPolicyNo")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Policy No{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sorting("Physician")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Physician Name{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sorting("Age")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Age{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sorting("AgeGrp")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Age Grp{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sorting("type")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Type{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sorting("ClaimStatus")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Claim Status{" "}
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.claimsdata &&
                              this.state.claimsdata.map((data, index) => (
                                <tr key={data._id}>
                                  <td
                                  className="sticky-table"
                                    onClick={() => ((
                                      <ActionViewClaim
                                        value={
                                          sessionStorage.setItem(
                                            "ClaimID",
                                            data.ClaimID
                                          ) ||
                                          sessionStorage.setItem(
                                            "ChartID",
                                            data.ChartID
                                          ) ||
                                          sessionStorage.setItem(
                                            "Module",
                                            "Work Queue"
                                          ) ||
                                          sessionStorage.setItem(
                                            "MRN",
                                            data.MRN
                                          ) ||
                                          sessionStorage.setItem(
                                            "Patient",
                                            data.Patient
                                          ) ||
                                          sessionStorage.setItem(
                                            "PatientDOB",
                                            data.PatientDOB
                                          ) ||
                                          sessionStorage.setItem(
                                            "Physician",
                                            data.Physician
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimDate",
                                            data.ClaimDate
                                          ) ||
                                          sessionStorage.setItem(
                                            "Pripayer",
                                            data.Pripayer
                                          ) ||
                                          sessionStorage.setItem(
                                            "DateOfService",
                                            data.DateOfService
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimDate",
                                            data.ClaimDate
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimDBID",
                                            data._id
                                          ) ||
                                          sessionStorage.setItem(
                                            "AllocatedTo",
                                            data.Claimunder
                                          )
                                        }
                                      />
                                    ),(
                                      <Viewmoreeligibility
                                        value={
                                          sessionStorage.setItem(
                                            "modulename",
                                            "ActionWorkQueue"
                                          ) ||
                                          sessionStorage.setItem(
                                            "Eligibilityclaimid",
                                            data.ClaimID
                                          )
                                        }
                                      />
                                    )
                              )}
                                  >
                                    {this.state.role_type === "Admin" ? (
                                      <SimpleMenu />
                                    ) : (
                                      <ActionSimpleMenu />
                                    )}
                                  </td>

                                  <td>
                                    {data.ClaimID}
                                  </td>
                                  <td>
                                    {data.Location}
                                  </td>
                                  <td>
                                    {data.patientAccountNumber}
                                  </td>
                                  <td>{data.MRN}</td>
                                  <td>
                                    {data.DateOfService}
                                  </td>

                                  <td>
                                    {data.PatientFirstName}&nbsp;
                                    {data.PatientLastName}
                                  </td>
                                  <td>
                                    {moment(data.PatientDOB).format("MM/DD/YYYY")}
                                  </td>
                                  <td>
                                    {data.PlanCode}
                                  </td>
                                  <td>
                                    {data.InsuranceName
                                      ? data.InsuranceName
                                      : "-"}
                                  </td>
                                  <td>
                                    <Row>
                                      <Col md="4">
                                        <Twilio value11={data.Pripayerphone} />
                                      </Col>
                                      <Col md="2">{data.Pripayerphone}</Col>
                                    </Row>
                                  </td>
                                  <td>
                                    {data.PrimaryPolicyNo}
                                  </td>
                                  <td>
                                    {data.Physician}
                                  </td>
                                  <td>{data.Age}</td>
                                  <td>
                                    {data.AgeGrp}
                                  </td>
                                  <td>{data.type}</td>

                                  <td>
                                    {data.ClaimStatus}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                        </div>
                      <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          forcePage={this.state.currentpage}
                        />
                      </div>
                  </div>
                ) : (
                  <div>
                    {this.state.searchdataresponse.length === 0 ? (
                      <div>
                        <p>
                          <img src={searchresult} />
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.spageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick1}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          forcePage={this.state.currentpagesearch}
                        />
                        </div>
                      <div style={{marginLeft:"3%", marginRight:"3%"}}>
                            <Table
                              responsive
                              bordered
                              className="StandardTable"
                            >
                              <thead>
                                <tr>
                                  <th>Action</th>
                                  <th
                                    onClick={() =>
                                      this.sortingfilter("ClaimID")
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Claim ID{" "}
                                    {this.state.openicon ? (
                                      <BiUpArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: false })
                                        }
                                      />
                                    ) : (
                                      <BiDownArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: true })
                                        }
                                      />
                                    )}
                                  </th>
                                  <th
                                    onClick={() =>
                                      this.sortingfilter("Location")
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Location{" "}
                                    {this.state.openicon ? (
                                      <BiUpArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: false })
                                        }
                                      />
                                    ) : (
                                      <BiDownArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: true })
                                        }
                                      />
                                    )}
                                  </th>
                                  <th
                                    onClick={() =>
                                      this.sortingfilter("patientAccountNumber")
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Account No{" "}
                                    {this.state.openicon ? (
                                      <BiUpArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: false })
                                        }
                                      />
                                    ) : (
                                      <BiDownArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: true })
                                        }
                                      />
                                    )}
                                  </th>
                                  <th
                                    onClick={() => this.sortingfilter("MRN")}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                  Claim No
                                    {this.state.openicon ? (
                                      <BiUpArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: false })
                                        }
                                      />
                                    ) : (
                                      <BiDownArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: true })
                                        }
                                      />
                                    )}
                                  </th>
                                  <th
                                    onClick={() =>
                                      this.sortingfilter("DateOfService")
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    DOS{" "}
                                    {this.state.openicon ? (
                                      <BiUpArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: false })
                                        }
                                      />
                                    ) : (
                                      <BiDownArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: true })
                                        }
                                      />
                                    )}
                                  </th>
                                  <th
                                    onClick={() =>
                                      this.sortingfilter("PatientFirstName")
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Patient Name{" "}
                                    {this.state.openicon ? (
                                      <BiUpArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: false })
                                        }
                                      />
                                    ) : (
                                      <BiDownArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: true })
                                        }
                                      />
                                    )}
                                  </th>
                                  <th
                                    onClick={() =>
                                      this.sortingfilter("PatientDOB")
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Patient DOB{" "}
                                    {this.state.openicon ? (
                                      <BiUpArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: false })
                                        }
                                      />
                                    ) : (
                                      <BiDownArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: true })
                                        }
                                      />
                                    )}
                                  </th>
                                  <th
                                    onClick={() =>
                                      this.sortingfilter("PlanCode")
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    PlanCode{" "}
                                    {this.state.openicon ? (
                                      <BiUpArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: false })
                                        }
                                      />
                                    ) : (
                                      <BiDownArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: true })
                                        }
                                      />
                                    )}
                                  </th>
                                  <th
                                    onClick={() =>
                                      this.sortingfilter("Pripayer")
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Insurance Name{" "}
                                    {this.state.openicon ? (
                                      <BiUpArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: false })
                                        }
                                      />
                                    ) : (
                                      <BiDownArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: true })
                                        }
                                      />
                                    )}
                                  </th>
                                  <th
                                    onClick={() =>
                                      this.sortingfilter("Pripayerphone")
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Insurance No
                                    {this.state.openicon ? (
                                      <BiUpArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: false })
                                        }
                                      />
                                    ) : (
                                      <BiDownArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: true })
                                        }
                                      />
                                    )}
                                  </th>
                                  <th
                                    onClick={() =>
                                      this.sortingfilter("PrimaryPolicyNo")
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Policy No{" "}
                                    {this.state.openicon ? (
                                      <BiUpArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: false })
                                        }
                                      />
                                    ) : (
                                      <BiDownArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: true })
                                        }
                                      />
                                    )}
                                  </th>
                                  <th
                                    onClick={() =>
                                      this.sortingfilter("Physician")
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Physician Name{" "}
                                    {this.state.openicon ? (
                                      <BiUpArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: false })
                                        }
                                      />
                                    ) : (
                                      <BiDownArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: true })
                                        }
                                      />
                                    )}
                                  </th>
                                  <th
                                    onClick={() => this.sortingfilter("Age")}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Age{" "}
                                    {this.state.openicon ? (
                                      <BiUpArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: false })
                                        }
                                      />
                                    ) : (
                                      <BiDownArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: true })
                                        }
                                      />
                                    )}
                                  </th>
                                  <th
                                    onClick={() => this.sortingfilter("AgeGrp")}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Age Grp{" "}
                                    {this.state.openicon ? (
                                      <BiUpArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: false })
                                        }
                                      />
                                    ) : (
                                      <BiDownArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: true })
                                        }
                                      />
                                    )}
                                  </th>
                                  <th
                                    onClick={() => this.sortingfilter("type")}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Type{" "}
                                    {this.state.openicon ? (
                                      <BiUpArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: false })
                                        }
                                      />
                                    ) : (
                                      <BiDownArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: true })
                                        }
                                      />
                                    )}
                                  </th>
                                  <th
                                    onClick={() =>
                                      this.sortingfilter("ClaimStatus")
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Claim Status{" "}
                                    {this.state.openicon ? (
                                      <BiUpArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: false })
                                        }
                                      />
                                    ) : (
                                      <BiDownArrowAlt
                                        onClick={() =>
                                          this.setState({ openicon: true })
                                        }
                                      />
                                    )}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.searchdataresponse &&
                                  this.state.searchdataresponse.map(
                                    (data, index) => (
                                      <tr key={data._id}>
                                        <td
                                          onClick={() => ((
                                            <ActionViewClaim
                                              value={
                                                sessionStorage.setItem(
                                                  "ClaimID",
                                                  data.ClaimID
                                                ) ||
                                                sessionStorage.setItem(
                                                  "Module",
                                                  "Work Queue"
                                                ) ||
                                                sessionStorage.setItem(
                                                  "ChartID",
                                                  data.ChartID
                                                ) ||
                                                sessionStorage.setItem(
                                                  "MRN",
                                                  data.MRN
                                                ) ||
                                                sessionStorage.setItem(
                                                  "Patient",
                                                  data.Patient
                                                ) ||
                                                sessionStorage.setItem(
                                                  "PatientDOB",
                                                  data.PatientDOB
                                                ) ||
                                                sessionStorage.setItem(
                                                  "Physician",
                                                  data.Physician
                                                ) ||
                                                sessionStorage.setItem(
                                                  "ClaimDate",
                                                  data.ClaimDate
                                                ) ||
                                                sessionStorage.setItem(
                                                  "Pripayer",
                                                  data.Pripayer
                                                ) ||
                                                sessionStorage.setItem(
                                                  "DateOfService",
                                                  data.DateOfService
                                                ) ||
                                                sessionStorage.setItem(
                                                  "ClaimDate",
                                                  data.ClaimDate
                                                ) ||
                                                sessionStorage.setItem(
                                                  "ClaimDBID",
                                                  data._id
                                                ) ||
                                                sessionStorage.setItem(
                                                  "AllocatedTo",
                                                  data.Claimunder
                                                )
                                              }
                                            />
                                          ),(
                                            <Viewmoreeligibility
                                              value={
                                                sessionStorage.setItem(
                                                  "modulename",
                                                  "ActionWorkQueue"
                                                ) ||
                                                sessionStorage.setItem(
                                                  "Eligibilityclaimid",
                                                  data.ClaimID
                                                )
                                              }
                                            />
                                          ))}
                                        >
                                          {this.state.role_type === "Admin" ? (
                                            <SimpleMenu />
                                          ) : (
                                            <ActionSimpleMenu />
                                          )}
                                        </td>

                                        <td>
                                          {data.ClaimID}
                                        </td>
                                        <td>
                                          {data.Location}
                                        </td>
                                        <td>
                                          {data.patientAccountNumber}
                                        </td>
                                        <td>
                                          {data.MRN}
                                        </td>
                                        <td>
                                          {data.DateOfService}
                                        </td>

                                        <td>
                                          {data.PatientFirstName}&nbsp;
                                          {data.PatientLastName}
                                        </td>
                                        <td>
                                          {moment(data.PatientDOB).format("MM/DD/YYYY")}
                                        </td>
                                        <td>
                                          {data.PlanCode}
                                        </td>
                                        <td>
                                          {data.InsuranceName
                                            ? data.InsuranceName
                                            : "-"}
                                        </td>
                                        <td>
                                          <Row>
                                            <Col md="4">
                                              <Twilio
                                                value11={data.Pripayerphone}
                                              />
                                            </Col>
                                            <Col md="2">
                                              {data.Pripayerphone}
                                            </Col>
                                          </Row>
                                        </td>
                                        <td>
                                          {data.PrimaryPolicyNo}
                                        </td>
                                        <td>
                                          {data.Physician}
                                        </td>
                                        <td>
                                          {data.Age}
                                        </td>
                                        <td>
                                          {data.AgeGrp}
                                        </td>
                                        <td>
                                          {data.type}
                                        </td>

                                        <td>
                                          {data.ClaimStatus}
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </Table>
                            </div>
                          <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                            <ReactPaginate
                              previousLabel={<IoIosArrowBack />}
                              nextLabel={<IoIosArrowForward />}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.spageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick1}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                              forcePage={this.state.currentpagesearch}
                            />
                          </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default Actions;
