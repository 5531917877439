import React, { useState } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import { DatePicker } from "antd";
import moment from "moment-timezone";
import Tabs, { Tab } from "react-best-tabs";
import { Input } from "reactstrap";
import Automationatage30 from "./Automationatage30";
import Searchanderror from "./Searchanderror";
import Uploadautomation from "./Uploadautomation";
import UploadedClaims from "./UploadedClaims";

const { RangePicker } = DatePicker;

const Automationtrack = () => {
  const now = new Date();
  const [start, setStart] = useState(moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)));
  const [end, setEnd] = useState(moment(start).add(1, "days").subtract(1, "seconds"));
  const [start1, setStart1] = useState(moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)));
  const [end1, setEnd1] = useState(moment(start).add(1, "days").subtract(1, "seconds"));
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [filterstatus, setFilterstatus] = useState("custom");
  const [tabindex, setTabindex] = useState(1);
  const [startdatetime, setStartdatetime] = useState("");
  const [enddatetime, setEnddatetime] = useState("");
  const [startdatetime1, setStartdatetime1] = useState("");
  const [enddatetime1, setEnddatetime1] = useState("");

  const valuedate = (option, e) => {
    if (option === "yesterday") {
      setStartdate(e.target.value);
      setEnddate(moment().subtract(1, "days").format("MM-DD-YYYY"));
      setFilterstatus(option);
    } else {
      setStartdate(e.target.value);
      setEnddate(moment().format("MM-DD-YYYY"));
      setFilterstatus(option);
    }
  };

  let date1 = moment().subtract(7, "days").format().substring(0, 16);
  let date2 = moment().format().substring(0, 16);
  let value = startdatetime && enddatetime ? `${startdatetime} ~ ${enddatetime}` : `${date1} ~ ${date2}`;
  let value1 = startdatetime1 && enddatetime1 ? `${startdatetime1} ~ ${enddatetime1}` : "Start Date ~ End Date";

  return (
    <div>
      <Newsidebar name="Automation track" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <div className="align-column">
          <br />
          <div>
            <Input
              type="select"
              style={{ width: "20%", float: "right" }}
              className="form-control form-select"
              onChange={(e) => {
                const index = e.target.selectedIndex;
                const el = e.target.childNodes[index];
                const option = el.getAttribute("title");
                valuedate(option, e);
              }}
            >
              <option value="" title="custom">
                Custom
              </option>
              <option value={moment().format("MM-DD-YYYY")} title="">
                Today
              </option>
              <option value={moment().subtract(1, "days").format("MM-DD-YYYY")} title="yesterday">
                Yesterday
              </option>
              <option value={moment().subtract(3, "days").format("MM-DD-YYYY")} title="">
                Last 3 days
              </option>
              <option value={moment().subtract(7, "days").format("MM-DD-YYYY")} title="">
                Last 7 days
              </option>
              <option value={moment().subtract(30, "days").format("MM-DD-YYYY")} title="">
                Last 30 days
              </option>
              <option value={moment().subtract(365, "days").format("MM-DD-YYYY")} title="">
                Last 365 days
              </option>
            </Input>
            {filterstatus === "custom" && (
              <RangePicker
                format="MM-DD-YYYY"
                onChange={(dates, datestring) => {
                  setStartdate(datestring[0]);
                  setEnddate(datestring[1]);
                }}
                allowClear={true}
                width="200%"
                style={{
                  borderColor: "gray",
                  float: "right",
                  marginRight: "2%",
                }}
              />
            )}
          </div>

          <div style={{ paddingTop: "1%" }}>
            <Tabs
              activeTab="1"
              activityClassName="text-secondary"
              style={{ margin: "5%" }}
              onClick={(event, tab) => setTabindex(tab)}
            >
              <Tab title="Follow Up Rundate">
                <Automationatage30 startdate={startdate} enddate={enddate} />
              </Tab>
              <Tab title="Automate Follow Up-date">
                <Uploadautomation startdate={startdate} enddate={enddate} />
              </Tab>
              <Tab title="Searches and Error">
                <Searchanderror startdate={startdate} enddate={enddate} />
              </Tab>
              <Tab title="Uploaded Claims">
                <UploadedClaims startdate={startdate} enddate={enddate} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Automationtrack;
