import React, { useContext, useEffect, useState } from "react";
import Login from "./Login";
import "./App.css";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import Forgotpassword from "./Forgotpassword";
import DailyReport from "./Components/Management/Reports/DailyReport";
import ClaimwiseProduction from "./Components/Management/Reports/ClaimwiseProduction";
import OpenedClaims from "./Components/Management/Reports/OpenedClaims";
import DatabaseSync from "./Components/Data/DatabaseSync";
import DataCleaning from "./Components/Data/DataCleaning";
import ApiSync from "./Components/Data/ApiSync";
import Data from "./Components/Data/Data";
import Admin from "./Components/Admin/Admin";
import UserSubscription from "./Components/Admin/UserSubscription";
import Logout from "./Logout";
import PayerCategory from "./Components/Configure/PayerCategory";
import PayerMaster from "./Components/Configure/PayerMaster";
import PrimaryPayer from "./Components/Configure/PrimaryPayer";
import Ageing from "./Components/Configure/Ageing";
import Cpt from "./Components/Configure/Cpt";
import ARmanagement from "./Components/Management/InventoryAnalysis";
import DenialManagement from "./Components/Transaction/DenialManagement";
import Collection from "./Components/AI/Collection";
import AiBot from "./Components/AI/AiBot";
import ActionManagement from "./Components/Management/Actions";
import Actions from "./Components/Actions/Actions";
import Roles from "./Components/Roles/Roles";
import Performances from "./Components/Performances/Performances";
import tflExceed from "./Components/Transaction/AllocationCardComponents/tflExceed";
import Modifiers from "./Components/Configure/Modifiers";
import ViewClaim from "./Components/Transaction/AllocationCardComponents/ViewClaim";
import ARfollowUpUpdate from "./Components/Transaction/AllocationCardComponents/ARfollowUpUpdate";
import SubCategory from "./Components/Transaction/SubDenial/SubCategory";
import SubDenial from "./Components/Transaction/SubDenial/SubDenial";
import DenialDetails from "./Components/Transaction/SubDenial/DenialDetails";
import Denialallocatedtable from "./Components/Transaction/SubDenial/Denialallocatedtable";
import Denialunallocatedtable from "./Components/Transaction/SubDenial/Denialunallocatedtable";
import Denialtableallocate1 from "./Components/Transaction/SubDenial/Denialtableallocate1";
import Denialtableallocate2 from "./Components/Transaction/SubDenial/Denialtableallocate2";
import Denialtableallocate3 from "./Components/Transaction/SubDenial/Denialtableallocate3";
import Allocated from "./Components/Allocation/Allocated";
import UnAllocated from "./Components/Allocation/UnAllocated";
import FastApproaching from "./Components/Transaction/AllocationCardComponents/FastApproaching";
import TwoTouchClaims from "./Components/Transaction/AllocationCardComponents/TwoTouchClaims";
import ThreeTouchClaims from "./Components/Transaction/AllocationCardComponents/ThreeTouchClaims";
import NotFollowedUpMoreThan30Days from "./Components/Transaction/AllocationCardComponents/NotFollowedUpMoreThan30Days";
import NotFollowUpMoreThan60Days from "./Components/Transaction/AllocationCardComponents/NotFollowUpMoreThan60Days";
import NotFollowUpMoreThan180Days from "./Components/Transaction/AllocationCardComponents/NotFollowUpMoreThan180Days";
import NotFollowUpMoreThan90Days from "./Components/Transaction/AllocationCardComponents/NotFollowUpMoreThan90Days";
import LessThan30daysClaims from "./Components/Transaction/AllocationCardComponents/LessThan30DaysNotFollow";
import DenialClaim from "./Components/Transaction/SubDenial/DenialClaim";
import DataUpload from "./Components/Eligibility/DataUpload";
import AccessRoute from "./Components/routepage/AccessRoute";
import DenialMaster from "./Components/Configure/Denialmaster";
import CheckAutoClaim from "./Components/Transaction/AllocationCardComponents/CheckAutoClaim";
import Eligibility from "./Components/Transaction/AllocationCardComponents/Eligibility";
import ActionViewClaim from "./Components/Actions/ActionsComponents/ActionViewClaim";
import ActionEligibility from "./Components/Actions/ActionsComponents/ActionEligibility";
import ActionCheckAutoClaim from "./Components/Actions/ActionsComponents/ActionCheckAutoClaim";
import AgedPaymentCharts from "./Components/Management/TrendsCharts/AgedPaymentCharts";
import ARPrimaryRisk from "./Components/Management/TrendsCharts/ARPrimaryRisk";
import BillingPayment from "./Components/Management/TrendsCharts/BillingPayment";
import TimeGapChart from "./Components/Management/TrendsCharts/TimeGapChart";
import PayerAnalysis from "./Components/Management/TrendsCharts/PayerAnalysis";
import PatientPrimaryAR from "./Components/Management/TrendsCharts/PatientPrimaryAR";
import TopPayers from "./Components/Management/TrendsCharts/TopPayers";
import TopCpt from "./Components/Management/TrendsCharts/TopCpt";
import TopCptPerformance from "./Components/Management/TrendsCharts/TopCptPerformance";
import AgedAnalysis from "./Components/Management/TrendsCharts/AgedAnalysis";
import ConfigureList from "./Components/Configure/ConfigureList";
import DenialPayment from "./Components/Transaction/SubDenial/DenialPayment";
import UserNeedtocallPage from "./Components/Actions/UserNeedtocallPage";
import TrendsPatterns from "./Components/Management/TrendsPatterns";
import CardAlloc from "./Components/Transaction/CardAlloc";
import UserCompletedPage from "./Components/Actions/UserCompletedPage";
import UserInprogressPage from "./Components/Actions/UserInprogressPage";
import UserDenialPage from "./Components/Actions/UserDenialPage";
import BillingProvider from "./Components/Configure/BillingProvider";
import AdvBillingProvider from "./Components/Configure/AdvBillingProv";
import UserDetails from "./Components/Configure/UserDetails";
import Twilio from "./Components/Transaction/Twilio";
import FourOrMoreTouchClaims from "./Components/Transaction/AllocationCardComponents/FourOrMoreTouch";
import LowFollowupClaims from "./Components/Transaction/AllocationCardComponents/LowFollowup";
import LessThan180daysClaims from "./Components/Transaction/AllocationCardComponents/LessThan180DaysClaims";
import LessThan30DaysNotFollow from "./Components/Transaction/AllocationCardComponents/LessThan30DaysNotFollow";
import PerformanceDashboard from "./Components/Management/Outcomes";
import HealthScorecard from "./Components/Management/IndustryStandards";
import SubCategory1 from "./Components/Transaction/SubDenial/SubCategory1";
import SubCategory2 from "./Components/Transaction/SubDenial/SubCategory2";
import SubCategory3 from "./Components/Transaction/SubDenial/SubCategory3";
import SubCategory4 from "./Components/Transaction/SubDenial/SubCategory4";
import SubCategory5 from "./Components/Transaction/SubDenial/SubCategory5";
import SubCategory6 from "./Components/Transaction/SubDenial/SubCategory6";
import SubCategory7 from "./Components/Transaction/SubDenial/SubCategory7";
import SubCategory8 from "./Components/Transaction/SubDenial/SubCategory8";
import SubCategory9 from "./Components/Transaction/SubDenial/SubCategory9";
import ManagementUserPerformanceDashboard from "./Components/Management/ManagementUserPerformanceDashboard";
import DenialPrediction from "./Components/AI/DenialPrediction";
import ClaimManagement from "./Components/Transaction/ClaimManagement";
import Acknowledgements from "./Components/Transaction/ClaimManagement/Acknowledgements";
import AdditionalInformation from "./Components/Transaction/ClaimManagement/AdditionalInformation";
import DataReporting from "./Components/Transaction/ClaimManagement/DataReporting";
import Finalized from "./Components/Transaction/ClaimManagement/Finalized";
import Pending from "./Components/Transaction/ClaimManagement/Pending";
import Errors from "./Components/Transaction/ClaimManagement/Errors";
import Searches from "./Components/Transaction/ClaimManagement/Machine1Searches";
import ClaimSubCategoryDetails from "./Components/Transaction/ClaimManagement/ClaimSubCategoryDetails";
import DenialPredictionDashboard from "./Components/AI/DenialPredictionDashboard";
import CollectionPredictionDashboard from "./Components/AI/CollectionPredictionDashboard";
import ClaimStatus from "./Components/Configure/ClaimStatus";
import ClaimCategory from "./Components/Configure/ClaimCategory";
import ClaimSubCategory from "./Components/Configure/ClaimSubCategory";
import ActionCode from "./Components/Configure/ActionCode";
import StatusActionCode from "./Components/Configure/StatusActionCode";
import DataConversionOCR from "./Components/Data/DataConversionOCR";
import WebClaims from "./Components/Claims/WebClaims";
import IVRClaims from "./Components/Claims/IVRClaims";
import ManualClaims from "./Components/Claims/ManualClaims";
import ClientOnboard from "./Components/SuperAdmin/ClientOnboard";
import { clientNotes } from "./Components/Configure/clientNotes";
import Locations from "./Components/Configure/Locations";
import Reports from "./Components/Management/Reports";
import UserNotTouchPage from "./Components/Actions/UserNotTouchPage";
import Rejection from "./Components/Allocation/Rejection";
import ActionRejection from "./Components/Actions/ActionRejection";
import OpenClaims from "./Components/Actions/OpenClaims";
import ClosedClaims from "./Components/Actions/ClosedClaims";
import DataMigration from "./Components/Data/DataMigration";
import Claimsubcategoryunallocate1 from "./Components/Transaction/ClaimManagement/Claimsubcategoryunallocate1";
import Claimtablenottouchweb1 from "./Components/Transaction/ClaimManagement/Claimtablenottouchweb1";
import Claimtablenottouchweb2 from "./Components/Transaction/ClaimManagement/Claimtablenottouchweb2";
import Claimtablenottouchweb3 from "./Components/Transaction/ClaimManagement/Claimtablenottouchweb3";
import AgeGreaterThan365days from "./Components/Transaction/AllocationCardComponents/AgeGreaterThan365days";
import AgeBetween181to365Days from "./Components/Transaction/AllocationCardComponents/AgeBetween181to365Days";
import AgeBetween91to180Days from "./Components/Transaction/AllocationCardComponents/AgeBetween91to180Days";
import AgeBetween61to90Days from "./Components/Transaction/AllocationCardComponents/AgeBetween61and90Days";
import AgeBetween31to60Days from "./Components/Transaction/AllocationCardComponents/AgeBetween31to60Days";
import OneTouchClaims from "./Components/Transaction/AllocationCardComponents/OneTouchClaims";
import NotTouchClaims from "./Components/Transaction/AllocationCardComponents/NotTouchClaims";
import FourTouchClaims from "./Components/Transaction/AllocationCardComponents/FourTouchClaims";
import OutcomeData from "./Components/Eligibility/OutcomeData";
import Viewmoreeligibility from "./Components/Eligibility/Viewmoreeligibility";
import ActionBucket from "./Components/Management/ActionBucket";
import KeyUnallocated from "./Components/Transaction/AllocationCardComponents/KeyUnallocated";
import Keyallocated from "./Components/Transaction/AllocationCardComponents/Keyallocated";
import Actionunallocated from "./Components/Actions/Actionunallocated";
import Actionallocated1 from "./Components/Management/Actionallocated1";
import Automationtrack from "./Components/Admin/Automationtrack/Automationtrack";
import Automationatage30 from "./Components/Admin/Automationtrack/Automationatage30";
import Searchanderror from "./Components/Admin/Automationtrack/Searchanderror";
import Uploadautomation from "./Components/Admin/Automationtrack/Uploadautomation";
import Nextfollow from "./Components/Admin/Automationtrack/Nextfollow";
import Keyallocated1 from "./Components/Transaction/AllocationCardComponents/Keyallocated1";
import Keyallocated2 from "./Components/Transaction/AllocationCardComponents/Keyallocated2";
import Actionallocated3 from "./Components/Management/Actionallocated3";
import Actionallocated2 from "./Components/Management/Actionallocated2";
import Authorization from "./Components/Eligibility/Authorization";
import EligibilityCheck from "./Components/Eligibility/EligibilityCheck";
import AuthForm from "./Components/Eligibility/AuthForm";
import PriorAuth from "./Components/Eligibility/PriorAuth";
import Icd from "./Components/Configure/Icd";
import RefProvider from "./Components/Configure/RefProvider";
import Updates from "./Components/Configure/Updates";
import EditForm from "./Components/Eligibility/EditForm";
import WebclaimAllocate from "./Components/Claims/WebclaimAllocate";
import IvrclaimAllocate from "./Components/Claims/IvrclaimAllocate";
import ManualclaimAllocate from "./Components/Claims/ManualclaimAllocate";
import Unallocatedclaim from "./Components/Claims/Unallocateclaim";
import ChangePassword from "./ChangePassword";
import setQues from "./setQues";
import DataDelete from "./Components/Data/DataDelete";
import UnpaidClaims from "./Components/Management/TrendsCharts/Unpaidclaims";
import Errors2 from "./Components/Transaction/ClaimManagement/Machine2Error";
import Searches2 from "./Components/Transaction/ClaimManagement/Machine2Searches";
import GeneralSettings from "./Components/Configure/GeneralSettings";
import RejectionUpload from "./Components/Configure/RejectionUpload";
import AdvancedMD from "./Components/Data/AdvancedMD";
import Machine1Error from "./Components/Transaction/ClaimManagement/Machine1Error";
import Machine1Searches from "./Components/Transaction/ClaimManagement/Machine1Searches";
import Machine2Error from "./Components/Transaction/ClaimManagement/Machine2Error";
import Machine2Searches from "./Components/Transaction/ClaimManagement/Machine2Searches";
import noteType from "./Components/Configure/noteType";
import { UserActivityProvider } from "./Components/Utilities/UserActivityProvider";
import PageTracker from "./Components/Utilities/PageTracker";
import history from "./Components/Utilities/history";
import ClaimCheck from "./Components/Management/ClaimCheck";
import { listenToSocketEvents, disconnectSocket } from "./socket"; // Import your socket setup
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import the toast CSS
import { SocketProvider } from "./Components/Context/socketContext";
function App() {
  useEffect(() => {
    console.log("Current Path:", history.location.pathname);
  }, []);
  return (
    <div className="App">
      <BrowserRouter history={history}>
        <SocketProvider>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/forgotpassword" component={Forgotpassword} />
            <AccessRoute path="/claim-management" component={ClaimManagement} />
            <AccessRoute path="/single-claim-check" component={ClaimCheck} />
            <AccessRoute path="/client-onboard" component={ClientOnboard} />
            <AccessRoute
              path="/claim/acknowledgements"
              component={Acknowledgements}
            />
            <AccessRoute
              path="/claim/data reporting acknowledgements"
              component={DataReporting}
            />
            <AccessRoute path="/claim/finalized" component={Finalized} />
            <AccessRoute path="/claim/pending" component={Pending} />
            <AccessRoute
              path="/claim/requests for additional information"
              component={AdditionalInformation}
            />
            <AccessRoute path="/claim/error" component={Errors} />
            <AccessRoute
              path="/error/machine1 error"
              component={Machine1Error}
            />
            <AccessRoute
              path="/error/machine1 searches"
              component={Machine1Searches}
            />
            <AccessRoute
              path="/error/machine2 Error"
              component={Machine2Error}
            />
            <AccessRoute
              path="/error/machine2 Searches"
              component={Machine2Searches}
            />
            <AccessRoute path="/twilio" component={Twilio} />
            <AccessRoute path="/claim/searches" component={Searches} />
            <AccessRoute
              path="/claim/sub-category-details"
              component={ClaimSubCategoryDetails}
            />
            <AccessRoute path="/claim/availity error" component={Errors2} />
            <AccessRoute
              path="/claim/availity searches"
              component={Searches2}
            />
            <AccessRoute
              path="/management-outcomes"
              component={PerformanceDashboard}
            ></AccessRoute>
            <AccessRoute
              path="/management-actions"
              component={ActionManagement}
            ></AccessRoute>
            <AccessRoute
              path="/management-industry-standards"
              component={HealthScorecard}
            ></AccessRoute>
            <AccessRoute
              path="/management-userperformance-dashboard"
              component={ManagementUserPerformanceDashboard}
            ></AccessRoute>
            <AccessRoute
              path="/management-inventory-analysis"
              component={ARmanagement}
            />
            <AccessRoute path="/action-bucket" component={ActionBucket} />
            <AccessRoute path="/generate-reports" component={Reports} />
            <AccessRoute
              path="/daily-report-prodution"
              component={{ DailyReport }}
            />
            <AccessRoute
              path="/automation-at-age-30"
              component={{ Automationatage30 }}
            />
            <AccessRoute
              path="/claim wise-production"
              component={{ ClaimwiseProduction }}
            />
            <AccessRoute path="/opened-claims" component={{ OpenedClaims }} />
            <AccessRoute
              path="/denial-prediction"
              component={DenialPrediction}
            />
            <AccessRoute
              path="/denial-prediction-dashboard"
              component={DenialPredictionDashboard}
            />
            <AccessRoute
              path="/collection-prediction-dashboard"
              component={CollectionPredictionDashboard}
            />
            <AccessRoute
              path="/denial-management"
              component={DenialManagement}
            />
            <AccessRoute path="/allocation-buckets" component={CardAlloc} />
            <AccessRoute path="/data" component={Data} />
            <AccessRoute path="/claims/Web" component={WebClaims} />
            <AccessRoute path="/Web/Allocate" component={WebclaimAllocate} />
            <AccessRoute path="/IVR/Allocate" component={IvrclaimAllocate} />
            <AccessRoute
              path="/Manual/Allocate"
              component={ManualclaimAllocate}
            />
            <AccessRoute
              path="/unallocatedclaims"
              component={Unallocatedclaim}
            />
            <AccessRoute path="/claims/IVR" component={IVRClaims} />
            <AccessRoute path="/claims/Manual" component={ManualClaims} />
            <AccessRoute
              path="/eligibility/Dataupload"
              component={DataUpload}
            />
            <AccessRoute
              path="/eligibility/Outcomedata"
              component={OutcomeData}
            />
            <AccessRoute path="/eligibility/Auth" component={Authorization} />
            <AccessRoute path="/eligibility/PriorAuth" component={PriorAuth} />
            <AccessRoute path="/eligibility/AuthForm" component={AuthForm} />
            <AccessRoute path="/eligibility/EditForm" component={EditForm} />
            <AccessRoute
              path="/eligibility/Check"
              component={EligibilityCheck}
            />
            <AccessRoute path="/allocate/rejection" component={Rejection} />
            <AccessRoute
              path="/dataconversionocr"
              component={DataConversionOCR}
            />
            <AccessRoute path="/dataCleaning" component={DataCleaning} />
            <AccessRoute path="/dataDeleting" component={DataDelete} />
            <AccessRoute path="/datamigration" component={DataMigration} />
            <AccessRoute path="/admin/useronboard" component={Admin} />
            <AccessRoute
              path="/admin/usersubscription"
              component={UserSubscription}
            />
            <AccessRoute path="/database-sync" component={DatabaseSync} />
            <AccessRoute path="/api-sync" component={ApiSync} />
            <AccessRoute path="/apisync/advancedmd" component={AdvancedMD} />
            <AccessRoute
              path="/configure/claim-subcategory"
              component={ClaimSubCategory}
            />
            <AccessRoute path="/configure/action-code" component={ActionCode} />
            <AccessRoute path="/configure/note-types" component={noteType} />
            <AccessRoute
              path="/configure/status-action-code"
              component={StatusActionCode}
            />
            <AccessRoute
              path="/configure/claim-category"
              component={ClaimCategory}
            />
            <AccessRoute
              path="/configure/claim-status"
              component={ClaimStatus}
            />
            <AccessRoute
              path="/configure/client-notes"
              component={clientNotes}
            />
            <AccessRoute
              path="/configure/general-settings"
              component={GeneralSettings}
            />
            <AccessRoute
              path="/configure/self-upload"
              component={RejectionUpload}
            />
            <AccessRoute path="/configure/location" component={Locations} />
            <AccessRoute path="/configure/icd" component={Icd} />
            <AccessRoute path="/configure/icd" component={Icd} />
            <AccessRoute
              path="/configure/refprovider"
              component={RefProvider}
            />
            <AccessRoute path="/configure/newlyupdates" component={Updates} />
            <AccessRoute
              path="/configure/payer-category"
              component={PayerCategory}
            />
            <AccessRoute
              path="/configure/payer-master"
              component={PayerMaster}
            />
            <AccessRoute
              path="/configure/primary-payer"
              component={PrimaryPayer}
            />
            <AccessRoute path="/configure/cpt" component={Cpt} />
            <AccessRoute path="/configure/modifiers" component={Modifiers} />
            <AccessRoute
              path="/configure/denial-master"
              component={DenialMaster}
            />
            <AccessRoute
              path="/configure/configure-list"
              component={ConfigureList}
            />
            <AccessRoute path="/configure/ageing" component={Ageing} />
            <AccessRoute path="/performances" component={Performances} />
            <AccessRoute path="/change_password" component={ChangePassword} />
            <AccessRoute path="/setAnswers" component={setQues} />
            <AccessRoute path="/actions" component={Actions} />
            <AccessRoute
              path="/action-view-claim"
              component={ActionViewClaim}
            />
            <AccessRoute
              path="/action-rejection-denials"
              component={ActionRejection}
            />
            <AccessRoute path="/open-claims" component={OpenClaims} />
            <AccessRoute path="/closed-claims" component={ClosedClaims} />
            <AccessRoute
              path="/keyindicatorallocated"
              component={Keyallocated}
            />
            <AccessRoute
              path="/actionunallocated"
              component={Actionunallocated}
            />
            <AccessRoute
              path="/actionallocated1"
              component={Actionallocated1}
            />
            <AccessRoute
              path="/actionallocated2"
              component={Actionallocated2}
            />
            <AccessRoute
              path="/actionallocated3"
              component={Actionallocated3}
            />
            <AccessRoute
              path="/keyindicatorallocated1"
              component={Keyallocated1}
            />
            <AccessRoute
              path="/keyindicatorallocated2"
              component={Keyallocated2}
            />
            <AccessRoute
              path="/action-eligibility"
              component={ActionEligibility}
            />
            <AccessRoute
              path="/action-check-auto-claim"
              component={ActionCheckAutoClaim}
            />
            <AccessRoute
              path="/user-completed-page"
              component={UserCompletedPage}
            />
            <AccessRoute
              path="/user-inprogress-page"
              component={UserInprogressPage}
            />
            <AccessRoute path="/user-denial-page" component={UserDenialPage} />
            <AccessRoute
              path="/user-nottouch-page"
              component={UserNotTouchPage}
            />
            <AccessRoute
              path="/user-needtocall-page"
              component={UserNeedtocallPage}
            />
            <AccessRoute path="/roles" component={Roles} />
            <AccessRoute path="/ai-bot" component={AiBot} />
            <AccessRoute path="/TFLexceed" component={tflExceed} />
            <AccessRoute
              path="/Keyindicatorunallocate"
              component={KeyUnallocated}
            />
            <AccessRoute path="/Fast-Approaching" component={FastApproaching} />
            <AccessRoute path="/TwoTouch-Claims" component={TwoTouchClaims} />
            <AccessRoute
              path="/ThreeTouch-Claims"
              component={ThreeTouchClaims}
            />
            <AccessRoute
              path="/four-or-more-touch"
              component={FourOrMoreTouchClaims}
            />
            <AccessRoute path="/lowfollow-up" component={LowFollowupClaims} />
            <AccessRoute
              path="/less-than-180days-claims"
              component={LessThan180daysClaims}
            />
            <AccessRoute
              path="/more-than-60days-notfollow"
              component={NotFollowUpMoreThan60Days}
            />
            <AccessRoute
              path="/more-than-180days-notfollow"
              component={NotFollowUpMoreThan180Days}
            />
            <AccessRoute
              path="/more-than-90days-notfollow"
              component={NotFollowUpMoreThan90Days}
            />
            <AccessRoute
              path="/more-than-30days-notfollow"
              component={NotFollowedUpMoreThan30Days}
            />
            <AccessRoute
              path="/less-than-30days-NotFollow"
              component={LessThan30DaysNotFollow}
            />
            <AccessRoute
              path="/agegreaterthan365days"
              component={AgeGreaterThan365days}
            />
            <AccessRoute
              path="/age-between-181-365-days"
              component={AgeBetween181to365Days}
            />
            <AccessRoute
              path="/age-between-91-180-days"
              component={AgeBetween91to180Days}
            />
            <AccessRoute
              path="/age-between-61-90-days"
              component={AgeBetween61to90Days}
            />
            <AccessRoute
              path="/age-between-31-60-days"
              component={AgeBetween31to60Days}
            />
            <AccessRoute path="/one-touch-claims" component={OneTouchClaims} />
            <AccessRoute path="/zero-touch-claims" component={NotTouchClaims} />
            <AccessRoute
              path="/four-touch-claims"
              component={FourTouchClaims}
            />
            <AccessRoute path="/view-claim" component={ViewClaim} />
            {/* <AccessRoute path="/manage-payment" component={ManagePayment}/> */}
            <AccessRoute
              path="/ar-follow-up-update"
              component={ARfollowUpUpdate}
            />
            <AccessRoute path="/eligibility" component={Eligibility} />
            <AccessRoute
              path="/viewmoreeligibility"
              component={Viewmoreeligibility}
            />
            <AccessRoute
              path="/management-trends-patterns"
              component={TrendsPatterns}
            />
            <AccessRoute
              path="/trends/aged-payment-charts"
              component={AgedPaymentCharts}
            />
            <AccessRoute path="/trends/Unpaidclaims" component={UnpaidClaims} />
            <AccessRoute
              path="/trends/primary-risk"
              component={ARPrimaryRisk}
            />
            <AccessRoute
              path="/trends/billing-payment"
              component={BillingPayment}
            />
            <AccessRoute path="/trends/time-gap-dos" component={TimeGapChart} />
            <AccessRoute
              path="/trends/payer-analysis"
              component={PayerAnalysis}
            />
            <AccessRoute
              path="/trends/patient-primary-ar"
              component={PatientPrimaryAR}
            />
            <AccessRoute path="/trends/top10-cpt" component={TopCpt} />
            <AccessRoute path="/trends/top10-payers" component={TopPayers} />
            <AccessRoute
              path="/trends/top-cpt-performance"
              component={TopCptPerformance}
            />
            <AccessRoute
              path="/trends/aged-payer-analysis"
              component={AgedAnalysis}
            />
            <AccessRoute path="/denial/category" component={SubDenial} />
            <AccessRoute path="/denial/sub-category" component={SubCategory} />
            <AccessRoute
              path="/denial/sub-category1"
              component={SubCategory1}
            />
            <AccessRoute
              path="/denial/sub-category2"
              component={SubCategory2}
            />
            <AccessRoute
              path="/denial/sub-category3"
              component={SubCategory3}
            />
            <AccessRoute
              path="/denial/sub-category4"
              component={SubCategory4}
            />
            <AccessRoute
              path="/denial/sub-category5"
              component={SubCategory5}
            />
            <AccessRoute
              path="/denial/sub-category6"
              component={SubCategory6}
            />
            <AccessRoute
              path="/denial/sub-category7"
              component={SubCategory7}
            />
            <AccessRoute
              path="/denial/sub-category8"
              component={SubCategory8}
            />
            <AccessRoute
              path="/denial/sub-category9"
              component={SubCategory9}
            />
            <AccessRoute
              path="/denial/denial-details"
              component={DenialDetails}
            />
            <AccessRoute
              path="/Transaction/SubDenial/Denialallocatedtable"
              component={Denialallocatedtable}
            />
            <AccessRoute
              path="/Transaction/SubDenial/Denialunallocatedtable"
              component={Denialunallocatedtable}
            />
            <AccessRoute
              path="/Transaction/SubDenial/Denialtableallocate1"
              component={Denialtableallocate1}
            />
            <AccessRoute
              path="/Transaction/SubDenial/Denialtableallocate2"
              component={Denialtableallocate2}
            />
            <AccessRoute
              path="/Transaction/SubDenial/Denialtableallocate3"
              component={Denialtableallocate3}
            />
            <AccessRoute path="/automation-track" component={Automationtrack} />
            <AccessRoute
              path="/search and error automation"
              component={Searchanderror}
            />
            <AccessRoute
              path="/upload automation"
              component={Uploadautomation}
            />
            <AccessRoute
              path="/next follow automation"
              component={Nextfollow}
            />
            <AccessRoute path="/allocate/allocated" component={Allocated} />
            <AccessRoute path="/allocate/unallocated" component={UnAllocated} />
            <AccessRoute
              path="/Transaction/ClaimManagement/Claimsubcategoryunallocate1"
              component={Claimsubcategoryunallocate1}
            />
            <AccessRoute
              path="/Transaction/ClaimManagement/Claimtablenottouchweb1"
              component={Claimtablenottouchweb1}
            />
            <AccessRoute
              path="/Transaction/ClaimManagement/Claimtablenottouchweb2"
              component={Claimtablenottouchweb2}
            />
            <AccessRoute
              path="/Transaction/ClaimManagement/Claimtablenottouchweb3"
              component={Claimtablenottouchweb3}
            />
            <AccessRoute path="/denialclaim" component={DenialClaim} />
            <AccessRoute path="/denial-payment" component={DenialPayment} />
            <AccessRoute
              path="/configure/denial-master"
              component={DenialMaster}
            />
            <AccessRoute path="/check-auto-claim" component={CheckAutoClaim} />
            <AccessRoute
              path="/configure/billing-provider"
              component={BillingProvider}
            />
            <AccessRoute
              path="/configure/advance-billing-provider"
              component={AdvBillingProvider}
            />
            <AccessRoute
              path="/configure/user-details"
              component={UserDetails}
            />
            <Redirect to="/logout" />
          </Switch>
        </SocketProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
