import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Row, Col, Table } from "react-bootstrap";
import datafile from "../../Files/claimwiseandcptwise.xlsx";
import Newsidebar from "../Dashboard/Newsidebar";
import { ip } from "../Api";
import upload from "../../Images/upload.png";
import excel from "../../Images/excel.png";
import { IoMdClose } from "react-icons/io";
const DataMigration = () => {
  const [file, setFile] = useState(null);
  const [accesstoken] = useState(sessionStorage.getItem("accesstoken"));
  const [client] = useState(sessionStorage.getItem("clientid"));
  const [username] = useState(
    sessionStorage.getItem("firstname") + sessionStorage.getItem("lastname")
  );
  const [offset, setOffset] = useState(0);
  const [currentpage, setCurrentPage] = useState(0);
  const [perPage] = useState(10);
  const [datamigrationdata, setDatamigrationdata] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const fileInput = useRef(null);

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const onFileUpload = async (e) => {
    if (!file) {
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }
    e.preventDefault();

    const value = new FormData();
    value.append("file", fileInput.current.files[0]);
    value.append("clientids", client);
    value.append("Username", username);

    const headers = {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ${accesstoken}`,
    };

    try {
      const response = await axios.post(`${ip}/helper/datamigration`, value, {
        headers: headers,
      });

      if (response.status === 200) {
        toast.success("File uploaded successfully", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (err) {
      if (err.response) {
        const { status } = err.response;
        if (status === 500) {
          toast.error("Server Error", {
            position: "top-right",
            autoClose: 5000,
          });
        } else if (status === 401) {
          toast.error("Please reload and try again", {
            position: "top-right",
            autoClose: 5000,
          });
        } else if (status === 400) {
          toast.error("Please include all the fields in excel data", {
            position: "top-right",
            autoClose: 5000,
          });
        } else {
          toast.error("An unknown error occurred", {
            position: "top-right",
            autoClose: 5000,
          });
        }
      }
    }
  };

  const getdatamigrationdata = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };

    try {
      const res = await axios.get(
        `${ip}/helper/datamigrationlog?clientId=${client}&pageno=${currentpage}&Username=${username}`,
        { headers: headers }
      );

      setPageCount(Math.ceil(res.data.count / perPage));
      setDatamigrationdata(res.data.arfollowuplog);
    } catch (err) {
      // handle error
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const newOffset = selectedPage * perPage;

    setCurrentPage(selectedPage);
    setOffset(newOffset);
  };

  useEffect(() => {
    getdatamigrationdata();
  }, [currentpage]);

  return (
    <div>
      <Newsidebar name="Data Migration" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <ToastContainer />
        <input
          type="file"
          id="file"
          ref={fileInput}
          onChange={onFileChange}
          style={{ display: "none" }}
          accept=".xlsx"
        />
        {file ? (
          <div className="file-card-upload">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                flexDirection: "column",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <div className="file-card" style={{ position: "relative" }}>
                  <span>
                    <img
                      src={excel}
                      alt="excel"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </span>
                  <div className="file-info">
                    <div style={{ flex: 1 }}>
                      <h6>{file.name}</h6>
                    </div>
                  </div>
                  <button
                    className="close-icon"
                    onClick={() => setFile(null)} // Replace setFile with your state update function
                  >
                    <IoMdClose />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="file-btn" onClick={() => fileInput.current.click()}>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <img
                src={upload}
                alt="upload"
                style={{ width: "90px", height: "90px" }}
              />
              <div>
                <p style={{ margin: 0 }}>Click file to this area to upload</p>
                <span style={{ color: "GrayText", fontSize: "11px" }}>
                  The given input should be in .xlsx format
                </span>
              </div>
            </div>
          </div>
        )}
        <div className="upload_container1">
          <div className="upload_head">
            <div className="vertical" />
            <span style={{ fontWeight: "800", fontSize: "18px" }}>
              Data Migration History
            </span>
          </div>
          <div className="btn-group" role="group">
            <button
              type="button"
              className="btn"
              style={{ backgroundColor: "#041c3c", color: "#fff" }}
            >
              <Link
                to={datafile}
                download="DataMigration.xlsx"
                target="_blank"
                style={{ textDecoration: "none", color: "#a8adad" }}
              >
                Sample
              </Link>
            </button>
            <button
              type="button"
              className="btn"
              style={{ backgroundColor: "#7DD657", color: "#fff" }}
              onClick={onFileUpload}
            >
              Upload
            </button>
          </div>
        </div>
        <hr />
        {datamigrationdata.length !== 0 ? (
          <div>
            <Table bordered className="StandardTable">
              <thead>
                <tr>
                  <th>Claim Id</th>
                  <th>User Id</th>
                  <th>Date of modification</th>
                </tr>
              </thead>
              <tbody>
                {datamigrationdata.map((data, index) => (
                  <tr key={index}>
                    <td>{data.ClaimID}</td>
                    <td>{data.UserId}</td>
                    <td>{data.Eventdate}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                forcePage={currentpage}
              />
            </div>
          </div>
        ) : (
          <p
            style={{
              fontSize: "20px",
              fontStyle: "italic",
              textAlign: "center",
            }}
          >
            No Data Found
          </p>
        )}
      </div>
    </div>
  );
};

export default DataMigration;
