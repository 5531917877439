import React, { useState, useEffect, useRef, useMemo } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import { ip, secretkey } from "../../Api";
import aes256 from "aes256";
import axios from "axios";
import { Link } from "react-router-dom";
import fileformat from "../../Files/Upload_Rejection.xlsx";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import {
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import upload from "../../Images/upload.png";
import excel from "../../Images/excel.png";
import { IoMdClose } from "react-icons/io";


const RejectionUpload = () => {
  const fileInput = useRef(null);
  const [file, setFile] = useState(null);
  const [offset, setOffset] = useState(0);
  const [currentpage, setCurrentpage] = useState(0);
  const [perPage] = useState(10);
  const [filedata, setFiledata] = useState([]);
  const [uploaddetails, setUploaddetails] = useState(false);
  const [pageCount, setPageCount] = useState(0);
 // Memoized client value to avoid unnecessary re-rendering
 const client = useMemo(() => sessionStorage.getItem("clientid"), []);
 const [accesstoken, setAccesstoken] = useState(sessionStorage.getItem("accesstoken"));
 const refreshtoken = useMemo(() => sessionStorage.getItem("refreshtoken"), []);

 const handlePageClick = (e) => {
  const selectedPage = e.selected;
  const newOffset = selectedPage * perPage;
  setCurrentpage(selectedPage);
  setOffset(newOffset);
  debouncedGetDetails(selectedPage); // Pass the selectedPage instead of relying on state
};

  const renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshtoken}`,
    };
    try {
      const renew = await axios.get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      });
      sessionStorage.setItem("accesstoken", renew.data.accessToken);
      setAccesstoken(renew.data.accessToken);
    } catch (err) {
      console.error(err);
    }
  };

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const clearFile = () => {
    setFile(null);
  };

  const onFileUpload = async (e) => {
    if (!file) {
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    e.preventDefault();
    const value = new FormData();
    value.append("file", fileInput.current.files[0]);
    value.append("clientids", client);

    const headers = {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ${accesstoken}`,
    };

    try {
      const response = await axios.post(
        `${ip}/organizationdetails/bulk/upload`,
        value,
        { headers }
      );
      if (response.status === 200) {
        toast.success("File uploaded successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        debouncedGetDetails();
      }
    } catch (err) {
      if (err.response) {
        handleErrors(err.response.status);
      }
    }
  };

  const handleErrors = (status) => {
    const errorMessages = {
      500: "Server Error",
      401: "Please reload and try again",
      400: "Please include all the fields in excel data",
    };
    toast.error(errorMessages[status] || "An unknown error occurred", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    });
  };

  const toggleUpload = () => {
    setUploaddetails(!uploaddetails);
    setFile(null);
  };
  // Debounced API call to reduce frequent network requests
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };
  const debouncedGetDetails = debounce(async (selectedPage = 0) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    try {
      const res = await axios.get(
        `${ip}/organizationdetails/getorganizationname?clientId=${client}&pageno=${selectedPage}`,
        { headers }
      );
      let orgData;
      orgData = aes256.decrypt(`${secretkey}`, res.data.organizationdetails);
      const decryptedOrgdata = JSON.parse(orgData); 
      setFiledata(decryptedOrgdata);
      setPageCount(Math.ceil(res.data.count / perPage));
    } catch (err) {
      console.error(err);
    }
  },500);

  useEffect(() => {
    renewaccesstoken();
    debouncedGetDetails();
  }, []);

  return (
    <div className="align-row" style={{ overflow: "hidden" }}>
      <Newsidebar name="Provider Details" />
      <ToastContainer />
      <div className="align-column">
        <br />
        <div className="btn-group" role="group" style={{ marginLeft: "70%", marginRight: "10%" }}>
          <button
            type="button"
            className="btn"
            style={{ backgroundColor: "#7DD657", color: "#fff" }}
            onClick={toggleUpload}
          >
            Upload file
          </button>
        </div>
        <div style={{ marginLeft: "3%" }}>
          <ReactPaginate
            previousLabel={<IoIosArrowBack />}
            nextLabel={<IoIosArrowForward />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={currentpage}
          />
        </div>
        <div style={{ marginLeft: "3%", marginRight: "5%" }}>
          <Table bordered>
            <thead style={{ background: "#041c3c", color: "white", border: "1.5px solid black" }}>
              <tr>
                <td>Location</td>
                <td>Provider Code</td>
                <td>Provider Name</td>
                <td>NPI</td>
                <td>Organization Name</td>
                <td>TaxID</td>
              </tr>
            </thead>
            <tbody style={{ border: "1.5px solid black" }}>
              {filedata &&
                filedata.map((data) => (
                  <tr key={data._id} style={{ border: "1.5px solid black" }}>
                    <td>{data.Location}</td>
                    <td>{data.ProviderCode}</td>
                    <td>{data.ProviderName}</td>
                    <td>{data.Npi}</td>
                    <td>{data.OrganizationName}</td>
                    <td>{data.TaxID}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal centered isOpen={uploaddetails} toggle={toggleUpload}>
        <ModalHeader toggle={toggleUpload} style={{ fontWeight: "700", fontSize: "30px" }}>
          Bulk Allocate
        </ModalHeader>
        <ModalBody>
          <button
            type="button"
            className="btn"
            style={{ backgroundColor: "#041c3c", color: "#fff" }}
          >
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to={fileformat}
              download="Sample_Provider_Details.xlsx"
              target="_blank"
            >
              Sample File
            </Link>
          </button>
          <input
            type="file"
            id="file"
            ref={fileInput}
            onChange={onFileChange}
            style={{ display: "none" }}
            accept=".xlsx"
          />
          {file ? (
            <div className="file-card-upload">
              <div style={{ display: "flex", alignItems: "center", gap: "5px", flexDirection: "column" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <div className="file-card" style={{ position: "relative" }}>
                    <span>
                      <img src={excel} alt="excel" style={{ width: "30px", height: "30px" }} />
                    </span>
                    <div className="file-info">
                      <div style={{ flex: 1 }}>
                        <h6>{file.name}</h6>
                      </div>
                    </div>
                    <button className="close-icon" onClick={clearFile}>
                      <IoMdClose />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="file-btn" onClick={() => fileInput.current.click()}>
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <img src={upload} alt="upload" style={{ width: "90px", height: "90px" }} />
                <div>
                  <p style={{ margin: 0 }}>Click file to this area to upload</p>
                  <span style={{ color: "GrayText", fontSize: "11px" }}>
                    The given input should be in .xlsx format
                  </span>
                </div>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="multi-button">
            <Button className="reunalloc" onClick={onFileUpload}>
              Upload
            </Button>
            <Button className="reunalloc" onClick={toggleUpload}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RejectionUpload;
