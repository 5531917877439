import React, { Component } from "react";
import axios from "axios";
import "../../Styles/configure.css";
import Newsidebar from "../Dashboard/Newsidebar";
import ReactPaginate from "react-paginate";
import "../../Styles/loader.scss";
import { Link } from "react-router-dom";
import { components } from "react-select";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import { BiUpArrowAlt } from "react-icons/bi";
import { BiDownArrowAlt } from "react-icons/bi";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "reactstrap";
import {  Row } from "react-bootstrap";
import { ip, secretkey } from "../../Api.js";
import aes256 from "aes256";
import KeyUnallocated from "../Transaction/AllocationCardComponents/KeyUnallocated";
import Actionunallocated from "../Actions/Actionunallocated";
import Actionallocated1 from "./Actionallocated1";
import Actionallocated2 from "./Actionallocated2";
import Actionallocated3 from "./Actionallocated3";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

class ActionBucket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalunallocate: false,
      doioptionSelected: [],
      modalunallocate1: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      allocatedcountaction: 0,
      unallocatedcountaction: 0,
      claimsdata: [],
      reports: [],
      actiontable1: [],
      allocatemodal1: false,
      fetching: true,
      ar: [],
      loading: true,
      allocatecountaction: "",
      unallocatecountaction: "",
      any: [],
      statuslist: [],
      PatientFirstName: "",
      PatientLastName: "",
      patientAccountNumber: "",
      searchtext: "",
      searchclaimdata: [],
      payercategories: [],
      cptdata: [],
      status: [],
      optionSelected: [],
      username: "",
      claimfield: "",
      user: "",
      claimvalue: "",
      claimtype: "",
      payervalue: [],
      cptvalue: [],
      userdetails: [],
      query: [],
      ids: [],
      offset: 0,
      currentpage: 0,
      currentpagesearch: 0,
      perPage: 10,
      checkedAll: false,
      alldataresponse: [],
      searchdataresponse: [],
      selecteduser: [],
      users: [],
      Bucketnameforaction: sessionStorage.getItem("Bucketnameforaction"),
      user_id: sessionStorage.getItem("user_id"),
      role: sessionStorage.getItem("role_type"),
      client: sessionStorage.getItem("clientid"),
      clientname: sessionStorage.getItem("clientname"),
      clientslist: [],
      opendropdown: false,
      Location: "",
      locationslist: [],
      currentpageact: 0,
      perPageact: 10,
      offsetact: 0,
      count: 10,
      agegrplist: [
        "0-30days",
        "31-60days",
        "61-90days",
        "91-120days",
        "121-180days",
        "181-365days",
        "above 1year",
      ],
      doiagegrplist: [
        "0-30days",
        "31-60days",
        "61-90days",
        "91-120days",
        "121-180days",
        "181-365days",
        "above 1year",
      ],
      currentpageaction: 0,
      currentpage1: 0,
      actiontable: [],
      userstatuslist: ["Completed", "TO-DO", "Not Touch"],
      userstatus: "",
      agegrp: "",
      doiagegrp: "",
      unallocateid: "",
      sortstate: "ASC",
      openicon: false,
      claimids: [],
      sperPage: 10,
      anchorEl: null,
      openicon: false,
      claimids: [],
      sort: "",
      fieldsort: "",
      sorttable: "false",
      sortdata: [],
      perPagesort: 10,
      offsetsort: 0,
      currentpagesort: 0,
      pageCountsort: "",
    };
  }
  handleClose = () => this.setState({ anchorEl: null });
  toggle1 = () => {
    this.setState(() => {
      this.setState({ allocatemodal1: !this.state.allocatemodal1 });
    });
  };

  handleChange = (selected) => {
    this.setState({
      optionSelected: selected,
    });
  };
  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });
  doihandleChange = (selected) => {
    this.setState({
      doioptionSelected: selected,
    });
  };

  allocateclaims = () => {
    const isValid = this.validate();
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        ids: this.state.ids,
      };
      const res = axios
        .put(
          `${ip}/allocation/allocate/${this.state.selecteduser[0]._id}`,
          value,
          {
            headers: headers,
          }
        )
        .then((res) => {
          alert(
            `claims allocated to user ${this.state.selecteduser[0].first_name}`
          );

          this.getallocatedclaims();

          this.toggle();
          this.toggle2();
          // window.location.reload();
        })

        .catch((err) => {});
    }
  };

  bulkunallocatedclaims = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      ids: this.state.ids,
      logdetails:{
        // newUser:this.state.username,
        admin:this.state.UserAllocate,
      }
    };
    const res = axios
      .put(`${ip}/claim/updatemultipleclaimunder`, value, {
        headers: headers,
      })
      .then((res) => {
        alert(`claim unallocated successfully`);

        this.getallocatedclaims();

        this.toggle2();
        // window.location.reload();
      })

      .catch((err) => {});
  };
  unallocateclaims = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      id: this.state.unallocateid,
      logdetails:{
        // newUser:this.state.username,
        admin:this.state.UserAllocate,
      }
    };
    const res = axios
      .put(`${ip}/claim/updateclaimunder`, value, {
        headers: headers,
      })
      .then((res) => {
        alert(`claim unallocated successfully`);

        this.getallocatedclaims();

        // window.location.reload();
      })

      .catch((err) => {});
  };
  toggledropdown = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };

  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        if (res.data.clientonboard.length !== 0) {
          this.setState({
            clientslist: res.data.clientonboard,
            // clientname: res.data.clientonboard[0].client,
          });
        } else {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        }
      })
      .catch((err) => {});
  };
  getClaimStatus = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    const res = await axios
      .get(`${ip}/claimstatus/all`, { headers: headers })
      .then((res) => {
        this.setState({
          statuslist: res.data.claimstatus,
        });
      })
      .catch((err) => {});
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.sperPage;

    this.setState(
      {
        currentpageaction: selectedPage,
        offset: offset,
      },
      () => {
        this.getbucketclaims();
      }
    );
  };

  handlePageClickact = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPageact;

    this.setState(
      {
        currentpageact: selectedPage,
        offsetact: offset,
      },
      () => {
        this.getactionclaims();
      }
    );
  };
  getbucketclaims = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = await axios
      .get(
        `${ip}/actioncode/actioncodeclaims?clientId=${this.state.client}&actioncode=${this.state.Bucketnameforaction}&pageno=${this.state.currentpageaction}&count=${this.state.sperPage}`,
        {
          headers: headers,
        }
      )
      .then(
        (res) => {
          let actionbucketData;
          actionbucketData = aes256.decrypt(`${secretkey}`, res.data.claims);
          const decryptedactionbucketData = JSON.parse(actionbucketData);
          this.setState({
            actiontable: decryptedactionbucketData,
            fetching: false,
            pageCount: Math.ceil(res.data.count / this.state.sperPage),
          });
        },
        () => this.allocateusers()
      )

      .catch((err) => {});
  };

  getcount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = await axios
      .get(
        `${ip}/actioncode/getactioncodeclaims?actioncode=${this.state.Bucketnameforaction}&pageno=${this.state.currentpage1}&clientId=${this.state.client}`,
        {
          headers: headers,
        }
      )
      .then(
        (res) => {
          this.setState({
            unallocatedcountaction: res.data.unallocatedcount,
            allocatedcountaction: res.data.allocatedcount,
          });
     
        },
        () => this.allocateusers()
      )

      .catch((err) => {});
  };
  getactionclaims = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = await axios
      .get(
        `${ip}/actioncode/getactioncodeclaims?actioncode=${this.state.Bucketnameforaction}&allocatedpageno=${this.state.currentpageact}&clientId=${this.state.client}`,
        {
          headers: headers,
        }
      )
      .then(
        (res) => {
          this.setState({
            pageCountact: Math.ceil(res.data.usercount / this.state.perPageact),
            actiontable1: res.data.claimdetails,
          });
        },
        () => this.allocateusers()
      )

      .catch((err) => {});
  };
  //get Location
  getLocation = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      url = `${ip}/location/all/list?clientId=${this.state.client}`;
    } else {
      url = `${ip}/location/all/list`;
    }
    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        let data = res.data.location;
        data = data.filter((item) => {
          return item.clientId == this.state.client;
        });
        data.sort((a, b) => {
          return a.Location < b.Location ? -1 : 1;
        });

        this.setState({ locationslist: data });
      })

      .catch((err) => {});
  };

  //getusers
  getallocatedclaims = async () => {
    //this.setState({ fetching: true });
    //api call to get all users

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    if (
      this.state.role === "Admin" ||
      this.state.role === "SuperAdmin" ||
      this.state.role === "Team Manager"
    ) {
      let url;
      if (this.state.client) {
        if (this.state.Location) {
          url = `${ip}/allocation/allocated/all?clientId=${
            this.state.client
          }&pageno=${this.state.currentpage + 1}&count=${
            this.state.perPage
          }&Location=${this.state.Location}`;
        } else {
          url = `${ip}/allocation/allocated/all?clientId=${
            this.state.client
          }&pageno=${this.state.currentpage + 1}&count=${this.state.perPage}`;
        }
      } else {
        url = `${ip}/allocation/allocated/all?pageno=${
          this.state.currentpage + 1
        }&count=${this.state.perPage}`;
      }
      const res = axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          let allocData;
          allocData = aes256.decrypt(`${secretkey}`, res.data.claims);
          const decryptedallocData = JSON.parse(allocData);
          this.setState({
            alldataresponse: decryptedallocData,
            claimsdata: decryptedallocData,
          });
        })
        .catch((err) => {});
    } else if (this.state.role === "Team Member") {
      const res = axios
        .get(`${ip}/claim/user?userid=${this.state.user_id}`, {
          headers: headers,
        })
        .then((res) => {
          const slice = res.data.Claims.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          this.setState({
            alldataresponse: res.data.Claims,

            claimsdata: slice,
          });
        })
        .catch((err) => {});
    }
  };

  getcpt = async () => {
    //api call to get all cpt data
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/cptmaster/all`, { headers: headers })
      .then((res) => {
        this.setState({
          cptdata: res.data.cpt,
        });
      })
      .catch((err) => {});
  };
  getsorting = (col) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      if (this.state.Location) {
        url = `${ip}/actioncode/getactioncodeclaims/sort?clientId=${this.state.client}&actioncode=${this.state.Bucketnameforaction}&pageno=${this.state.currentpagesort}&sorting=${this.state.sort}&field=${this.state.fieldsort}&count=${this.state.perPagesort}&Location=${this.state.Location}`;
      } else {
        url = `${ip}/actioncode/getactioncodeclaims/sort?clientId=${this.state.client}&actioncode=${this.state.Bucketnameforaction}&pageno=${this.state.currentpagesort}&sorting=${this.state.sort}&field=${this.state.fieldsort}&count=${this.state.perPagesort}`;
      }
    } else {
      url = `${ip}/actioncode/getactioncodeclaims/sort?actioncode=${this.state.Bucketnameforaction}&pageno=${this.state.currentpagesort}&sorting=${this.state.sort}&field=${this.state.fieldsort}&count=${this.state.perPagesort}`;
    }
    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        let actionbucsortData;
        actionbucsortData = aes256.decrypt(`${secretkey}`, res.data.claims);
        const decryptedactionbucsortData = JSON.parse(actionbucsortData);
        this.setState({
          sortdata:decryptedactionbucsortData,
          pageCountsort: Math.ceil(res.data.claimcount / this.state.perPagesort),
        });
      })
      .catch((err) => {});
  };
  handlePagesort = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPagesort;
    this.setState(
      {
        currentpagesort: selectedPage,
        offsetsort: offset,
      },
      () => {
        this.getsorting();
      }
    );
  };
  getusers = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(`${ip}/users/all?clientId=${this.state.client}`, { headers: headers })
      .then((res) => {
        let data = res.data.user;
        // this.setState({ userdetails: res.data.user });
        if(this.state.client){
          data = data.filter((item) => {
            return (
              item.role === "Team Member" 
            );
          });
        }
        else{
          data = data.filter((item) => {
            return (
              item.role === "Admin" ||  item.role==="SuperAdmin"
            );
          });
        }
        

        this.setState({ users: data });
      })
      .catch((err) => {});
  };
  resetuser = () => {
    this.setState({
      ids: [],
    });
  };
  resetcount = () => {
    this.setState(
      {
        perPage: 10,
      },
      () => this.getallocatedclaims()
    );
  };
  reset = () => {
    this.setState(
      {
        claimfield: "",
        claimids: [],
        claimvalue: "",
        username: "",
        userstatus: [],
        user: "",
        ar: [],
        claimtype: "",
        status: [],
        agegrp: "",
        payervalue: [],
        cptvalue: [],
        searchdataresponse: [],
        searchclaimdata: [],
        searchtext: "",
        currentpage: 0,
        currentpagesearch: 0,
        checkedAll: false,
        sperPage: 10,
        doiagegrp: "",
        optionSelected: [],
        doioptionSelected: [],
      },
      () => this.getallocatedclaims()
    );
  };

  componentDidMount() {
    
    this.getallocatedclaims();
    this.getcpt();

    this.getbucketclaims();
    this.getcount();
    this.getClaimStatus();
    this.getclients();
    this.getusers();
    this.getactionclaims();
    this.getLocation();
  }

  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    const { anchorEl } = this.state;
    return (
      <div>
      <Newsidebar name="Action " />
      <div className="align-row">
        <div className="align-column">
          <div
            style={{ textAlign: "left", marginLeft: "1%", fontSize: "16px" }}
          >
            <p>
              <Link
                to="/management-actions"
                style={{
                  textDecoration: "none",
                  color: "black",
                  marginLeft: "2%",
                }}
              >
                Actions
              </Link>
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ height: "100%", width: "100%" }}>
              <div style={{ marginLeft: "77%" }}>
                <Badge
                  badgeContent={this.state.allocatedcountaction}
                  color="primary"
                  showZero
                  max={this.state.pageCount * this.state.sperPage}
                >
                  <Button
                    style={{ backgroundColor: "#2e5a88", color: "white" }}
                    onClick={() => {
                      this.toggle1();
                      this.handleClose();
                    }}
                  >
                    Allocated
                  </Button>
                </Badge>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Badge
                  badgeContent={this.state.unallocatedcountaction}
                  color="primary"
                  showZero
                  max={this.state.pageCount * this.state.sperPage}
                >
                  <Button>
                    <Link
                      to="/actionunallocated"
                      style={{
                        textDecoration: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => (
                        <Actionunallocated
                          value={sessionStorage.setItem(
                            "Bucketnameforaction",
                            this.state.Bucketnameforaction
                          )}
                        />
                      )}
                    >
                      Unallocated
                    </Link>
                  </Button>
                </Badge>
              </div>
              <br />
              <br />
              {this.state.fetching ? (
                <div>
                  <p
                    style={{
                      fontSize: "23px",
                      marginTop: "10px",
                      color: "#000",
                    }}
                  >
                    <span>Loading...</span>
                    <span>Please Wait...</span>
                  </p>
                  <div className="boxes body__loader dribbble">
                    <div className="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div className="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div className="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div className="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              ) : this.state.actiontable.length === 0 ? (
                <div className="bot-ui">
                  <div className="bot-head">
                    <div className="eyes-container">
                      <div className="to-left eye"></div>
                      <div className="to-right eye"></div>
                    </div>
                  </div>
                  <div className="bot-body">
                    <div className="analyzer"></div>
                  </div>
                  <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                    No Data Found
                  </p>
                </div>
              ) : (
                <div style={{ marginLeft: "3%" }}>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 700 }}
                      aria-label="customized table"
                      style={{ padding: "3%" }}
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                          onClick={() =>
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({       
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "ClaimID",
                                    sorttable: true, 
                                  }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({       
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "ClaimID",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim ID{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({       
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "ClaimID",
                                    sorttable: true, 
                                  }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({       
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "ClaimID",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                          onClick={() =>
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({   openicon: false,
                                    sort: "ascending",
                                    fieldsort: "Location",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({             
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "Location",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Location{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({   openicon: false,
                                    sort: "ascending",
                                    fieldsort: "Location",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({             
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "Location",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                          onClick={() =>
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({           
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "patientAccountNumber",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({     
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "patientAccountNumber",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Account No{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({           
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "patientAccountNumber",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({     
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "patientAccountNumber",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({      
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "DOS",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({            
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "DOS",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOS{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({      
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "DOS",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({            
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "DOS",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() =>
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({       
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "PatientFirstName",
                                      sorttable: true, }, ()=> {this.getsorting()})
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({        
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "PatientFirstName",
                                      sorttable: true, }, ()=> {this.getsorting()})
                                  }
                                />
                              )}
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({       
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "PatientFirstName",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({        
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "PatientFirstName",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({          
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "PatientDOB",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({          
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "PatientDOB",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient DOB{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({          
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "PatientDOB",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({          
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "PatientDOB",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({       
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "PlanCode",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({          
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "PlanCode",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Plan Code{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({       
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "PlanCode",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({          
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "PlanCode",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({    
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "InsuranceName",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({         
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "InsuranceName",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Insurance Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({    
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "InsuranceName",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({         
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "InsuranceName",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() =>
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({   
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "PrimaryPolicyNo",
                                      sorttable: true, }, ()=> {this.getsorting()})
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({           
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "PrimaryPolicyNo",
                                      sorttable: true, }, ()=> {this.getsorting()})
                                  }
                                />
                              )}
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Policy No{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({   
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "PrimaryPolicyNo",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({           
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "PrimaryPolicyNo",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({                
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "Physician",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({           
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "Physician",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Physician Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({                
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "Physician",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({           
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "Physician",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({           
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "Age",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({       
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "Age",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({           
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "Age",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({       
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "Age",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({        
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "AgeGrp",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({          
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "AgeGrp",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age Grp{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({        
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "AgeGrp",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({          
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "AgeGrp",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({       
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "DOIAge",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({         
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "DOIAge",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOI Age
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({       
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "DOIAge",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({         
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "DOIAge",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ 
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "DOIAgeGrp",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({          
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "DOIAgeGrp",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOI AgeGrp
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ 
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "DOIAgeGrp",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({          
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "DOIAgeGrp",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({              
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "type",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({            
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "type",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Type{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({              
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "type",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({            
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "type",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ 
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "Claimunder",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({            
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "Claimunder",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Allocated To{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ 
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "Claimunder",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({            
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "Claimunder",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({   
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "OverallClaimStatus",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({          
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "OverallClaimStatus",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            User Status{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({   
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "OverallClaimStatus",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({          
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "OverallClaimStatus",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() =>   {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({            
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "ClaimStatus",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({         
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "ClaimStatus",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim Status{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({            
                                    openicon: false,
                                    sort: "ascending",
                                    fieldsort: "ClaimStatus",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({         
                                    openicon: true,
                                    sort: "descending",
                                    fieldsort: "ClaimStatus",
                                    sorttable: true, }, ()=> {this.getsorting()})
                                }
                              />
                            )}
                          </StyledTableCell>{" "}
                        </TableRow>
                      </TableHead>
                      {this.state.sorttable === true ? (
                        <TableBody>
                        {this.state.sortdata &&
                          this.state.sortdata.map((data, index) => (
                            <TableRow key={data._id}>
                              <StyledTableCell>{data.ClaimID}</StyledTableCell>
                              <StyledTableCell>{data.Location}</StyledTableCell>
                              <StyledTableCell>
                                {data.patientAccountNumber
                                  ? data.patientAccountNumber
                                  : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.DateOfService ? data.DateOfService : "-"}
                              </StyledTableCell>

                              <StyledTableCell>
                                {data.PatientFirstName}&nbsp;
                                {data.PatientLastName}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.PatientDOB ? data.PatientDOB : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.PlanCode ? data.PlanCode : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.InsuranceName ? data.InsuranceName : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.PrimaryPolicyNo}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.Physician}
                              </StyledTableCell>

                              <StyledTableCell>{data.Age}</StyledTableCell>
                              <StyledTableCell>{data.AgeGrp}</StyledTableCell>
                              <StyledTableCell>{data.DOIAge}</StyledTableCell>
                              <StyledTableCell>
                                {data.DOIAgeGrp}
                              </StyledTableCell>
                              <StyledTableCell>{data.type}</StyledTableCell>
                              <StyledTableCell>
                                {data.Claimunder}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.OverallClaimStatus}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.ClaimStatus}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                      ) : (
                      <TableBody>
                        {this.state.actiontable &&
                          this.state.actiontable.map((data, index) => (
                            <TableRow key={data._id}>
                              <StyledTableCell>{data.ClaimID}</StyledTableCell>
                              <StyledTableCell>{data.Location}</StyledTableCell>
                              <StyledTableCell>
                                {data.patientAccountNumber
                                  ? data.patientAccountNumber
                                  : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.DateOfService ? data.DateOfService : "-"}
                              </StyledTableCell>

                              <StyledTableCell>
                                {data.PatientFirstName}&nbsp;
                                {data.PatientLastName}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.PatientDOB ? data.PatientDOB : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.PlanCode ? data.PlanCode : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.InsuranceName ? data.InsuranceName : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.PrimaryPolicyNo}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.Physician}
                              </StyledTableCell>

                              <StyledTableCell>{data.Age}</StyledTableCell>
                              <StyledTableCell>{data.AgeGrp}</StyledTableCell>
                              <StyledTableCell>{data.DOIAge}</StyledTableCell>
                              <StyledTableCell>
                                {data.DOIAgeGrp}
                              </StyledTableCell>
                              <StyledTableCell>{data.type}</StyledTableCell>
                              <StyledTableCell>
                                {data.Claimunder}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.OverallClaimStatus}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.ClaimStatus}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  {this.state.sorttable === true ? (
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCountsort}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePagesort}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.currentpagesort}
                      />
                    </div>
                  ) : (
                  <div style={{ marginTop: "20px" }}>
                    <ReactPaginate
                      forcePage={0}
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick1}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>)}
                </div>
              )}
              {this.state.allocatemodal1 && (
                <Modal
                  isOpen={this.state.allocatemodal1}
                  onHide={this.toggle1}
                  onClose={this.handleClose}
                  size="lg"
                  centered
                >
                  <ModalHeader toggle={this.toggle1}>Allocated</ModalHeader>
                  <ModalBody>
                    <Row style={{ margin: "0px" }}>
                      <div style={{ width: "100%" }}>
                        <Table
                          className="table"
                          bordered
                          style={{
                            marginLeft: "3%",
                            width: "99%",
                            marginTop: "4%",
                          }}
                        >
                          <thead
                            style={{
                              backgroundColor: "#041c3c",
                              color: "white",
                            }}
                          >
                            <tr align="center">
                              <th>Username</th>
                              <th>No of Claims </th>
                              <th>Completed</th>
                              <th>Not Touched</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.actiontable1 &&
                              this.state.actiontable1.map((data, index) => {
                                return (
                                  <tr>
                                    <td>{data.username}</td>
                                    <td>
                                      <Link
                                        to={{
                                          pathname: "/actionallocated1",
                                        }}
                                        onClick={() => (
                                          <Actionallocated1
                                            value={
                                              sessionStorage.setItem(
                                                "usernamekey",
                                                data.username
                                              ) ||
                                              sessionStorage.setItem(
                                                "claimdetailskey",
                                                data.filteredClaims.length
                                              ) ||
                                              sessionStorage.setItem(
                                                "completedclaims",
                                                data.completedClaims.length
                                              ) ||
                                              sessionStorage.setItem(
                                                "nottouch",
                                                data.nottouchedClaims.length
                                              ) ||
                                              sessionStorage.setItem(
                                                "keypageno",
                                                this.state.currentpageact
                                              ) ||
                                              sessionStorage.setItem(
                                                "checkfiltertable",
                                                true
                                              )
                                            }
                                          />
                                        )}
                                      >
                                        {data.filteredClaims.length}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={{
                                          pathname: "/actionallocated2",
                                        }}
                                        onClick={() => (
                                          <Actionallocated2
                                            value={
                                              sessionStorage.setItem(
                                                "usernamekey",
                                                data.username
                                              ) ||
                                              sessionStorage.setItem(
                                                "claimdetailskey",
                                                data.filteredClaims.length
                                              ) ||
                                              sessionStorage.setItem(
                                                "completedclaims",
                                                data.completedClaims.length
                                              ) ||
                                              sessionStorage.setItem(
                                                "nottouch",
                                                data.nottouchedClaims.length
                                              ) ||
                                              sessionStorage.setItem(
                                                "checkcompletetable",
                                                true
                                              ) ||
                                              sessionStorage.setItem(
                                                "keytype",
                                                "IVR"
                                              ) ||
                                              sessionStorage.setItem(
                                                "keycategory",
                                                "61to90"
                                              ) ||
                                              sessionStorage.setItem(
                                                "keypageno",
                                                this.state.currentpageact
                                              )
                                            }
                                          />
                                        )}
                                      >
                                        {data.completedClaims.length}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={{
                                          pathname: "/actionallocated3",
                                        }}
                                        onClick={() => (
                                          <Actionallocated3
                                            value={
                                              sessionStorage.setItem(
                                                "usernamekey",
                                                data.username
                                              ) ||
                                              sessionStorage.setItem(
                                                "claimdetailskey",
                                                data.filteredClaims.length
                                              ) ||
                                              sessionStorage.setItem(
                                                "completedclaims",
                                                data.completedClaims.length
                                              ) ||
                                              sessionStorage.setItem(
                                                "nottouch",
                                                data.nottouchedClaims.length
                                              ) ||
                                              sessionStorage.setItem(
                                                "nottouchtable",
                                                true
                                              ) ||
                                              sessionStorage.setItem(
                                                "keytype",
                                                "IVR"
                                              ) ||
                                              sessionStorage.setItem(
                                                "keycategory",
                                                "61to90"
                                              ) ||
                                              sessionStorage.setItem(
                                                "keypageno",
                                                this.state.currentpageact
                                              )
                                            }
                                          />
                                        )}
                                      >
                                        {data.nottouchedClaims.length}
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <div style={{ marginRight: "368px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCountact}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClickact}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </ModalFooter>
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default ActionBucket;
