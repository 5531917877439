import React, { Component } from "react";
import "../../Styles/datacleaning.css";
import "antd/dist/antd.css";
import { ToastContainer, toast } from "react-toastify";
import { Table } from "reactstrap";
import { DatePicker } from "antd";
import axios from "axios";
import { ip } from "../Api";
import "../../Styles/data.css";
import swal from "sweetalert";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import upload from "../../Images/upload.png";
import excel from "../../Images/excel.png";
import { IoMdClose } from "react-icons/io";
import {
  RiNumber1,
  RiNumber2,
  RiNumber3,
  RiNumber4,
  RiNumber5,
  RiNumber6,
  RiNumber7,
} from "react-icons/ri";
import fileformat from "../../Files/fileformat.xlsx";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Spinner } from "react-bootstrap";
import Newsidebar from "../Dashboard/Newsidebar";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

class DataCleaning extends Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    this.state = {
      all: [],
      toss: false,
      next: [],
      close: [],
      status: [],
      deletemodal: false,
      allclaimdata: [],
      closedclaimdata: [],
      duplicateclaimdata: [],
      statusclaimdata: [],
      reopenclaimdata: [],
      newclaimdata: [],
      allClaims1: [],
      newClaims1: [],
      ClosedClaims1: [],
      duplicateClaims1: [],
      tabindex: 1,
      duplicate: [],
      fileUploaded: false,
      fileUploading: false,
      fileUploading1: false,
      fileUploading2: false,
      fileUploading3: false,
      fileUploading4: false,
      fileUploading5: false,
      allbutton: true,
      closedbutton: true,
      duplicatebutton: true,
      statusbutton: true,
      reopenbutton: true,
      clearbutton: true,
      loading_file: false,
      reopen: [],
      Allclaims: [],
      new_claim: [],
      start: start,
      end: end,
      A: false,
      startdate: "",
      claimwise: false,
      enddate: "",
      filterstatus: "custom",
      tabindex: 1,
      startdatetime: "",
      enddatetime: "",
      status: false,
      fetchingcpt: true,
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      cptwise: [],
      allclaims: [],
      new_claims: [],
      closed_claims: [],
      duplicate_claims: [],
      dailyreport: [],
      currentpage1: 0,
      currentpage2: 0,
      currentpage3: 0,
      currentpage4: 0,
      perPage: 10,
      offset1: 0,
      offset2: 0,
      offset3: 0,
      offset4: 0,
      A: false,
      N: false,
      C: false,
      S: false,
      D: false,
      R: false,
      cleardata: false,
      loading: true,
      loadingall: true,
      id: "",
      search: "",
      start: "",
      end: "",
      fetching: true,
      valueOfInput1: moment().format("MM-DD-YYYY"),
      valueOfInput2: "",
      range: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
    };
    this.fileInput = React.createRef();
    this.onFileChange = this.onFileChange.bind(this);
    this.clearFile = this.clearFile.bind(this);
  }

  getcptwisereports = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;

    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/datacleaning?clientId=${this.state.client}&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
    } else {
      url = `${ip}/helper/datacleaning?clientId=${this.state.client}`;
    }

    const res = await axios

      .get(`${ip}/helper/datacleaning?clientId=${this.state.client}`, {
        headers: headers,
      })
      .then((res) => {
        let allClaims = [];
        let temp = "";
        res.data.Allclaims.forEach((claims) => {
          claims.claims.forEach((ele) => {
            if (!temp.includes(ele)) allClaims.push(ele);
            temp.concat(ele);
          });
        });
        const slice = allClaims.slice(
          this.state.offset1,
          this.state.offset1 + this.state.perPage
        );
        this.setState({
          pageCount: Math.ceil(allClaims.length / this.state.perPage),
          allClaims1: slice,
          fetching: false,
        });

        // allClaims=[],

        // this.setState({
        //   pageCount2: Math.ceil(res.data.count / this.state.perPage2),
        //   cptwise: res.data.data,
        //   fetchingcpt: false,
        // });
      });
  };
  getnewclaimreports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;

    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/datacleaning?clientId=${this.state.client}&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
    } else {
      url = `${ip}/helper/datacleaning?clientId=${this.state.client}`;
    }
    const res = axios

      .get(url, {
        headers: headers,
      })
      .then((res) => {
        let newClaims = [];
        let temp = "";
        res.data.new_claims.forEach((claims) => {
          claims.claims.forEach((ele) => {
            if (!temp.includes(ele)) newClaims.push(ele);
            temp.concat(ele);
          });
        });
        const slice = newClaims.slice(
          this.state.offset2,
          this.state.offset2 + this.state.perPage
        );

        this.setState({
          pageCount: Math.ceil(newClaims.length / this.state.perPage),
          newClaims1: slice,
          fetching: false,
        });

        // this.setState({
        //   pageCount2: Math.ceil(res.data.count / this.state.perPage2),
        //   cptwise: res.data.data,
        //   fetchingcpt: false,
        // });
      });
  };

  getclosedclaimreports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;

    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/datacleaning?clientId=${this.state.client}&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
    } else {
      url = `${ip}/helper/datacleaning?clientId=${this.state.client}`;
    }
    const res = axios

      .get(url, {
        headers: headers,
      })
      .then((res) => {
        let closedClaims = [];
        let temp = "";
        res.data.closed_claims.forEach((claims) => {
          claims.claims.forEach((ele) => {
            if (!temp.includes(ele)) closedClaims.push(ele);
            temp.concat(ele);
          });
        });
        const slice = closedClaims.slice(
          this.state.offset3,
          this.state.offset3 + this.state.perPage
        );
        this.setState({
          pageCount: Math.ceil(closedClaims.length / this.state.perPage),
          ClosedClaims1: slice,
          fetching: false,
        });

        // this.setState({
        //   pageCount2: Math.ceil(res.data.count / this.state.perPage2),
        //   cptwise: res.data.data,
        //   fetchingcpt: false,
        // });
      });
  };

  getduplicateclaimreports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;

    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/datacleaning?clientId=${this.state.client}&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
    } else {
      url = `${ip}/helper/datacleaning?clientId=${this.state.client}`;
    }
    const res = axios

      .get(url, {
        headers: headers,
      })
      .then((res) => {
        let duplicateClaims = [];
        let temp = "";
        res.data.duplicate_claims.forEach((claims) => {
          claims.claims.forEach((ele) => {
            if (!temp.includes(ele)) duplicateClaims.push(ele);
            temp.concat(ele);
          });
        });
        const slice = duplicateClaims.slice(
          this.state.offset4,
          this.state.offset4 + this.state.perPage
        );

        this.setState({
          pageCount: Math.ceil(duplicateClaims.length / this.state.perPage),
          duplicateClaims1: slice,
          fetching: false,
        });

        // this.setState({
        //   pageCount2: Math.ceil(res.data.count / this.state.perPage2),
        //   cptwise: res.data.data,
        //   fetchingcpt: false,
        // });
      });
  };

  onFileUpload = async (e) => {
    if (!this.state.file) {
      // Display an error message or handle the validation as needed
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    this.setState({ fileUploaded: !this.state.fileUploaded });
    e.preventDefault();
    const value = new FormData();
    value.append("file", this.fileInput.current.files[0]);
    value.append("clientids", JSON.stringify(this.state.client));
    const headers = {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    this.setState({
      A: true,
      C: true,
      S: true,
      D: true,
      N: false,
      R: true,
      cleardata: true,
    });
    await axios({
      method: "post",
      url: `${ip}/helper/newclaims`,
      data: value,
      headers: headers,
    })
      .then((response) => {
        //handle success
        if (response.status === 200) {
          this.setState({
            fileUploaded: !this.state.fileUploaded,
          });
          toast.success("File uploaded successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
          this.setState({
            A: false,
            C: false,
            S: false,
            D: false,
            N: false,
            R: false,
            cleardata: false,
          });
        }
      })
      .catch((err) => {
        //handle error
        this.setState({ fileUploaded: !this.state.fileUploaded });

        if (err.response) {
          if (err.response.status === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 400) {
            toast.error("Please include all the fields in excel data", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  onFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };
  clearFile() {
    this.setState({ file: null });
  }
  togglemodal = () => {
    this.setState({
      fileUploading: !this.state.fileUploading,
    });
  };
  togglemodal1 = () => {
    this.setState({
      fileUploading1: !this.state.fileUploading1,
    });
  };
  togglemodal2 = () => {
    this.setState({
      fileUploading2: !this.state.fileUploading2,
    });
  };
  togglemodal3 = () => {
    this.setState({
      fileUploading3: !this.state.fileUploading3,
    });
  };
  togglemodal4 = () => {
    this.setState({
      fileUploading4: !this.state.fileUploading4,
    });
  };
  togglemodal5 = () => {
    this.setState({
      fileUploading5: !this.state.fileUploading5,
    });
  };

  allclaim = () => {
    this.setState({
      claimwise: false,
      A: false,
      C: true,
      S: true,
      D: true,
      N: true,
      R: true,
      cleardata: true,
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/allclaims?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}`;
    } else {
      url = `${ip}/helper/allclaims?clientId=${this.state.client}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          loading_file: false,
        });
        if (res.status === 200) {
          this.setState({
            A: false,
            C: false,
            S: false,
            D: false,
            N: false,
            R: false,
            allbutton: false,
            cleardata: false,
          });
        }
      });
  };

  newclaim = () => {
    this.setState({
      claimwise: true,
    });
  };

  closedclaim = () => {
    // this.timerpopup();
    this.setState({
      A: true,
      C: false,
      S: true,
      D: true,
      N: true,
      R: true,
      cleardata: true,
      claimwise: false,
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/closedclaims?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}`;
    } else {
      url = `${ip}/helper/closedclaims?clientId=${this.state.client}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          loading_file: false,
        });
        if (res.status === 200) {
          // alert("Closed claims Completed")
          this.setState({
            A: false,
            C: false,
            S: false,
            D: false,
            N: false,
            R: false,
            cleardata: false,
            closedbutton: false,
          });

          // toast.success("Completed", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   progress: undefined,
          // });
        }
      });
  };

  duplicateclaim = () => {
    this.setState({
      A: true,
      C: true,
      S: true,
      D: false,
      N: true,
      R: true,
      cleardata: true,
      claimwise: false,
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/duplicateclaims?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}`;
    } else {
      url = `${ip}/helper/duplicateclaims?clientId=${this.state.client}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          loading_file: false,
        });
        if (res.status === 200) {
          // alert("Duplicate claims Completed")
          this.setState({
            A: false,
            C: false,
            S: false,
            D: false,
            N: false,
            R: false,
            cleardata: false,
            duplicatebutton: false,
            // fileUploading2:false,
          });
          // toast.success("Completed", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   progress: undefined,
          // });
        }
      });
  };

  reopenclaim = () => {
    this.setState({
      A: true,
      C: true,
      S: true,
      D: true,
      N: true,
      R: false,
      cleardata: true,
      claimwise: false,
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/reopenclaims?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}`;
    } else {
      url = `${ip}/helper/reopenclaims?clientId=${this.state.client}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          loading_file: false,
        });
        if (res.status === 200) {
          // alert("Reopen claims Completed")
          this.setState({
            A: false,
            C: false,
            S: false,
            D: false,
            N: false,
            R: false,
            cleardata: false,
            reopenbutton: false,
            // fileUploading4:false,
          });
          // toast.success("Completed", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   progress: undefined,
          // });
        }
      });
  };

  statusclaim = () => {
    this.setState({
      A: true,
      C: true,
      S: false,
      D: true,
      N: true,
      R: true,
      cleardata: true,
      claimwise: false,
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/statuschange?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}`;
    } else {
      url = `${ip}/helper/statuschange?clientId=${this.state.client}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          loading_file: false,
        });
        if (res.status === 200) {
          // alert("Status change completed")
          this.setState({
            A: false,
            C: false,
            S: false,
            D: false,
            N: false,
            R: false,
            cleardata: false,
            statusbutton: false,
            // fileUploading3:false,
          });
          // toast.success("Completed", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   progress: undefined,
          // });
        }
      });
  };
  clearclaim = () => {
    this.setState({
      A: true,
      C: true,
      S: true,
      D: true,
      N: true,
      R: true,
      cleardata: false,
      claimwise: false,
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/cleardatacleaning?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}`;
    } else {
      url = `${ip}/helper/cleardatacleaning?clientId=${this.state.client}`;
    }
    const res = axios
      .delete(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          loading_file: false,
        });
        if (res.status === 200) {
          this.setState({
            A: false,
            C: false,
            S: false,
            D: false,
            N: false,
            R: false,
            cleardata: false,
            clearbutton: false,
          });
        }
      });
  };
  render() {
    return (
      <div>
        <Newsidebar name="Data Cleaning" />
        <div
          className="align-row"
          style={{ overflow: "hidden", paddingTop: "200px" }}
        >
          <ToastContainer></ToastContainer>
          <div className="align-column">
            <ul className="data-cleaning-ul">
              <li style={{ "--accent-color": "#EB1A23" }}>
                <div class="icon">
                  <RiNumber1 />
                </div>
                <Button
                  style={{ fontSize: "13px" }}
                  onClick={this.togglemodal5}
                  disabled={this.state.cleardata}
                >
                  Data Cleaning
                </Button>
              </li>
              <li style={{ "--accent-color": "#002755" }}>
                <div class="icon">
                  <RiNumber2 />
                </div>
                <Button
                  style={{ fontSize: "13px" }}
                  onClick={() => {
                    this.togglemodal();
                  }}
                  disabled={this.state.A}
                >
                  All Claims
                </Button>
              </li>
              <li style={{ "--accent-color": "#EB1A23" }}>
                <div class="icon">
                  <RiNumber3 />
                </div>
                <Button
                  style={{ fontSize: "13px" }}
                  onClick={this.newclaim}
                  disabled={this.state.N}
                >
                  New Claims
                </Button>
              </li>
              <li style={{ "--accent-color": "#002755" }}>
                <div class="icon">
                  <RiNumber4 />
                </div>
                <Button
                  style={{ fontSize: "13px" }}
                  onClick={() => {
                    this.togglemodal1();
                  }}
                  disabled={this.state.C}
                >
                  Closed Claims
                </Button>
              </li>
              <li style={{ "--accent-color": "#EB1A23" }}>
                <div class="icon">
                  <RiNumber5 />
                </div>
                <Button
                  style={{ fontSize: "13px" }}
                  onClick={() => {
                    this.togglemodal2();
                  }}
                  disabled={this.state.D}
                >
                  Duplicate Claims
                </Button>
              </li>
              <li style={{ "--accent-color": "#002755" }}>
                <div class="icon">
                  <RiNumber6 />
                </div>
                <Button
                  style={{ fontSize: "13px" }}
                  onClick={this.togglemodal3}
                  disabled={this.state.S}
                >
                  Status Change
                </Button>
              </li>
              <li style={{ "--accent-color": "#EB1A23" }}>
                <div class="icon">
                  <RiNumber7 />
                </div>
                <Button
                  style={{ fontSize: "13px" }}
                  onClick={this.togglemodal4}
                  disabled={this.state.R}
                >
                  Reopen Claims
                </Button>
              </li>
            </ul>

            {this.state.claimwise && (
              <div>
                <div>
                  <input
                    type="file"
                    id="file"
                    ref={this.fileInput}
                    onChange={this.onFileChange}
                    style={{ display: "none" }}
                    accept=".xlsx"
                  />
                  {this.state.file ? (
                    <div className="file-card-upload">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <div
                            className="file-card"
                            style={{ position: "relative" }}
                          >
                            <span>
                              <img
                                src={excel}
                                alt="excel"
                                style={{ width: "30px", height: "30px" }}
                              />
                            </span>
                            <div className="file-info">
                              <div style={{ flex: 1 }}>
                                <h6>{this.state.file.name}</h6>
                              </div>
                            </div>
                            <button
                              className="close-icon"
                              onClick={this.clearFile} // Clear file function
                            >
                              <IoMdClose />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="file-btn"
                      onClick={() => this.fileInput.current.click()}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <img
                          src={upload}
                          alt="upload"
                          style={{ width: "90px", height: "90px" }}
                        />
                        <div>
                          <p style={{ margin: 0 }}>
                            Click file to this area to upload
                          </p>
                          <span style={{ color: "GrayText", fontSize: "11px" }}>
                            The given input should be in .xlsx format
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "#041c3c", color: "#fff" }}
                  >
                    <Link
                    style={{
                      textDecoration: "none",
                      color: "#a8adad",
                    }}
                    to={fileformat}
                    download="claimdata.xlsx"
                    target="_blank"
                    >
                      Sample
                    </Link>
                  </button>
                  <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "#7DD657", color: "#fff" }}
                    onClick={this.onFileUpload}
                  >
                    Upload
                  </button>
                </div>
              </div>
            )}

            {this.state.fileUploaded && (
              <Modal isOpen={this.state.fileUploaded}>
                <ModalHeader>File Processing Data</ModalHeader>
                <ModalBody>
                  <p align="left">
                    Please wait for sometime...&nbsp;&nbsp;Your file is being
                    processed!
                  </p>
                  <button className="">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;&nbsp;Processing....
                  </button>
                </ModalBody>
              </Modal>
            )}
            {this.state.fileUploading && (
              <Modal isOpen={this.togglemodal} centered>
                <ModalHeader>All claims</ModalHeader>
                <ModalBody>
                  <p align="left">
                    Please wait for sometime...&nbsp;&nbsp;All claims files have
                    been processed! Click to start
                  </p>
                  {this.state.loading_file === true && (
                    <div class="lds-dual-ring"></div>
                  )}
                  {this.state.allbutton === false && (
                    <p
                      style={{
                        fontSize: "25px",
                        textAlign: "center",
                        color: "green",
                        fontFamily: "monospace",
                      }}
                    >
                      All claims Completed
                    </p>
                  )}
                </ModalBody>
                <ModalFooter>
                  <button
                    className="datauploadcss1"
                    onClick={() => {
                      this.setState({ loading_file: true });
                      this.allclaim();
                    }}
                  >
                    Start
                  </button>
                  {this.state.allbutton === "true" ? (
                    <button disabled={this.state.allbutton}>Close</button>
                  ) : (
                    <button
                      className="datauploadcss1"
                      disabled={this.state.allbutton}
                      onClick={this.togglemodal}
                    >
                      Close
                    </button>
                  )}
                </ModalFooter>
              </Modal>
            )}
            {this.state.fileUploading1 && (
              <Modal isOpen={this.togglemodal1} centered>
                <ModalHeader>Closed Claims</ModalHeader>
                <ModalBody>
                  <p align="left">
                    Please wait for sometime...&nbsp;&nbsp;Closed claims file
                    has been processed! Click to start
                  </p>
                  {this.state.loading_file === true && (
                    <div class="lds-dual-ring"></div>
                  )}
                  {this.state.closedbutton === false && (
                    <p
                      style={{
                        fontSize: "25px",
                        textAlign: "center",
                        color: "green",
                        fontFamily: "monospace",
                      }}
                    >
                      Closed claims Completed
                    </p>
                  )}
                </ModalBody>
                <ModalFooter>
                  <button
                    className="datauploadcss1"
                    onClick={() => {
                      this.setState({ loading_file: true });
                      this.closedclaim();
                    }}
                  >
                    Start
                  </button>
                  {this.state.closedbutton === "true" ? (
                    <button disabled={this.state.closedbutton}>Close</button>
                  ) : (
                    <button
                      className="datauploadcss1"
                      disabled={this.state.closedbutton}
                      onClick={this.togglemodal1}
                    >
                      Close
                    </button>
                  )}
                </ModalFooter>
              </Modal>
            )}
            {this.state.fileUploading2 && (
              <Modal isOpen={this.togglemodal2} centered>
                <ModalHeader>Duplicate Claims</ModalHeader>
                <ModalBody>
                  <p align="left">
                    Please wait for sometime...&nbsp;&nbsp;Duplicate claims file
                    has been processed! Click to start
                  </p>
                  {this.state.loading_file === true && (
                    <div class="lds-dual-ring"></div>
                  )}
                  {this.state.duplicatebutton === false && (
                    <p
                      style={{
                        fontSize: "25px",
                        textAlign: "center",
                        color: "green",
                        fontFamily: "monospace",
                      }}
                    >
                      Duplicate claims Completed
                    </p>
                  )}
                </ModalBody>
                <ModalFooter>
                  <button
                    className="datauploadcss1"
                    onClick={() => {
                      this.setState({ loading_file: true });
                      this.duplicateclaim();
                    }}
                  >
                    Start
                  </button>
                  {this.state.duplicatebutton === "true" ? (
                    <button disabled={this.state.duplicatebutton}>Close</button>
                  ) : (
                    <button
                      className="datauploadcss1"
                      disabled={this.state.duplicatebutton}
                      onClick={this.togglemodal2}
                    >
                      Close
                    </button>
                  )}
                </ModalFooter>
              </Modal>
            )}
            {this.state.fileUploading3 && (
              <Modal
                isOpen={this.togglemodal3}
                // onChange={this.timerpopup}
                centered
              >
                <ModalHeader>Status Change</ModalHeader>
                <ModalBody>
                  <p align="left">
                    Please wait for sometime...&nbsp;&nbsp;Status Change file
                    has been processed! Click to start
                  </p>
                  {this.state.loading_file === true && (
                    <div class="lds-dual-ring"></div>
                  )}
                  {this.state.statusbutton === false && (
                    <p
                      style={{
                        fontSize: "25px",
                        textAlign: "center",
                        color: "green",
                        fontFamily: "monospace",
                      }}
                    >
                      Status change Completed
                    </p>
                  )}
                </ModalBody>
                <ModalFooter>
                  <button
                    className="datauploadcss1"
                    onClick={() => {
                      this.setState({ loading_file: true });
                      this.statusclaim();
                    }}
                  >
                    Start
                  </button>
                  {this.state.statusbutton === "true" ? (
                    <button disabled={this.state.statusbutton}>Close</button>
                  ) : (
                    <button
                      className="datauploadcss1"
                      disabled={this.state.statusbutton}
                      onClick={this.togglemodal3}
                    >
                      Close
                    </button>
                  )}
                </ModalFooter>
              </Modal>
            )}
            {this.state.fileUploading4 && (
              <Modal isOpen={this.togglemodal4} centered>
                <ModalHeader>Reopen Claims</ModalHeader>
                <ModalBody>
                  <p align="left">
                    Please wait for sometime...&nbsp;&nbsp;Reopen claims file
                    has been processed! Click to start
                  </p>
                  {this.state.loading_file === true && (
                    <div class="lds-dual-ring"></div>
                  )}
                  {this.state.reopenbutton === false && (
                    <p
                      style={{
                        fontSize: "25px",
                        textAlign: "center",
                        color: "green",
                        fontFamily: "monospace",
                      }}
                    >
                      Reopen claims Completed
                    </p>
                  )}
                </ModalBody>
                <ModalFooter>
                  <button
                    className="datauploadcss1"
                    onClick={() => {
                      this.setState({ loading_file: true });
                      this.reopenclaim();
                    }}
                  >
                    Start
                  </button>
                  {this.state.reopenbutton === "true" ? (
                    <button disabled={this.state.reopenbutton}>Close</button>
                  ) : (
                    <button
                      className="datauploadcss1"
                      disabled={this.state.reopenbutton}
                      onClick={this.togglemodal4}
                    >
                      Close
                    </button>
                  )}
                </ModalFooter>
              </Modal>
            )}
            {this.state.fileUploading5 && (
              <Modal isOpen={this.togglemodal5} centered>
                <ModalHeader>Clear Claims</ModalHeader>
                <ModalBody>
                  <p align="left">
                    Please wait for sometime...&nbsp;&nbsp;Clear claims file has
                    been processed! Click to start
                  </p>
                  {this.state.loading_file === true && (
                    <div class="lds-dual-ring"></div>
                  )}
                  {this.state.clearbutton === false && (
                    <p
                      style={{
                        fontSize: "25px",
                        textAlign: "center",
                        color: "green",
                        fontFamily: "monospace",
                      }}
                    >
                      Clear Data Completed
                    </p>
                  )}
                </ModalBody>
                <ModalFooter>
                  <button
                    className="datauploadcss1"
                    onClick={() => {
                      this.setState({ loading_file: true });
                      this.clearclaim();
                    }}
                  >
                    Start
                  </button>
                  {this.state.clearbutton === "true" ? (
                    <button disabled={this.state.clearbutton}>Close</button>
                  ) : (
                    <button
                      className="datauploadcss1"
                      disabled={this.state.clearbutton}
                      onClick={this.togglemodal5}
                    >
                      Close
                    </button>
                  )}
                </ModalFooter>
              </Modal>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default DataCleaning;
