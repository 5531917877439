import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../../Styles/configure.css";
import Select from "react-dropdown-select";
import { FcUpload } from "react-icons/fc";
import Newsidebar from "../Dashboard/Newsidebar";
import {
  Row,
  Col,
  Form,
  Label,
  Input,
  Button,
  FormGroup,
} from "reactstrap";
import { Modal, Spinner, Table } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
 
import { Card } from "react-bootstrap";
import { ip } from "../../Api";
import { faIcicles } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineFolder } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loading from "../../Images/loader.gif";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
  IoIosCloudUpload,
} from "react-icons/io";
import ReactPaginate from "react-paginate";
const options = [
  { value: "1", label: "ClaimID" },
  { value: "2", label: "Claim No" },
  { value: "9", label: "Patient" },
  { value: "3", label: "Physician" },
  { value: "4", label: "PayerID" },
  { value: "5", label: "Age" },
  { value: "6", label: "PolicyNo" },
  { value: "7", label: "PatientDOB" },
  { value: "8", label: "ClaimDate" },
];
export class ClaimStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query1: [],
      status1: "",
      status2: "",
      searchtext1: "",
      searchdataresponse1: [],
      searchclaimdata1: [],
      addnewmodal: false,
      createmodal: false,
      editmodal: false,
      pageCountweb: "",
      statuslist: [],
      deletemodal: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role_type: sessionStorage.getItem("role_type"),
      status: "",
      selectedfile: "",
      uploading: false,
      offset: 0,
      currentpage: 0,
      perPage: 7,
      perPageweb: 7,
      statusname: "",
      updatestatusname: "",
      id: "",
      updatestatus: "",
      uploadmodal: false,
    };
    this.handlePageClick1 = this.handlePageClick1.bind(this);
    this.fileInput = React.createRef();
  }
  imageHandler = (e) => {
    this.setState({ selectedfile: e.target.files[0] });
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getClaimStatus();
      }
    );
  };
  handleStatusChange = (selected) => {
    if (selected.length > 0) {
      this.setState({ status1: selected[0] });
    } else {
      this.setState({ status1: '' });
    }
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.filtersearch1();
      }
    );
  };
  updateClaimStatus = async () => {
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      claimStatus: this.state.updatestatusname,
      status: this.state.updatestatus,
    };
     await axios
      .put(`${ip}/claimstatus/${this.state.id} `, value, { headers: headers })
      .then((res) => {
        if (res) {
          this.openeditmodal();
          this.getClaimStatus();
          this.setState({ createmodal: !this.state.createmodal });
          toast.info("Claim Status updated", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.openeditmodal();
          this.getClaimStatus();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  deleteClaimStatus = async () => {
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     await axios
      .delete(`${ip}/claimstatus/${this.state.id} `, { headers: headers })
      .then((res) => {
        if (res) {
          this.opendeletemodal();
          this.getClaimStatus();
          this.setState({ createmodal: !this.state.createmodal });
          toast.error("Claim Status deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.opendeletemodal();
          this.getClaimStatus();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  getquery = () => {
    var query_o;
    let query = [];
    if (this.state.status1.length !== 0) {
      query_o = `claimStatus=${this.state.status1}`;
      query.push(query_o);
    }

    if (this.state.status2.length !== 0) {
      query_o = `status=${this.state.status2}`;
      query.push(query_o);
    }
    if (query.length === 1) {
      this.state.query1.push(`${query[0]}`);
    }
    if (query.length === 2) {
      this.state.query1.push(`${query[0]}&${query[1]}`);
    }
  };
  addClaimStatus = async () => {
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      claimStatus: this.state.statusname,
      status: this.state.status,
    };
     await axios
      .post(`${ip}/claimstatus/create`, value, { headers: headers })
      .then((res) => {
        if (res) {
          this.getClaimStatus();
          this.openmodal();
          this.setState({ createmodal: !this.state.createmodal });
          toast.success("Claim Status created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.openmodal();
          this.getClaimStatus();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  openmodal = () => {
    this.setState({ addnewmodal: !this.state.addnewmodal });
  };

  openeditmodal = () => {
    this.setState({ editmodal: !this.state.editmodal });
  };

  openuploadmodal = () => {
    this.setState({ uploadmodal: !this.state.uploadmodal });
  };

  opendeletemodal = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  filtersearch1 = (e) => {
    // e.preventDefault();
    this.setState({ query1: [] });
    this.getquery();
    let filtereddata = [];
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.state.query1.length !== 0) {
      let url = `${ip}/claimstatus/advancedmultiplefilter?${this.state.query1}`;
       axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          filtereddata = res.data.claims;
          if (filtereddata.length !== 0) {
            this.setState({ searchtext1: 1 });
            const slice = filtereddata.slice(
              this.state.offsetweb,
              this.state.offsetweb + this.state.perPageweb
            );
            this.setState({
              searchdataresponse1: filtereddata,
              pageCountweb: Math.ceil(
                filtereddata.length / this.state.perPageweb
              ),
              searchclaimdata1: slice,
            });
          } else if (filtereddata.length === 0) {
            this.setState({
              searchtext1: 1,
              searchdataresponse1: [],
              searchclaimdata1: [],
            });
          }
        })
        .catch((err) => {});
    } else {
      alert("Select data to search");
    }
  };
  reset = (e) => {
    e.preventDefault();
    this.setState({
      updateDescErr: "",
      DescErr: "",
      claimfield1: "",
      claimvalue1: "",
      status1: "",
      status2: "",
      status:"",
      payervalue1: [],
      cptvalue1: [],
      searchdataresponse1: [],
      searchclaimdata1: [],
      searchtext1: "",
    });
  };
  getClaimStatus = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

     await axios
      .get(`${ip}/claimstatus/all`, { headers: headers })
      .then((res) => {
        const slice = res.data.claimstatus.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        this.setState({
          pageCount: Math.ceil(
            res.data.claimstatus.length / this.state.perPage
          ),
          statuslist: slice,
        });
      })
      .catch((err) => {});
  };

  componentDidMount() {
    
    this.getClaimStatus();
  }
  render() {
    return (
      <div>
      <Newsidebar name="Claim Status" />
      <div className="align-row" style={{overflow:"hidden"}}>
        <div className="align-column"> 
        <br/>
              <Form
              style={{
                border: "3px solid #FBFBFB",
                padding: "8px",
                borderRadius: "16px",
                background: "#FBFBFB",
                width:"80%",
                marginLeft:"15%"
              }}
              >
                <Row>
                  <Col style={{ marginLeft: "0%" }}>
                    <FormGroup>
                      <Label>Claim Status</Label>
                      <Typeahead
                  id="myTypeahead"
                  clearButton={true}
                  options={this.state.statuslist.map((data) => data.claimStatus)}
                  onChange={this.handleStatusChange}
                  placeholder="Select Claim Status..."
                  selected={this.state.status1 ? [this.state.status1] : []}
                />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Status</Label>
                      <Input
                        value={this.state.status2}
                        onChange={(e) => {
                          this.setState({ status2: e.target.value });
                        }}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <Button
                      style={{
                        height: "59%",
                        marginTop: "23.3px",
                        borderRadius: "8px",
                        color: "white",
                        background: "black",
                        fontWeight: "bold",
                      }}
                      onClick={(e) => {
                        this.setState({ tabname: "web" }, (e) => {
                          this.filtersearch1(e);
                        });
                      }}
                    >
                      Search
                    </Button>
                    <Button
                      style={{
                        width: "20%",
                        height: "59%",
                        marginTop: "23.3px",
                        color: "white",
                        background: "white",
                        fontWeight: "bold",
                      }}
                      onClick={(e) => {
                        this.reset(e);
                      }}
                    >
                      <IoIosRefresh
                        style={{
                          color: "blue",
                          fontSize: "25px",
                        }}
                      ></IoIosRefresh>
                    </Button>
                  </Col>
                </Row>
              </Form>
          <br />
          <br />
          <ToastContainer></ToastContainer>
          <Modal show={this.state.addnewmodal} onHide={this.openmodal} centered>
            <Modal.Header closeButton closeLabel="">
              <Modal.Title>Add Claim Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Row>
                    <Col>
                      <Label className="required">Claim Status</Label>
                      <Input
                        type="text"
                        value={this.state.statusname}
                        onChange={(e) =>
                          this.setState({ statusname: e.target.value })
                        }
                      ></Input>
                    </Col>
                    <Col>
                      <Label for="select" className="required">
                        Status
                      </Label>
                      <Input
                        type="select"
                        name="select"
                        value={this.state.status}
                        onChange={(e) =>
                          this.setState({ status: e.target.value })
                        }
                        className="form-control form-select"
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Input>
                    </Col>
                  </Row>
                  <br />
                </Row>
                <br />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{
                  border: "1px solid grey",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  this.openmodal();
                  //   this.reset();
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ backgroundColor: "black", color: "white" }}
                onClick={this.addClaimStatus}
              >
                Add
              </Button>
            </Modal.Footer>
          </Modal>

          {this.state.statuslist.length === 0 ? (
            <div className="bot-ui">
              <div className="bot-head">
                <div className="eyes-container">
                  <div className="to-left eye"></div>
                  <div className="to-right eye"></div>
                </div>
              </div>
              <div className="bot-body">
                <div className="analyzer"></div>
              </div>
              <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                No Data Found
              </p>
            </div>
          ) : (
            <div>
              {this.state.searchtext1 === "" ? (
                <div>
                  <div style={{marginLeft:"2%"}}>
                    <Table bordered
                    className="StandardTable"
                    >
                      <thead>
                        <tr>
                          <th>Claim Status</th>
                          <th>Status</th>
                          {this.state.role_type === "Admin" && <th>Action
                          &nbsp;&nbsp;&nbsp;
                          <button
                          className="AddNew"
                          onClick={this.openmodal}
                          style={{
                            borderRadius: "8px",
                            color: "white",
                            background: "black",
                            fontWeight: "bold",
                          }}
                        >
                          Add Claim Status
                        </button>
                          </th>}
                        </tr>
                      </thead>
                      <tbody >
                        {this.state.statuslist.map((data, index) => (
                          <tr >
                            <td>{data.claimStatus}</td>
                            <td>{data.status}</td>
                            {this.state.role_type === "Admin" && (
                              <td>
                                <button
                                  className="Edit"
                                  onClick={() => {
                                    this.setState({
                                      id: data._id,
                                      updatestatus: data.status,
                                      updatestatusname: data.claimStatus,
                                    });
                                    this.openeditmodal();
                                  }}
                                >
                                  Edit
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                  className="Delete"
                                  onClick={() => {
                                    this.setState({ id: data._id });
                                    this.opendeletemodal();
                                  }}
                                >
                                  Delete
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <br />
                    <div>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {this.state.searchdataresponse1.length === 0 ? (
                    <div className="bot-ui">
                      <div className="bot-head">
                        <div className="eyes-container">
                          <div className="to-left eye"></div>
                          <div className="to-right eye"></div>
                        </div>
                      </div>
                      <div className="bot-body">
                        <div className="analyzer"></div>
                      </div>
                      <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                        No Data Found
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div className="claimstatus-table">
                        <Table striped  className="Standardtable">
                          <thead>
                            <tr>
                              <th>Claim Status</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.searchdataresponse1.map(
                              (data, index) => (
                                <tr>
                                  <td>{data.claimStatus}</td>
                                  <td>{data.status}</td>
                                  <td>
                                    <button
                                      className="Edit"
                                      onClick={() => {
                                        this.setState({
                                          id: data._id,
                                          updatestatus: data.status,
                                          updatestatusname: data.claimStatus,
                                        });
                                        this.openeditmodal();
                                      }}
                                    >
                                      Edit
                                    </button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <button
                                      className="Delete"
                                      onClick={() => {
                                        this.setState({ id: data._id });
                                        this.opendeletemodal();
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                        <br />
                        <div>
                          <ReactPaginate
                            previousLabel={<IoIosArrowBack />}
                            nextLabel={<IoIosArrowForward />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pageCountweb}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick1}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <Modal show={this.state.editmodal} onHide={this.openeditmodal} centered>
          <Modal.Header closeButton closeLabel="">
            <Modal.Title>Edit Claim</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Label>Claim Status</Label>
                  <Input
                    type="text"
                    value={this.state.updatestatusname}
                    onChange={(e) =>
                      this.setState({ updatestatusname: e.target.value })
                    }
                  ></Input>
                </Col>
                <Col>
                  <Label for="select">Status</Label>
                  <Input
                    type="select"
                    value={this.state.updatestatus}
                    onChange={(e) =>
                      this.setState({ updatestatus: e.target.value })
                    }
                    name="select"
                    className="form-control form-select"
                  >
                    <option
                      value="Active"
                      selected={this.state.updatestatus === "Active"}
                    >
                      Active
                    </option>
                    <option
                      value="Inactive"
                      selected={this.state.updatestatus === "Inactive"}
                    >
                      Inactive
                    </option>
                  </Input>
                </Col>
              </Row>
              <br />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.openeditmodal();
                //   this.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={this.updateClaimStatus}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.deletemodal}
          onHide={this.opendeletemodal}
          centered
        >
          <Modal.Header closeButton closeLabel="">
            <Modal.Title>Delete Claim Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to delete this claim status?</Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.opendeletemodal();
                //   this.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={this.deleteClaimStatus}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.createmodal}
          centered
          onHide={this.openuploadmodal}
        >
          <Modal.Body>
            <img
              src={loading}
              style={{ width: "200px", height: "200px", marginLeft: "30%" }}
            />
          </Modal.Body>
        </Modal>
        {/* <Modal show={this.state.uploadmodal} centered>
          <Modal.Header closeButton closeLabel="">
            <Modal.Title>Upload Claim Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>

          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.openuploadmodal();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              // onClick={this.deleteClaimStatus}
            >
              Upload
            </Button>
          </Modal.Footer>
        </Modal> */}
      </div>
      </div>
    );
  }
}

export default ClaimStatus;
