import { React, Component } from "react";
import "../../../Styles/subdenial.css";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { SiAcclaim } from "react-icons/si";
import { SiCodesandbox } from "react-icons/si";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { MdOutlinePaid } from "react-icons/md";
import { GiHospitalCross } from "react-icons/gi";
import { MdPersonOutline } from "react-icons/md";
import { MdOutlinePersonPin } from "react-icons/md";
import axios from "axios";
import { ip } from "../../../Api";
import SubDenialAllocation from "./SubDenialAllocation";
export class SubdenialBuckets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: sessionStorage.getItem("queryurl"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      querystartdate: sessionStorage.getItem("querystartdate"),
      queryenddate: sessionStorage.getItem("queryenddate"),
      searchid: sessionStorage.getItem("querysearchid"),
      categorywisedata: null,
      show2: sessionStorage.getItem("queryclaimiddenial"),
      EligibilityIssueamount: "",
      EligibilityIssuecount: "",
      Authorizationamount: "",
      Authorizationcount: "",
      TFlIssueamount: "",
      TFlIssuecount: "",
      Callingamount: "",
      Callingcount: "",
      Codingamount: "",
      Codingcount: "",
      Provideramount: "",
      Providercount: "",
      Appealamount: "",
      Appealcount: "",

      Paymentamount: "",
      Paymentcount: "",
      Capitationamount: "",
      Capitationcount: "",
      Otherscount: "",
      othersamount: "",
      queryclient: sessionStorage.getItem("queryclient"),
    };
  }
  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });
  handleClose = () => this.setState({ anchorEl: null });
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getdata = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url = null;

    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/denial/category/value?${this.state.value}&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
    } else {
      if (this.state.querystartdate && this.state.queryenddate) {
        url = `${ip}/denial/category/value?${this.state.value}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
      } else {
        url = `${ip}/denial/category/value?${this.state.value}`;
      }
    }
    console.log("date filter", url);
     await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.data.length !== 0) {
          res.data.data.map((temp) => {
            switch (temp._id) {
              case "Appeal":
                return this.setState({
                  Appealamount: Math.round(temp.totalamount).toLocaleString(),
                  Appealcount: temp.totalcount,
                });
              case "Authorization":
                return this.setState({
                  Authorizationamount: Math.round(
                    temp.totalamount
                  ).toLocaleString(),
                  Authorizationcount: temp.totalcount,
                });
              case "Calling/Web Portal":
                return this.setState({
                  Callingamount: Math.round(temp.totalamount).toLocaleString(),
                  Callingcount: temp.totalcount,
                });
              case "Coding":
                return this.setState({
                  Codingamount: Math.round(temp.totalamount).toLocaleString(),
                  Codingcount: temp.totalcount,
                });
              case "Eligibility Issue":
                return this.setState({
                  EligibilityIssueamount: Math.round(
                    temp.totalamount
                  ).toLocaleString(),
                  EligibilityIssuecount: temp.totalcount,
                });
              case "Others":
                return this.setState({
                  othersamount: Math.round(temp.totalamount).toLocaleString(),
                  Otherscount: temp.totalcount,
                });
              case "Payment Posting":
                return this.setState({
                  Paymentamount: Math.round(temp.totalamount).toLocaleString(),
                  Paymentcount: temp.totalcount,
                });
              case "Provider":
                return this.setState({
                  Provideramount: Math.round(temp.totalamount).toLocaleString(),
                  Providercount: temp.totalcount,
                });
              case "TFL Issue":
                return this.setState({
                  TFlIssueamount: Math.round(temp.totalamount).toLocaleString(),
                  TFlIssuecount: temp.totalcount,
                });
              case "Capitation":
                return this.setState({
                  Capitationamount: Math.round(
                    temp.totalamount
                  ).toLocaleString(),
                  Capitationcount: temp.totalcount,
                });
                default:
                  return null;
            }
          });
        } else {
          this.setState({
            EligibilityIssueamount: 0,
            EligibilityIssuecount: 0,
            Authorizationamount: 0,
            Authorizationcount: 0,
            TFlIssueamount: 0,
            TFlIssuecount: 0,
            Callingamount: 0,
            Callingcount: 0,
            Codingamount: 0,
            Codingcount: 0,
            Provideramount: 0,
            Providercount: 0,
            Appealamount: 0,
            Appealcount: 0,
            Paymentamount: 0,
            Paymentcount: 0,
            Capitationamount: 0,
            Capitationcount: 0,
            Otherscount: 0,
            othersamount: 0,
          });
        }
      })
      .catch((err) => {});
  };

  componentDidMount() {
    
    this.getdata();
  }

  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.startdate === prevProps.startdate &&
        this.props.enddate === prevProps.enddate
      )
    ) {
      this.getdata();
    }
  }
  render() {
    return (
      <div style={{marginLeft:"1%"}}>
        <div className="row">
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div className="card-header border-white"
                    style={{
                  backgroundColor:
                  this.props.claimcategory === "Eligibility Issue" ? "#83a4d4" : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Eligibility Issue
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="subdenialicon-green">
                      <SiAcclaim
                        style={{
                          color: "green",
                          fontSize: "17px",
                          marginTop: "-25%",
                        }}
                      >
                        {" "}
                      </SiAcclaim>
                    </div>

                    <SubDenialAllocation data={1}></SubDenialAllocation>
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/sub-category",
                    state: {
                      current: `${this.state.value}&category=Eligibility Issue`,
                      past: `${this.state.value}`,
                      subcategorydenial: `${this.props.subcategorydenial}`,
                      searchid: `${this.props.searchid}`,
                    },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col className="alclaim">
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "19px",
                          }}
                        >
                          {" "}
                          {this.state.EligibilityIssuecount}
                        </div>
                      </span>
                    </Col>
                    <Col className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "19px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.EligibilityIssueamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div className="card-header border-white"
                    style={{
                  backgroundColor:
                  this.props.claimcategory === "Authorization" ? "#83a4d4" : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Authorization
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="subdenialicon-blue">
                      <SiCodesandbox
                        style={{
                          color: "blue",
                          fontSize: "19px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </SiCodesandbox>
                    </div>
                    <SubDenialAllocation data={2}></SubDenialAllocation>
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/sub-category1",
                    state: {
                      current: `${this.state.value}&category=Authorization`,
                      past: `${this.state.value}`,
                      subcategorydenial: `${this.props.subcategorydenial}`,
                      searchid: `${this.props.searchid}`,
                    },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col className="alclaim">
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "19px",
                          }}
                        >
                          {" "}
                          {this.state.Authorizationcount}
                        </div>
                      </span>
                    </Col>
                    <Col className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "19px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.Authorizationamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div className="card-header border-white"
                    style={{
                  backgroundColor:
                  this.props.claimcategory === "TFL Issue" ? "#83a4d4" : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      TFL Issue
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="subdenialicon-purple">
                      <MdOutlineIntegrationInstructions
                        style={{
                          color: "purple",
                          fontSize: "21px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </MdOutlineIntegrationInstructions>
                    </div>
                    <SubDenialAllocation data={3}></SubDenialAllocation>
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/sub-category2",
                    state: {
                      current: `${this.state.value}&category=TFL Issue`,
                      past: `${this.state.value}`,
                      subcategorydenial: `${this.props.subcategorydenial}`,
                      searchid: `${this.props.searchid}`,
                    },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col className="alclaim">
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "19px",
                          }}
                        >
                          {" "}
                          {this.state.TFlIssuecount}
                        </div>
                      </span>
                    </Col>
                    <Col className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "19px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.TFlIssueamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div className="card-header border-white"
                    style={{
                  backgroundColor:
                  this.props.claimcategory === "Calling/Web Portal" ? "#83a4d4" : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Calling/Web Portal
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="subdenialicon-pink">
                      <MdOutlinePaid
                        style={{
                          color: "#e60028",
                          fontSize: "22px",
                          marginTop: "18%",
                        }}
                      >
                        {" "}
                      </MdOutlinePaid>
                    </div>
                    <SubDenialAllocation data={4}></SubDenialAllocation>
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/sub-category3",
                    state: {
                      current: `${this.state.value}&category=Calling/Web Portal`,
                      past: `${this.state.value}`,
                      subcategorydenial: `${this.props.subcategorydenial}`,
                      searchid: `${this.props.searchid}`,
                    },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col className="alclaim">
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "19px",
                          }}
                        >
                          {" "}
                          {this.state.Callingcount}
                        </div>
                      </span>
                    </Col>
                    <Col className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "19px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.Callingamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div className="card-header border-white"
                    style={{
                  backgroundColor:
                  this.props.claimcategory === "Coding" ? "#83a4d4" : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Coding
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="subdenialicon-black">
                      <GiHospitalCross
                        style={{
                          color: "black",
                          fontSize: "21px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </GiHospitalCross>
                    </div>
                    <SubDenialAllocation data={5}></SubDenialAllocation>
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/sub-category4",
                    state: {
                      current: `${this.state.value}&category=Coding`,
                      past: `${this.state.value}`,
                      subcategorydenial: `${this.props.subcategorydenial}`,
                      searchid: `${this.props.searchid}`,
                    },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col className="alclaim">
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "19px",
                          }}
                        >
                          {" "}
                          {this.state.Codingcount}
                        </div>
                      </span>
                    </Col>
                    <Col className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "19px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.Codingamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div className="card-header border-white"
                    style={{
                  backgroundColor:
                  this.props.claimcategory === "Provider" ? "#83a4d4" : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Provider
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="subdenialicon-red">
                      <MdPersonOutline
                        style={{
                          color: "#800000",
                          fontSize: "23px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </MdPersonOutline>
                    </div>

                    <SubDenialAllocation data={6}></SubDenialAllocation>
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/sub-category5",
                    state: {
                      current: `${this.state.value}&category=Provider`,
                      past: `${this.state.value}`,
                      subcategorydenial: `${this.props.subcategorydenial}`,
                      searchid: `${this.props.searchid}`,
                    },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col className="alclaim">
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "19px",
                          }}
                        >
                          {" "}
                          {this.state.Providercount}
                        </div>
                      </span>
                    </Col>
                    <Col className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "19px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.Provideramount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div className="card-header border-white"
                    style={{
                  backgroundColor:
                  this.props.claimcategory === "Appeal" ? "#83a4d4" : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Appeal
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="subdenialicon-yellow">
                      <MdOutlinePersonPin
                        style={{
                          color: "4c4d00",
                          fontSize: "21px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </MdOutlinePersonPin>
                    </div>

                    <SubDenialAllocation data={7}></SubDenialAllocation>
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/sub-category6",
                    state: {
                      current: `${this.state.value}&category=Appeal`,
                      past: `${this.state.value}`,
                      subcategorydenial: `${this.props.subcategorydenial}`,
                      searchid: `${this.props.searchid}`,
                    },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col className="alclaim">
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "19px",
                          }}
                        >
                          {" "}
                          {this.state.Appealcount}
                        </div>
                      </span>
                    </Col>
                    <Col className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "19px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.Appealamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div className="card-header border-white"
                    style={{
                  backgroundColor:
                  this.props.claimcategory === "Payment Posting" ? "#83a4d4" : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Payment Posting
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="subdenialicon-black">
                      <GiHospitalCross
                        style={{
                          color: "black",
                          fontSize: "21px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </GiHospitalCross>
                    </div>
                    <SubDenialAllocation data={8}></SubDenialAllocation>
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/sub-category7",
                    state: {
                      current: `${this.state.value}&category=Payment Posting`,
                      past: `${this.state.value}`,
                      subcategorydenial: `${this.props.subcategorydenial}`,
                      searchid: `${this.props.searchid}`,
                    },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col className="alclaim">
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "19px",
                          }}
                        >
                          {" "}
                          {this.state.Paymentcount}
                        </div>
                      </span>
                    </Col>
                    <Col className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "19px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.Paymentamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div className="card-header border-white"
                    style={{
                  backgroundColor:
                  this.props.claimcategory === "Capitation" === 1 ? "#83a4d4" : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Capitation
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="subdenialicon-purple">
                      <MdOutlineIntegrationInstructions
                        style={{
                          color: "purple",
                          fontSize: "21px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </MdOutlineIntegrationInstructions>
                    </div>
                    <SubDenialAllocation data={9}></SubDenialAllocation>
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/sub-category8",
                    state: {
                      current: `${this.state.value}&category=Capitation`,
                      past: `${this.state.value}`,
                      subcategorydenial: `${this.props.subcategorydenial}`,
                      searchid: `${this.props.searchid}`,
                    },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col className="alclaim">
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "19px",
                          }}
                        >
                          {" "}
                          {this.state.Capitationcount}
                        </div>
                      </span>
                    </Col>
                    <Col className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "19px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.Capitationamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div className="card-header border-white"
                    style={{
                  backgroundColor:
                  this.props.claimcategory === "Others" ? "#83a4d4" : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Others
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="subdenialicon-yellow">
                      <MdOutlinePersonPin
                        style={{
                          color: "4c4d00",
                          fontSize: "21px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </MdOutlinePersonPin>
                    </div>
                    <SubDenialAllocation data={10}></SubDenialAllocation>
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/sub-category9",
                    state: {
                      current: `${this.state.value}&category=Others`,
                      past: `${this.state.value}`,
                      subcategorydenial: `${this.props.subcategorydenial}`,
                      searchid: `${this.props.searchid}`,
                    },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col className="alclaim">
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "19px",
                          }}
                        >
                          {" "}
                          {this.state.Otherscount}
                        </div>
                      </span>
                    </Col>
                    <Col className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "19px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.othersamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SubdenialBuckets;
