import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import axios from "axios";
import { Typeahead } from "react-bootstrap-typeahead";
import { AiOutlineSearch } from "react-icons/ai";
import {  Row, Col, Table } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Twilio from "../Transaction/Twilio";
import ActionViewClaim from "./ActionsComponents/ActionViewClaim";
import ActionSimpleMenu from "./ActionsComponents/ActionMenuList.js";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { ageOptions } from "./Agedata";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import ReactPaginate from "react-paginate";
import { BiUpArrowAlt } from "react-icons/bi";
import moment from "moment-timezone";
import { BiDownArrowAlt } from "react-icons/bi";
import { ip } from ".././Api";
import noresult from "../../Images/searchresult.jpg";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { Button, Form,  Input } from "reactstrap";
import Viewmoreeligibility from "../Eligibility/Viewmoreeligibility.js";
import { TableBody } from "@material-ui/core";
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
export default class UserNotTouchPage extends Component {
  constructor(props) {
    super(props);
    this.typeaheadRef = React.createRef();
    this.state = {
      spageCount:1,
      searchCompleted: false,
      optionsarray: [
        { value: "Location", label: "Location" },
        { value: "ClaimID", label: "ClaimID" },
        { value: "patientAccountNumber", label: "Account Number" },
        { value: "MRN", label: "Claim No" },
        { value: "PatientFirstName", label: "FirstName" },
        { value: "PatientLastName", label: "LastName" },
        { value: "Physician", label: "Physician" },
        { value: "ClaimStatus", label: "Claim Status"},
        { value: "Age", label: "Age" },
        { value: "PrimaryPolicyNo", label: "Policy No" },
        { value: "PatientDOB", label: "DOB" },
        { value: "PlanCode", label: "Plan Code" },
        { value: "DateOfService", label: "DOS" },
        { value: "InsuranceName", label: "Insurance Name" },
        { value: "type", label: "Type" },
        { value: "AgeGrp", label: "Age grp" },
      ],
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role_type: sessionStorage.getItem("role_type"),
      clientid: sessionStorage.getItem("clientid"),
      firstname: sessionStorage.getItem("firstname"),
      lastname: sessionStorage.getItem("lastname"),
      apiusername: sessionStorage.getItem("apiusername"),
      formValues: [
        {
          claimfield1: "",
          claimvalue: "",
        },
      ],
      claimtype: "",
      agegrplist: [
        "0-30days",
        "31-60days",
        "61-90days",
        "91-120days",
        "121-180days",
        "181-365days",
        "above 1year",
      ],
      agegrp: "",
      alldataresponse: [],
      statuslist: [],
      claimsdata: [],
      fetching: true,
      offset: 0,
      currentpage: 0,
      currentpagesearch: 0,
      perPage: sessionStorage.getItem("Default_pagination"),
      sperPage: sessionStorage.getItem("Default_pagination"),
      claimfield1: "",
      claimvalue1: "",
      payervalue1: [],
      searchclaimdata: [],
      searchdataresponse: [],
      searchtext: "",
      query: [],
      status: "",
      firstsearch: true,
      sortstate: "ASC",
      openicon: false,
      optionSelected: [],
    };
  }

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  handleStatusChange = (selected) => {
    if (selected.length > 0) {
      this.setState({ status: selected[0] });
    } else {
      this.setState({ status: "" });
    }
  };
  getnottouchclaims = () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let name = this.state.firstname + " " + this.state.lastname;
    const res = axios
      .get(
        `${ip}/claim/user/notworkedclaims?userid=${name}&clientId=${this.state.clientid}&pageno=${this.state.currentpage}&count=${this.state.perPage}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          pageCount: Math.ceil(res.data.count / this.state.perPage),
          claimsdata: res.data.Claim,
          fetching: false,
        });
      })
      .catch((err) => {});
  };
  handleChange = (selected) => {
    this.setState({
      optionSelected: selected,
    });
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getnottouchclaims();
      }
    );
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.sperPage;

    this.setState(
      {
        currentpagesearch: selectedPage,
        firstsearch: false,
      },
      (e) => {
        this.filtersearch(e);
      }
    );
  };

  getquery = () => {
    var query_o;
    let query = [];
    if (this.state.status !== "") {
      query_o = `ClaimStatus=${this.state.status}`;
      query.push(query_o);
    }
    if (this.state.claimfield1 && this.state.claimvalue1 !== "") {
      query_o = `${this.state.claimfield1}=${this.state.claimvalue1}`;
      query.push(query_o);
    }

    if (this.state.optionSelected.length !== 0) {
      let agegrp = [];
      let temp;
      let agelist;
      this.state.optionSelected.map((ele) => {
        agegrp.push(ele.value);
      });
      agelist = agegrp.map((str) => '"' + str + '"');
      query_o = `AgeGrp=[${agelist}]`;
      query.push(query_o);
    }
    if (this.state.claimtype) {
      query_o = `type=${this.state.claimtype}`;
      query.push(query_o);
    }
    if (query.length === 1) {
      this.state.query.push(`${query[0]}`);
    }
    if (query.length === 2) {
      this.state.query.push(`${query[0]}&${query[1]}`);
    }
    if (query.length === 3) {
      this.state.query.push(`${query[0]}&${query[1]}&${query[2]}`);
    }
    if (query.length === 4) {
      this.state.query.push(`${query[0]}&${query[1]}&${query[2]}&${query[3]}`);
    }
  };
  filtersearch = (e) => {
    if (this.state.firstsearch1) {
      e.preventDefault();
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.state.searchCompleted=== true && this.state.role_type === "Team Member") {
      if (this.state.formValues.some(formValue => formValue.claimfield1 !== "" && formValue.claimvalue !== "")) {
        let url;
        let name = this.state.firstname + " " + this.state.lastname;
        url = `${ip}/userdashboard/notworked/advancedmultiplefilter?pageno=${this.state.currentpagesearch}&count=${this.state.sperPage}&Claimunder=${name}&clientId=${this.state.clientid}`;
        const searchCriteria = {};
        this.state.formValues.forEach((formValue) => {
          if (formValue.claimfield1 && formValue.claimvalue) {
            searchCriteria[formValue.claimfield1] = formValue.claimvalue;
          }
        });
        this.state.formValues.forEach((formValue) => {
          if (formValue.claimfield1 === "type") {
            searchCriteria[formValue.claimfield1] = formValue.claimvalue;
          }
        });
        this.state.formValues.forEach((formValue) => {
          if (formValue.claimfield1 === "ClaimStatus") {
            const userValues = formValue.claimvalue.map((option) => option.value);
            let statuslist;
            statuslist = userValues.map((str) => '"' + str + '"');
            searchCriteria[formValue.claimfield1] = `[${statuslist.join(", ")}]`;     
             }
        });
        this.state.formValues.forEach((formValue) => {
          if (
            formValue.claimfield1 === "AgeGrp" &&
            formValue.claimvalue.length > 0
          ) {
            const userValues = formValue.claimvalue.map(
              (option) => option.value
            );
            let agegrplist;
            agegrplist = userValues.map((str) => '"' + str + '"');
            searchCriteria[formValue.claimfield1] = `[${agegrplist.join(
              ", "
            )}]`;
          }
        });
        const searchCriteriaQueryString = Object.keys(searchCriteria)
          .map((key) => `${key}=${searchCriteria[key]}`)
          .join("&");

        if (searchCriteriaQueryString) {
          url += `&${searchCriteriaQueryString}`;
        }
        const res = axios
          .get(url, {
            headers: headers,
          })
          .then((res) => {
            if (res && this.state.searchCompleted=== true && this.state.spageCount === 1) {
              toast.success("Searching completed!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
            this.setState({ searchtext: 1 });
            this.setState({
              searchdataresponse: res.data.claims,
              spageCount: Math.ceil(res.data.claimcount / this.state.perPage),
              searchclaimdata: res.data.claims,
            });
          });
      }
      else {
        alert("Select data to search");
      }
    }
  };
  addFormField() {
    this.setState({
      formValues: [
        ...this.state.formValues,
        { claimfield1: "", claimvalue: "" },
      ],
    });
  }
  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }
  getClaimStatus = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    const res = await axios
      .get(`${ip}/claimstatus/all`, { headers: headers })
      .then((res) => {
        var data = res.data.claimstatus.map((e) => (
         {
          value: e.claimStatus,
          label: e.claimStatus,
        }));
        this.setState({
          statuslist: data,
        });
      })
      .catch((err) => {});
  };
  resetcount = (e) => {
    this.setState(
      {
        perPage: 10,
      },
      () => this.getnottouchclaims()
    );
  };
  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.setState({ searchCompleted:true }, () => {
        this.filtersearch(e);
      });
    }
  };
  reset = (e) => {
    e.preventDefault();
    if(this.state.searchtext === 1){
      toast.info("All values displayed now!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    if (this.typeaheadRef.current) {
      this.typeaheadRef.current.clear(); // Use the ref to clear the Typeahead input
    }
    this.setState(
      {
        spageCount:1,
        searchCompleted: false,
        formValues: [],
        claimfield1: "",
        claimvalue1: "",
        status: "",
        agegrp: "",
        payervalue1: [],
        cptvalue1: [],
        searchdataresponse: [],
        searchresultresponse1: [],
        searchclaimdata: [],
        searchtext: "",
        currentpage: 0,
        currentpagesearch: 0,
        claimtype: "",
        sperPage: 10,
        optionSelected: [],
      },
      () => {
        this.addFormField()
        this.getnottouchclaims()
      }
    );
  };

  getcss = () => {
    if (this.state.role_type === "Team Member") {
      this.setState({ css_name: "align-col-actions" });
    } else {
      this.setState({ css_name: "align-column" });
    }
  };
  sorting = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.claimsdata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ claimsdata: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.claimsdata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ claimsdata: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };
  sortingfilter = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.searchdataresponse].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ searchdataresponse: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.searchdataresponse].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ searchdataresponse: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };
  componentDidMount() {
    
    this.getnottouchclaims();
    // this.getcss();
    this.getClaimStatus();
    let storedSearchnottouchInput = localStorage.getItem("storedClaimnottouchSearch");
    let storedSearchvalue = localStorage.getItem("storednottouchSearchvalue");
    let storedSearchkey = localStorage.getItem("storednottouchSearchkey");


    let val = JSON.parse(storedSearchvalue)
    if (val === 1) {
        this.setState({
        formValues: JSON.parse(storedSearchnottouchInput),
        searchtext: val,
        searchCompleted: JSON.parse(storedSearchkey)
          }, (e) => {
            this.filtersearch(e);
          })
    }
    else{
      this.getnottouchclaims()
    }
  }
componentWillUnmount() {
    let nottouchinput = JSON.stringify(this.state.formValues);
    localStorage.setItem("storedClaimnottouchSearch", nottouchinput);
    let nottouchinput1 = JSON.stringify(this.state.searchtext);
    localStorage.setItem("storednottouchSearchvalue", nottouchinput1);
    let nottouchinput2 = JSON.stringify(this.state.searchCompleted);
    localStorage.setItem("storednottouchSearchkey", nottouchinput2);
  }
  render() {
    return (
      <div>
      <Newsidebar name="List of Pending Claims" />
      <ToastContainer></ToastContainer>
      <br/>
        <div className="search__grid">
        <div className="search__grid1">
        <Form>
          {this.state.formValues.map((formValue, index) => (
            <div key={index} className="search-field">
              <Row
              style={{
                background:"whitesmoke",
                padding:"6px",
                borderRadius:"20px"
              }}>
                <Col md="5" className="d-flex justify-content-end">
                <Typeahead
                onKeyPress={this.handleKeyPress}
                onKeyDown={this.handleKeyPress}
                ref={this.typeaheadRef}
                // clearButton={true}
                id={`typeahead${index}`}
                style={{ width: "70%" }}
                options={this.state.optionsarray}
                value={formValue.claimfield1}
                selected={this.state.optionsarray.filter(option => option.value === formValue.claimfield1)}
                labelKey={(option) => `${option.label}`}
                placeholder="What are you looking for?..."
                onChange={(selected) => {
                  const updatedFormValues = [...this.state.formValues];
                  if (selected && selected.length > 0 && selected[0] && selected[0].value) {
                    updatedFormValues[index].claimfield1 = selected[0].value;
                  } else {
                    updatedFormValues[index].claimfield1 = "Default";
                  }
                  this.setState({ formValues: updatedFormValues });
                }}
              />
                </Col>
                <Col md="5">
                  {formValue.claimfield1 === "ClaimStatus" ? (
                    <ReactSelect
                    onKeyPress={this.handleKeyPress}
                    onKeyDown={this.handleKeyPress}
                    options={this.state.statuslist}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    onChange={(selected) => {
                      const updatedFormValues = [
                        ...this.state.formValues,
                      ];
                      updatedFormValues[index].claimvalue =
                        selected || [];
                      this.setState({ formValues: updatedFormValues });
                    }}
                    allowSelectAll={true}
                    value={formValue.claimvalue}
                  />
                  ) : formValue.claimfield1 === "AgeGrp" ? (
                    <ReactSelect
                    onKeyPress={this.handleKeyPress}
                    onKeyDown={this.handleKeyPress}
                      options={ageOptions}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option,
                      }}
                      onChange={(selected) => {
                        const updatedFormValues = [
                          ...this.state.formValues,
                        ];
                        updatedFormValues[index].claimvalue =
                          selected || [];
                        this.setState({
                          formValues: updatedFormValues,
                        });
                      }}
                      allowSelectAll={true}
                      value={formValue.claimvalue}
                    />
                  ) : formValue.claimfield1 === "type" ? (
                    <Typeahead
                      onKeyPress={this.handleKeyPress}
                      onKeyDown={this.handleKeyPress}
                      id="typeahed1"
                      clearButton={true}
                      options={["Web", "IVR", "Manual"]} // An array of available options
                      placeholder="Select Type..." // Placeholder text for the input field
                      selected={formValue.claimvalue} // Pass the selected option(s) as an array
                      onChange={(selected) => {
                        const updatedFormValues = [
                          ...this.state.formValues,
                        ];
                        updatedFormValues[index].claimvalue =
                          selected || [];
                        this.setState({
                          formValues: updatedFormValues,
                        });
                      }}
                    />
                  ) : (
                    <Input
                    onKeyPress={this.handleKeyPress}
                    onKeyDown={this.handleKeyPress}
                      className="header__searchInput"
                      type="text"
                      placeholder="Enter text"
                      value={formValue.claimvalue}
                      onChange={(e) => {
                        const updatedFormValues = [
                          ...this.state.formValues,
                        ];
                        updatedFormValues[index].claimvalue =
                          e.target.value;
                        this.setState({
                          formValues: updatedFormValues,
                        });
                      }}
                    />
                  )}
                </Col>
                <Col col="2"className="d-flex justify-content-end">
                  <div className="button-group">
                    {index > 0 && (
                      <Button
                        className="btn btn-danger float-right labelfontsize"
                        onClick={() => {
                          this.removeFormFields(index);
                        }}
                      >
                        <AiOutlineCloseCircle />
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
              <br />
            </div>
          ))}
      </Form>
      </div>
      <div class="search__grid2">
          <button
          type="button"
          class="btn"
          style={{ backgroundColor: "#89CFF0", color: "#fff" }}
            onClick={(e) => {
              this.setState({
                searchCompleted: true
              }, (e)=> {
                this.filtersearch(e);
              })
            }}
          >
            <AiOutlineSearch />
          </button>
          <button
          type="button"
          class="btn"
          style={{ backgroundColor: "#7DD657", color: "#fff" }}
            onClick={() => {
              this.addFormField();
            }}
          >
            <AiOutlinePlusCircle />
          </button>
          <button
          style={{ backgroundColor: "#041c3c", color: "#fff" }}
          type="button"
          class="btn"
            onClick={(e) => {
              this.reset(e);
            }}
          >
            <IoIosRefresh
              style={{
                color: "white",
              }}
            ></IoIosRefresh>
          </button>
        </div>
       <div style={{ display: "flex" }}>
      <div class="select_drop">
        <select
          style={{ height: "35px" }}
          value={this.state.perPage}
          onChange={(e) =>
            this.setState(
              {
                perPage: e.target.value,
                currentpage: 0,
                currentpagesearch: 0,
                sperPage: e.target.value,
              },
              () => {
                this.getnottouchclaims();
                // this.filtersearch();
              }
            )
          }
        >
          <option value="select" hidden selected>
            Select page count
          </option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
        </select>
      </div>

      <div>
        <Button
          style={{
            border: "none",
            color: "white",
            background: "white",
            fontWeight: "bold",
          }}
          onClick={(e) => {
            this.resetcount(e);
          }}
        >
          <IoIosRefresh
            style={{
              color: "blue",
              fontSize: "23px",
            }}
          ></IoIosRefresh>
        </Button>
      </div>
       </div>
        </div>
        <div style={{ height: "100%", width: "100%" }}>
          {this.state.fetching ? (
            <div>
              <p
                style={{ fontSize: "23px", marginTop: "25px", color: "#000" }}
              >
                <span>Loading...</span>
                <span>Please Wait...</span>
              </p>
              <div class="boxes body__loader dribbble">
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          ) : this.state.claimsdata.length === 0 ? (
            <div class="bot-ui">
              <div class="bot-head">
                <div class="eyes-container">
                  <div class="to-left eye"></div>
                  <div class="to-right eye"></div>
                </div>
              </div>
              <div class="bot-body">
                <div class="analyzer"></div>
              </div>
              <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                No Data Found
              </p>
            </div>
          ) : (
            <div>
              {this.state.searchtext === "" ? (
                <div id="actions">
                  <div style={{ marginLeft: "3%" }}>
                  <ReactPaginate
                    previousLabel={<IoIosArrowBack />}
                    nextLabel={<IoIosArrowForward />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={this.state.currentpage}
                  />
                </div>
                <div style={{marginLeft:"3%", marginRight:"3%"}}>
                    <Table
                    bordered
                    responsive
                    className="StandardTable"
                    >
                      <thead>
                        <tr className="thead">
                          <th>Action</th>
                          <th
                            onClick={() => this.sorting("ClaimID")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim ID{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sorting("Location")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Location{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() =>
                              this.sorting("patientAccountNumber")
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Account No{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sorting("MRN")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim No
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sorting("DateOfService")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOS{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sorting("PatientFirstName")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sorting("PatienthOB")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient DOB{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sorting("PlanCode")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PlanCode{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sorting("Pripayer")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Insurance Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sorting("Pripayerphone")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Insurance No
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sorting("PrimaryPolicyNo")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Policy No{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sorting("Physician")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Physician Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sorting("Age")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sorting("AgeGrp")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age Grp{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sorting("type")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Type{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sorting("ClaimStatus")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim Status{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>{" "}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.claimsdata &&
                          this.state.claimsdata.map((data, index) => (
                            <tr key={data._id}>
                              <td
                                onClick={() => (
                                  (
                                    <ActionViewClaim
                                      value={
                                        sessionStorage.setItem(
                                          "ClaimID",
                                          data.ClaimID
                                        ) ||
                                        sessionStorage.setItem(
                                          "Module",
                                          "Pending"
                                        ) ||
                                        sessionStorage.setItem(
                                          "ChartID",
                                          data.ChartID
                                        ) ||
                                        sessionStorage.setItem(
                                          "MRN",
                                          data.MRN
                                        ) ||
                                        sessionStorage.setItem(
                                          "Patient",
                                          data.Patient
                                        ) ||
                                        sessionStorage.setItem(
                                          "PatientDOB",
                                          data.PatientDOB
                                        ) ||
                                        sessionStorage.setItem(
                                          "Physician",
                                          data.Physician
                                        ) ||
                                        sessionStorage.setItem(
                                          "ClaimDate",
                                          data.ClaimDate
                                        ) ||
                                        sessionStorage.setItem(
                                          "Pripayer",
                                          data.Pripayer
                                        ) ||
                                        sessionStorage.setItem(
                                          "DateOfService",
                                          data.DateOfService
                                        ) ||
                                        sessionStorage.setItem(
                                          "ClaimDate",
                                          data.ClaimDate
                                        ) ||
                                        sessionStorage.setItem(
                                          "ClaimDBID",
                                          data._id
                                        ) ||
                                        sessionStorage.setItem(
                                          "AllocatedTo",
                                          data.Claimunder
                                        )
                                      }
                                    />
                                  ),
                                    (
                                      <Viewmoreeligibility
                                        value={
                                          sessionStorage.setItem(
                                            "modulename",
                                            "Pending"
                                          ) ||
                                          sessionStorage.setItem(
                                            "Eligibilityclaimid",
                                            data.ClaimID
                                          )
                                        }
                                      />
                                    ),
                                  (
                                    <ActionSimpleMenu
                                      value={
                                        sessionStorage.setItem(
                                          "ClaimID",
                                          data.ClaimID
                                        ) ||
                                        sessionStorage.setItem(
                                          "PatientFirstName",
                                          data.PatientFirstName
                                        ) ||
                                        sessionStorage.setItem(
                                          "PatientLastName",
                                          data.PatientLastName
                                        )
                                      }
                                    ></ActionSimpleMenu>
                                  )
                                )}
                              >
                                <ActionSimpleMenu />
                              </td>
                              <td>
                                {data.ClaimID}
                              </td>
                              <td>
                                {data.Location}
                              </td>
                              <td>
                                {data.patientAccountNumber}
                              </td>
                              <td>{data.MRN}</td>
                              <td>
                                {data.DateOfService}
                              </td>

                              <td>
                                {data.PatientFirstName}&nbsp;
                                {data.PatientLastName}
                              </td>
                              <td>
                                {moment(data.PatientDOB).format("MM/DD/YYYY")}
                              </td>
                              <td>
                                {data.PlanCode}
                              </td>
                              <td>
                                {data.InsuranceName
                                  ? data.InsuranceName
                                  : "-"}
                              </td>
                              <td>
                                <Row>
                                  <Col md="4">
                                    <Twilio value11={data.Pripayerphone} />
                                  </Col>
                                  <Col md="2">{data.Pripayerphone}</Col>
                                </Row>
                              </td>
                              <td>
                                {data.PrimaryPolicyNo}
                              </td>
                              <td>
                                {data.Physician}
                              </td>
                              <td>{data.Age}</td>
                              <td>{data.AgeGrp}</td>
                              <td>{data.type}</td>

                              <td>
                                {data.ClaimStatus ? data.ClaimStatus : "-"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    </div>
                  <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      forcePage={this.state.currentpage}
                    />
                  </div>
                </div>
              ) : this.state.searchdataresponse.length === 0 ? (
                <div>
                  <img src={noresult} />
                </div>
              ) : (
                <div id="actions">
                  <br />
                  <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                  <ReactPaginate
                    previousLabel={<IoIosArrowBack />}
                    nextLabel={<IoIosArrowForward />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.spageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick1}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={this.state.currentpagesearch}
                  />
                </div>
                <div style={{marginLeft:"3%", marginRight:"3%"}}>
                    <Table
                     responsive
                     bordered
                    className="StandardTable"
                    >
                      <thead>
                        <tr>
                          <td>Action</td>
                          <td
                            onClick={() => this.sortingfilter("ClaimID")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim ID{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </td>
                          <td
                            onClick={() => this.sortingfilter("Location")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Location{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </td>
                          <td
                            onClick={() =>
                              this.sortingfilter("patientAccountNumber")
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Account No{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </td>
                          <td
                            onClick={() => this.sortingfilter("MRN")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                          Claim No
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </td>
                          <td
                            onClick={() =>
                              this.sortingfilter("DateOfService")
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOS{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </td>
                          <td
                            onClick={() =>
                              this.sortingfilter("PatientFirstName")
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </td>
                          <td
                            onClick={() => this.sortingfilter("PatientDOB")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient DOB{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </td>
                          <td
                            onClick={() => this.sortingfilter("PlanCode")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PlanCode{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </td>
                          <td
                            onClick={() => this.sortingfilter("Pripayer")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Insurance Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </td>
                          <td
                            onClick={() =>
                              this.sortingfilter("Pripayerphone")
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Insurance No
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </td>
                          <td
                            onClick={() =>
                              this.sortingfilter("PrimaryPolicyNo")
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Policy No{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </td>
                          <td
                            onClick={() => this.sortingfilter("Physician")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Physician Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </td>
                          <td
                            onClick={() => this.sortingfilter("Age")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </td>
                          <td
                            onClick={() => this.sortingfilter("AgeGrp")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age Grp{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </td>
                          <td
                            onClick={() => this.sortingfilter("type")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Type{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </td>
                          <td
                            onClick={() => this.sortingfilter("ClaimStatus")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim Status{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.searchdataresponse &&
                          this.state.searchdataresponse.map((data, index) => (
                            <tr key={data._id}>
                              <td
                                onClick={() => (
                                  (
                                    <ActionViewClaim
                                      value={
                                        sessionStorage.setItem(
                                          "ClaimID",
                                          data.ClaimID
                                        ) ||
                                        sessionStorage.setItem(
                                          "Module",
                                          "Pending"
                                        ) ||
                                        sessionStorage.setItem(
                                          "ChartID",
                                          data.ChartID
                                        ) ||
                                        sessionStorage.setItem(
                                          "MRN",
                                          data.MRN
                                        ) ||
                                        sessionStorage.setItem(
                                          "Patient",
                                          data.Patient
                                        ) ||
                                        sessionStorage.setItem(
                                          "PatientDOB",
                                          data.PatientDOB
                                        ) ||
                                        sessionStorage.setItem(
                                          "Physician",
                                          data.Physician
                                        ) ||
                                        sessionStorage.setItem(
                                          "ClaimDate",
                                          data.ClaimDate
                                        ) ||
                                        sessionStorage.setItem(
                                          "Pripayer",
                                          data.Pripayer
                                        ) ||
                                        sessionStorage.setItem(
                                          "DateOfService",
                                          data.DateOfService
                                        ) ||
                                        sessionStorage.setItem(
                                          "ClaimDate",
                                          data.ClaimDate
                                        ) ||
                                        sessionStorage.setItem(
                                          "ClaimDBID",
                                          data._id
                                        ) ||
                                        sessionStorage.setItem(
                                          "AllocatedTo",
                                          data.Claimunder
                                        )
                                      }
                                    />
                                  )
                                  ,
                                    (
                                      <Viewmoreeligibility
                                        value={
                                          sessionStorage.setItem(
                                            "modulename",
                                            "Pending"
                                          ) ||
                                          sessionStorage.setItem(
                                            "Eligibilityclaimid",
                                            data.ClaimID
                                          )
                                        }
                                      />
                                    ),
                                  (
                                    <ActionSimpleMenu
                                      value={
                                        sessionStorage.setItem(
                                          "ClaimID",
                                          data.ClaimID
                                        ) ||
                                        sessionStorage.setItem(
                                          "PatientFirstName",
                                          data.PatientFirstName
                                        ) ||
                                        sessionStorage.setItem(
                                          "PatientLastName",
                                          data.PatientLastName
                                        )
                                      }
                                    ></ActionSimpleMenu>
                                  )
                                )}
                              >
                                <ActionSimpleMenu />
                              </td>
                              <td>
                                {data.ClaimID}
                              </td>
                              <td>
                                {data.Location}
                              </td>
                              <td>
                                {data.patientAccountNumber}
                              </td>
                              <td>{data.MRN}</td>

                              <td>
                                {data.DateOfService}
                              </td>

                              <td>
                                {data.PatientFirstName}&nbsp;
                                {data.PatientLastName}
                              </td>
                              <td>
                                {moment(data.PatientDOB).format("DD/MM/YYYY")}
                              </td>
                              <td>
                                {data.PlanCode}
                              </td>
                              <td>
                                {data.InsuranceName
                                  ? data.InsuranceName
                                  : "-"}
                              </td>
                              <td>
                                <Row>
                                  <Col md="4">
                                    <Twilio value11={data.Pripayerphone} />
                                  </Col>
                                  <Col md="2">{data.Pripayerphone}</Col>
                                </Row>
                              </td>
                              <td>
                                {data.PrimaryPolicyNo}
                              </td>
                              <td>
                                {data.Physician}
                              </td>
                              <td>{data.Age}</td>
                              <td>{data.AgeGrp}</td>
                              <td>{data.type}</td>

                              <td>
                                {data.ClaimStatus ? data.ClaimStatus : "-"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    </div>
                  <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.spageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick1}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      forcePage={this.state.currentpagesearch}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
    </div>
 
    );
  }
}
