import React, { createContext, useContext, useState, useEffect } from "react";
import { io } from "socket.io-client";

const SocketContext = createContext();

// Custom hook to use socket
export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [uploadSocket, setUploadSocket] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [notInserted, setNotInserted] = useState(0);
  const [inserted, setInserted] = useState(0);
  const [duplicate, setDuplicate] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [status, setStatus] = useState("");
  const [fileName, setFileName] = useState("");
  const [queuing, setQueuing] = useState(0);
  const [progressCount, setProgressCount] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("select");
  const [duration, setDuration] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileMeta, setFileMeta] = useState(null); // Store file metadata (name, size)

  // Retrieve clientId and roomId from session storage
  const clientId = parseInt(sessionStorage.getItem("clientid"));
  // URLs for the main server and upload server WebSocket connections
  const MAIN_SERVER_URL = 'https://devapirevshield.imagnumhealthcare.com/socket.io'; // main server WebSocket URL
  const UPLOAD_SERVER_URL = 'https://apidemorevshield.imagnumhealthcare.com/socket.io'; // upload server WebSocket URL

  // Toggle visibility for the progress card
  const toggleCard = () => {
    setIsVisible(!isVisible);
  };

  // Initialize the socket connection and set up event listeners
// Initialize the socket connection and set up event listeners for both servers
useEffect(() => {
  // Main server socket for handling general events
  const mainServerSocket = io(MAIN_SERVER_URL, {
    path: '/socket.io',
    withCredentials: true,
    transports: ["websocket"],
    secure: true,
    reconnection: true,
    reconnectionAttempts: 10,
    reconnectionDelay: 2000,
  });

  // Upload server socket for handling file-specific events
  const uploadServerSocket = io(UPLOAD_SERVER_URL, {
    path: '/socket.io',
    withCredentials: true,
    transports: ["websocket"],
    secure: true,
    reconnection: true,
    reconnectionAttempts: 10,
    reconnectionDelay: 2000,
  });

  // Handle main server connection
  mainServerSocket.on("connect", () => {
    console.log("Main server socket connected:", mainServerSocket.id);
    mainServerSocket.emit("registerClient", { clientId });
    if (roomId) {
      mainServerSocket.emit("joinRoom", roomId);
    }
  });

  // Handle upload server connection
  uploadServerSocket.on("connect", () => {
    console.log("Upload server socket connected:", uploadServerSocket.id);
    uploadServerSocket.emit("registerClient", { clientId });
    if (roomId) {
      uploadServerSocket.emit("joinRoom", roomId);
    }
  });

  // Reconnection logic for both servers
  mainServerSocket.on("reconnect", (attempt) => {
    console.log(`Reconnected to main server after ${attempt} attempts`);
    mainServerSocket.emit("registerClient", { clientId });
    if (roomId) {
      mainServerSocket.emit("joinRoom", roomId);
    }
  });

  uploadServerSocket.on("reconnect", (attempt) => {
    console.log(`Reconnected to upload server after ${attempt} attempts`);
    uploadServerSocket.emit("registerClient", { clientId });
    if (roomId) {
      uploadServerSocket.emit("joinRoom", roomId);
    }
  });

  mainServerSocket.on("disconnect", (reason) => {
    console.warn("Main server socket disconnected:", reason);
    if (reason === "io server disconnect") {
      mainServerSocket.connect();
    }
  });

  uploadServerSocket.on("disconnect", (reason) => {
    console.warn("Upload server socket disconnected:", reason);
    if (reason === "io server disconnect") {
      uploadServerSocket.connect();
    }
  });

  setSocket(mainServerSocket);
  setUploadSocket(uploadServerSocket); // Save the upload server socket

  return () => {
    mainServerSocket.close();
    uploadServerSocket.close();
  };
}, [clientId, roomId]);
  
  
  
  // Listen to socket events for upload progress and completion
  // Register clientId and roomId on socket connection
  useEffect(() => {
    if (uploadSocket && clientId) {
      //  const client = clientId.toString();
      // console.log("client id type", typeof clientId);
      uploadSocket.emit("registerClient", { clientId });
      console.log("Client registered context:", { clientId });
      console.log(
        "Setting up event listeners for upload progress and completion."
      );

      uploadSocket.on("uploadProgress", (data) => {
        console.log("Received upload progress:", data); // Ensure this logs the expected structure
        if (data.clientId === clientId) {
          setProgress(data.percentage);
          setNotInserted(data.notinserted);
          setInserted(data.inserted);
          setDuplicate(data.duplicate);
          setTotalCount(data.totalRows);
          setStatus(data.status);
          setFileName(data.filename);
          setQueuing(data.queuing);
          setProgressCount(data.ProcessedCount_TotalRecords);
          setUploadStatus("uploading");
          setIsCardVisible(true);
          // Fallback for fileMeta in case the backend misses the type
          const fileMeta = {
            name: data.filename,
            size: data.size || 0, // Default to 0 if size is not provided
            type: data.type || "application/octet-stream", // Default MIME type if not provided
          };

          setFileMeta(fileMeta); // Set fileMeta with fallback
           // Save the clientId along with fileMeta in sessionStorage
          sessionStorage.setItem("fileMeta", JSON.stringify({ ...fileMeta, clientId }));
          sessionStorage.setItem("uploadStatus", "uploading" )
        } else {
          console.error("Invalid upload progress data:", data);
        }
      });

      uploadSocket.on("uploadComplete", (data) => {
        console.log("Upload complete client id:", data); // Log upload completion
        if (data.clientId === clientId) {
          setDuration(data.uploadTime);
          setUploadStatus("done");
          setIsCardVisible(false);
          sessionStorage.setItem("uploadStatus", "done"); // Persist the upload status in sessionStorage
        } else {
          console.error("Invalid upload complete data:", data);
        }
      });

      return () => {
        uploadSocket.off("uploadProgress");
        uploadSocket.off("uploadComplete");
      };
    } else if (uploadSocket && roomId) {
      console.log(
        "Setting up event listeners for upload progress and completion."
      );
      uploadSocket.on("uploadProgress", (data) => {
        console.log("Received upload progress:", data); // Ensure this logs the expected structure
        if (data.roomId === roomId) {
          setProgress(data.percentage);
          setNotInserted(data.notinserted);
          setInserted(data.inserted);
          setDuplicate(data.duplicate);
          setTotalCount(data.totalRows);
          setStatus(data.status);
          setFileName(data.filename);
          setQueuing(data.queuing);
          setProgressCount(data.ProcessedCount_TotalRecords);
        } else {
          console.error("Invalid upload progress data:", data);
        }
      });

      uploadSocket.on("uploadComplete", (data) => {
        console.log("Upload complete roomID:", data); // Log upload completion
        if (data.roomId === roomId) {
          setDuration(data.uploadTime);
          setUploadStatus("done");
          sessionStorage.setItem("uploadStatus", "done"); // Persist the upload status in sessionStorage
        } else {
          console.error("Invalid upload complete data:", data);
        }
      });

      return () => {
        uploadSocket.off("uploadProgress");
        uploadSocket.off("uploadComplete");
      };
    }
    return () => {
      if (uploadSocket && roomId) {
        uploadSocket.emit("leaveRoom", roomId); // Leave the room on cleanup
        console.log(`Left room: ${roomId}`);
      }
    };
  }, [uploadSocket, clientId, roomId]);

  return (
    <SocketContext.Provider
      value={{
        socket,
        uploadSocket,
        progress,
        setProgress,
        notInserted,
        setNotInserted,
        inserted,
        setInserted,
        duplicate,
        setDuplicate,
        totalCount,
        setTotalCount,
        status,
        setStatus,
        fileName,
        setFileName,
        queuing,
        setQueuing,
        progressCount,
        setProgressCount,
        uploadStatus,
        setUploadStatus,
        duration,
        setDuration,
        toggleCard,
        isVisible,
        roomId,
        setRoomId,
        isCardVisible,
        setIsCardVisible,
        fileMeta,
        setFileMeta,
        setSelectedFile,
        selectedFile,
        clientId,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
