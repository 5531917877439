import { Component } from "react";
import { DatePicker } from "antd";
import { Input } from "reactstrap";
import "../../Styles/cardalloc.css";
import moment from "moment";
import { IoIosRefresh } from "react-icons/io";
import axios from "axios";
import {  Row, Col } from "react-bootstrap";
import Newsidebar from "./../Dashboard/Newsidebar";
import { ip } from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Allocation from "./Allocation";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";

const { RangePicker } = DatePicker;

class CardAlloc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //reallocatemodal: false,
      reallocmodal: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      AllocatedTo: sessionStorage.getItem("AllocatedTo"),
      anchorEl: null,
      valueOfInput1: "",
      valueOfInput2: "",
      querysearch: "",
      querysearch1: "",
      querysearch2: "",
      querysearch3: "",
      querysearch4: "",
      clientslist: [],
      client: sessionStorage.getItem("clientid"),
      clientname: sessionStorage.getItem("clientname"),
      role: sessionStorage.getItem("role_type"),
      opendropdown: false,
      filterstatus: "custom",
      Location: "",
      locationslist: [],
      claimids: [],
      searchid: "",
      openModal: true,
      openModal1: false,
      separate: [],
      separate1: [],
      separate2: [],
      separate3: [],
      separate4: [],
    };
  }

  toggledropdown = () =>
    this.setState({ opendropdown: !this.state.opendropdown });
  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });
  handleClose = () => this.setState({ anchorEl: null });

  getsearchid = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    if (this.state.searchid === "") {
      toast.error("Please fill the search value!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log("Show id");
    } else {
       await axios
        .get(
          `${ip}/allocation/keyindicators/search?claimid=${this.state.searchid}`,
          {
            headers: headers,
          }
        )
        .then((res) => {
          // if (this.state.claimids.length !== 0) {
          //   let claimlist = [];
          //   let output = this.state.claimids.split(",");
          //   this.setState({
          //     querysearch: res.data.DataView[2],
          //   });
          // }
          console.log(res, "rrrrr");
          if(res.data.DataView.length === 0){
            toast.error("Claim id not found", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }else{
            this.setState({
              querysearch: res.data.DataView[0],
              querysearch1: res.data.DataView[1],
              querysearch2: res.data.DataView[2],
              querysearch3: res.data.DataView[3],
              querysearch4: res.data.DataView[4],
              separate: this.state.querysearch.split(""),
              separate1: this.state.querysearch1.split(""),
              separate2: this.state.querysearch2.split(""),
              separate3: this.state.querysearch3.split(""),
              separate4: this.state.querysearch4.split(""),
            });
          }
   

          console.log(
            res,
            this.state.separate,
            this.state.querysearch1,
            this.state.separate1,
            this.state.querysearch2,
            "response"
          );
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 500) {
              toast.error("Claim id not found", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }}
        });
    }
    console.log(this.state.querysearch, this.state.separate, "secondcardname");
  };
  reset = () => {
    this.setState({
      searchid: "",
      querysearch: "",
      querysearch1: "",
      querysearch2: "",
      querysearch3: "",
      querysearch4: "",
    });
  };
  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        if (res.data.clientonboard.length !== 0) {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        } else {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        }
      })
      .catch((err) => {});
  };

  //get Location
  getLocation = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      url = `${ip}/location/all/list?clientId=${this.state.client}`;
    } else {
      url = `${ip}/location/all/list`;
    }
    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        let data = res.data.location;
        data = data.filter((item) => {
          return item.clientId == this.state.client;
        });
        data.sort((a, b) => {
          return a.Location < b.Location ? -1 : 1;
        });

        this.setState({ locationslist: data });
      })

      .catch((err) => {});
  };

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  icon1 = () => {
    this.setState({
      openModal: false,
      openModal1: true,
    });
  };

  icon2 = () => {
    this.setState({
      openModal: true,
      openModal1: false,
    });
  };
  handleChange = (selectedOptions) => {
    let Location = selectedOptions.map((data) => {
      return data.Location;
    });
    this.setState(
      {
        Location: Location[0],
      },
      () => {
        this.getclients();
        this.getLocation();
      }
    );
  };
  valuedate = (option, e) => {
    if (option === "yesterday") {
      this.setState({
        valueOfInput1: e.target.value,
        valueOfInput2: moment().subtract(1, "days").format("MM-DD-YYYY"),
        filterstatus: option,
      });
    } else {
      this.setState({
        valueOfInput1: e.target.value,
        valueOfInput2: moment().format("MM-DD-YYYY"),
        filterstatus: option,
      });
    }
  };
  componentDidMount() {
    this.getclients();
    this.getLocation();

    sessionStorage.removeItem("querystartdate");
    sessionStorage.removeItem("queryenddate");
    sessionStorage.removeItem("queryLocation");
  }
  render() {
    return (
      <div>
      <Newsidebar name="Key Indicators" />

      <div className="align-row" style={{ overflow: "hidden" }}>
        <ToastContainer></ToastContainer>
        <div className="a-card-container">
        <div style={{ display: "flex", flexDirection: "row", gap:"20px" }}>
        <Col md="4">
        <form>
          <div class="input-group mb-4">
            <input
              style={{ zIndex: 0.5 }}
              type="search"
              placeholder="Search by ID?"
              aria-describedby="button-addon5"
              class="form-control"
              value={this.state.searchid}
              onChange={(e) => {
                this.setState({ searchid: e.target.value });
              }}
            />
            <div class="input-group-append" style={{ zIndex: 1 }}>
              <div class="btn-group">
                <button
                style={{background:"#002755", color:"white"}}
                  id="button-addon5"
                  type="submit"
                  class="btn btn-success"
                  onClick={(e) => {
                    e.preventDefault();
                    this.getsearchid();
                  }}
                >
                  <i class="fa fa-search"></i>
                </button>
                <button
                style={{background:"#EB1A23", color:"white"}}
                  id="button-addon5"
                  type="submit"
                  class="btn btn-dark"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent the default form submission
                    this.reset();
                  }}
                >
                  <IoIosRefresh
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                    }}
                  ></IoIosRefresh>
                </button>
              </div>
            </div>
          </div>
        </form>
      </Col>
        <section>
        {this.state.role === "SuperAdmin" ? (
          <ButtonDropdown
            isOpen={this.state.opendropdown}
            toggle={this.toggle}
            style={{
              width: "12%",
              height:"5%",
              position: "inherit",
            }}
          >
            <DropdownToggle caret>
              {this.state.clientname ? this.state.clientname : "All"}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() =>
                  this.setState({ clientname: "", client: "" }, () =>
                    this.getLocation()
                  )
                }
              >
                All
              </DropdownItem>
              {this.state.clientslist.map((e) => {
                return (
                  <DropdownItem
                    id={e.clientId}
                    key={e.clientId}
                    onClick={(e) => {
                      this.setState(
                        {
                          client: e.target.id,
                          clientname: e.target.innerText,
                        },
                        () => this.getLocation()
                      );
                    }}
                  >
                    {e.client}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </ButtonDropdown>
        ) : null}
        {this.state.client && (
          // <Input
          //   type="select"
          //   className="form-control form-select"
          //   onChange={(e) =>
          //     this.setState({
          //       Location: e.target.value,
          //     })
          //   }
          //   style={{
          //     width: "12%",
          //     marginLeft: "4%",
          //     position: "inherit",
          //   }}
          // >
          //   <option value="">All Locations</option>
          //   {this.state.locationslist.map((e) => (
          //     <option value={e.Location}>{e.Location}</option>
          //   ))}
          // </Input>

          <Typeahead
            style={{height:"35px"}}
            id="typeahead-cardAlloc"
            clearButton={true}
            onChange={this.handleChange}
            options={this.state.locationslist}
            labelKey={(option) => `${option.Location}`}
            placeholder="All Location"
            selected={this.state.selected}
          />
        )}
        </section>
          <section>        
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize:"13px",
            gap:"20px"
          }}>
          <Input
            type="select"
            style={{  height:"5%" ,
            fontSize:"13px"
          }}
            className="form-control form-select"
            onChange={(e) => {
              const index = e.target.selectedIndex;
              const el = e.target.childNodes[index];
              const option = el.getAttribute("title");
              this.valuedate(option, e);
              this.setState(() => {
                sessionStorage.setItem(
                  "querystartdate",
                  this.state.valueOfInput1
                );
                sessionStorage.setItem(
                  "queryenddate",
                  this.state.valueOfInput2
                );
              });
            }}
          >
            <option value="" title="custom">
              Custom
            </option>
            <option value={moment().format("MM-DD-YYYY")} title="">
              Today
            </option>
            <option
              value={moment().subtract(1, "days").format("MM-DD-YYYY")}
              title="yesterday"
            >
              Yesterday
            </option>
            <option
              value={moment().subtract(3, "days").format("MM-DD-YYYY")}
              title=""
            >
              Last 3 days
            </option>
            <option
              value={moment().subtract(7, "days").format("MM-DD-YYYY")}
              title=""
            >
              Last 7 days
            </option>
            <option
              value={moment().subtract(30, "days").format("MM-DD-YYYY")}
              title=""
            >
              Last 30 days
            </option>
            <option
              value={moment().subtract(365, "days").format("MM-DD-YYYY")}
              title=""
            >
              Last 365 days
            </option>
          </Input>
          {this.state.filterstatus === "custom" && (
            <RangePicker
              format="MM-DD-YYYY"
              onChange={(dates, datestring) => {
                this.setState(
                  {
                    valueOfInput1: datestring[0],
                    valueOfInput2: datestring[1],
                  },
                  () => {
                    sessionStorage.setItem(
                      "querystartdate",
                      this.state.valueOfInput1
                    );
                    sessionStorage.setItem(
                      "queryenddate",
                      this.state.valueOfInput2
                    );
                  }
                );
              }}
              height={"40%"}
              style={{
                borderColor: "gray",
                fontSize:"13px",
                width:"150%"
              }}
            />
          )}
        </div>
          </section>
        </div>
          <Allocation
            search={this.state.querysearch}
            search1={this.state.querysearch1}
            search2={this.state.querysearch2}
            search3={this.state.querysearch3}
            search4={this.state.querysearch4}
            gridicon={this.state.openModal}
            gridicon1={this.state.openModal1}
            client={this.state.client}
            startdate={this.state.valueOfInput1}
            enddate={this.state.valueOfInput2}
            Location={this.state.Location}
          />
        </div>
      </div>
      </div>
    );
  }
}

export default CardAlloc;
