import React, { Component } from "react";
import axios from "axios";
import "../../../Styles/configure.css";
import Newsidebar from "../../Dashboard/Newsidebar";
 
import ReactPaginate from "react-paginate";
import "../../../Styles/loader.scss";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import {
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import { BiUpArrowAlt } from "react-icons/bi";
import { BiDownArrowAlt } from "react-icons/bi";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { styled } from "@mui/material/styles";
import Checkbox from "../../Allocation/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Col } from "reactstrap";
import { Button, Label } from "reactstrap";
import {  Row } from "react-bootstrap";
import Select from "react-dropdown-select";
import { ip } from "../../../Api";

class Denialtableallocate3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalunallocate: false,
      doioptionSelected: [],
      modalunallocate1: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      username: sessionStorage.getItem("username"),
      filteredclaims: sessionStorage.getItem("filteredclaims"),
      completedclaims: sessionStorage.getItem("completedclaims"),

      claimsdata: [],
      UserAllocate:
      sessionStorage.getItem("firstname") +
      " " +
      sessionStorage.getItem("lastname"),
      reports: [],
      fetching: true,
      ar: [],
      loading: true,
      any: [],
      statuslist: [],
      PatientFirstName: "",
      PatientLastName: "",
      webclaim: [],
      patientAccountNumber: "",
      searchtext: "",
      offset1: 0,
      searchclaimdata: [],
      payercategories: [],
      cptdata: [],
      totalClaims: [],
      status: [],
      optionSelected: [],
      checkfiltertable: sessionStorage.getItem("checkfiltertable"),
      Checkcompletetable: sessionStorage.getItem("Checkcompletetable"),
      nottouchtable: sessionStorage.getItem("nottouchtable"),
      username1: "",
      subcategoryname: sessionStorage.getItem("querysub"),
      claimfield: "",
      user: "",
      claimvalue: "",
      claimtype: "",
      value: sessionStorage.getItem("Allocatedenialtable"),
      denialpageno: sessionStorage.getItem("denialpageno"),
      payervalue: [],
      currentpage1: 0,
      perPage1: 10,
      currentpage2: 0,
      perPage2: 10,
      currentpage3: 0,
      perPage3: 10,
      cptvalue: [],
      userdetails: [],
      query: [],
      ids: [],
      offset: 0,
      currentpage: 0,
      currentpagesearch: 0,
      categoryname: sessionStorage.getItem("querycategory"),
      perPage: 10,
      checkedAll: false,
      alldataresponse: [],
      searchdataresponse: [],
      selecteduser: [],
      users: [],
      user_id: sessionStorage.getItem("user_id"),
      role: sessionStorage.getItem("role_type"),
      client: sessionStorage.getItem("clientid"),
      clientname: sessionStorage.getItem("clientname"),
      clientslist: [],
      opendropdown: false,
      nottouch: [],
      complete: [],
      Location: "",
      locationslist: [],
      count: 10,
      agegrplist: [
        "0-30days",
        "31-60days",
        "61-90days",
        "91-120days",
        "121-180days",
        "181-365days",
        "above 1year",
      ],
      doiagegrplist: [
        "0-30days",
        "31-60days",
        "61-90days",
        "91-120days",
        "121-180days",
        "181-365days",
        "above 1year",
      ],
      userstatuslist: ["Completed", "TO-DO", "Not Touch"],
      userstatus: "",
      agegrp: "",
      doiagegrp: "",
      unallocateid: "",
      sortstate: "ASC",
      openicon: false,
      claimids: [],
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  toggle1 = () => {
    const { modalunallocate } = this.state;
    this.setState({ modalunallocate: !modalunallocate });
  };
  toggle2 = () => {
    const { modalunallocate1 } = this.state;
    this.setState({ modalunallocate1: !modalunallocate1 });
  };
  handleChange = (selected) => {
    this.setState({
      optionSelected: selected,
    });
  };
  doihandleChange = (selected) => {
    this.setState({
      doioptionSelected: selected,
    });
  };
  checkboxsearchall = () => {
    if (this.state.checkedAll === false) {
      let idvalue = this.state.nottouch.map((data) => data._id);
      this.setState(
        { checkedAll: !this.state.checkedAll, ids: idvalue },
        () => {
          this.toggle2();
        }
      );
    } else {
      this.setState({ checkedAll: !this.state.checkedAll, ids: [] }, () => {});
    }
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getallocateclaim();
      }
    );
  };

  allocateclaims = () => {
    const isValid = this.validate();
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        ids: this.state.ids,
        logdetails:{
          // newUser:this.state.username,
          admin:this.state.UserAllocate,
          module:"Denial Management Allocation",
          clientId:this.state.client,

          // ClaimID:["11751701/25/2022INS-206"],
          // admin:"demoImagnum",
          // module:"Allocation",
          // clientId":2004
          }
      };
       axios
        .put(
          `${ip}/allocation/allocate/${this.state.selecteduser[0]._id}`,
          value,
          {
            headers: headers,
          }
        )
        .then((res) => {
          alert(
            `claims allocated to user ${this.state.selecteduser[0].first_name}`
          );
          this.getallocateclaim();
          this.resetuser();
          this.toggle();
          this.toggle2();
          // window.location.reload();
        })

        .catch((err) => {});
    }
  };
  bulkunallocatedclaims = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      ids: this.state.ids,
      logdetails:{
        // newUser:this.state.username,
        admin:this.state.UserAllocate,
      }
    };
     axios
      .put(`${ip}/claim/updatemultipleclaimunder`, value, {
        headers: headers,
      })
      .then((res) => {
        alert(`claim unallocated successfully`);
        this.getallocateclaim();
        this.resetuser();
        this.toggle1();
        this.toggle2();
        // window.location.reload();
      })

      .catch((err) => {});
  };
  unallocateclaims = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      id: this.state.unallocateid,
      logdetails:{
        // newUser:this.state.username,
        admin:this.state.UserAllocate,
      }
    };
     axios
      .put(`${ip}/claim/updateclaimunder`, value, {
        headers: headers,
      })
      .then((res) => {
        alert(`claim unallocated successfully`);
        this.getallocateclaim();
        this.resetuser();
        this.toggle1();
        // window.location.reload();
      })

      .catch((err) => {});
  };
  toggledropdown = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };

  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        if (res.data.clientonboard.length !== 0) {
          this.setState({
            clientslist: res.data.clientonboard,
            // clientname: res.data.clientonboard[0].client,
          });
        } else {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        }
      })
      .catch((err) => {});
  };
  getClaimStatus = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

     await axios
      .get(`${ip}/claimstatus/all`, { headers: headers })
      .then((res) => {
        this.setState({
          statuslist: res.data.claimstatus,
        });
      })
      .catch((err) => {});
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getallocatedclaims();
      }
    );
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getallocateclaim = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line

    let url;
    if (this.state.querystartdate && this.state.queryenddate) {
      url = `${ip}/denial/claim?${this.state.value}&allocatedpageno=${this.state.denialpageno}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
    } else {
      url = `${ip}/denial/claim?${this.state.value}&allocatedpageno=${this.state.denialpageno}`;
    }
     await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        res.data.claimdetails.map((values) => {
          return (
            values.username === this.state.username &&
            this.setState({
              totalClaims: values.filteredClaims,
              nottouch: values.nottouchedClaims,
              complete: values.completedClaims,
              fetching: false,
              alldataresponse: values.nottouchedClaims,
            })
          );
        });

        const slice = this.state.nottouch.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        this.setState({
          pageCount1: Math.ceil(
            this.state.nottouch.length / this.state.perPage
          ),
          nottouch: slice,
        });
      })
      .catch((err) => {});
  };

  //getusers
  getallocatedclaims = async () => {
    //this.setState({ fetching: true });
    //api call to get all users

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    if (
      this.state.role === "Admin" ||
      this.state.role === "SuperAdmin" ||
      this.state.role === "Team Manager"
    ) {
      let url;
      if (this.state.client) {
        if (this.state.Location) {
          url = `${ip}/allocation/allocated/all?clientId=${
            this.state.client
          }&pageno=${this.state.currentpage + 1}&count=${
            this.state.perPage
          }&Location=${this.state.Location}`;
        } else {
          url = `${ip}/allocation/allocated/all?clientId=${
            this.state.client
          }&pageno=${this.state.currentpage + 1}&count=${this.state.perPage}`;
        }
      } else {
        url = `${ip}/allocation/allocated/all?pageno=${
          this.state.currentpage + 1
        }&count=${this.state.perPage}`;
      }
       axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          this.setState({
            claimsdata: res.data.claims,

            // any: res.data.claims,
          });
        })
        .catch((err) => {});
    } else if (this.state.role === "Team Member") {
       axios
        .get(`${ip}/claim/user?userid=${this.state.user_id}`, {
          headers: headers,
        })
        .then((res) => {
          const slice = res.data.Claims.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          this.setState({
            claimsdata: slice,
          });
        })
        .catch((err) => {});
    }
  };

  getclaims = async () => {
    //this.setState({ fetching: true });
    //api call to get all users
    this.setState({ loading: true });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    if (
      this.state.role === "Admin" ||
      this.state.role === "SuperAdmin" ||
      this.state.role === "Team Manager"
    ) {
      let url;
      if (this.state.client) {
        if (this.state.Location) {
          url = `${ip}/allocation/allocated/all?clientId=${
            this.state.client
          }&pageno=${this.state.currentpage + 1}&count=${
            this.state.perPage
          }&Location=${this.state.Location}`;
        } else {
          url = `${ip}/allocation/allocated/all?clientId=${
            this.state.client
          }&pageno=${this.state.currentpage + 1}&count=${this.state.perPage}`;
        }
      } else {
        url = `${ip}/allocation/allocated/all?pageno=${
          this.state.currentpage + 1
        }&count=${this.state.perPage}`;
      }
       axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          this.setState({
            claimsdata: res.data.claims,

            // any: res.data.claims,

            loading: false,
          });
          console.log(res, "Full data");
          const mappedData = res.data.claims.map((data) => {
            return {
              ClaimID: data.ClaimID,
              DateOfService: data.DateOfService,
              TlfAge: data.TlfAge,
              DOIAge: data.DOIAge,
              DOIAgeGrp: data.DOIAgeGrp,
              type: data.type,
            };
          });
        
          // Update the state with the new array
          this.setState({
            any: mappedData,
          });
        })
        .catch((err) => {});
    } else if (this.state.role === "Team Member") {
       axios
        .get(`${ip}/claim/user?userid=${this.state.user_id}`, {
          headers: headers,
        })
        .then((res) => {
          const slice = res.data.Claims.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          this.setState({
            claimsdata: slice,
          });
        })
        .catch((err) => {});
    }
  };

  getcpt = async () => {
    //api call to get all cpt data
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     axios
      .get(`${ip}/cptmaster/all`, { headers: headers })
      .then((res) => {
        this.setState({
          cptdata: res.data.cpt,
        });
      })
      .catch((err) => {});
  };

  getusers = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(`${ip}/users/all?clientId=${this.state.client}`, { headers: headers })
      .then((res) => {
        let data = res.data.user;
        // this.setState({ userdetails: res.data.user });
        if(this.state.client){
          data = data.filter((item) => {
            return (
              item.role === "Team Member" 
            );
          });
        }
        else{
          data = data.filter((item) => {
            return (
              item.role === "Admin" || item.role === "SuperAdmin" 
            );
          });
        }
        

        this.setState({ users: data });
      })
      .catch((err) => {});
  };
  sorting = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.nottouch].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ nottouch: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.nottouch].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ nottouch: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };
  resetuser = () => {
    this.setState({
      ids: [],
    });
  };
  reset = () => {
    this.setState(
      {
        claimfield: "",
        claimids: [],
        claimvalue: "",
        username: "",
        userstatus: [],
        user: "",
        ar: [],
        claimtype: "",
        status: [],
        agegrp: "",
        payervalue: [],
        cptvalue: [],
        searchdataresponse: [],
        searchclaimdata: [],
        searchtext: "",
        currentpage: 0,
        currentpagesearch: 0,
        checkedAll: false,
        perPage: 10,
        doiagegrp: "",
        optionSelected: [],
        doioptionSelected: [],
      },
      () => this.getallocatedclaims()
    );
  };

  componentDidMount() {
    
    this.getallocatedclaims();
    this.getcpt();
    this.getclaims();

    this.getallocateclaim();
    this.getClaimStatus();
    this.getclients();
    this.getusers();
  }

  addsinglecheckboxid = (data, check) => {
    if (check) {
      this.setState({ ids: [...this.state.ids, data] }, () => this.toggle2());
    } else {
      let arr = this.state.ids.slice();
      let val = arr.filter((id) => {
        return id !== data;
      });
      this.setState({ ids: val }, () => {
        this.toggle2();
      });
    }
  };

  checkBoxAll = () => {
    if (this.state.checkedAll === false) {
      let idvalue = this.state.nottouch.map((data) => data._id);
      this.setState(
        { checkedAll: !this.state.checkedAll, ids: idvalue },
        () => {
          this.toggle2();
        }
      );
    } else {
      this.setState({ checkedAll: !this.state.checkedAll, ids: [] }, () => {});
    }
  };

  validate = () => {
    let hasErr = "true";
    let selectuserErr = "";
    if (this.state.selecteduser.length === 0) {
      selectuserErr = "This field is required";
      this.setState({ selectuserErr });
      hasErr = false;
    }
    return hasErr;
  };
  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const checkboxstyle = {
      marginTop: "15px",
    };
    return (
      <div className="align-row">
        <Newsidebar name="Allocated Claims" />
        <div className="align-column">

          <br />

          <br />
          <br />

          <div style={{ display: "flex", flexDirection: "row" }}>
            {this.state.role === "SuperAdmin" ? (
              <ButtonDropdown
                isOpen={this.state.opendropdown}
                toggle={this.toggledropdown}
                style={{ width: "12%", marginLeft: "4%", position: "inherit" }}
              >
                <DropdownToggle caret>
                  {this.state.clientname ? this.state.clientname : "All"}
                </DropdownToggle>
                <DropdownMenu>
                  {this.state.clientslist.map((e) => {
                    return (
                      <DropdownItem
                        id={e.clientId}
                        key={e.clientId}
                        onClick={(e) =>
                          this.setState(
                            {
                              client: e.target.id,
                              clientname: e.target.innerText,
                            },
                            () => this.getallocatedclaims()
                          )
                        }
                      >
                        {e.client}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </ButtonDropdown>
            ) : null}
          </div>

          {this.state.nottouchtable && (
            <div style={{ height: "100%", width: "100%" }}>
              {this.state.fetching ? (
                // <img src={loading} />
                <div>
                  <p
                    style={{
                      fontSize: "23px",
                      marginTop: "10px",
                      color: "#000",
                    }}
                  >
                    <span>Loading...</span>
                    <span>Please Wait...</span>
                  </p>
                  <div class="boxes body__loader dribbble">
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              ) : this.state.claimsdata.length === 0 ? (
                <div class="bot-ui">
                  <div class="bot-head">
                    <div class="eyes-container">
                      <div class="to-left eye"></div>
                      <div class="to-right eye"></div>
                    </div>
                  </div>
                  <div class="bot-body">
                    <div class="analyzer"></div>
                  </div>
                  <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                    No Data Found
                  </p>
                </div>
              ) : (
                <div>
                  <div>
                  
                  <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                  <ReactPaginate
                    forcePage={this.state.currentpage}
                    previousLabel={<IoIosArrowBack />}
                    nextLabel={<IoIosArrowForward />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount1}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick1}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                        style={{ padding: "3%" }}
                      >
                        <TableHead>
                          <TableRow>
                            {this.state.role === "Admin" && (
                              <StyledTableCell>
                                <input
                                  type="checkbox"
                                  checked={this.state.checkedAll}
                                  onChange={this.checkBoxAll}
                                />
                              </StyledTableCell>
                            )}
                            <StyledTableCell
                              onClick={() => this.sorting("ClaimID")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Claim ID{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => this.sorting("Location")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Location{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() =>
                                this.sorting("patientAccountNumber")
                              }
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Account No{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => this.sorting("DateOfService")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              DOS{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => this.sorting("PatientFirstName")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Patient Name{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => this.sorting("PatientDOB")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Patient DOB{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => this.sorting("PlanCode")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Plan Code{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => this.sorting("Pripayer")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Insurance Name{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => this.sorting("PrimaryPolicyNo")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Policy No{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => this.sorting("Physician")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Physician Name{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => this.sorting("Age")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Age{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => this.sorting("AgeGrp")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Age Grp{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => this.sorting("DOIAge")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              DOI Age
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => this.sorting("DOIAgeGrp")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              DOI AgeGrp
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => this.sorting("type")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Type{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => this.sorting("Claimunder")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Allocated To{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => this.sorting("OverallClaimStatus")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              User Status{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              onClick={() => this.sorting("ClaimStatus")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Claim Status{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </StyledTableCell>
                            {this.state.role === "Admin" && (
                              <StyledTableCell>Unallocate</StyledTableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.nottouch &&
                            this.state.nottouch.map((data, index) => (
                              <TableRow key={data._id}>
                                {this.state.role === "Admin" &&
                                  (this.state.checkedAll === true ? (
                                    <div style={checkboxstyle}>
                                      <input
                                        type="checkbox"
                                        checked={this.state.checkedAll}
                                      />
                                    </div>
                                  ) : (
                                    <div style={checkboxstyle}>
                                      <Checkbox
                                        handleidpush={this.addsinglecheckboxid}
                                        number={data._id}
                                        key={index}
                                      />
                                    </div>
                                  ))}
                                <StyledTableCell>
                                  {data.ClaimID}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.Location}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.patientAccountNumber
                                    ? data.patientAccountNumber
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.DateOfService
                                    ? data.DateOfService
                                    : "-"}
                                </StyledTableCell>

                                <StyledTableCell>
                                  {data.PatientFirstName}&nbsp;
                                  {data.PatientLastName}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.PatientDOB ? data.PatientDOB : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.PlanCode ? data.PlanCode : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.InsuranceName
                                    ? data.InsuranceName
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.PrimaryPolicyNo}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.Physician}
                                </StyledTableCell>

                                <StyledTableCell>{data.Age}</StyledTableCell>
                                <StyledTableCell>{data.AgeGrp}</StyledTableCell>
                                <StyledTableCell>{data.DOIAge}</StyledTableCell>
                                <StyledTableCell>
                                  {data.DOIAgeGrp}
                                </StyledTableCell>
                                <StyledTableCell>{data.type}</StyledTableCell>
                                <StyledTableCell>
                                  {data.Claimunder}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.OverallClaimStatus}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.ClaimStatus}
                                </StyledTableCell>
                                {this.state.role === "Admin" && (
                                  <StyledTableCell>
                                    <Button
                                      onClick={() =>
                                        this.setState(
                                          { unallocateid: data._id },
                                          () => {
                                            this.toggle1();
                                          }
                                        )
                                      }
                                    >
                                      Unallocate
                                    </Button>
                                  </StyledTableCell>
                                )}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        forcePage={this.state.currentpage}
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount1}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick1}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {this.state.ids.length !== 0 && this.state.modal && (
          <Modal
            style={{ width: "30%" }}
            isOpen={this.state.modal}
            onHide={this.toggle}
            size="md"
            centered
          >
            <ModalHeader toggle={this.toggle}>Reallocate</ModalHeader>
            <ModalBody>
              <Row style={{ margin: "0px" }}>
                <Col>
                  <Label>Agent/Team Member Name</Label>
                  <Select
                    options={this.state.users}
                    values={this.state.selecteduser}
                    searchable={true}
                    dropdownHandle={true}
                    searchBy="first_name"
                    labelField="first_name"
                    noDataLabel="No matches found"
                    onChange={(values) =>
                      this.setState({
                        selecteduser: values,
                        selectuserErr: "",
                      })
                    }
                    required
                  />
                </Col>
                <div style={{ fontSize: 16, color: "red" }}>
                  {this.state.selectuserErr}
                </div>
              </Row>
              <br />
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  border: "1px solid grey",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  this.toggle();
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.allocateclaims();
                  this.resetuser();
                }}
                style={{ backgroundColor: "grey", color: "#fff" }}
              >
                Allocate
              </Button>
            </ModalFooter>
          </Modal>
        )}

        <Modal
          style={{ width: "30%" }}
          isOpen={this.state.modalunallocate}
          onHide={this.toggle1}
          size="md"
          centered
        >
          <ModalHeader toggle={this.toggle1}>Unallocate</ModalHeader>
          <ModalBody>Do you want to unallocate this claim..?</ModalBody>
          <ModalFooter>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.toggle1();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.unallocateclaims();
              }}
              style={{ backgroundColor: "grey", color: "#fff" }}
            >
              Unallocate
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          style={{ width: "30%" }}
          isOpen={this.state.modalunallocate1}
          onHide={this.toggle2}
          size="md"
          centered
        >
          <ModalHeader toggle={this.toggle2}>Reallocate/Unallocate</ModalHeader>
          <ModalBody>
            <span
              style={{
                fontSize: "20px",
                color: "#041c3c",
                textAlign: "center",
              }}
            >
              Do you want to..?
            </span>
            <br />
            <div className="multi-button">
              <button
                className="reunalloc"
                onClick={() => {
                  this.toggle();
                }}
              >
                Reallocate
              </button>
              <button
                className="reunalloc"
                onClick={() => {
                  this.bulkunallocatedclaims();
                  this.resetuser();
                }}
              >
                Unallocate
              </button>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.toggle2();
              }}
            >
              Cancel
            </Button>
            {/* <Button
              onClick={() => {
                this.unallocateclaims();
              }}
              style={{ backgroundColor: "grey", color: "#fff" }}
            >
              Unallocate
            </Button> */}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Denialtableallocate3;
