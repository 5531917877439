import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import axios from "axios";
import Badge from "@mui/material/Badge";
import { Row, Col, Button } from "reactstrap";
import { Table } from "react-bootstrap";
import Twilio from "../Twilio";
import { Form, FormGroup, Label, Input } from "reactstrap";
import "../../../Styles/tflexceed.css";
import "../../../Styles/loader.scss";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactPaginate from "react-paginate";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { BiUpArrowAlt } from "react-icons/bi";
import { BiDownArrowAlt } from "react-icons/bi";
import ViewClaim from "./../AllocationCardComponents/ViewClaim";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Tabs, { Tab } from "react-best-tabs";
import SimpleMenu from "./../AllocationCardComponents/MenuList";
import { Card } from "react-bootstrap";
import { Redirect } from "react-router";
import Select from "react-dropdown-select";
import { ip, secretkey } from "../../../Api";
import aes256 from "aes256";
import searchresult from "../../../Images/searchresult.jpg";
import Claimtablenottouch from "./Claimtablenottouchweb1";
import Claimtablenottouchweb1 from "./Claimtablenottouchweb1";
import Claimtablenottouchweb2 from "./Claimtablenottouchweb2";
import Claimtablenottouchweb3 from "./Claimtablenottouchweb3";
import moment from "moment-timezone";
import Claimsubcategoryunallocate1 from "./Claimsubcategoryunallocate1";
import Viewmoreeligibility from "../../Eligibility/Viewmoreeligibility";
const options = [
  { value: "1", label: "ClaimID" },
  { value: "2", label: "Claim No" },
  { value: "9", label: "Patient" },

  { value: "3", label: "Physician" },
  { value: "4", label: "PayerID" },
  { value: "5", label: "Age" },
  { value: "6", label: "PrimaryPolicyNo" },
  { value: "7", label: "PatientDOB" },
];

class ClaimSubCategoryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      currentpagesort: 0,
      sorttable: false,
      sortdata: [],
      perPagesort: 10,
      sortvalue: "",
      fieldvalue: "",
      Showthetable: false,
      ShowthetableAck: false,
      ShowthetableDAck: false,
      ShowthetablePending: false,
      ShowthetableFinal: false,
      ShowthetableReq: false,
      ShowthetableClosed: false,
      ShowthetableSubmitted: false,
      ShowthetableWorked: false,
      ShowthetableMachine1: false,
      ShowthetablePaid: false,
      ShowthetableNotsys: false,
      Showthetablemachine1search: false,
      Showthetablemachine2search:false,
      Showthetablemachine1err: false,
      Showthetablemachine2err:false,
      Showthetablesystem1err: false,
      Showthetablesystem2err:false,
      Showthetableuploaderr:false, 
      searchbyid: [],
      SearchAck: [],
      SearchDAck: [],
      SearchPending: [],
      SearchFinal: [],
      SearchReq: [],
      SearchClosed: [],
      SearchSubmitted: [],
      SearchWorked: [],
      SearchMachine1: [],
      SearchPaid: [],
      SearchNotsys: [],
      SearchMachine1Searches:[],
      SearchMachine2Searches:[],
      SearchSystem1Error:[],
      SearchSystem2Error:[],
      SearchUploadError:[],
      SearchMachine1Error:[],
      SearchMachine2Error:[],
      searchidclaimmanagement: localStorage.getItem("searchkeyidlocal"),
      username: sessionStorage.getItem("Username"),
      payercategories: [],
      cptdata: [],
      tabname: "web",
      url: "/claim/",
      statuslist: [],
      ivrclaim: [],
      manualclaim: [],
      firstsearch1: true,
      perPagesearchid: 10,
      firstsearch2: true,
      firstsearch3: true,
      allocated1: 0,
      allocated2: 0,
      allocated3: 0,
      unallocated1: 0,
      unallocated2: 0,
      unallocated3: 0,
      currentpage1: 0,
      perPage1: 10,
      currentpage2: 0,
      perPage2: 10,
      currentpage3: 0,
      perPage3: 10,
      offset1: 0,
      offset2: 0,
      offset3: 0,
      offset4: 0,
      tabindex: 1,
      pathname: this.props.location.pathname,
      value: sessionStorage.getItem("claimurl"),
      categoryname: sessionStorage.getItem("querycategory"),
      subcategoryname: sessionStorage.getItem("querysub"),
      currentpagesearch1: 0,
      subclaimname: sessionStorage.getItem("subclaimname"),
      queryclient: sessionStorage.getItem("queryclient"),
      querystartdate: sessionStorage.getItem("querystartdate"),
      queryenddate: sessionStorage.getItem("queryenddate"),
      currentpagesearch2: 0,
      currentpagesearch3: 0,
      offsetweb: 0,
      currentpageweb: 0,
      webtotal: [],
      ivrtotal: [],
      manualtotal: [],
      webclaim: [],
      username: [],
      perPageweb: sessionStorage.getItem("Default_pagination"),
      status1: [],
      claimfield1: "",
      claimvalue1: "",
      payervalue1: [],
      cptvalue1: [],
      query1: [],
      searchtext1: "",
      searchclaimdata1: [],
      searchresultresponse1: [],
      offsetmanual: 0,
      fetchingWeb: true,
      fetchingIvr: true,
      fetchingManual: true,
      filtercheck: true,
      currentpagemanual: 0,
      perPagemanual: sessionStorage.getItem("Default_pagination"),
      status3: [],
      claimfield3: "",
      claimvalue3: "",
      payervalue3: [],
      cptvalue3: [],
      query3: [],
      searchtext3: "",
      searchclaimdata3: [],
      searchresultresponse3: [],
      offsetivr: 0,
      currentpageivr: 0,
      perPageivr: sessionStorage.getItem("Default_pagination"),
      allocatemodal1: false,
      allocatemodal2: false,
      allocatemodal3: false,
      status2: [],
      anchorEl: null,
      claimfield2: "",
      claimvalue2: "",
      payervalue2: [],
      cptvalue2: [],
      query2: [],
      searchtext2: "",
      searchclaimdata2: [],
      searchresultresponse2: [],
      offsetsearch: 0,
      currentpagesearch: 0,
      perPagesearch: 10,
      claimweb: [],
      claimmanual: [],
      claimivr: [],
      location: sessionStorage.getItem("queryLocation"),
      sortstate: "ASC",
      openicon: false,
      currentpagesort: 0,
      sorttable: false,
      sortdata: [],
      perPagesort: 10,
      sortvalue: "",
      fieldvalue: "",
      webclaimsort: [],
      ivrclaimsort: [],
      manualclaimsort: [],
      currentpagesortivr: 0,
      sorttableivr: false,
      perPagesortivr: 10,
      sortvalueivr: "",
      fieldvalueivr: "",
      currentpagesortmanual: 0,
      sorttablemanual: false,
      perPagesortmanual: 10,
      sortvaluemanual: "",
      fieldvaluemanual: "",
      offsetsortivr: 0,
      offsetsortmanual: 0,
    };
  }
  getsortingweb = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.categoryname === "Not In System") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpagesort}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpagesort}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpagesort}&Location=${this.state.location}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpagesort}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=NIS&pageno=${this.state.currentpagesort}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=NIS&pageno=${this.state.currentpagesort}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
        }
      }
    } else if (this.state.categoryname === "Closed/Already Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpagesort}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpagesort}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpagesort}&Location=${this.state.location}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpagesort}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpagesort}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpagesort}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
        }
      }
    } else if (this.state.categoryname === "Recently Submitted") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&Location=${this.state.location}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&type=Web&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
        }
      }
    } else if (this.state.categoryname === "Recently Worked") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Web`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Web`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpage1}&Location=${this.state.location}&type=Web`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpage1}&type=Web`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Recently Worked&pageno=${this.state.currentpage1}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Web`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Recently Worked&pageno=${this.state.currentpage1}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&type=Web`;
        }
      }
    } else if (this.state.categoryname === "Partially Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&ClaimStatus=Partially Paid&pageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Web`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&ClaimStatus=Partially Paid&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Web`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpage1}&Location=${this.state.location}&type=Web`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpage1}&type=Web`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?pageno=${this.state.currentpage1}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Web`;
        } else {
          url = `${ip}/claimstatus/sorting?pageno=${this.state.currentpage1}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&type=Web`;
        }
      }
    } else {
      url = `${ip}/claimstatus/claimsubcategory/claims/sorting?pageno=${this.state.currentpagesort}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&count=${this.state.perPagesort}&type=Web&${this.state.value}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (this.state.categoryname === "Not In System") {
          this.setState({
            pageCountwebsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            webclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Closed/Already Paid") {
          this.setState({
            pageCountwebsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            webclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Recently Submitted") {
          this.setState({
            pageCountwebsort: Math.ceil(
              res.data.usercount / this.state.perPage1
            ),
            webclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Avality Error") {
          this.setState({
            pageCountwebsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            webclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Avality Searches") {
          this.setState({
            pageCountwebsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            webclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Availity Empty") {
          this.setState({
            pageCountwebsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            webclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Recently Worked") {
          this.setState({
            pageCountwebsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            webclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Partially Paid") {
          this.setState({
            pageCountwebsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            webclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "System Error") {
          this.setState({
            pageCountwebsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            webclaimsort: res.data.claims,
          });
        } else {
          this.setState({
            pageCountwebsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            webclaimsort: res.data.claimdetails,
          });
        }
      })
      .catch((err) => {});
  };
  getsortingivr = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.categoryname === "Not In System") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=IVR`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=IVR`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpage1}&Location=${this.state.location}&type=IVR`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpage1}&type=IVR`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=NIS&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=IVR`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=NIS&pageno=${this.state.currentpage1}&type=IVR`;
        }
      }
    } else if (this.state.categoryname === "Closed/Already Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=IVR`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=IVR`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpage1}&Location=${this.state.location}&type=IVR`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpage1}&type=IVR`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=IVR`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpage1}&type=IVR`;
        }
      }
    } else if (this.state.categoryname === "Recently Submitted") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=IVR`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=IVR`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&Location=${this.state.location}&type=IVR`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&type=IVR`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=IVR`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&type=IVR`;
        }
      }
    } else if (this.state.categoryname === "Recently Worked") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&pageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=IVR`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=IVR`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&pageno=${this.state.currentpage1}&Location=${this.state.location}&type=IVR`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&pageno=${this.state.currentpage1}&type=IVR`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Recently Worked&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=IVR`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Recently Worked&pageno=${this.state.currentpage1}&type=IVR`;
        }
      }
    } else if (this.state.categoryname === "Partially Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Partially Paid&pageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=IVR`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Partially Paid&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=IVR`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&pageno=${this.state.currentpage1}&Location=${this.state.location}&type=IVR`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&pageno=${this.state.currentpage1}&type=IVR`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=IVR`;
        } else {
          url = `${ip}/claimstatus/sorting?pageno=${this.state.currentpage1}&type=IVR`;
        }
      }
    } else {
      url = `${ip}/claimstatus/claimsubcategory/claims/sorting?pageno=${this.state.currentpagesort}&type=IVR&count=${this.state.perPagesort}&${this.state.value}&sorting=${this.state.sortvalue}&field=${this.state.fieldvalue}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (this.state.categoryname === "Not In System") {
          this.setState({
            pageCountivrsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            ivrclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Closed/Already Paid") {
          this.setState({
            pageCountivrsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            ivrclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Recently Submitted") {
          this.setState({
            pageCountivrsort: Math.ceil(
              res.data.usercount / this.state.perPage1
            ),
            ivrclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Availity Error") {
          this.setState({
            pageCountivrsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            ivrclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Availity Searches") {
          this.setState({
            pageCountivrsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            ivrclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Availity Empty") {
          this.setState({
            pageCountivrsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            ivrclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Recently Worked") {
          this.setState({
            pageCountivrsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            ivrclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Partially Paid") {
          this.setState({
            pageCountivrsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            ivrclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "System error") {
          this.setState({
            pageCountivrsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            ivrclaimsort: res.data.claims,
          });
        } else {
          this.setState({
            pageCountivrsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            ivrclaimsort: res.data.claims,
          });
        }
      })
      .catch((err) => {});
  };
  getsortingmanual = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.categoryname === "Not In System") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Manual`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Manual`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpage1}&Location=${this.state.location}&type=Manual`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpage1}&type=Manual`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=NIS&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Manual`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=NIS&pageno=${this.state.currentpage1}&type=Manual`;
        }
      }
    } else if (this.state.categoryname === "Closed/Already Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Manual`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Manual`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpage1}&Location=${this.state.location}&type=Manual`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpage1}&type=Manual`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Manual`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpage1}&type=Manual`;
        }
      }
    } else if (this.state.categoryname === "Recently Submitted") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Manual`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Manual`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&Location=${this.state.location}&type=Manual`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&type=Manual`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Manual`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Recently Submitted&pageno=${this.state.currentpage1}&type=Manual`;
        }
      }
    } else if (this.state.categoryname === "Recently Worked") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&pageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Manual`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Manual`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&pageno=${this.state.currentpage1}&Location=${this.state.location}&type=Manual`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&pageno=${this.state.currentpage1}&type=Manual`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Recently Worked&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Manual`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Recently Worked&pageno=${this.state.currentpage1}&type=Manual`;
        }
      }
    } else if (this.state.categoryname === "Partially Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Partially Paid&pageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Manual`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Partially Paid&pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Manual`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&pageno=${this.state.currentpage1}&Location=${this.state.location}&type=Manual`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&pageno=${this.state.currentpage1}&type=Manual`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?pageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=Manual`;
        } else {
          url = `${ip}/claimstatus/sorting?pageno=${this.state.currentpage1}&type=Manual`;
        }
      }
    } else {
      url = `${ip}/claimstatus/claimsubcategory/claims/sorting?pageno=${this.state.currentpagesort}&type=Manual&count=${this.state.perPagesort}&${this.state.value}&sorting=${this.state.sortvalue}&field=${this.state.fieldvalue}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (this.state.categoryname === "Not In System") {
          this.setState({
            pageCountmanualsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            manualclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Closed/Already Paid") {
          this.setState({
            pageCountmanualsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            manualclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Recently Submitted") {
          this.setState({
            pageCountmanualsort: Math.ceil(
              res.data.usercount / this.state.perPage1
            ),
            manualclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Availity Error") {
          this.setState({
            pageCountmanualsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            manualclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Availity Searches") {
          this.setState({
            pageCountmanualsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            manualclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Availity Empty") {
          this.setState({
            pageCountmanualsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            manualclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Recently Worked") {
          this.setState({
            pageCountmanualsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            manualclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "Partially Paid") {
          this.setState({
            pageCountmanualsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            manualclaimsort: res.data.claims,
          });
        } else if (this.state.categoryname === "System error") {
          this.setState({
            pageCountmanualsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            manualclaimsort: res.data.claims,
          });
        } else {
          this.setState({
            pageCountmanualsort: Math.ceil(
              res.data.claimcount / this.state.perPage1
            ),
            manualclaimsort: res.data.claimdetails,
          });
        }
      })
      .catch((err) => {});
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage1;

    this.setState(
      {
        currentpage1: selectedPage,
        offset1: offset,
      },
      () => {
        this.getwebclaims();
      }
    );
  };
  handlePageClick2 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage2;

    this.setState(
      {
        currentpage2: selectedPage,
        offset2: offset,
      },
      () => {
        this.getmanualclaims();
      }
    );
  };
  handlePageClick3 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage3;

    this.setState(
      {
        currentpage3: selectedPage,
        offset3: offset,
      },
      () => {
        this.getivrclaims();
      }
    );
  };
  getclaimsbyid = async () => {
    //api call to get all user
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let claimid = JSON.parse(this.state.searchidclaimmanagement);
    let url;
    if(this.state.categoryname === "Recently Worked"){
     url=`${ip}/claimstatus/claimid/search?ClaimID=${claimid}&ClaimStatus=Recently Worked&clientId=${this.state.queryclient}`
    }
    else if(this.state.categoryname === "Recently Submitted"){
      url=`${ip}/claimstatus/claimid/search?ClaimID=${claimid}&ClaimStatus=Recently Submitted&clientId=${this.state.queryclient}`
    }
    else if(this.state.categoryname === "System Error"){
      url=`${ip}/claimstatus/claimid/search?ClaimID=${claimid}&ClaimStatus=System Error&clientId=${this.state.queryclient}`
    }
    else if(this.state.categoryname === "Machine2 System Error"){
      url=`${ip}/claimstatus/claimid/search?ClaimID=${claimid}&ClaimStatus=AvailityEmpty&clientId=${this.state.queryclient}`
    }
    else if(this.state.categoryname === "Upload Error"){
      url=`${ip}/claimstatus/claimid/search?ClaimID=${claimid}&ClaimStatus=UploadError&clientId=${this.state.queryclient}`
    }
    else if(this.state.categoryname === "Partially Paid"){
      url=`${ip}/claimstatus/claimid/search?ClaimID=${claimid}&ClaimStatus=PartiallyPaid&clientId=${this.state.queryclient}`
    }
    else if(this.state.categoryname === "Not In System"){
      url=`${ip}/claimstatus/claimid/search?ClaimID=${claimid}&ClaimStatus=NIS&clientId=${this.state.queryclient}`
    }
    else if(this.state.categoryname === "Not In System"){
      url=`${ip}/claimstatus/claimid/search?ClaimID=${claimid}&ClaimStatus=NIS&clientId=${this.state.queryclient}`
    }
    else{
      url = `${ip}/claimstatus/claimid/search?ClaimID=${claimid}&${this.state.value}`
    }
    const res = await axios
      .get(
        url,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (this.state.categoryname === "Acknowledgements") {
          this.setState({
            SearchAck: res.data.claimdetails,
            ShowthetableAck: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        } else if (
          this.state.categoryname === "Data Reporting Acknowledgements"
        ) {
          this.setState({
            SearchDAck: res.data.claimdetails,
            ShowthetableDAck: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        } else if (this.state.categoryname === "Pending") {
          this.setState({
            SearchPending: res.data.claimdetails,
            ShowthetablePending: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        } else if (this.state.categoryname === "Finalized") {
          this.setState({
            SearchFinal: res.data.claimdetails,
            ShowthetableFinal: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        } else if (
          this.state.categoryname === "Requests for Additional Information"
        ) {
          this.setState({
            SearchReq: res.data.claimdetails,
            ShowthetableReq: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        } else if (this.state.categoryname === "Closed/Already Paid") {
          this.setState({
            SearchClosed: res.data.claimdetails,
            ShowthetableClosed: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        } else if (this.state.categoryname === "Recently Submitted") {
          this.setState({
            SearchSubmitted: res.data.claimdetails,
            ShowthetableSubmitted: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        } else if (this.state.categoryname === "Recently Worked") {
          this.setState({
            SearchWorked: res.data.claimdetails,
            ShowthetableWorked: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        } else if (this.state.categoryname === "Not In System") {
          this.setState({
            SearchNotsys: res.data.claimdetails,
            ShowthetableNotsys: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        } else if (this.state.categoryname === "Partially Paid") {
          this.setState({
            SearchPaid: res.data.claimdetails,
            ShowthetablePaid: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        }
        else if (this.state.categoryname === "Machine1 Searches") {
          this.setState({
            SearchMachine1Searches : res.data.claimdetails,
            Showthetablemachine1search: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        }
        else if (this.state.categoryname === "Machine2 Searches") {
          this.setState({
            SearchMachine2Searches : res.data.claimdetails,
            Showthetablemachine2search: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        }
        else if (this.state.categoryname === "System Error") {
          this.setState({
            SearchSystem1Error : res.data.claimdetails,
            Showthetablesystem1err: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        }
        else if (this.state.categoryname === "Machine2 System Error") {
          this.setState({
            SearchSystem2Error: res.data.claimdetails,
            Showthetablesystem2err: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        }
        else if (this.state.categoryname === "Upload Error") {
          this.setState({
            SearchUploadError: res.data.claimdetails,
            Showthetableuploaderr: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        }
        else if (this.state.categoryname === "Machine1 Error") {
          this.setState({
            SearchMachine1Error: res.data.claimdetails,
            Showthetablemachine1err: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        }
        else if (this.state.categoryname === "Machine2 Error") {
          this.setState({
            SearchMachine2Error: res.data.claimdetails,
            Showthetablemachine2err: true,
            pageCountforsearch: Math.ceil(
              res.data.claimdetails.length / this.state.perPagesearchid
            ),
          });
        }
      });
  };
  getclaimstatusivrclaims = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.categoryname === "Not In System") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/ivr?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpageivr}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageivr}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/ivr?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/ivr?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/ivr?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=NIS&pageno=${this.state.currentpageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageivr}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=NIS&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}`;
        }
      }
    } else if (this.state.categoryname === "Partially Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/partiallypaid/ivr?clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageivr}`;
          } else {
            url = `${ip}/claimstatus/partiallypaid/ivr?clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageivr}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/partiallypaid/ivr?clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/partiallypaid/ivr?clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/partiallypaid/ivr?pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/partiallypaid/ivr?pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}`;
        }
      }
    } else if (this.state.categoryname === "Closed/Already Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Closed/Already Paid&clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Closed/Already Paid&clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Closed/Already Paid&clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Closed/Already Paid&clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/ivr?pageno=${this.state.currentpageivr}&ClaimStatus=Closed/Already Paid&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageivr}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/ivr?pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&ClaimStatus=Closed/Already Paid`;
        }
      }
    } else if (this.state.categoryname === "Recently Submitted") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Recently Submitted&clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Recently Submitted&clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Recently Submitted&clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Recently Submitted&clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/ivr?pageno=${this.state.currentpageivr}&ClaimStatus=Recently Submitted&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageivr}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/ivr?pageno=${this.state.currentpageivr}&ClaimStatus=Recently Submitted&count=${this.state.perPageivr}`;
        }
      }
    } else if (this.state.categoryname === "Recently Worked") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Recently Worked&clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Recently Worked&clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Recently Worked&clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Recently Worked&clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/ivr?pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&ClaimStatus=Recently Worked&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/ivr?pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&ClaimStatus=Recently Worked`;
        }
      }
    } else if (this.state.categoryname === "System Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/ivr?clientId=${this.state.queryclient}&ClaimStatus=System Error&pageno=${this.state.currentpageivr}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageivr}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/ivr?clientId=${this.state.queryclient}&ClaimStatus=System Error&pageno=${this.state.currentpageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageivr}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/ivr?clientId=${this.state.queryclient}&ClaimStatus=System Error&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/ivr?clientId=${this.state.queryclient}&ClaimStatus=System Error&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/availityempty/ivr?pageno=${this.state.currentpageivr}&ClaimStatus=System Error&count=${this.state.perPageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/availityempty/ivr?pageno=${this.state.currentpageivr}&ClaimStatus=System Error&count=${this.state.perPageivr}`;
        }
      }
    } else if (this.state.categoryname === "Machine2 System Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/ivr?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&pageno=${this.state.currentpageivr}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageivr}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/ivr?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&pageno=${this.state.currentpageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageivr}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/ivr?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/ivr?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/availityempty/ivr?pageno=${this.state.currentpageivr}&ClaimStatus=AvailityEmpty&count=${this.state.perPageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/availityempty/ivr?pageno=${this.state.currentpageivr}&ClaimStatus=AvailityEmpty&count=${this.state.perPageivr}`;
        }
      }
    } else if (this.state.categoryname === "Upload Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=IVR&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=IVR&pageno=${this.state.currentpageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageivr}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=IVR&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=IVR&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=IVR&pageno=${this.state.currentpageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageivr}`;
        } else {
          url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=IVR&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}`;
        }
      }
    } else if (
      this.state.categoryname === "Machine1 Searches" ||
      this.state.categoryname === "Machine2 Searches" ||
      this.state.categoryname === "Machine1 Error" ||
      this.state.categoryname === "Machine2 Error"
    ) {
      url = `${ip}/claimstatus/claimsubcategory/claims/ivr?${this.state.value}/&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
    } else {
      url = `${ip}/claimstatus/claimsubcategory/claims/ivr?${this.state.value}&pageno=${this.state.currentpageivr}&count=${this.state.perPageivr}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (this.state.categoryname === "Not In System") {
          this.setState({
            pageCountivr: Math.ceil(
              res.data.claimcount / this.state.perPageivr
            ),
            claimivr: res.data.claim,
            fetchingIvr: false,
            unallocated2: res.data.unallocatedcount,

            allocated2: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Closed/Already Paid") {
          this.setState({
            pageCountivr: Math.ceil(
              res.data.claimcount / this.state.perPageivr
            ),
            claimivr: res.data.claim,
            fetchingIvr: false,
            unallocated2: res.data.unallocatedcount,
            allocated2: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "System Error") {
          this.setState({
            pageCountivr: Math.ceil(
              res.data.claimcount / this.state.perPageivr
            ),
            claimivr: res.data.claim,
            fetchingIvr: false,
            unallocated2: res.data.unallocatedcount,
            allocated2: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Machine2 System Error") {
          this.setState({
            pageCountivr: Math.ceil(
              res.data.claimcount / this.state.perPageivr
            ),
            claimivr: res.data.claim,
            fetchingIvr: false,
            unallocated2: res.data.unallocatedcount,
            allocated2: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Recently Submitted") {
          this.setState({
            pageCountivr: Math.ceil(
              res.data.claimcount / this.state.perPageivr
            ),
            claimivr: res.data.claim,
            fetchingIvr: false,
            unallocated2: res.data.unallocatedcount,
            allocated2: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Recently Worked") {
          this.setState({
            pageCountivr: Math.ceil(
              res.data.claimcount / this.state.perPageivr
            ),
            claimivr: res.data.claim,
            fetchingIvr: false,
            unallocated2: res.data.unallocatedcount,
            allocated2: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Partially Paid") {
          this.setState({
            pageCountivr: Math.ceil(res.data.count / this.state.perPageivr),
            claimivr: res.data.claims,
            fetchingIvr: false,
            unallocated2: res.data.unallocatedcount,
            allocated2: res.data.allocatedcount,
          });
        } else if (
          this.state.categoryname === "Machine1 Searches" ||
          this.state.categoryname === "Machine2 Searches" ||
          this.state.categoryname === "Machine1 Error" ||
          this.state.categoryname === "Machine2 Error"
        ) {
          this.setState({
            pageCountivr: Math.ceil(res.data.count / this.state.perPageivr),
            claimivr: res.data.claims,
            fetchingIvr: false,
            unallocated2: res.data.unallocatedcount,
            allocated2: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Upload Error") {
          this.setState({
            pageCountivr: Math.ceil(res.data.count / this.state.perPageivr),
            claimivr: res.data.claims,
            fetchingIvr: false,
            unallocated2: res.data.unallocatedcount,
            allocated2: res.data.allocatedcount,
          });
        } else {
          this.setState({
            pageCountivr: Math.ceil(
              res.data.claimcount / this.state.perPageivr
            ),
            claimivr: res.data.claims,
            fetchingIvr: false,
            unallocated2: res.data.unallocatedcount,
            allocated2: res.data.allocatedcount,
          });
 
        }
      })
      .catch((err) => {});
  };
  toggle1 = () => {
    this.setState(() => {
      this.setState({ allocatemodal1: !this.state.allocatemodal1 });
    });
  };
  toggle2 = () => {
    this.setState(() => {
      this.setState({ allocatemodal2: !this.state.allocatemodal2 });
    });
  };
  toggle3 = () => {
    this.setState(() => {
      this.setState({ allocatemodal3: !this.state.allocatemodal3 });
    });
  };
  handleClose = () => this.setState({ anchorEl: null });

  getwebclaims = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.categoryname === "Not In System") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=NIS&allocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=NIS&allocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=NIS&allocatedpageno=${this.state.currentpage1}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=NIS&allocatedpageno=${this.state.currentpage1}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/web?ClaimStatus=NIS&allocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/web?ClaimStatus=NIS&allocatedpageno=${this.state.currentpage1}`;
        }
      }
    } else if (this.state.categoryname === "Closed/Already Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&allocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&allocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&allocatedpageno=${this.state.currentpage1}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&allocatedpageno=${this.state.currentpage1}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/web?ClaimStatus=Closed/Already Paid&allocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/web?ClaimStatus=Closed/Already Paid&allocatedpageno=${this.state.currentpage1}`;
        }
      }
    } else if (this.state.categoryname === "Recently Submitted") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&allocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&allocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&allocatedpageno=${this.state.currentpage1}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&allocatedpageno=${this.state.currentpage1}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/web?ClaimStatus=Recently Submitted&allocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/web?ClaimStatus=Recently Submitted&allocatedpageno=${this.state.currentpage1}`;
        }
      }
    } else if (this.state.categoryname === "Recently Worked") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&allocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&allocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&allocatedpageno=${this.state.currentpage1}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&allocatedpageno=${this.state.currentpage1}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/web?ClaimStatus=Recently Worked&allocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/web?ClaimStatus=Recently Worked&allocatedpageno=${this.state.currentpage1}`;
        }
      }
    } else if (this.state.categoryname === "Partially Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/partiallypaid/web?clientId=${this.state.queryclient}&ClaimStatus=Partially Paid&allocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/partiallypaid/web?clientId=${this.state.queryclient}&ClaimStatus=Partially Paid&allocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/partiallypaid/web?clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage1}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/partiallypaid/web?clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage1}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/partiallypaid/web?allocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/partiallypaid/web?allocatedpageno=${this.state.currentpage1}`;
        }
      }
    } else if (this.state.categoryname === "System Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/web?clientId=${this.state.queryclient}&ClaimStatus=System Error&allocatedpageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/web?clientId=${this.state.queryclient}&ClaimStatus=System Error&allocatedpageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/web?clientId=${this.state.queryclient}&ClaimStatus=System Error&allocatedpageno=${this.state.currentpageweb}&Location=${this.state.location}&count=${this.state.perPageweb}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/web?clientId=${this.state.queryclient}&ClaimStatus=System Error&allocatedpageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/availityempty/web?allocatedpageno=${this.state.currentpageweb}&ClaimStatus=System Error&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageweb}`;
        } else {
          url = `${ip}/claimstatus/claims/availityempty/web?allocatedpageno=${this.state.currentpageweb}&ClaimStatus=System Error&count=${this.state.perPageweb}`;
        }
      }
    } else if (this.state.categoryname === "Machine2 System Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/web?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&allocatedpageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/web?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&allocatedpageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/web?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&allocatedpageno=${this.state.currentpageweb}&Location=${this.state.location}&count=${this.state.perPageweb}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/web?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&allocatedpageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/availityempty/web?allocatedpageno=${this.state.currentpageweb}&ClaimStatus=AvailityEmpty&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageweb}`;
        } else {
          url = `${ip}/claimstatus/claims/availityempty/web?allocatedpageno=${this.state.currentpageweb}&ClaimStatus=AvailityEmpty&count=${this.state.perPageweb}`;
        }
      }
    } else {
      url = `${ip}/claimstatus/claimsubcategory/claims/web?${this.state.value}&allocatedpageno=${this.state.currentpage1}`;
    }

    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (this.state.categoryname === "Not In System") {
          this.setState({
            pageCount1: Math.ceil(res.data.usercount / this.state.perPage1),
            // claimweb: res.data.claim,
            // fetchingWeb: false,
            webclaim: res.data.claimdetails,
            // unallocated1: res.data.unallocatedcount,
            // allocated1: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Closed/Already Paid") {
          this.setState({
            pageCount1: Math.ceil(res.data.usercount / this.state.perPage1),
            // claimweb: res.data.claim,
            // fetchingWeb: false,
            webclaim: res.data.claimdetails,
            // unallocated1: res.data.unallocatedcount,
            // allocated1: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Recently Submitted") {
          this.setState({
            pageCount1: Math.ceil(res.data.usercount / this.state.perPage1),
            // claimweb: res.data.claim,
            // fetchingWeb: false,
            webclaim: res.data.claimdetails,
            // unallocated1: res.data.unallocatedcount,
            // allocated1: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Recently Worked") {
          this.setState({
            pageCount1: Math.ceil(res.data.usercount / this.state.perPage1),
            // claimweb: res.data.claim,
            webclaim: res.data.claimdetails,
            // fetchingWeb: false,
            // unallocated1: res.data.unallocatedcount,
            // allocated1: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Partially Paid") {
          this.setState({
            pageCount1: Math.ceil(res.data.usercount / this.state.perPage1),
            // claimweb: res.data.claims,
            // fetchingWeb: false,
            // unallocated1: res.data.unallocatedcount,
            webclaim: res.data.claimdetails,
            // allocated1: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "System Error") {
          this.setState({
            pageCount1: Math.ceil(res.data.usercount / this.state.perPage1),
            // claimweb: res.data.claims,
            // fetchingWeb: false,
            // unallocated1: res.data.unallocatedcount,
            webclaim: res.data.claimdetails,
            // allocated1: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Machine2 System Error") {
          this.setState({
            pageCount1: Math.ceil(res.data.usercount / this.state.perPage1),
            // claimweb: res.data.claims,
            // fetchingWeb: false,
            // unallocated1: res.data.unallocatedcount,
            webclaim: res.data.claimdetails,
            // allocated1: res.data.allocatedcount,
          });
        }
      })

      .catch((err) => {});
  };
  getmanualclaims = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.categoryname === "Not In System") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=NIS&allocatedpageno=${this.state.currentpage2}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=NIS&allocatedpageno=${this.state.currentpage2}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=NIS&allocatedpageno=${this.state.currentpage2}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=NIS&allocatedpageno=${this.state.currentpage2}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/manual?ClaimStatus=NIS&allocatedpageno=${this.state.currentpage2}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/manual?ClaimStatus=NIS&allocatedpageno=${this.state.currentpage2}`;
        }
      }
    } else if (this.state.categoryname === "Closed/Already Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&allocatedpageno=${this.state.currentpage2}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&allocatedpageno=${this.state.currentpage2}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&allocatedpageno=${this.state.currentpage2}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&allocatedpageno=${this.state.currentpage2}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/manual?ClaimStatus=Closed/Already Paid&allocatedpageno=${this.state.currentpage2}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/manual?ClaimStatus=Closed/Already Paid&allocatedpageno=${this.state.currentpage2}`;
        }
      }
    } else if (this.state.categoryname === "Recently Submitted") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&allocatedpageno=${this.state.currentpage2}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&allocatedpageno=${this.state.currentpage2}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&allocatedpageno=${this.state.currentpage2}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&allocatedpageno=${this.state.currentpage2}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/manual?ClaimStatus=Recently Submitted&allocatedpageno=${this.state.currentpage2}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/manual?ClaimStatus=Recently Submitted&allocatedpageno=${this.state.currentpage2}`;
        }
      }
    } else if (this.state.categoryname === "Recently Worked") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&allocatedpageno=${this.state.currentpage2}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&allocatedpageno=${this.state.currentpage2}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&allocatedpageno=${this.state.currentpage2}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&allocatedpageno=${this.state.currentpage2}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/manual?ClaimStatus=Recently Worked&allocatedpageno=${this.state.currentpage2}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/manual?ClaimStatus=Recently Worked&allocatedpageno=${this.state.currentpage2}`;
        }
      }
    } else if (this.state.categoryname === "Partially Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/partiallypaid/manual?clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage2}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/partiallypaid/manual?clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage2}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/partiallypaid/manual?clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage2}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/partiallypaid/manual?clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage2}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/partiallypaid/manual?allocatedpageno=${this.state.currentpage2}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/partiallypaid/manual?allocatedpageno=${this.state.currentpage2}`;
        }
      }
    } else if (this.state.categoryname === "System Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/manual?clientId=${this.state.queryclient}&ClaimStatus=System Error&allocatedpageno=${this.state.currentpagemanual}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/manual?clientId=${this.state.queryclient}&ClaimStatus=System Error&allocatedpageno=${this.state.currentpagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/manual?clientId=${this.state.queryclient}&ClaimStatus=System Error&allocatedpageno=${this.state.currentpagemanual}&Location=${this.state.location}&count=${this.state.perPagemanual}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/manual?clientId=${this.state.queryclient}&ClaimStatus=System Error&allocatedpageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/availityempty/manual?allocatedpageno=${this.state.currentpagemanual}&ClaimStatus=System Error&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
        } else {
          url = `${ip}/claimstatus/claims/availityempty/manual?allocatedpageno=${this.state.currentpagemanual}&ClaimStatus=System Error&count=${this.state.perPagemanual}`;
        }
      }
    } else if (this.state.categoryname === "Machine2 System Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/manual?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&allocatedpageno=${this.state.currentpagemanual}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/manual?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&allocatedpageno=${this.state.currentpagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/manual?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&allocatedpageno=${this.state.currentpagemanual}&Location=${this.state.location}&count=${this.state.perPagemanual}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/manual?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&allocatedpageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/availityempty/manual?allocatedpageno=${this.state.currentpagemanual}&ClaimStatus=AvailityEmpty&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
        } else {
          url = `${ip}/claimstatus/claims/availityempty/manual?allocatedpageno=${this.state.currentpagemanual}&ClaimStatus=AvailityEmpty&count=${this.state.perPagemanual}`;
        }
      }
    } else {
      url = `${ip}/claimstatus/claimsubcategory/claims/manual?${this.state.value}&allocatedpageno=${this.state.currentpage2}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (this.state.categoryname === "Not In System") {
          this.setState({
            pageCount2: Math.ceil(res.data.usercount / this.state.perPage2),
            manualclaim: res.data.claimdetails,
          });
        } else if (this.state.categoryname === "Closed/Already Paid") {
          this.setState({
            pageCount2: Math.ceil(res.data.usercount / this.state.perPage2),
            manualclaim: res.data.claimdetails,
          });
        } else if (this.state.categoryname === "Recently Submitted") {
          this.setState({
            pageCount2: Math.ceil(res.data.usercount / this.state.perPage2),
            manualclaim: res.data.claimdetails,
          });
        } else if (this.state.categoryname === "Recently Worked") {
          this.setState({
            pageCount2: Math.ceil(res.data.usercount / this.state.perPage2),
            manualclaim: res.data.claimdetails,
          });
        } else if (this.state.categoryname === "System Error") {
          this.setState({
            pageCount2: Math.ceil(res.data.usercount / this.state.perPage2),
            manualclaim: res.data.claimdetails,
          });
        } else if (this.state.categoryname === "Machine2 System Error") {
          this.setState({
            pageCount2: Math.ceil(res.data.usercount / this.state.perPage2),
            manualclaim: res.data.claimdetails,
          });
        } else if (this.state.categoryname === "Partially Paid") {
          this.setState({
            pageCount2: Math.ceil(res.data.usercount / this.state.perPage2),
            manualclaim: res.data.claimdetails,
          });
        } else {
          this.setState({
            pageCount2: Math.ceil(res.data.usercount / this.state.perPage2),
            manualclaim: res.data.claimdetails,
          });
        }
      })

      .catch((err) => {});
  };
  getivrclaims = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.categoryname === "Not In System") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/ivr?clientId=${this.state.queryclient}&ClaimStatus=NIS&allocatedpageno=${this.state.currentpage3}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/ivr?clientId=${this.state.queryclient}&ClaimStatus=NIS&allocatedpageno=${this.state.currentpage3}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/ivr?clientId=${this.state.queryclient}&ClaimStatus=NIS&allocatedpageno=${this.state.currentpage3}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/ivr?clientId=${this.state.queryclient}&ClaimStatus=NIS&allocatedpageno=${this.state.currentpage3}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=NIS&allocatedpageno=${this.state.currentpage3}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=NIS&allocatedpageno=${this.state.currentpage3}`;
        }
      }
    } else if (this.state.categoryname === "Partially Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/partiallypaid/ivr?clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage3}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/partiallypaid/ivr?clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage3}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/partiallypaid/ivr?clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage3}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/partiallypaid/ivr?clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage3}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/partiallypaid/ivr?allocatedpageno=${this.state.currentpage3}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/partiallypaid/ivr?allocatedpageno=${this.state.currentpage3}`;
        }
      }
    } else if (this.state.categoryname === "Closed/Already Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Closed/Already Paid&clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage3}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Closed/Already Paid&clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage3}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Closed/Already Paid&clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage3}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Closed/Already Paid&clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage3}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/ivr?allocatedpageno=${this.state.currentpage3}&ClaimStatus=Closed/Already Paid&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/ivr?allocatedpageno=${this.state.currentpage3}&ClaimStatus=Closed/Already Paid`;
        }
      }
    } else if (this.state.categoryname === "Recently Submitted") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Recently Submitted&clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage3}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Recently Submitted&clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage3}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Recently Submitted&clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage3}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Recently Submitted&clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage3}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/ivr?allocatedpageno=${this.state.currentpage3}&ClaimStatus=Recently Submitted&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/ivr?allocatedpageno=${this.state.currentpage3}&ClaimStatus=Recently Submitted`;
        }
      }
    } else if (this.state.categoryname === "Recently Worked") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Recently Worked&clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage3}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Recently Worked&clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage3}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Recently Worked&clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage3}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/ivr?ClaimStatus=Recently Worked&clientId=${this.state.queryclient}&allocatedpageno=${this.state.currentpage3}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/ivr?allocatedpageno=${this.state.currentpage3}&ClaimStatus=Recently Worked&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/ivr?allocatedpageno=${this.state.currentpage3}&ClaimStatus=Recently Worked`;
        }
      }
    } else if (this.state.categoryname === "System Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/ivr?clientId=${this.state.queryclient}&ClaimStatus=System Error&allocatedpageno=${this.state.currentpageivr}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageivr}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/ivr?clientId=${this.state.queryclient}&ClaimStatus=System Error&allocatedpageno=${this.state.currentpageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageivr}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/ivr?clientId=${this.state.queryclient}&ClaimStatus=System Error&allocatedpageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/ivr?clientId=${this.state.queryclient}&ClaimStatus=System Error&allocatedpageno=${this.state.currentpageivr}&count=${this.state.perPageivr}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/availityempty/ivr?allocatedpageno=${this.state.currentpageivr}&ClaimStatus=System Error&count=${this.state.perPageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/availityempty/ivr?allocatedpageno=${this.state.currentpageivr}&ClaimStatus=System Error&count=${this.state.perPageivr}`;
        }
      }
    } else if (this.state.categoryname === "Machine2 System Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/ivr?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&allocatedpageno=${this.state.currentpageivr}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageivr}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/ivr?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&allocatedpageno=${this.state.currentpageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageivr}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/ivr?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&allocatedpageno=${this.state.currentpageivr}&count=${this.state.perPageivr}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/ivr?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&allocatedpageno=${this.state.currentpageivr}&count=${this.state.perPageivr}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/availityempty/ivr?allocatedpageno=${this.state.currentpageivr}&ClaimStatus=AvailityEmpty&count=${this.state.perPageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/availityempty/ivr?allocatedpageno=${this.state.currentpageivr}&ClaimStatus=AvailityEmpty&count=${this.state.perPageivr}`;
        }
      }
    } else {
      url = `${ip}/claimstatus/claimsubcategory/claims/ivr?${this.state.value}&allocatedpageno=${this.state.currentpage3}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (this.state.categoryname === "Not In System") {
          this.setState({
            pageCount3: Math.ceil(res.data.usercount / this.state.perPage3),

            ivrclaim: res.data.claimdetails,
          });
        } else if (this.state.categoryname === "Closed/Already Paid") {
          this.setState({
            pageCount3: Math.ceil(res.data.usercount / this.state.perPage3),
            ivrclaim: res.data.claimdetails,
          });
        } else if (this.state.categoryname === "Recently Submitted") {
          this.setState({
            pageCount3: Math.ceil(res.data.usercount / this.state.perPage3),
            ivrclaim: res.data.claimdetails,
          });
        } else if (this.state.categoryname === "System Error") {
          this.setState({
            pageCount3: Math.ceil(res.data.usercount / this.state.perPage3),
            ivrclaim: res.data.claimdetails,
          });
        } else if (this.state.categoryname === "Machine2 System Error") {
          this.setState({
            pageCount3: Math.ceil(res.data.usercount / this.state.perPage3),
            ivrclaim: res.data.claimdetails,
          });
        } else if (this.state.categoryname === "Recently Worked") {
          this.setState({
            pageCount3: Math.ceil(res.data.usercount / this.state.perPage3),
            ivrclaim: res.data.claimdetails,
          });
        } else if (this.state.categoryname === "Partially Paid") {
          this.setState({
            pageCount3: Math.ceil(res.data.usercount / this.state.perPage3),
            ivrclaim: res.data.claimdetails,
          });
        } else {
          this.setState({
            pageCount3: Math.ceil(res.data.usercount / this.state.perPage3),
            ivrclaim: res.data.claimdetails,
          });
        }
      })

      .catch((err) => {});
  };
  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });

  getclaimstatuswebclaims = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.categoryname === "Not In System") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/web?ClaimStatus=NIS&pageno=${this.state.currentpageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/web?ClaimStatus=NIS&pageno=${this.state.currentpageweb}`;
        }
      }
    } else if (this.state.categoryname === "Closed/Already Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpageweb}&Location=${this.state.location}&count=${this.state.perPageweb}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/web?ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/web?ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
        }
      }
    } else if (this.state.categoryname === "Recently Submitted") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpageweb}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageweb}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageweb}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpageweb}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpageweb}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/web?ClaimStatus=Recently Submitted&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/web?ClaimStatus=Recently Submitted&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
        }
      }
    } else if (this.state.categoryname === "Recently Worked") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/web?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/web?ClaimStatus=Recently Worked&pageno=${this.state.currentpageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageweb}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/web?ClaimStatus=Recently Worked&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
        }
      }
    } else if (this.state.categoryname === "Partially Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/partiallypaid/web?clientId=${this.state.queryclient}&ClaimStatus=Partially Paid&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/partiallypaid/web?clientId=${this.state.queryclient}&ClaimStatus=Partially Paid&pageno=${this.state.currentpageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageweb}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/partiallypaid/web?clientId=${this.state.queryclient}&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/partiallypaid/web?clientId=${this.state.queryclient}&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/partiallypaid/web?pageno=${this.state.currentpageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageweb}`;
        } else {
          url = `${ip}/claimstatus/partiallypaid/web?pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
        }
      }
    } else if (this.state.categoryname === "System Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/web?clientId=${this.state.queryclient}&ClaimStatus=System Error&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/web?clientId=${this.state.queryclient}&ClaimStatus=System Error&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/web?clientId=${this.state.queryclient}&ClaimStatus=System Error&pageno=${this.state.currentpageweb}&Location=${this.state.location}&count=${this.state.perPageweb}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/web?clientId=${this.state.queryclient}&ClaimStatus=System Error&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/availityempty/web?pageno=${this.state.currentpageweb}&ClaimStatus=System Error&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageweb}`;
        } else {
          url = `${ip}/claimstatus/claims/availityempty/web?pageno=${this.state.currentpageweb}&ClaimStatus=System Error&count=${this.state.perPageweb}`;
        }
      }
    } else if (this.state.categoryname === "Machine2 System Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/web?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/web?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/web?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&pageno=${this.state.currentpageweb}&Location=${this.state.location}&count=${this.state.perPageweb}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/web?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/availityempty/web?pageno=${this.state.currentpageweb}&ClaimStatus=AvailityEmpty&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageweb}`;
        } else {
          url = `${ip}/claimstatus/claims/availityempty/web?pageno=${this.state.currentpageweb}&ClaimStatus=AvailityEmpty&count=${this.state.perPageweb}`;
        }
      }
    } else if (this.state.categoryname === "Upload Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=Web&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=Web&pageno=${this.state.currentpageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageweb}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=Web&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=Web&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=Web&pageno=${this.state.currentpageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPageweb}`;
        } else {
          url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=Web&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
        }
      }
    } else if (
      this.state.categoryname === "Machine1 Searches" ||
      this.state.categoryname === "Machine2 Searches" ||
      this.state.categoryname === "Machine1 Error" ||
      this.state.categoryname === "Machine2 Error"
    ) {
      url = `${ip}/claimstatus/claimsubcategory/claims/web?${this.state.value}&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
    } else {
      url = `${ip}/claimstatus/claimsubcategory/claims/web?${this.state.value}&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
    }

    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (this.state.categoryname === "Not In System") {
          this.setState({
            pageCountweb: Math.ceil(
              res.data.claimcount / this.state.perPageweb
            ),
            claimweb: res.data.claim,
            fetchingWeb: false,
            // webclaim: res.data.claimdetails,
            unallocated1: res.data.unallocatedcount,
            allocated1: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Closed/Already Paid") {
          this.setState({
            pageCountweb: Math.ceil(
              res.data.claimcount / this.state.perPageweb
            ),
            claimweb: res.data.claim,
            fetchingWeb: false,
            // webclaim: res.data.claimdetails,
            unallocated1: res.data.unallocatedcount,
            allocated1: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "System Error") {
          this.setState({
            pageCountweb: Math.ceil(
              res.data.claimcount / this.state.perPageweb
            ),
            claimweb: res.data.claim,
            fetchingWeb: false,
            // webclaim: res.data.claimdetails,
            unallocated1: res.data.unallocatedcount,
            allocated1: res.data.allocatedcount,
          });
    
        } else if (this.state.categoryname === "Machine2 System Error") {
          this.setState({
            pageCountweb: Math.ceil(
              res.data.claimcount / this.state.perPageweb
            ),
            claimweb: res.data.claim,
            fetchingWeb: false,
            // webclaim: res.data.claimdetails,
            unallocated1: res.data.unallocatedcount,
            allocated1: res.data.allocatedcount,
          });
  
        } else if (this.state.categoryname === "Recently Submitted") {
          this.setState({
            pageCountweb: Math.ceil(
              res.data.claimcount / this.state.perPageweb
            ),
            claimweb: res.data.claim,
            fetchingWeb: false,
            // webclaim: res.data.claimdetails,
            unallocated1: res.data.unallocatedcount,
            allocated1: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Recently Worked") {
          this.setState({
            pageCountweb: Math.ceil(
              res.data.claimcount / this.state.perPageweb
            ),
            claimweb: res.data.claim,
            // webclaim: res.data.claimdetails,
            fetchingWeb: false,
            unallocated1: res.data.unallocatedcount,
            allocated1: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Partially Paid") {
          this.setState({
            pageCountweb: Math.ceil(res.data.count / this.state.perPageivr),
            claimweb: res.data.claims,
            fetchingWeb: false,
            unallocated1: res.data.unallocatedcount,
            // webclaim: res.data.claimdetails,
            allocated1: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Upload Error") {
          this.setState({
            pageCountweb: Math.ceil(
              res.data.claimcount / this.state.perPageweb
            ),
            claimweb: res.data.claims,
            fetchingWeb: false,
            unallocated1: res.data.unallocatedcount,
            allocated1: res.data.allocatedcount,
          });
        } else if (
          this.state.categoryname === "Machine1 Searches" ||
          this.state.categoryname === "Machine2 Searches" ||
          this.state.categoryname === "Machine1 Error" ||
          this.state.categoryname === "Machine2 Error"
        ) {
          this.setState({
            pageCountweb: Math.ceil(
              res.data.claimcount / this.state.perPageweb
            ),
            claimweb: res.data.claims,
            // webclaim: res.data.claimdetails,

            fetchingWeb: false,
            unallocated1: res.data.unallocatedcount,
            allocated1: res.data.allocatedcount,
          });
        } else {
          this.setState({
            pageCountweb: Math.ceil(
              res.data.claimcount / this.state.perPageweb
            ),
            claimweb: res.data.claims,
            // webclaim: res.data.claimdetails,

            fetchingWeb: false,
            unallocated1: res.data.unallocatedcount,
            allocated1: res.data.allocatedcount,
          });
        }
      })

      .catch((err) => {});
  };

  getclaimstatusmanualclaims = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.categoryname === "Not In System") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpagemanual}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpagemanual}&Location=${this.state.location}&count=${this.state.perPagemanual}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=NIS&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/manual?ClaimStatus=NIS&pageno=${this.state.currentpagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/manual?ClaimStatus=NIS&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
        }
      }
    } else if (this.state.categoryname === "Closed/Already Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpagemanual}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpagemanual}&Location=${this.state.location}&count=${this.state.perPagemanual}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/manual?ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/manual?ClaimStatus=Closed/Already Paid&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
        }
      }
    } else if (this.state.categoryname === "Recently Submitted") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpagemanual}&Location=${this.state.location}&count=${this.state.perPagemanual}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/manual?ClaimStatus=Recently Submitted&pageno=${this.state.currentpagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/manual?ClaimStatus=Recently Submitted&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
        }
      }
    } else if (this.state.categoryname === "Recently Worked") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&pageno=${this.state.currentpagemanual}&Location=${this.state.location}&count=${this.state.perPagemanual}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/manual?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/manual?ClaimStatus=Recently Worked&pageno=${this.state.currentpagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/manual?ClaimStatus=Recently Worked&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
        }
      }
    } else if (this.state.categoryname === "Partially Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/partiallypaid/manual?clientId=${this.state.queryclient}&pageno=${this.state.currentpagemanual}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
          } else {
            url = `${ip}/claimstatus/partiallypaid/manual?clientId=${this.state.queryclient}&pageno=${this.state.currentpagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/partiallypaid/manual?clientId=${this.state.queryclient}&pageno=${this.state.currentpagemanual}&Location=${this.state.location}&count=${this.state.perPagemanual}`;
          } else {
            url = `${ip}/claimstatus/partiallypaid/manual?clientId=${this.state.queryclient}&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/partiallypaid/manual?pageno=${this.state.currentpagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
        } else {
          url = `${ip}/claimstatus/partiallypaid/manual?pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
        }
      }
    } else if (this.state.categoryname === "System Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/manual?clientId=${this.state.queryclient}&ClaimStatus=System Error&pageno=${this.state.currentpagemanual}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/manual?clientId=${this.state.queryclient}&ClaimStatus=System Error&pageno=${this.state.currentpagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/manual?clientId=${this.state.queryclient}&ClaimStatus=System Error&pageno=${this.state.currentpagemanual}&Location=${this.state.location}&count=${this.state.perPagemanual}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/manual?clientId=${this.state.queryclient}&ClaimStatus=System Error&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/availityempty/manual?pageno=${this.state.currentpagemanual}&ClaimStatus=System Error&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
        } else {
          url = `${ip}/claimstatus/claims/availityempty/manual?pageno=${this.state.currentpagemanual}&ClaimStatus=System Error&count=${this.state.perPagemanual}`;
        }
      }
    } else if (this.state.categoryname === "Machine2 System Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/manual?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&pageno=${this.state.currentpagemanual}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/manual?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&pageno=${this.state.currentpagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/availityempty/manual?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&pageno=${this.state.currentpagemanual}&Location=${this.state.location}&count=${this.state.perPagemanual}`;
          } else {
            url = `${ip}/claimstatus/claims/availityempty/manual?clientId=${this.state.queryclient}&ClaimStatus=AvailityEmpty&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/availityempty/manual?pageno=${this.state.currentpagemanual}&ClaimStatus=AvailityEmpty&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
        } else {
          url = `${ip}/claimstatus/claims/availityempty/manual?pageno=${this.state.currentpagemanual}&ClaimStatus=AvailityEmpty&count=${this.state.perPagemanual}`;
        }
      }
    } else if (this.state.categoryname === "Upload Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=Manual&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=Manual&pageno=${this.state.currentpagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=Manual&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=Manual&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=Manual&pageno=${this.state.currentpagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagemanual}`;
        } else {
          url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=Manual&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
        }
      }
    } else if (
      this.state.categoryname === "Machine1 Searches" ||
      this.state.categoryname === "Machine2 Searches" ||
      this.state.categoryname === "Machine1 Error" ||
      this.state.categoryname === "Machine2 Error"
    ) {
      url = `${ip}/claimstatus/claimsubcategory/claims/manual?${this.state.value}&pageno=${this.state.currentpageweb}&count=${this.state.perPageweb}`;
    } else {
      url = `${ip}/claimstatus/claimsubcategory/claims/manual?${this.state.value}&pageno=${this.state.currentpagemanual}&count=${this.state.perPagemanual}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (this.state.categoryname === "Not In System") {
          this.setState({
            pageCountmanual: Math.ceil(
              res.data.claimcount / this.state.perPagemanual
            ),
            claimmanual: res.data.claim,

            fetchingManual: false,
            unallocated3: res.data.unallocatedcount,
            allocated3: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Closed/Already Paid") {
          this.setState({
            pageCountmanual: Math.ceil(
              res.data.claimcount / this.state.perPagemanual
            ),
            claimmanual: res.data.claim,
            fetchingManual: false,

            unallocated3: res.data.unallocatedcount,
            allocated3: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "System Error") {
          this.setState({
            pageCountmanual: Math.ceil(
              res.data.count / this.state.perPagemanual
            ),
            claimmanual: res.data.claim,
            fetchingManual: false,

            unallocated3: res.data.unallocatedcount,
            allocated3: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Machine2 System Error") {
          this.setState({
            pageCountmanual: Math.ceil(
              res.data.claimcount / this.state.perPagemanual
            ),
            claimmanual: res.data.claim,
            fetchingManual: false,

            unallocated3: res.data.unallocatedcount,
            allocated3: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Recently Submitted") {
          this.setState({
            pageCountmanual: Math.ceil(
              res.data.claimcount / this.state.perPagemanual
            ),
            claimmanual: res.data.claim,
            fetchingManual: false,

            unallocated3: res.data.unallocatedcount,
            allocated3: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Recently Worked") {
          this.setState({
            pageCountmanual: Math.ceil(
              res.data.claimcount / this.state.perPagemanual
            ),
            claimmanual: res.data.claim,
            fetchingManual: false,

            unallocated3: res.data.unallocatedcount,
            allocated3: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Partially Paid") {
          this.setState({
            pageCountmanual: Math.ceil(res.data.count / this.state.perPageivr),
            claimmanual: res.data.claims,
            fetchingManual: false,

            unallocated3: res.data.unallocatedcount,
            allocated3: res.data.allocatedcount,
          });
        } else if (this.state.categoryname === "Upload Error") {
          this.setState({
            pageCountmanual: Math.ceil(res.data.count / this.state.perPageivr),
            claimmanual: res.data.claims,
            fetchingManual: false,

            unallocated3: res.data.unallocatedcount,
            allocated3: res.data.allocatedcount,
          });
        } else if (
          this.state.categoryname === "Machine1 Searches" ||
          this.state.categoryname === "Machine2 Searches" ||
          this.state.categoryname === "Machine1 Error" ||
          this.state.categoryname === "Machine2 Error"
        ) {
          this.setState({
            pageCountmanual: Math.ceil(res.data.count / this.state.perPageivr),
            claimmanual: res.data.claims,
            fetchingManual: false,

            unallocated3: res.data.unallocatedcount,
            allocated3: res.data.allocatedcount,
          });
        } else {
          this.setState({
            pageCountmanual: Math.ceil(
              res.data.claimcount / this.state.perPagemanual
            ),
            claimmanual: res.data.claims,
            fetchingManual: false,

            unallocated3: res.data.unallocatedcount,
            allocated3: res.data.allocatedcount,
          });
        }
      })

      .catch((err) => {});
  };

  handlePageClickWeb = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPageweb;

    this.setState(
      {
        currentpageweb: selectedPage,
        offsetweb: offset,
      },
      () => {
        this.getclaimstatuswebclaims();
      }
    );
  };
  handlePageClickWebsort = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPagesort;

    this.setState(
      {
        currentpagesort: selectedPage,
        offsetsort: offset,
      },
      () => {
        this.getsortingweb();
      }
    );
  };
  handlePageClickIvrsort = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPagesortivr;

    this.setState(
      {
        currentpagesortivr: selectedPage,
        offsetsortivr: offset,
      },
      () => {
        this.getsortingivr();
      }
    );
  };
  handlePageClickManualsort = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPagesortmanual;

    this.setState(
      {
        currentpagesortmanual: selectedPage,
        offsetsortmanual: offset,
      },
      () => {
        this.getsortingmanual();
      }
    );
  };
  handlePageClickManual = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPagemanual;

    this.setState(
      {
        currentpagemanual: selectedPage,
        offsetmanual: offset,
      },
      () => {
        this.getclaimstatusmanualclaims();
      }
    );
  };

  handlePageClickIvr = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPageivr;

    this.setState(
      {
        currentpageivr: selectedPage,
        offsetivr: offset,
      },
      () => {
        this.getclaimstatusivrclaims();
      }
    );
  };

  handlePageClickWeb1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPageweb;

    this.setState(
      {
        currentpagesearch1: selectedPage,
        offsetweb: offset,
        firstsearch1: false,
      },
      () => {
        this.filtersearch1();
      }
    );
  };

  handlePageClickManual1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPagemanual;

    this.setState(
      {
        currentpagesearch3: selectedPage,
        offsetmanual: offset,
        firstsearch3: false,
      },
      () => {
        this.filtersearch3();
      }
    );
  };

  handlePageClickIvr1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPageivr;

    this.setState(
      {
        currentpagesearch2: selectedPage,
        offsetivr: offset,
        firstsearch2: false,
      },
      () => {
        this.filtersearch2();
      }
    );
  };

  getcpt = async () => {
    //api call to get all cpt data
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/cptmaster/all`, { headers: headers })
      .then((res) => {
        this.setState({
          cptdata: res.data.cpt,
        });
      })
      .catch((err) => {});
  };

  getpayercategory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(`${ip}/payercategory/all`, {
        headers: headers,
      })
      .then((res) => {
           let categoryData;
        categoryData = aes256.decrypt(`${secretkey}`, res.data.payer);
        const decryptedcategorydata = JSON.parse(categoryData);  
        let other = {
          category_name: "Others",
        };
        let data = decryptedcategorydata;
        data.push(other);
        this.setState({ payercategories: data }, () => {});
      })
      .catch((err) => {});
  };

  reset = (e) => {
    e.preventDefault();
    if (this.state.tabname === "web") {
      this.setState(
        {
          claimfield1: "",
          claimvalue1: "",
          status1: [],
          payervalue1: [],
          cptvalue1: [],
          searchresultresponse1: [],
          searchclaimdata1: [],
          searchtext1: "",
          currentpagesearch1: 0,
          currentpageweb: 0,
          allocated1: 0,
          unallocated1: 0,
          perPageweb: 10,
          perPageivr: 10,
          perPagemanual: 10,
        },
        () => this.getclaimstatuswebclaims()
      );
    } else if (this.state.tabname === "ivr") {
      this.setState(
        {
          claimfield2: "",
          claimvalue2: "",
          status2: [],
          payervalue2: [],
          cptvalue2: [],
          searchresultresponse2: [],
          searchclaimdata2: [],
          searchtext2: "",
          currentpageivr: 0,
          currentpagesearch2: 0,
          allocated2: 0,
          unallocated2: 0,
        },
        () => this.getclaimstatusivrclaims()
      );
    } else if (this.state.tabname === "manual") {
      this.setState(
        {
          claimfield3: "",
          claimvalue3: "",
          status3: [],
          payervalue3: [],
          cptvalue3: [],
          searchresultresponse3: [],
          searchclaimdata3: [],
          searchtext3: "",
          currentpagemanual: 0,
          currentpagesearch3: 0,
          allocated3: 0,
          unallocated3: 0,
        },
        () => this.getclaimstatusmanualclaims(),
        this.getmanualclaims()
      );
    }
  };

  getClaimStatus = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    const res = await axios
      .get(`${ip}/claimstatus/all`, { headers: headers })
      .then((res) => {
        this.setState({
          statuslist: res.data.claimstatus,
        });
      })
      .catch((err) => {});
  };

  componentDidMount() {
    let value = JSON.parse(this.state.searchidclaimmanagement);
    if (value !== null && value !== "") {
      this.getclaimsbyid();
    }else{
      this.getwebclaims();
      this.getivrclaims();
      this.getmanualclaims();
      this.getclaimstatusivrclaims();
      this.getclaimstatuswebclaims();
      this.getclaimstatusmanualclaims();
      this.getcpt();
      this.getpayercategory();
      this.getClaimStatus();
    }
  }

  render() {
    return (
      <div>
      {this.state.categoryname === "Not In System" ? (
        <Newsidebar>
        <p
        style={{
          margin: "0px",
          textAlign: "left",
        }}
      >
        <Link
          style={{ textDecoration: "none", color:"white",fontSize: "15px",
          fontWeight: 500 }}
          to="/claim-management"
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
       <span> Not In System Claims</span>
       </p>
        </Newsidebar>
      ) : this.state.categoryname === "Partially Paid" ? (
        <Newsidebar>
        <p
        style={{
          margin: "0px",
          textAlign: "left",
        }}
      >
        <Link
          style={{ textDecoration: "none", color:"white",fontSize: "15px",
          fontWeight: 500 }}
          to="/claim-management"
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
       <span>Partially Paid Claims</span>
       </p>
        </Newsidebar>
      ) : this.state.categoryname === "Closed/Already Paid" ? (
        <Newsidebar>
        <p
        style={{
          margin: "0px",
          textAlign: "left",
        }}
      >
        <Link
          style={{ textDecoration: "none", color:"white",fontSize: "15px",
          fontWeight: 500 }}
          to="/claim-management"
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
       <span>Closed Claims</span>
       </p>
        </Newsidebar>

      ) : this.state.categoryname === "System Error" ? (
        <Newsidebar>
        <p
        style={{
          margin: "0px",
          textAlign: "left",
        }}
      >
        <Link
          style={{ textDecoration: "none", color:"white",fontSize: "15px",
          fontWeight: 500 }}
          to="/claim-management"
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
       <span>System Error</span>
       </p>
        </Newsidebar>
      ) : this.state.categoryname === "Recently Submitted" ? (
        <Newsidebar>
        <p
        style={{
          margin: "0px",
          textAlign: "left",
        }}
      >
        <Link
          style={{ textDecoration: "none", color:"white" }}
          to="/claim-management"
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
       <span>Recently Submitted</span>
       </p>
        </Newsidebar>
      ) :
        this.state.categoryname === "Recently Worked" ? (
        <Newsidebar>
        <p
        style={{
          margin: "0px",
          textAlign: "left",
        }}
      >
        <Link
          style={{ textDecoration: "none", color:"white",fontSize: "15px",
          fontWeight: 500 }}
          to="/claim-management"
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
       <span>Recently Worked</span>
       </p>
        </Newsidebar>
      ) : this.state.categoryname === "Upload Error" ? (
        <Newsidebar>
        <p
        style={{
          margin: "0px",
          textAlign: "left",
        }}
      >
        <Link
          style={{ textDecoration: "none", color:"white" ,fontSize: "15px",
          fontWeight: 500}}
          to="/claim-management"
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
       <span>Upload Error</span>
       </p>
        </Newsidebar>
      ) : this.state.categoryname === "Machine1 Error" ? (
        <Newsidebar>
        <p
        style={{
          margin: "0px",
          textAlign: "left",
        }}
      >
        <Link
          style={{ textDecoration: "none", color:"white" ,fontSize: "15px",
          fontWeight: 500}}
          to="/claim-management"
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
       <span>Machine1 Error</span>
       </p>
        </Newsidebar>
      ) : this.state.categoryname === "Machine2 Error" ? (
        <Newsidebar>
        <p
        style={{
          margin: "0px",
          textAlign: "left",
        }}
      >
        <Link
          style={{ textDecoration: "none", color:"white",fontSize: "15px",
          fontWeight: 500 }}
          to="/claim-management"
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
       <span>Machine2 Error</span>
       </p>
        </Newsidebar>
      ) : this.state.categoryname === "Machine1 Searches" ? (
        <Newsidebar>
        <p
        style={{
          margin: "0px",
          textAlign: "left",
        }}
      >
        <Link
          style={{ textDecoration: "none", color:"white" ,fontSize: "15px",
          fontWeight: 500}}
          to="/claim-management"
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
       <span>Machine1 Searches</span>
       </p>
        </Newsidebar>
      ) : this.state.categoryname === "Machine2 Searches" ? (
        <Newsidebar>
        <p
        style={{
          margin: "0px",
          textAlign: "left",
        }}
      >
        <Link
          style={{ textDecoration: "none", color:"white",fontSize: "15px",
          fontWeight: 500 }}
          to="/claim-management"
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
       <span>Machine2 Searches</span>
       </p>
        </Newsidebar>
      ) :
      this.state.categoryname === "Machine2 System Error" ? (
        <Newsidebar>
        <p
        style={{
          margin: "0px",
          textAlign: "left",
        }}
      >
        <Link
          style={{ textDecoration: "none", color:"white",fontSize: "15px",
          fontWeight: 500 }}
          to="/claim-management"
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
       <span>Machine2 System Error</span>
       </p>
        </Newsidebar>
      ) :
      (
        <Newsidebar>
        <div>
        {this.state.categoryname !== "Not In System" &&
        this.state.categoryname !== "Partially Paid" &&
        this.state.categoryname !== "Closed/Already Paid" &&
        this.state.categoryname !== "Recently Submitted" &&
        this.state.categoryname !== "Recently Worked" &&
        this.state.categoryname !== "Machine1 Searches" &&
        this.state.categoryname !== "Machine2 Searches" &&
        this.state.categoryname !== "Machine1 Error" &&
        this.state.categoryname !== "Machine2 Error" &&
        this.state.categoryname !== "Machine2 System Error" &&
        this.state.categoryname !== "System Error" &&
        this.state.categoryname !== "Upload Error" ? (
          <div style={{ margin: "0" }}>
            <p
              style={{
                margin: "0px",
                textAlign: "left",
              }}
            >
              <Link
                style={{ textDecoration: "none", color:"white" ,fontSize: "15px",
                fontWeight: 500}}
                to="/claim-management"
              >
                Claim Status Management
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              <Link
                to={
                  this.state.url + this.state.categoryname.toLowerCase()
                }
                style={{ textDecoration: "none", color:"white",fontSize: "15px",
                fontWeight: 500 }}
              >
                {this.state.categoryname}
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              {this.state.subcategoryname}
            </p>
          </div>
        ) : this.state.categoryname === "Machine1 Searches" ? (
          <div style={{ margin: "0" }}>
            <p
              style={{
                margin: "0px",
                textAlign: "left",
              }}
            >
              <Link
                style={{ textDecoration: "none", color:"white" }}
                to="/claim-management"
              >
                Claim Status Management
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              <Link
                to="/error/machine1 searches"
                style={{ textDecoration: "none" , color:"white"}}
              >
                {this.state.categoryname}
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              {this.state.subcategoryname}
            </p>
          </div>
        ) : this.state.categoryname === "Machine2 Searches" ? (
          <div style={{ margin: "0" }}>
            <p
              style={{
                margin: "0px",
                textAlign: "left",
              }}
            >
              <Link
                style={{ textDecoration: "none", color:"white" }}
                to="/claim-management"
              >
                Claim Status Management
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              <Link
                to="/error/machine2 searches"
                style={{ textDecoration: "none" , color:"white"}}
              >
                {this.state.categoryname}
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              {this.state.subcategoryname}
            </p>
          </div>
        ) : this.state.categoryname === "Machine1 Error" ? (
          <div style={{ margin: "0" }}>
            <p
              style={{
                margin: "0px",
                textAlign: "left",
              }}
            >
              <Link
                style={{ textDecoration: "none", color:"white" }}
                to="/claim-management"
              >
                Claim Status Management
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              <Link
                to="/error/machine1 error"
                style={{ textDecoration: "none", color:"white" }}
              >
                {this.state.categoryname}
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              {this.state.subcategoryname}
            </p>
          </div>
        ) : this.state.categoryname === "Machine2 Error" ? (
          <div style={{ margin: "0" }}>
            <p
              style={{
                margin: "0px",
                textAlign: "left",
              }}
            >
              <Link
                style={{ textDecoration: "none", color:"white" }}
                to="/claim-management"
              >
                Claim Status Management
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              <Link
                to="/error/machine2 error"
                style={{ textDecoration: "none", color:"white" }}
              >
                {this.state.categoryname}
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              {this.state.subcategoryname}
            </p>
          </div>
        ) : this.state.categoryname === "Upload Error" ||
          this.state.categoryname === "System Error" ||
          this.state.categoryname === "Machine2 System Error" ? (
          <div style={{ margin: "0" }}>
            <p
              style={{
                margin: "0px",
                textAlign: "left",
              }}
            >
              <Link
                style={{ textDecoration: "none", color:"white" }}
                to="/claim-management"
              >
                Claim Status Management
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              <Link to="/claim/error" style={{ textDecoration: "none" , color:"white"}}>
                Error
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              {this.state.categoryname}
            </p>
          </div>
        ) : (
          <div>
            <p style={{ fontWeight: "bold" }}>
              <Link
                to="/claim-management"
                style={{
                  textDecoration: "none", color:"white"
                }}
              >
                Claim Status Management
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;{" "}
              {this.state.categoryname}
            </p>
          </div>
        )}
      </div>
        </Newsidebar>
      )
    
    }
      <div style={{ overflow: "hidden" }}>
        <div className="responsive-three-column-grid">
      
          <div>
            {this.state.tabindex === 1 && (
              <div style={{ display: "flex" }}>
                <div class="select_drop">
                  <select
                    style={{ height: "35px" }}
                    value={this.state.perPageweb}
                    onChange={(e) =>
                      this.setState(
                        {
                          perPageweb: e.target.value,
                          currentpage: 0,
                        },
                        () => {
                          this.getclaimstatuswebclaims();
                        }
                      )
                    }
                  >
                    <option value="select" hidden selected>
                      Select page count
                    </option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                  </select>
                </div>
                <div>
                  <Button
                    style={{
                      border: "none",
                      color: "white",
                      background: "white",
                    }}
                    onClick={(e) => {
                      this.reset(e);
                    }}
                  >
                    <IoIosRefresh
                      style={{
                        color: "blue",
                        fontSize: "13px",
                      }}
                    ></IoIosRefresh>
                  </Button>
                </div>
              </div>
            )}
            {this.state.tabindex === 2 && (
              <div style={{ display: "flex" }}>
                <div class="select_drop">
                  <select
                    style={{ height: "35px" }}
                    value={this.state.perPageivr}
                    onChange={(e) =>
                      this.setState(
                        {
                          perPageivr: e.target.value,
                          currentpage: 0,
                        },
                        () => {
                          this.getclaimstatusivrclaims();
                        }
                      )
                    }
                  >
                    <option value="select" hidden selected>
                      Select page count
                    </option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                  </select>
                </div>

                <div>
                  <Button
                    style={{
                      border: "none",
                      color: "white",
                      background: "white",
                      fontWeight: "bold",
                    }}
                    onClick={(e) => {
                      this.reset(e);
                    }}
                  >
                    <IoIosRefresh
                      style={{
                        color: "blue",
                        fontSize: "13px",
                      }}
                    ></IoIosRefresh>
                  </Button>
                </div>
              </div>
            )}
            {this.state.tabindex === 3 && (
              <div style={{ display: "flex" }}>
                <div class="select_drop">
                  <select
                    style={{ height: "35px" }}
                    value={this.state.perPagemanual}
                    onChange={(e) =>
                      this.setState(
                        {
                          perPagemanual: e.target.value,
                          currentpage: 0,
                        },
                        () => {
                          this.getclaimstatusmanualclaims();
                        }
                      )
                    }
                  >
                    <option value="select" hidden selected>
                      Select page count
                    </option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                  </select>
                </div>

                <div>
                  <Button
                    style={{
                      border: "none",
                      color: "white",
                      background: "white",
                      fontWeight: "bold",
                    }}
                    onClick={(e) => {
                      this.reset(e);
                    }}
                  >
                    <IoIosRefresh
                      style={{
                        color: "blue",
                        fontSize: "13px",
                      }}
                    ></IoIosRefresh>
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div>
            {this.state.Showthetable === true ? null : (
              <div style={{ marginTop: "1%" }}>
                {this.state.tabindex === 1 && (
                  <div>
                    <Badge
                      badgeContent={this.state.allocated1}
                      color="primary"
                      showZero
                      max={this.state.pageCountweb * this.state.perPageweb}
                    >
                      <Button
                        style={{ backgroundColor: "#2e5a88", color: "white" }}
                        onClick={() => {
                          this.toggle1();
                          this.handleClose();
                        }}
                      >
                        Allocated
                      </Button>
                    </Badge>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Badge
                      badgeContent={this.state.unallocated1}
                      color="primary"
                      showZero
                      max={this.state.pageCountweb * this.state.perPageweb}
                    >
                      <Button>
                        <Link
                          to="/Transaction/ClaimManagement/Claimsubcategoryunallocate1"
                          style={{
                            textDecoration: "none",
                            color: "whitesmoke",
                          }}
                          onClick={() => (
                            <Claimsubcategoryunallocate1
                              value={sessionStorage.setItem(
                                "claimtype",
                                "Web"
                              )}
                            />
                          )}
                        >
                          Unallocated
                        </Link>
                      </Button>
                    </Badge>
                  </div>
                )}
                {this.state.tabindex === 2 && (
                  <div>
                    <Badge
                      badgeContent={this.state.allocated2}
                      color="primary"
                      showZero
                      max={this.state.pageCountivr * this.state.perPageivr}
                    >
                      <Button
                        style={{ backgroundColor: "#2e5a88", color: "white" }}
                        onClick={() => {
                          this.toggle2();
                          this.handleClose();
                        }}
                      >
                        Allocated
                      </Button>
                    </Badge>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Badge
                      badgeContent={this.state.unallocated2}
                      color="primary"
                      showZero
                      max={this.state.pageCountivr * this.state.perPageivr}
                    >
                      <Button>
                        <Link
                          to="/Transaction/ClaimManagement/Claimsubcategoryunallocate1"
                          style={{
                            textDecoration: "none",
                            color: "whitesmoke",
                          }}
                          onClick={() => (
                            <Claimsubcategoryunallocate1
                              value={sessionStorage.setItem(
                                "claimtype",
                                "ivr"
                              )}
                            />
                          )}
                        >
                          {" "}
                          Unallocated
                        </Link>{" "}
                      </Button>
                    </Badge>
                  </div>
                )}
                {this.state.tabindex === 3 && (
                  <div>
                    <Badge
                      badgeContent={this.state.allocated3}
                      color="primary"
                      showZero
                      max={
                        this.state.pageCountmanual * this.state.perPagemanual
                      }
                    >
                      <Button
                        style={{ backgroundColor: "#2e5a88", color: "white" }}
                        onClick={() => {
                          this.toggle3();
                          this.handleClose();
                        }}
                      >
                        Allocated
                      </Button>
                    </Badge>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Badge
                      badgeContent={this.state.unallocated3}
                      color="primary"
                      showZero
                      max={
                        this.state.pageCountmanual * this.state.perPagemanual
                      }
                    >
                      <Button>
                        <Link
                          to="/Transaction/ClaimManagement/Claimsubcategoryunallocate1"
                          style={{
                            textDecoration: "none",
                            color: "whitesmoke",
                          }}
                          onClick={() => (
                            <Claimsubcategoryunallocate1
                              value={sessionStorage.setItem(
                                "claimtype",
                                "manual"
                              )}
                            />
                          )}
                        >
                          Unallocated
                        </Link>
                      </Button>
                    </Badge>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {this.state.ShowthetableAck === true ? (
          <div>
            {this.state.SearchAck.length === 0 ?
              <div class="bot-ui">
              <div class="bot-head">
                <div class="eyes-container">
                  <div class="to-left eye"></div>
                  <div class="to-right eye"></div>
                </div>
              </div>
              <div class="bot-body">
                <div class="analyzer"></div>
              </div>
              <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                No Data Found
              </p>
            </div>
           :
           <div>
           <Table
           className="StandardTable"
           bordered
           responsive
           style={{
             marginLeft: "3%",
           }}
         >
           <thead>
             <tr align="center">
               <th>Action</th>
               <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
               <th style={{ whiteSpace: "nowrap" }}> Location</th>
               <th style={{ whiteSpace: "nowrap" }}>Account No</th>
               <th style={{ whiteSpace: "nowrap" }}>DOS</th>
               <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
               <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
               <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
               <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
               <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
               <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
               <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
               <th style={{ whiteSpace: "nowrap" }}>Age</th>
               <th style={{ whiteSpace: "nowrap" }}>Type</th>
               <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
               <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
             </tr>
           </thead>
           <tbody>
             {this.state.SearchAck &&
               this.state.SearchAck.map((data, index) => {
                 return (
                   <tr>
                     <td
                       onClick={() => (
                         (
                           <ViewClaim
                             value={
                               sessionStorage.setItem(
                                 "Module",
                                 "Claim Status Management"
                               ) ||
                               sessionStorage.setItem(
                                 "ClaimID",
                                 data.ClaimID
                               ) ||
                               sessionStorage.setItem(
                                 "ChartID",
                                 data.ChartID
                               ) ||
                               sessionStorage.setItem("MRN", data.MRN) ||
                               sessionStorage.setItem(
                                 "Patient",
                                 data.Patient
                               ) ||
                               sessionStorage.setItem(
                                 "PatientDOB",
                                 data.PatientDOB
                               ) ||
                               sessionStorage.setItem(
                                 "Physician",
                                 data.Physician
                               ) ||
                               sessionStorage.setItem(
                                 "ClaimDate",
                                 data.ClaimDate
                               ) ||
                               sessionStorage.setItem(
                                 "Pripayer",
                                 data.Pripayer
                               ) ||
                               sessionStorage.setItem(
                                 "DateOfService",
                                 data.DateOfService
                               ) ||
                               sessionStorage.setItem(
                                 "ClaimDate",
                                 data.ClaimDate
                               ) ||
                               sessionStorage.setItem(
                                 "ClaimDBID",
                                 data._id
                               ) ||
                               sessionStorage.setItem(
                                 "AllocatedTo",
                                 data.Claimunder
                               )
                             }
                           />
                         ),
                         (
                           <Viewmoreeligibility
                             value={
                               sessionStorage.setItem(
                                 "modulename",
                                 "Claim Status Management"
                               ) ||
                               sessionStorage.setItem(
                                 "Eligibilityclaimid",
                                 data.ClaimID
                               ) ||
                               sessionStorage.setItem(
                                 "ChartID",
                                 data.ChartID
                               ) ||
                               sessionStorage.setItem("MRN", data.MRN) ||
                               sessionStorage.setItem(
                                 "Patient",
                                 data.Patient
                               ) ||
                               sessionStorage.setItem(
                                 "PatientDOB",
                                 data.PatientDOB
                               ) ||
                               sessionStorage.setItem(
                                 "Physician",
                                 data.Physician
                               ) ||
                               sessionStorage.setItem(
                                 "ClaimDate",
                                 data.ClaimDate
                               ) ||
                               sessionStorage.setItem(
                                 "Pripayer",
                                 data.Pripayer
                               ) ||
                               sessionStorage.setItem(
                                 "DateOfService",
                                 data.DateOfService
                               ) ||
                               sessionStorage.setItem(
                                 "ClaimDate",
                                 data.ClaimDate
                               ) ||
                               sessionStorage.setItem(
                                 "ClaimDBID",
                                 data._id
                               ) ||
                               sessionStorage.setItem(
                                 "AllocatedTo",
                                 data.Claimunder
                               )
                             }
                           />
                         ),
                         (
                           <SimpleMenu
                             value={
                               sessionStorage.setItem(
                                 "modulename",
                                 "Claim Status Management"
                               ) ||
                               sessionStorage.setItem(
                                 "Eligibilityclaimid",
                                 data.ClaimID
                               ) ||
                               sessionStorage.setItem(
                                 "PatientFirstName",
                                 data.PatientFirstName
                               ) ||
                               sessionStorage.setItem(
                                 "PatientLastName",
                                 data.PatientLastName
                               )
                             }
                           ></SimpleMenu>
                         )
                       )}
                     >
                       {" "}
                       <SimpleMenu></SimpleMenu>
                     </td>
                     <td >
                       {data.ClaimID}
                     </td>
                     <td >
                       {data.Location}
                     </td>
                     <td >
                       {data.patientAccountNumber}
                     </td>
                     <td >
                       {data.DateOfService ? data.DateOfService : "-"}
                     </td>

                     <td >
                       {data.PatientFirstName}&nbsp;
                       {data.PatientLastName}
                     </td>

                     <td >
                       {data.PatientDOB
                         ? moment(data.PatientDOB).format("MM/DD/YYYY")
                         : "-"}
                     </td>
                     <td >
                       {data.PlanCode ? data.PlanCode : "-"}
                     </td>
                     <td >
                       {data.Pripayer}
                     </td>
                     <td >
                       <Row>
                         <Col md="4">
                           <Twilio value11={data.Pripayerphone} />
                         </Col>
                         <Col md="2">{data.Pripayerphone}</Col>
                       </Row>
                     </td>
                     <td >
                       {data.PrimaryPolicyNo}
                     </td>
                     <td >
                       {data.Physician}
                     </td>
                     <td >
                       {data.Age}
                     </td>
                     <td >
                       {data.type}
                     </td>
                     <td >
                       {data.ClaimStatus}
                     </td>
                     <td >
                       {data.Claimunder}
                     </td>
                   </tr>
                 );
               })}
           </tbody>
         </Table>
         <div style={{ marginLeft: "3%", marginTop: "20px" }}>
         <ReactPaginate
           previousLabel={<IoIosArrowBack />}
           nextLabel={<IoIosArrowForward />}
           breakLabel={"..."}
           breakClassName={"break-me"}
           pageCount={this.state.pageCountforsearch}
           marginPagesDisplayed={2}
           pageRangeDisplayed={5}
           onPageChange={this.handlePagesearchid}
           containerClassName={"pagination"}
           subContainerClassName={"pages pagination"}
           activeClassName={"active"}
         />
            </div>
            </div>
            }
          </div>
        ) : this.state.ShowthetablePending === true ? (
          <div>
          {this.state.SearchPending.length === 0 ?
            <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
         :
         <div>
            <div>
              <Table className="StandardTable" bordered responsive>
                <thead>
                  <tr align="center">
                    <th>Action</th>
                    <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                    <th style={{ whiteSpace: "nowrap" }}> Location</th>
                    <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                    <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
                    <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Age</th>
                    <th style={{ whiteSpace: "nowrap" }}>Type</th>
                    <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
                    <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
                  </tr>
                </thead>

                <tbody >
                  {this.state.SearchPending &&
                    this.state.SearchPending.map((data, index) => {
                      return (
                        <tr >
                          <td
                            
                            onClick={() => (
                              (
                                <ViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "Module",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <SimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></SimpleMenu>
                              )
                            )}
                          >
                            {" "}
                            <SimpleMenu></SimpleMenu>
                          </td>
                          <td >
                            {data.ClaimID}
                          </td>
                          <td >
                            {data.Location}
                          </td>
                          <td >
                            {data.patientAccountNumber}
                          </td>
                          <td >
                            {data.DateOfService ? data.DateOfService : "-"}
                          </td>

                          <td >
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>

                          <td >
                            {data.PatientDOB
                              ? moment(data.PatientDOB).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                          <td >
                            {data.PlanCode ? data.PlanCode : "-"}
                          </td>
                          <td >
                            {data.Pripayer}
                          </td>
                          <td >
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td >
                            {data.PrimaryPolicyNo}
                          </td>
                          <td >
                            {data.Physician}
                          </td>
                          <td >
                            {data.Age}
                          </td>
                          <td >
                            {data.type}
                          </td>
                          <td >
                            {data.ClaimStatus}
                          </td>
                          <td >
                            {data.Claimunder}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCountforsearch}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePagesearchid}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
            </div>
                  }
          </div>
        ) : this.state.ShowthetableFinal === true ? (
          <div>
          {this.state.SearchFinal.length === 0 ?
            <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
         :
         <div>
            <div>
              <Table bordered responsive className="StandardTable">
                <thead>
                  <tr align="center">
                    <th>Action</th>
                    <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                    <th style={{ whiteSpace: "nowrap" }}> Location</th>
                    <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                    <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
                    <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Age</th>
                    <th style={{ whiteSpace: "nowrap" }}>Type</th>
                    <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
                    <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.SearchFinal &&
                    this.state.SearchFinal.map((data, index) => {
                      return (
                        <tr>
                          <td
                            onClick={() => (
                              (
                                <ViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "Module",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <SimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></SimpleMenu>
                              )
                            )}
                          >
                            {" "}
                            <SimpleMenu></SimpleMenu>
                          </td>
                          <td >
                            {data.ClaimID}
                          </td>
                          <td >
                            {data.Location}
                          </td>
                          <td >
                            {data.patientAccountNumber}
                          </td>
                          <td >
                            {data.DateOfService ? data.DateOfService : "-"}
                          </td>

                          <td >
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>

                          <td >
                            {data.PatientDOB
                              ? moment(data.PatientDOB).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                          <td >
                            {data.PlanCode ? data.PlanCode : "-"}
                          </td>
                          <td >
                            {data.Pripayer}
                          </td>
                          <td >
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td >
                            {data.PrimaryPolicyNo}
                          </td>
                          <td >
                            {data.Physician}
                          </td>
                          <td >
                            {data.Age}
                          </td>
                          <td >
                            {data.type}
                          </td>
                          <td >
                            {data.ClaimStatus}
                          </td>
                          <td >
                            {data.Claimunder}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCountforsearch}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePagesearchid}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
            </div>
                  }
          </div>
        ) : this.state.ShowthetableDAck === true ? (
          <div>
          {this.state.SearchDAck.length === 0 ?
            <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
         :
         <div>
            <div>
              <Table bordered responsive className="StandardTable">
                <thead>
                  <tr align="center">
                    <th>Action</th>
                    <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                    <th style={{ whiteSpace: "nowrap" }}> Location</th>
                    <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                    <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
                    <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Age</th>
                    <th style={{ whiteSpace: "nowrap" }}>Type</th>
                    <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
                    <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.SearchDAck &&
                    this.state.SearchDAck.map((data, index) => {
                      return (
                        <tr>
                          <td
                            onClick={() => (
                              (
                                <ViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "Module",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <SimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></SimpleMenu>
                              )
                            )}
                          >
                            {" "}
                            <SimpleMenu></SimpleMenu>
                          </td>
                          <td >
                            {data.ClaimID}
                          </td>
                          <td >
                            {data.Location}
                          </td>
                          <td >
                            {data.patientAccountNumber}
                          </td>
                          <td >
                            {data.DateOfService ? data.DateOfService : "-"}
                          </td>

                          <td >
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>

                          <td >
                            {data.PatientDOB
                              ? moment(data.PatientDOB).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                          <td >
                            {data.PlanCode ? data.PlanCode : "-"}
                          </td>
                          <td >
                            {data.Pripayer}
                          </td>
                          <td >
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td >
                            {data.PrimaryPolicyNo}
                          </td>
                          <td >
                            {data.Physician}
                          </td>
                          <td >
                            {data.Age}
                          </td>
                          <td >
                            {data.type}
                          </td>
                          <td >
                            {data.ClaimStatus}
                          </td>
                          <td >
                            {data.Claimunder}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCountforsearch}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePagesearchid}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div> 
        }
            </div>
        ) : this.state.ShowthetableReq === true ? (
          <div>
          {this.state.SearchReq.length === 0 ?
            <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
         :
         <div>
            <div>
              <Table bordered responsive className="StandardTable">
                <thead>
                  <tr align="center">
                    <th>Action</th>
                    <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                    <th style={{ whiteSpace: "nowrap" }}> Location</th>
                    <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                    <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
                    <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Age</th>
                    <th style={{ whiteSpace: "nowrap" }}>Type</th>
                    <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
                    <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
                  </tr>
                </thead>

                <tbody >
                  {this.state.SearchReq &&
                    this.state.SearchReq.map((data, index) => {
                      return (
                        <tr >
                          <td
                            
                            onClick={() => (
                              (
                                <ViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "Module",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <SimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></SimpleMenu>
                              )
                            )}
                          >
                            {" "}
                            <SimpleMenu></SimpleMenu>
                          </td>
                          <td >
                            {data.ClaimID}
                          </td>
                          <td >
                            {data.Location}
                          </td>
                          <td >
                            {data.patientAccountNumber}
                          </td>
                          <td >
                            {data.DateOfService ? data.DateOfService : "-"}
                          </td>

                          <td >
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>

                          <td >
                            {data.PatientDOB
                              ? moment(data.PatientDOB).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                          <td >
                            {data.PlanCode ? data.PlanCode : "-"}
                          </td>
                          <td >
                            {data.Pripayer}
                          </td>
                          <td >
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td >
                            {data.PrimaryPolicyNo}
                          </td>
                          <td >
                            {data.Physician}
                          </td>
                          <td >
                            {data.Age}
                          </td>
                          <td >
                            {data.type}
                          </td>
                          <td >
                            {data.ClaimStatus}
                          </td>
                          <td >
                            {data.Claimunder}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCountforsearch}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePagesearchid}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
                  }
            </div>
        ) : this.state.ShowthetableClosed === true ? (
          <div>
          {this.state.SearchClosed.length === 0 ?
            <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
         :
         <div>
            <div>
              <Table bordered responsive className="StandardTable">
                <thead>
                  <tr align="center">
                    <th>Action</th>
                    <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                    <th style={{ whiteSpace: "nowrap" }}> Location</th>
                    <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                    <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
                    <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Age</th>
                    <th style={{ whiteSpace: "nowrap" }}>Type</th>
                    <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
                    <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
                  </tr>
                </thead>

                <tbody >
                  {this.state.SearchClosed &&
                    this.state.SearchClosed.map((data, index) => {
                      return (
                        <tr >
                          <td
                            
                            onClick={() => (
                              (
                                <ViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "Module",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <SimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></SimpleMenu>
                              )
                            )}
                          >
                            {" "}
                            <SimpleMenu></SimpleMenu>
                          </td>
                          <td >
                            {data.ClaimID}
                          </td>
                          <td >
                            {data.Location}
                          </td>
                          <td >
                            {data.patientAccountNumber}
                          </td>
                          <td >
                            {data.DateOfService ? data.DateOfService : "-"}
                          </td>

                          <td >
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>

                          <td >
                            {data.PatientDOB
                              ? moment(data.PatientDOB).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                          <td >
                            {data.PlanCode ? data.PlanCode : "-"}
                          </td>
                          <td >
                            {data.Pripayer}
                          </td>
                          <td >
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td >
                            {data.PrimaryPolicyNo}
                          </td>
                          <td >
                            {data.Physician}
                          </td>
                          <td >
                            {data.Age}
                          </td>
                          <td >
                            {data.type}
                          </td>
                          <td >
                            {data.ClaimStatus}
                          </td>
                          <td >
                            {data.Claimunder}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCountforsearch}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePagesearchid}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
                  }
            </div>
        ) : this.state.ShowthetableSubmitted === true ? (
          <div>
          {this.state.SearchSubmitted.length === 0 ?
            <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
         :
          <div>
            <div>
              <Table bordered responsive className="StandardTable">
                <thead>
                  <tr align="center">
                    <th>Action</th>
                    <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                    <th style={{ whiteSpace: "nowrap" }}> Location</th>
                    <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                    <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
                    <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Age</th>
                    <th style={{ whiteSpace: "nowrap" }}>Type</th>
                    <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
                    <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.SearchSubmitted &&
                    this.state.SearchSubmitted.map((data, index) => {
                      return (
                        <tr >
                          <td
                            
                            onClick={() => (
                              (
                                <ViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "Module",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <SimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></SimpleMenu>
                              )
                            )}
                          >
                            {" "}
                            <SimpleMenu></SimpleMenu>
                          </td>
                          <td >
                            {data.ClaimID}
                          </td>
                          <td >
                            {data.Location}
                          </td>
                          <td >
                            {data.patientAccountNumber}
                          </td>
                          <td >
                            {data.DateOfService ? data.DateOfService : "-"}
                          </td>

                          <td >
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>

                          <td >
                            {data.PatientDOB
                              ? moment(data.PatientDOB).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                          <td >
                            {data.PlanCode ? data.PlanCode : "-"}
                          </td>
                          <td >
                            {data.Pripayer}
                          </td>
                          <td >
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td >
                            {data.PrimaryPolicyNo}
                          </td>
                          <td >
                            {data.Physician}
                          </td>
                          <td >
                            {data.Age}
                          </td>
                          <td >
                            {data.type}
                          </td>
                          <td >
                            {data.ClaimStatus}
                          </td>
                          <td >
                            {data.Claimunder}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCountforsearch}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePagesearchid}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
            </div> }
          </div>
        ) : this.state.ShowthetableWorked === true ? (
          <div>
          {this.state.SearchWorked.length === 0 ?
            <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
         :
          <div>
            <div>
              <Table bordered responsive className="StandardTable">
                <thead>
                  <tr align="center">
                    <th>Action</th>
                    <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                    <th style={{ whiteSpace: "nowrap" }}> Location</th>
                    <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                    <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
                    <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Age</th>
                    <th style={{ whiteSpace: "nowrap" }}>Type</th>
                    <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
                    <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
                  </tr>
                </thead>

                <tbody >
                  {this.state.SearchWorked &&
                    this.state.SearchWorked.map((data, index) => {
                      return (
                        <tr >
                          <td
                            
                            onClick={() => (
                              (
                                <ViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "Module",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <SimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></SimpleMenu>
                              )
                            )}
                          >
                            {" "}
                            <SimpleMenu></SimpleMenu>
                          </td>
                          <td >
                            {data.ClaimID}
                          </td>
                          <td >
                            {data.Location}
                          </td>
                          <td >
                            {data.patientAccountNumber}
                          </td>
                          <td >
                            {data.DateOfService ? data.DateOfService : "-"}
                          </td>

                          <td >
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>

                          <td >
                            {data.PatientDOB
                              ? moment(data.PatientDOB).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                          <td >
                            {data.PlanCode ? data.PlanCode : "-"}
                          </td>
                          <td >
                            {data.Pripayer}
                          </td>
                          <td >
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td >
                            {data.PrimaryPolicyNo}
                          </td>
                          <td >
                            {data.Physician}
                          </td>
                          <td >
                            {data.Age}
                          </td>
                          <td >
                            {data.type}
                          </td>
                          <td >
                            {data.ClaimStatus}
                          </td>
                          <td >
                            {data.Claimunder}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCountforsearch}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePagesearchid}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
            </div>
                  }
          </div>
        ) : this.state.ShowthetableNotsys === true ? (
          <div>
          {this.state.SearchNotsys.length === 0 ?
            <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
         :
         <div>
            <div>
              <Table responsive bordered className="StandardTable">
                <thead>
                  <tr align="center">
                    <th>Action</th>
                    <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                    <th style={{ whiteSpace: "nowrap" }}> Location</th>
                    <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                    <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
                    <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Age</th>
                    <th style={{ whiteSpace: "nowrap" }}>Type</th>
                    <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
                    <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
                  </tr>
                </thead>

                <tbody >
                  {this.state.SearchNotsys &&
                    this.state.SearchNotsys.map((data, index) => {
                      return (
                        <tr >
                          <td
                            
                            onClick={() => (
                              (
                                <ViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "Module",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <SimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></SimpleMenu>
                              )
                            )}
                          >
                            {" "}
                            <SimpleMenu></SimpleMenu>
                          </td>
                          <td >
                            {data.ClaimID}
                          </td>
                          <td >
                            {data.Location}
                          </td>
                          <td >
                            {data.patientAccountNumber}
                          </td>
                          <td >
                            {data.DateOfService ? data.DateOfService : "-"}
                          </td>

                          <td >
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>

                          <td >
                            {data.PatientDOB
                              ? moment(data.PatientDOB).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                          <td >
                            {data.PlanCode ? data.PlanCode : "-"}
                          </td>
                          <td >
                            {data.Pripayer}
                          </td>
                          <td >
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td >
                            {data.PrimaryPolicyNo}
                          </td>
                          <td >
                            {data.Physician}
                          </td>
                          <td >
                            {data.Age}
                          </td>
                          <td >
                            {data.type}
                          </td>
                          <td >
                            {data.ClaimStatus}
                          </td>
                          <td >
                            {data.Claimunder}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCountforsearch}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePagesearchid}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div> }
            </div>
        ) : this.state.ShowthetablePaid === true ? (
          <div>
          {this.state.SearchPaid.length === 0 ?
            <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
         :
         <div>
            <div>
              <Table bordered responsive className="StandardTable">
                <thead>
                  <tr align="center">
                    <th>Action</th>
                    <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                    <th style={{ whiteSpace: "nowrap" }}> Location</th>
                    <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                    <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
                    <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Age</th>
                    <th style={{ whiteSpace: "nowrap" }}>Type</th>
                    <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
                    <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
                  </tr>
                </thead>

                <tbody >
                  {this.state.SearchPaid &&
                    this.state.SearchPaid.map((data, index) => {
                      return (
                        <tr >
                          <td
                            
                            onClick={() => (
                              (
                                <ViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "Module",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <SimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></SimpleMenu>
                              )
                            )}
                          >
                            {" "}
                            <SimpleMenu></SimpleMenu>
                          </td>
                          <td >
                            {data.ClaimID}
                          </td>
                          <td >
                            {data.Location}
                          </td>
                          <td >
                            {data.patientAccountNumber}
                          </td>
                          <td >
                            {data.DateOfService ? data.DateOfService : "-"}
                          </td>

                          <td >
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>

                          <td >
                            {data.PatientDOB
                              ? moment(data.PatientDOB).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                          <td >
                            {data.PlanCode ? data.PlanCode : "-"}
                          </td>
                          <td >
                            {data.Pripayer}
                          </td>
                          <td >
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td >
                            {data.PrimaryPolicyNo}
                          </td>
                          <td >
                            {data.Physician}
                          </td>
                          <td >
                            {data.Age}
                          </td>
                          <td >
                            {data.type}
                          </td>
                          <td >
                            {data.ClaimStatus}
                          </td>
                          <td >
                            {data.Claimunder}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCountforsearch}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePagesearchid}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div> }
            </div>
        ) : this.state.Showthetablemachine1search === true ? (
          <div>
          {this.state.SearchMachine1Searches.length === 0 ?
            <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
         :
         <div>
            <div>
              <Table bordered responsive className="StandardTable">
                <thead>
                  <tr align="center">
                    <th>Action</th>
                    <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                    <th style={{ whiteSpace: "nowrap" }}> Location</th>
                    <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                    <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
                    <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Age</th>
                    <th style={{ whiteSpace: "nowrap" }}>Type</th>
                    <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
                    <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
                  </tr>
                </thead>

                <tbody >
                  {this.state.SearchMachine1Searches &&
                    this.state.SearchMachine1Searches.map((data, index) => {
                      return (
                        <tr >
                          <td
                            
                            onClick={() => (
                              (
                                <ViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "Module",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <SimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></SimpleMenu>
                              )
                            )}
                          >
                            {" "}
                            <SimpleMenu></SimpleMenu>
                          </td>
                          <td >
                            {data.ClaimID}
                          </td>
                          <td >
                            {data.Location}
                          </td>
                          <td >
                            {data.patientAccountNumber}
                          </td>
                          <td >
                            {data.DateOfService ? data.DateOfService : "-"}
                          </td>

                          <td >
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>

                          <td >
                            {data.PatientDOB
                              ? moment(data.PatientDOB).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                          <td >
                            {data.PlanCode ? data.PlanCode : "-"}
                          </td>
                          <td >
                            {data.Pripayer}
                          </td>
                          <td >
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td >
                            {data.PrimaryPolicyNo}
                          </td>
                          <td >
                            {data.Physician}
                          </td>
                          <td >
                            {data.Age}
                          </td>
                          <td >
                            {data.type}
                          </td>
                          <td >
                            {data.ClaimStatus}
                          </td>
                          <td >
                            {data.Claimunder}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCountforsearch}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePagesearchid}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div> }
            </div>
        ) : this.state.Showthetablemachine2search === true ? (
          <div>
          {this.state.SearchMachine2Searches.length === 0 ?
            <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
         :
         <div>
            <div>
              <Table bordered responsive className="StandardTable">
                <thead>
                  <tr align="center">
                    <th>Action</th>
                    <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                    <th style={{ whiteSpace: "nowrap" }}> Location</th>
                    <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                    <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
                    <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Age</th>
                    <th style={{ whiteSpace: "nowrap" }}>Type</th>
                    <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
                    <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
                  </tr>
                </thead>

                <tbody >
                  {this.state.SearchMachine2Searches &&
                    this.state.SearchMachine2Searches.map((data, index) => {
                      return (
                        <tr >
                          <td
                            
                            onClick={() => (
                              (
                                <ViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "Module",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <SimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></SimpleMenu>
                              )
                            )}
                          >
                            {" "}
                            <SimpleMenu></SimpleMenu>
                          </td>
                          <td >
                            {data.ClaimID}
                          </td>
                          <td >
                            {data.Location}
                          </td>
                          <td >
                            {data.patientAccountNumber}
                          </td>
                          <td >
                            {data.DateOfService ? data.DateOfService : "-"}
                          </td>

                          <td >
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>

                          <td >
                            {data.PatientDOB
                              ? moment(data.PatientDOB).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                          <td >
                            {data.PlanCode ? data.PlanCode : "-"}
                          </td>
                          <td >
                            {data.Pripayer}
                          </td>
                          <td >
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td >
                            {data.PrimaryPolicyNo}
                          </td>
                          <td >
                            {data.Physician}
                          </td>
                          <td >
                            {data.Age}
                          </td>
                          <td >
                            {data.type}
                          </td>
                          <td >
                            {data.ClaimStatus}
                          </td>
                          <td >
                            {data.Claimunder}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCountforsearch}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePagesearchid}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div> }
            </div>
        ) :
        this.state.Showthetablesystem1err === true ? (
          <div>
          {this.state.SearchSystem1Error.length === 0 ?
            <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
         :
         <div>
            <div>
              <Table bordered responsive className="StandardTable">
                <thead>
                  <tr align="center">
                    <th>Action</th>
                    <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                    <th style={{ whiteSpace: "nowrap" }}> Location</th>
                    <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                    <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
                    <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Age</th>
                    <th style={{ whiteSpace: "nowrap" }}>Type</th>
                    <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
                    <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
                  </tr>
                </thead>

                <tbody >
                  {this.state.SearchSystem1Error &&
                    this.state.SearchSystem1Error.map((data, index) => {
                      return (
                        <tr >
                          <td
                            
                            onClick={() => (
                              (
                                <ViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "Module",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <SimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></SimpleMenu>
                              )
                            )}
                          >
                            {" "}
                            <SimpleMenu></SimpleMenu>
                          </td>
                          <td >
                            {data.ClaimID}
                          </td>
                          <td >
                            {data.Location}
                          </td>
                          <td >
                            {data.patientAccountNumber}
                          </td>
                          <td >
                            {data.DateOfService ? data.DateOfService : "-"}
                          </td>

                          <td >
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>

                          <td >
                            {data.PatientDOB
                              ? moment(data.PatientDOB).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                          <td >
                            {data.PlanCode ? data.PlanCode : "-"}
                          </td>
                          <td >
                            {data.Pripayer}
                          </td>
                          <td >
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td >
                            {data.PrimaryPolicyNo}
                          </td>
                          <td >
                            {data.Physician}
                          </td>
                          <td >
                            {data.Age}
                          </td>
                          <td >
                            {data.type}
                          </td>
                          <td >
                            {data.ClaimStatus}
                          </td>
                          <td >
                            {data.Claimunder}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCountforsearch}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePagesearchid}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div> }
            </div>
        ) : 
        this.state.Showthetablesystem2err === true ? (
          <div>
          {this.state.SearchSystem2Error.length === 0 ?
            <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
         :
         <div>
            <div>
              <Table bordered responsive className="StandardTable">
                <thead>
                  <tr align="center">
                    <th>Action</th>
                    <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                    <th style={{ whiteSpace: "nowrap" }}> Location</th>
                    <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                    <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
                    <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Age</th>
                    <th style={{ whiteSpace: "nowrap" }}>Type</th>
                    <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
                    <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
                  </tr>
                </thead>

                <tbody >
                  {this.state.SearchSystem2Error &&
                    this.state.SearchSystem2Error.map((data, index) => {
                      return (
                        <tr >
                          <td
                            
                            onClick={() => (
                              (
                                <ViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "Module",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <SimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></SimpleMenu>
                              )
                            )}
                          >
                            {" "}
                            <SimpleMenu></SimpleMenu>
                          </td>
                          <td >
                            {data.ClaimID}
                          </td>
                          <td >
                            {data.Location}
                          </td>
                          <td >
                            {data.patientAccountNumber}
                          </td>
                          <td >
                            {data.DateOfService ? data.DateOfService : "-"}
                          </td>

                          <td >
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>

                          <td >
                            {data.PatientDOB
                              ? moment(data.PatientDOB).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                          <td >
                            {data.PlanCode ? data.PlanCode : "-"}
                          </td>
                          <td >
                            {data.Pripayer}
                          </td>
                          <td >
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td >
                            {data.PrimaryPolicyNo}
                          </td>
                          <td >
                            {data.Physician}
                          </td>
                          <td >
                            {data.Age}
                          </td>
                          <td >
                            {data.type}
                          </td>
                          <td >
                            {data.ClaimStatus}
                          </td>
                          <td >
                            {data.Claimunder}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCountforsearch}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePagesearchid}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div> }
            </div>
        ) : 
        this.state.Showthetableuploaderr === true ? (
          <div>
          {this.state.SearchUploadError.length === 0 ?
            <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
         :
         <div>
            <div>
              <Table bordered responsive className="StandardTable">
                <thead>
                  <tr align="center">
                    <th>Action</th>
                    <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                    <th style={{ whiteSpace: "nowrap" }}> Location</th>
                    <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                    <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
                    <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Age</th>
                    <th style={{ whiteSpace: "nowrap" }}>Type</th>
                    <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
                    <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
                  </tr>
                </thead>

                <tbody >
                  {this.state.SearchUploadError &&
                    this.state.SearchUploadError.map((data, index) => {
                      return (
                        <tr >
                          <td
                            
                            onClick={() => (
                              (
                                <ViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "Module",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <SimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></SimpleMenu>
                              )
                            )}
                          >
                            {" "}
                            <SimpleMenu></SimpleMenu>
                          </td>
                          <td >
                            {data.ClaimID}
                          </td>
                          <td >
                            {data.Location}
                          </td>
                          <td >
                            {data.patientAccountNumber}
                          </td>
                          <td >
                            {data.DateOfService ? data.DateOfService : "-"}
                          </td>

                          <td >
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>

                          <td >
                            {data.PatientDOB
                              ? moment(data.PatientDOB).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                          <td >
                            {data.PlanCode ? data.PlanCode : "-"}
                          </td>
                          <td >
                            {data.Pripayer}
                          </td>
                          <td >
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td >
                            {data.PrimaryPolicyNo}
                          </td>
                          <td >
                            {data.Physician}
                          </td>
                          <td >
                            {data.Age}
                          </td>
                          <td >
                            {data.type}
                          </td>
                          <td >
                            {data.ClaimStatus}
                          </td>
                          <td >
                            {data.Claimunder}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCountforsearch}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePagesearchid}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div> }
            </div>
        ) : 
         this.state.Showthetablemachine1err === true ? (
          <div>
          {this.state.SearchMachine1Error.length === 0 ?
            <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
         :
         <div>
            <div>
              <Table bordered responsive className="StandardTable">
                <thead>
                  <tr align="center">
                    <th>Action</th>
                    <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                    <th style={{ whiteSpace: "nowrap" }}> Location</th>
                    <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                    <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
                    <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Age</th>
                    <th style={{ whiteSpace: "nowrap" }}>Type</th>
                    <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
                    <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
                  </tr>
                </thead>

                <tbody >
                  {this.state.SearchMachine1Error &&
                    this.state.SearchMachine1Error.map((data, index) => {
                      return (
                        <tr >
                          <td
                            
                            onClick={() => (
                              (
                                <ViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "Module",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <SimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></SimpleMenu>
                              )
                            )}
                          >
                            {" "}
                            <SimpleMenu></SimpleMenu>
                          </td>
                          <td >
                            {data.ClaimID}
                          </td>
                          <td >
                            {data.Location}
                          </td>
                          <td >
                            {data.patientAccountNumber}
                          </td>
                          <td >
                            {data.DateOfService ? data.DateOfService : "-"}
                          </td>

                          <td >
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>

                          <td >
                            {data.PatientDOB
                              ? moment(data.PatientDOB).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                          <td >
                            {data.PlanCode ? data.PlanCode : "-"}
                          </td>
                          <td >
                            {data.Pripayer}
                          </td>
                          <td >
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td >
                            {data.PrimaryPolicyNo}
                          </td>
                          <td >
                            {data.Physician}
                          </td>
                          <td >
                            {data.Age}
                          </td>
                          <td >
                            {data.type}
                          </td>
                          <td >
                            {data.ClaimStatus}
                          </td>
                          <td >
                            {data.Claimunder}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCountforsearch}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePagesearchid}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div> }
            </div>
        ) : 
        this.state.Showthetablemachine2err === true ? (
          <div>
          {this.state.SearchMachine2Error.length === 0 ?
            <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
          </div>
         :
         <div>
            <div>
              <Table bordered responsive className="StandardTable">
                <thead>
                  <tr align="center">
                    <th>Action</th>
                    <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                    <th style={{ whiteSpace: "nowrap" }}> Location</th>
                    <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                    <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Patient DOB</th>
                    <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Insurance No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                    <th style={{ whiteSpace: "nowrap" }}>Physician Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Age</th>
                    <th style={{ whiteSpace: "nowrap" }}>Type</th>
                    <th style={{ whiteSpace: "nowrap" }}>Claim Status</th>
                    <th style={{ whiteSpace: "nowrap" }}>AllocatedTo</th>
                  </tr>
                </thead>

                <tbody >
                  {this.state.SearchMachine2Error &&
                    this.state.SearchMachine2Error.map((data, index) => {
                      return (
                        <tr >
                          <td
                            
                            onClick={() => (
                              (
                                <ViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "Module",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem("MRN", data.MRN) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),
                              (
                                <SimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Claim Status Management"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></SimpleMenu>
                              )
                            )}
                          >
                            {" "}
                            <SimpleMenu></SimpleMenu>
                          </td>
                          <td >
                            {data.ClaimID}
                          </td>
                          <td >
                            {data.Location}
                          </td>
                          <td >
                            {data.patientAccountNumber}
                          </td>
                          <td >
                            {data.DateOfService ? data.DateOfService : "-"}
                          </td>

                          <td >
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>

                          <td >
                            {data.PatientDOB
                              ? moment(data.PatientDOB).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                          <td >
                            {data.PlanCode ? data.PlanCode : "-"}
                          </td>
                          <td >
                            {data.Pripayer}
                          </td>
                          <td >
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td >
                            {data.PrimaryPolicyNo}
                          </td>
                          <td >
                            {data.Physician}
                          </td>
                          <td >
                            {data.Age}
                          </td>
                          <td >
                            {data.type}
                          </td>
                          <td >
                            {data.ClaimStatus}
                          </td>
                          <td >
                            {data.Claimunder}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCountforsearch}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePagesearchid}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div> }
            </div>
        ) : 
        (
          <Tabs
            activeTab="1"
            activityClassName="text-secondary"
            onClick={(event, tab) => {
              this.setState({ tabindex: tab });
            }}
          >
            <Tab title="WEB CLAIMS">
              {this.state.fetchingWeb ? (
                <div>
                  <p
                    style={{
                      fontSize: "13px",
                      marginTop: "10px",
                      color: "#000",
                    }}
                  >
                    <span>Loading...</span>
                    <span>Please Wait...</span>
                  </p>
                  <div class="boxes body__loader dribbble">
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              ) : this.state.claimweb.length === 0 ? (
                <div class="bot-ui">
                  <div class="bot-head">
                    <div class="eyes-container">
                      <div class="to-left eye"></div>
                      <div class="to-right eye"></div>
                    </div>
                  </div>
                  <div class="bot-body">
                    <div class="analyzer"></div>
                  </div>
                  <p style={{ fontSize: "13px", fontStyle: "italic" }}>
                    No Data Found
                  </p>
                </div>
              ) : (
                <div>
                  <div>
                    {this.state.sorttable === true ? (
                      <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCountwebsort}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClickWebsort}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          forcePage={this.state.currentpagesort}
                        />
                      </div>
                    ) : (
                      <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCountweb}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClickWeb}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          forcePage={this.state.currentpageweb}
                        />
                      </div>
                    )}
                    <Table responsive bordered className="StandardTable">
                      <thead>
                        <tr align="center">
                          <th>Action</th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "ClaimID",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "ClaimID",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            ClaimID
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "ClaimID",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "ClaimID",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Location",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "Location",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Location
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Location",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "Location",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        fieldvalue: "patientAccountNumber",
                                        sortvalue: "ascending",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        fieldvalue: "patientAccountNumber",
                                        sortvalue: "descending",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            AccountNo
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      fieldvalue: "patientAccountNumber",
                                      sortvalue: "ascending",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      fieldvalue: "patientAccountNumber",
                                      sortvalue: "descending",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "DateOfService",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalu: "descending",
                                        fieldvalue: "DateOfService",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOS
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "DateOfService",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalu: "descending",
                                      fieldvalue: "DateOfService",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "DOIAge",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalu: "descending",
                                        fieldvalue: "DOIAge",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            DOI Age
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "DOIAge",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalu: "descending",
                                      fieldvalue: "DOIAge",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "DOIAgeGrp",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalu: "descending",
                                        fieldvalue: "DOIAgeGrp",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            DOI Agegrp
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "DOIAgeGrp",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalu: "descending",
                                      fieldvalue: "DOIAgeGrp",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PatientFirstName",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalu: "descending",
                                        fieldvalue: "PatientFirstName",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PatientName
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "PatientFirstName",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalu: "descending",
                                      fieldvalue: "PatientFirstName",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PatientDOB",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalu: "descending",
                                        fieldvalue: "PatientDOB",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PatientDOB
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "PatientDOB",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalu: "descending",
                                      fieldvalue: "PatientDOB",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PlanCode",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalu: "descending",
                                        fieldvalue: "PlanCode",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PlanCode
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "PlanCode",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalu: "descending",
                                      fieldvalue: "PlanCode",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "InsuranceName",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalu: "descending",
                                        fieldvalue: "InsuranceName",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            InsuranceName
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "InsuranceName",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalu: "descending",
                                      fieldvalue: "InsuranceName",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PriPayerphone",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalu: "descending",
                                        fieldvalue: "PriPayerphone",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Insurance No
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "PriPayerphone",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalu: "descending",
                                      fieldvalue: "PriPayerphone",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PrimaryPolicyNo",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalu: "descending",
                                        fieldvalue: "PrimaryPolicyNo",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PolicyNo
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "PrimaryPolicyNo",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalu: "descending",
                                      fieldvalue: "PrimaryPolicyNo",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PrimaryPolicyNo",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalu: "descending",
                                        fieldvalue: "PrimaryPolicyNo",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PhysicianName
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Physician",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalu: "descending",
                                      fieldvalue: "Physician",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Age",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalu: "descending",
                                        fieldvalue: "Age",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Age",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalu: "descending",
                                      fieldvalue: "Age",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "type",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalu: "descending",
                                        fieldvalue: "type",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Type
                            {() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "type",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalu: "descending",
                                        fieldvalue: "type",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Claimunder",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalu: "descending",
                                        fieldvalue: "Claimunder",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            AllocatedTo
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Claimunder",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalu: "descending",
                                      fieldvalue: "Claimunder",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "ClaimStatus",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalu: "descending",
                                        fieldvalue: "ClaimStatus",
                                        sorttable: true,
                                      },
                                      () => this.getsortingweb()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            ClaimStatus
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "ClaimStatus",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalu: "descending",
                                      fieldvalue: "ClaimStatus",
                                      sorttable: true,
                                    },
                                    () => this.getsortingweb()
                                  )
                                }
                              />
                            )}
                          </th>
                        </tr>
                      </thead>
                      {this.state.sorttable === true ? (
                        <tbody>
                          {this.state.webclaimsort &&
                            this.state.webclaimsort.map((data, index) => {
                              return (
                                <tr>
                                  <td
                                    onClick={() => (
                                      (
                                        <ViewClaim
                                          value={
                                            sessionStorage.setItem(
                                              "Module",
                                              "Claim Status Management"
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimID",
                                              data.ClaimID
                                            ) ||
                                            sessionStorage.setItem(
                                              "ChartID",
                                              data.ChartID
                                            ) ||
                                            sessionStorage.setItem(
                                              "MRN",
                                              data.MRN
                                            ) ||
                                            sessionStorage.setItem(
                                              "Patient",
                                              data.Patient
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientDOB",
                                              data.PatientDOB
                                            ) ||
                                            sessionStorage.setItem(
                                              "Physician",
                                              data.Physician
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "Pripayer",
                                              data.Pripayer
                                            ) ||
                                            sessionStorage.setItem(
                                              "DateOfService",
                                              data.DateOfService
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDBID",
                                              data._id
                                            ) ||
                                            sessionStorage.setItem(
                                              "AllocatedTo",
                                              data.Claimunder
                                            )
                                          }
                                        />
                                      ),
                                      (
                                        <Viewmoreeligibility
                                          value={
                                            sessionStorage.setItem(
                                              "modulename",
                                              "Claim Status Management"
                                            ) ||
                                            sessionStorage.setItem(
                                              "Eligibilityclaimid",
                                              data.ClaimID
                                            )
                                          }
                                        />
                                      )
                                    )}
                                  >
                                    {" "}
                                    <SimpleMenu></SimpleMenu>
                                  </td>
                                  <td>{data.ClaimID}</td>
                                  <td>{data.Location}</td>
                                  <td>{data.patientAccountNumber}</td>

                                  <td>
                                    {data.DateOfService
                                      ? data.DateOfService
                                      : "-"}
                                  </td>
                                  <td>{data.DOIAge ? data.DOIAge : "-"}</td>
                                  <td>
                                    {data.DOIAgeGrp ? data.DOIAgeGrp : "-"}
                                  </td>
                                  <td>
                                    {data.PatientFirstName}&nbsp;
                                    {data.PatientLastName}
                                  </td>
                                  <td>
                                    {data.PatientDOB
                                      ? moment(data.PatientDOB).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {data.PlanCode ? data.PlanCode : "-"}
                                  </td>
                                  <td>
                                    {" "}
                                    {data.InsuranceName
                                      ? data.InsuranceName
                                      : "-"}
                                  </td>
                                  <td>
                                    <Row>
                                      <Col md="4">
                                        <Twilio
                                          value11={data.Pripayerphone}
                                        />
                                      </Col>
                                      <Col md="2">{data.Pripayerphone}</Col>
                                    </Row>
                                  </td>
                                  <td>{data.PrimaryPolicyNo}</td>
                                  <td>{data.Physician}</td>
                                  <td>{data.Age}</td>
                                  <td>{data.type}</td>
                                  <td>{data.Claimunder}</td>
                                  <td>{data.ClaimStatus}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      ) : (
                        <tbody>
                          {this.state.claimweb &&
                            this.state.claimweb.map((data, index) => {
                              return (
                                <tr>
                                  <td
                                    onClick={() => (
                                      (
                                        <ViewClaim
                                          value={
                                            sessionStorage.setItem(
                                              "Module",
                                              "Claim Status Management"
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimID",
                                              data.ClaimID
                                            ) ||
                                            sessionStorage.setItem(
                                              "ChartID",
                                              data.ChartID
                                            ) ||
                                            sessionStorage.setItem(
                                              "MRN",
                                              data.MRN
                                            ) ||
                                            sessionStorage.setItem(
                                              "Patient",
                                              data.Patient
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientDOB",
                                              data.PatientDOB
                                            ) ||
                                            sessionStorage.setItem(
                                              "Physician",
                                              data.Physician
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "Pripayer",
                                              data.Pripayer
                                            ) ||
                                            sessionStorage.setItem(
                                              "DateOfService",
                                              data.DateOfService
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDBID",
                                              data._id
                                            ) ||
                                            sessionStorage.setItem(
                                              "AllocatedTo",
                                              data.Claimunder
                                            )
                                          }
                                        />
                                      ),
                                      (
                                        <Viewmoreeligibility
                                          value={
                                            sessionStorage.setItem(
                                              "modulename",
                                              "Claim Status Management"
                                            ) ||
                                            sessionStorage.setItem(
                                              "Eligibilityclaimid",
                                              data.ClaimID
                                            )
                                          }
                                        />
                                      )
                                    )}
                                  >
                                    {" "}
                                    <SimpleMenu></SimpleMenu>
                                  </td>
                                  <td>{data.ClaimID}</td>
                                  <td>{data.Location}</td>
                                  <td>{data.patientAccountNumber}</td>

                                  <td>
                                    {data.DateOfService
                                      ? data.DateOfService
                                      : "-"}
                                  </td>
                                  <td>{data.DOIAge ? data.DOIAge : "-"}</td>
                                  <td>
                                    {data.DOIAgeGrp ? data.DOIAgeGrp : "-"}
                                  </td>
                                  <td>
                                    {data.PatientFirstName}&nbsp;
                                    {data.PatientLastName}
                                  </td>
                                  <td>
                                    {data.PatientDOB
                                      ? moment(data.PatientDOB).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {data.PlanCode ? data.PlanCode : "-"}
                                  </td>
                                  <td>
                                    {" "}
                                    {data.InsuranceName
                                      ? data.InsuranceName
                                      : "-"}
                                  </td>
                                  <td>
                                    <Row>
                                      <Col md="4">
                                        <Twilio
                                          value11={data.Pripayerphone}
                                        />
                                      </Col>
                                      <Col md="2">{data.Pripayerphone}</Col>
                                    </Row>
                                  </td>
                                  <td>{data.PrimaryPolicyNo}</td>
                                  <td>{data.Physician}</td>
                                  <td>{data.Age}</td>
                                  <td>{data.type}</td>
                                  <td>{data.Claimunder}</td>
                                  <td>{data.ClaimStatus}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      )}
                    </Table>
                  </div>
                  {this.state.sorttable === true ? (
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCountwebsort}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClickWebsort}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.currentpagesort}
                      />
                    </div>
                  ) : (
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCountweb}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClickWeb}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.currentpageweb}
                      />
                    </div>
                  )}
                </div>
              )}
            </Tab>
            <Tab title="IVR CLAIMS">
              {this.state.fetchingIvr ? (
                <div>
                  <p
                    style={{
                      fontSize: "13px",
                      marginTop: "10px",
                      color: "#000",
                    }}
                  >
                    <span>Loading...</span>
                    <span>Please Wait...</span>
                  </p>
                  <div class="boxes body__loader dribbble">
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              ) : this.state.claimivr.length === 0 ? (
                <div class="bot-ui">
                  <div class="bot-head">
                    <div class="eyes-container">
                      <div class="to-left eye"></div>
                      <div class="to-right eye"></div>
                    </div>
                  </div>
                  <div class="bot-body">
                    <div class="analyzer"></div>
                  </div>
                  <p style={{ fontSize: "13px", fontStyle: "italic" }}>
                    No Data Found
                  </p>
                </div>
              ) : (
                <div>
                  <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCountivr}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClickIvr}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      forcePage={this.state.currentpageivr}
                    />
                  </div>
                  <div>
                    <Table bordered responsive className="StandardTable">
                      <thead>
                        <tr align="center">
                          <th>Action</th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "ClaimID",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "ClaimID",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                          >
                            ClaimID
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "ClaimID",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "ClaimID",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Location",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "Location",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Location
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Location",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "Location",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "patientAccountNumber",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "patientAccountNumber",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            AccountNo
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "patientAccountNumber",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "patientAccountNumber",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "DateOfService",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "DateOfService",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOS
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "DateOfService",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "DateOfService",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "DOIAge",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "DOIAge",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            DOI Age
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "DOIAge",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "DOIAge",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "DOIAgeGrp",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "DOIAge",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            DOI Agegrp
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "DOIAgeGrp",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "DOIAge",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PatientFirstName",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "PatientFirstName",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PatientName
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "PatientFirstName",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "PatientFirstName",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PatientDOB",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "PatientDOB",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PatientDOB
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "PatientDOB",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "PatientDOB",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PlanCode",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "PlanCode",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PlanCode
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "PlanCode",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "PlanCode",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "InsuranceName",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "InsuranceName",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            InsuranceName
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "InsuranceName",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "InsuranceName",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PriPayerphone",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "PriPayerphone",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Insurance No
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "PriPayerphone",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "PriPayerphone",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PrimaryPolicyNo",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "PrimaryPolicyNo",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PolicyNo
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "PrimaryPolicyNo",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "PrimaryPolicyNo",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Physician",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "Physician",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PhysicianName
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Physician",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "Physician",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Physician",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "Physician",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Age",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "Age",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Physician",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "Physician",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Type
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "type",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "type",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Claimunder",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "Claimunder",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            AllocatedTo
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Claimunder",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "Claimunder",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "ClaimStatus",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "ClaimStatus",
                                        sorttableivr: true,
                                      },
                                      () => this.getsortingivr()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            ClaimStatus
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "ClaimStatus",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "ClaimStatus",
                                      sorttableivr: true,
                                    },
                                    () => this.getsortingivr()
                                  )
                                }
                              />
                            )}
                          </th>
                        </tr>
                      </thead>
                      {this.state.sorttableivr === true ? (
                        <tbody>
                          {this.state.ivrclaimsort &&
                            this.state.ivrclaimsort.map((data, index) => {
                              return (
                                <tr>
                                  <td
                                    onClick={() => (
                                      (
                                        <ViewClaim
                                          value={
                                            sessionStorage.setItem(
                                              "Module",
                                              "Claim Status Management"
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimID",
                                              data.ClaimID
                                            ) ||
                                            sessionStorage.setItem(
                                              "ChartID",
                                              data.ChartID
                                            ) ||
                                            sessionStorage.setItem(
                                              "MRN",
                                              data.MRN
                                            ) ||
                                            sessionStorage.setItem(
                                              "Patient",
                                              data.Patient
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientDOB",
                                              data.PatientDOB
                                            ) ||
                                            sessionStorage.setItem(
                                              "Physician",
                                              data.Physician
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "Pripayer",
                                              data.Pripayer
                                            ) ||
                                            sessionStorage.setItem(
                                              "DateOfService",
                                              data.DateOfService
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDBID",
                                              data._id
                                            ) ||
                                            sessionStorage.setItem(
                                              "AllocatedTo",
                                              data.Claimunder
                                            )
                                          }
                                        />
                                      ),
                                      (
                                        <Viewmoreeligibility
                                          value={
                                            sessionStorage.setItem(
                                              "modulename",
                                              "Claim Status Management"
                                            ) ||
                                            sessionStorage.setItem(
                                              "Eligibilityclaimid",
                                              data.ClaimID
                                            )
                                          }
                                        />
                                      )
                                    )}
                                  >
                                    {" "}
                                    <SimpleMenu></SimpleMenu>
                                  </td>
                                  <td>{data.ClaimID}</td>
                                  <td>{data.Location}</td>
                                  <td>{data.patientAccountNumber}</td>
                                  <td>
                                    {data.DateOfService
                                      ? data.DateOfService
                                      : "-"}
                                  </td>
                                  <td>{data.DOIAge ? data.DOIAge : "-"}</td>
                                  <td>
                                    {data.DOIAgeGrp ? data.DOIAgeGrp : "-"}
                                  </td>
                                  <td>
                                    {data.PatientFirstName}&nbsp;
                                    {data.PatientLastName}
                                  </td>
                                  <td>
                                    {data.PatientDOB
                                      ? moment(data.PatientDOB).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {data.PlanCode ? data.PlanCode : "-"}
                                  </td>
                                  <td>
                                    {" "}
                                    {data.InsuranceName
                                      ? data.InsuranceName
                                      : "-"}
                                  </td>
                                  <td>
                                    <Row>
                                      <Col md="4">
                                        <Twilio
                                          value11={data.Pripayerphone}
                                        />
                                      </Col>
                                      <Col md="2">{data.Pripayerphone}</Col>
                                    </Row>
                                  </td>
                                  <td>{data.PrimaryPolicyNo}</td>
                                  <td>{data.Physician}</td>
                                  <td>{data.Age}</td>
                                  <td>{data.type}</td>
                                  <td>{data.Claimunder}</td>
                                  <td>{data.ClaimStatus}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      ) : (
                        <tbody>
                          {this.state.claimivr &&
                            this.state.claimivr.map((data, index) => {
                              return (
                                <tr>
                                  <td
                                    onClick={() => (
                                      (
                                        <ViewClaim
                                          value={
                                            sessionStorage.setItem(
                                              "Module",
                                              "Claim Status Management"
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimID",
                                              data.ClaimID
                                            ) ||
                                            sessionStorage.setItem(
                                              "ChartID",
                                              data.ChartID
                                            ) ||
                                            sessionStorage.setItem(
                                              "MRN",
                                              data.MRN
                                            ) ||
                                            sessionStorage.setItem(
                                              "Patient",
                                              data.Patient
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientDOB",
                                              data.PatientDOB
                                            ) ||
                                            sessionStorage.setItem(
                                              "Physician",
                                              data.Physician
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "Pripayer",
                                              data.Pripayer
                                            ) ||
                                            sessionStorage.setItem(
                                              "DateOfService",
                                              data.DateOfService
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDBID",
                                              data._id
                                            ) ||
                                            sessionStorage.setItem(
                                              "AllocatedTo",
                                              data.Claimunder
                                            )
                                          }
                                        />
                                      ),
                                      (
                                        <Viewmoreeligibility
                                          value={
                                            sessionStorage.setItem(
                                              "modulename",
                                              "Claim Status Management"
                                            ) ||
                                            sessionStorage.setItem(
                                              "Eligibilityclaimid",
                                              data.ClaimID
                                            )
                                          }
                                        />
                                      )
                                    )}
                                  >
                                    {" "}
                                    <SimpleMenu></SimpleMenu>
                                  </td>
                                  <td>{data.ClaimID}</td>
                                  <td>{data.Location}</td>
                                  <td>{data.patientAccountNumber}</td>
                                  <td>
                                    {data.DateOfService
                                      ? data.DateOfService
                                      : "-"}
                                  </td>
                                  <td>{data.DOIAge ? data.DOIAge : "-"}</td>
                                  <td>
                                    {data.DOIAgeGrp ? data.DOIAgeGrp : "-"}
                                  </td>
                                  <td>
                                    {data.PatientFirstName}&nbsp;
                                    {data.PatientLastName}
                                  </td>
                                  <td>
                                    {data.PatientDOB
                                      ? moment(data.PatientDOB).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {data.PlanCode ? data.PlanCode : "-"}
                                  </td>
                                  <td>
                                    {" "}
                                    {data.InsuranceName
                                      ? data.InsuranceName
                                      : "-"}
                                  </td>
                                  <td>
                                    <Row>
                                      <Col md="4">
                                        <Twilio
                                          value11={data.Pripayerphone}
                                        />
                                      </Col>
                                      <Col md="2">{data.Pripayerphone}</Col>
                                    </Row>
                                  </td>
                                  <td>{data.PrimaryPolicyNo}</td>
                                  <td>{data.Physician}</td>
                                  <td>{data.Age}</td>
                                  <td>{data.type}</td>
                                  <td>{data.Claimunder}</td>
                                  <td>{data.ClaimStatus}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      )}
                    </Table>
                  </div>
                  {this.state.sorttableivr === true ? (
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCountivrsort}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClickIvrsort}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.currentpageivr}
                      />
                    </div>
                  ) : (
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCountivr}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClickIvr}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.currentpageivr}
                      />
                    </div>
                  )}
                </div>
              )}
            </Tab>
            <Tab title="MANUAL CLAIMS">
              {this.state.fetchingManual ? (
                // <img src={loading} style={{ marginTop: "5%" }} />
                <div>
                  <p
                    style={{
                      fontSize: "13px",
                      marginTop: "10px",
                      color: "#000",
                    }}
                  >
                    <span>Loading...</span>
                    <span>Please Wait...</span>
                  </p>
                  <div class="boxes body__loader dribbble">
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              ) : this.state.claimmanual.length === 0 ? (
                <div class="bot-ui">
                  <div class="bot-head">
                    <div class="eyes-container">
                      <div class="to-left eye"></div>
                      <div class="to-right eye"></div>
                    </div>
                  </div>
                  <div class="bot-body">
                    <div class="analyzer"></div>
                  </div>
                  <p style={{ fontSize: "13px", fontStyle: "italic" }}>
                    No Data Found
                  </p>
                </div>
              ) : (
                <div>
                  {this.state.sorttablemanual === true ? (
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCountmanualsort}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClickManualsort}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.currentpagemanual}
                      />
                    </div>
                  ) : (
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCountmanual}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClickManual}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.currentpagemanual}
                      />
                    </div>
                  )}
                  <div>
                    <Table bordered responsive className="StandardTable">
                      <thead>
                        <tr align="left">
                          <th> Action</th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "ClaimID",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "ClaimID",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            ClaimID
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "ClaimID",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "ClaimID",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Location",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "Location",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Location
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Location",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "Location",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Location",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "Location",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            AccountNo
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "patientAccountNumber",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "patientAccountNumber",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "DateOfService",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "DateOfService",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOS
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "DateOfService",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "DateOfService",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "DOIAge",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "DOIAge",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            DOI Age
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "DOIAge",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "DOIAge",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "DOIAgeGrp",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "DOIAgeGrp",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            DOI Agegrp
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "DOIAgeGrp",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "DOIAgeGrp",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "patientFirstName",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "patientFirstName",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PatientName
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "patientFirstName",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "patientFirstName",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PatientDOB",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "PatientDOB",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PatientDOB
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "PatientDOB",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "PatientDOB",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PlanCode",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "PlanCode",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PlanCode
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "PlanCode",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "PlanCode",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "InsuranceName",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "InsuranceName",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            InsuranceName
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "InsuranceName",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "InsuranceName",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PriPayerphone",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "PriPayerphone",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Insurance No
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "PriPayerphone",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "PriPayerphone",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PrimaryPolicyNo",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "PrimaryPolicyNo",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PolicyNo
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "PrimaryPolicyNo",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "PrimaryPolicyNo",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Physician",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "Physician",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PhysicianName
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Physician",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "Physician",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Age",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "Age",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Age",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "Age",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "type",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "type",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Type
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "type",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "type",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Claimunder",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "Claimunder",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            AllocatedTo
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Claimunder",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "Claimunder",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "ClaimStatus",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "ClaimStatus",
                                        sorttablemanual: true,
                                      },
                                      () => this.getsortingmanual()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            ClaimStatus
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "ClaimStatus",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "ClaimStatus",
                                      sorttablemanual: true,
                                    },
                                    () => this.getsortingmanual()
                                  )
                                }
                              />
                            )}
                          </th>
                        </tr>
                      </thead>
                      {this.state.sorttablemanual === true ? (
                        <tbody>
                          {this.state.manualclaimsort &&
                            this.state.manualclaimsort.map((data, index) => {
                              return (
                                <tr>
                                  <td
                                    onClick={() => (
                                      (
                                        <ViewClaim
                                          value={
                                            sessionStorage.setItem(
                                              "Module",
                                              "Claim Status Management"
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimID",
                                              data.ClaimID
                                            ) ||
                                            sessionStorage.setItem(
                                              "ChartID",
                                              data.ChartID
                                            ) ||
                                            sessionStorage.setItem(
                                              "MRN",
                                              data.MRN
                                            ) ||
                                            sessionStorage.setItem(
                                              "Patient",
                                              data.Patient
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientDOB",
                                              data.PatientDOB
                                            ) ||
                                            sessionStorage.setItem(
                                              "Physician",
                                              data.Physician
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "Pripayer",
                                              data.Pripayer
                                            ) ||
                                            sessionStorage.setItem(
                                              "DateOfService",
                                              data.DateOfService
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDBID",
                                              data._id
                                            ) ||
                                            sessionStorage.setItem(
                                              "AllocatedTo",
                                              data.Claimunder
                                            )
                                          }
                                        />
                                      ),
                                      (
                                        <Viewmoreeligibility
                                          value={
                                            sessionStorage.setItem(
                                              "modulename",
                                              "Claim Status Management"
                                            ) ||
                                            sessionStorage.setItem(
                                              "Eligibilityclaimid",
                                              data.ClaimID
                                            )
                                          }
                                        />
                                      )
                                    )}
                                  >
                                    {" "}
                                    <SimpleMenu></SimpleMenu>
                                  </td>
                                  <td>{data.ClaimID}</td>
                                  <td>{data.Location}</td>
                                  <td>{data.patientAccountNumber}</td>
                                  <td>
                                    {data.DateOfService
                                      ? data.DateOfService
                                      : "-"}
                                  </td>
                                  <td>{data.DOIAge ? data.DOIAge : "-"}</td>
                                  <td>
                                    {data.DOIAgeGrp ? data.DOIAgeGrp : "-"}
                                  </td>
                                  <td>
                                    {data.PatientFirstName}&nbsp;
                                    {data.PatientLastName}
                                  </td>
                                  <td>
                                    {data.PatientDOB
                                      ? moment(data.PatientDOB).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {data.PlanCode ? data.PlanCode : "-"}
                                  </td>
                                  <td>
                                    {" "}
                                    {data.InsuranceName
                                      ? data.InsuranceName
                                      : "-"}
                                  </td>
                                  <td>
                                    <Row>
                                      <Col md="4">
                                        <Twilio
                                          value11={data.Pripayerphone}
                                        />
                                      </Col>
                                      <Col md="2">{data.Pripayerphone}</Col>
                                    </Row>
                                  </td>
                                  <td>{data.PrimaryPolicyNo}</td>
                                  <td>{data.Physician}</td>
                                  <td>{data.Age}</td>
                                  <td>{data.type}</td>
                                  <td>{data.Claimunder}</td>
                                  <td>{data.ClaimStatus}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      ) : (
                        <tbody>
                          {this.state.claimmanual &&
                            this.state.claimmanual.map((data, index) => {
                              return (
                                <tr>
                                  <td
                                    onClick={() => (
                                      (
                                        <ViewClaim
                                          value={
                                            sessionStorage.setItem(
                                              "Module",
                                              "Claim Status Management"
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimID",
                                              data.ClaimID
                                            ) ||
                                            sessionStorage.setItem(
                                              "ChartID",
                                              data.ChartID
                                            ) ||
                                            sessionStorage.setItem(
                                              "MRN",
                                              data.MRN
                                            ) ||
                                            sessionStorage.setItem(
                                              "Patient",
                                              data.Patient
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientDOB",
                                              data.PatientDOB
                                            ) ||
                                            sessionStorage.setItem(
                                              "Physician",
                                              data.Physician
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "Pripayer",
                                              data.Pripayer
                                            ) ||
                                            sessionStorage.setItem(
                                              "DateOfService",
                                              data.DateOfService
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDBID",
                                              data._id
                                            ) ||
                                            sessionStorage.setItem(
                                              "AllocatedTo",
                                              data.Claimunder
                                            )
                                          }
                                        />
                                      ),
                                      (
                                        <Viewmoreeligibility
                                          value={
                                            sessionStorage.setItem(
                                              "modulename",
                                              "Claim Status Management"
                                            ) ||
                                            sessionStorage.setItem(
                                              "Eligibilityclaimid",
                                              data.ClaimID
                                            )
                                          }
                                        />
                                      )
                                    )}
                                  >
                                    {" "}
                                    <SimpleMenu></SimpleMenu>
                                  </td>
                                  <td>{data.ClaimID}</td>
                                  <td>{data.Location}</td>
                                  <td>{data.patientAccountNumber}</td>
                                  <td>
                                    {data.DateOfService
                                      ? data.DateOfService
                                      : "-"}
                                  </td>
                                  <td>{data.DOIAge ? data.DOIAge : "-"}</td>
                                  <td>
                                    {data.DOIAgeGrp ? data.DOIAgeGrp : "-"}
                                  </td>
                                  <td>
                                    {data.PatientFirstName}&nbsp;
                                    {data.PatientLastName}
                                  </td>
                                  <td>
                                    {data.PatientDOB
                                      ? moment(data.PatientDOB).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {data.PlanCode ? data.PlanCode : "-"}
                                  </td>
                                  <td>
                                    {" "}
                                    {data.InsuranceName
                                      ? data.InsuranceName
                                      : "-"}
                                  </td>
                                  <td>
                                    <Row>
                                      <Col md="4">
                                        <Twilio
                                          value11={data.Pripayerphone}
                                        />
                                      </Col>
                                      <Col md="2">{data.Pripayerphone}</Col>
                                    </Row>
                                  </td>
                                  <td>{data.PrimaryPolicyNo}</td>
                                  <td>{data.Physician}</td>
                                  <td>{data.Age}</td>
                                  <td>{data.type}</td>
                                  <td>{data.Claimunder}</td>
                                  <td>{data.ClaimStatus}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      )}
                    </Table>
                  </div>
                  {this.state.sorttablemanual === true ? (
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCountmanualsort}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClickManualsort}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.currentpagemanual}
                      />
                    </div>
                  ) : (
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCountmanual}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClickManual}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.currentpagemanual}
                      />
                    </div>
                  )}
                </div>
              )}
            </Tab>
          </Tabs>
        )}
      </div>
      {this.state.allocatemodal1 && (
        <Modal
          // // style={{ width: "30%" }}
          isOpen={this.state.allocatemodal1}
          onHide={this.toggle1}
          onClose={this.handleClose}
          size="lg"
          centered
        >
          <ModalHeader toggle={this.toggle1}>Allocated</ModalHeader>
          <ModalBody>
            <Row style={{ margin: "0px" }}>
              <div style={{ width: "100%" }}>
                <Table
                  className="StandardTable"
                  bordered
                  style={{
                    marginLeft: "3%",
                    marginTop: "4%",
                  }}
                >
                  <thead>
                    <tr align="center">
                      <th>Username</th>
                      <th>No of Claims</th>
                      <th>Completed</th>
                      <th>Not Touched</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.webclaim &&
                      this.state.webclaim.map((data, index) => {
                        return (
                          <tr>
                            <td>{data.username}</td>
                            <td>
                              <Link
                                to={{
                                  pathname:
                                    "/Transaction/ClaimManagement/Claimtablenottouchweb1",
                                }}
                                onClick={() => (
                                  <Claimtablenottouchweb1
                                    value={
                                      sessionStorage.setItem(
                                        "username",
                                        data.username
                                      ) ||
                                      sessionStorage.setItem(
                                        "filteredclaims",
                                        data.filteredClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "completedclaims",
                                        data.completedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "nottouch",
                                        data.nottouchedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "checkfiltertable",
                                        true
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimtype",
                                        "Web"
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimpageno",
                                        this.state.currentpage1
                                      )
                                    }
                                  />
                                )}
                              >
                                {data.filteredClaimscount}
                              </Link>
                            </td>

                            <td>
                              <Link
                                to={{
                                  pathname:
                                    "/Transaction/ClaimManagement/Claimtablenottouchweb2",
                                }}
                                onClick={() => (
                                  <Claimtablenottouchweb2
                                    value={
                                      sessionStorage.setItem(
                                        "username",
                                        data.username
                                      ) ||
                                      sessionStorage.setItem(
                                        "filteredclaims",
                                        data.filteredClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "completedclaims",
                                        data.completedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "nottouch",
                                        data.nottouchedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "Checkcompletetable",
                                        true
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimtype",
                                        "Web"
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimpageno",
                                        this.state.currentpage1
                                      )
                                    }
                                  />
                                )}
                              >
                                {data.completedClaimscount}
                              </Link>
                            </td>

                            <td>
                              <Link
                                to={{
                                  pathname:
                                    "/Transaction/ClaimManagement/Claimtablenottouchweb3",
                                }}
                                onClick={() => (
                                  <touchweb3
                                    value={
                                      sessionStorage.setItem(
                                        "username",
                                        data.username
                                      ) ||
                                      sessionStorage.setItem(
                                        "filteredclaims",
                                        data.filteredClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "completedclaims",
                                        data.completedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "nottouch",
                                        data.nottouchedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "nottouchtable",
                                        true
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimtype",
                                        "Web"
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimpageno",
                                        this.state.currentpage1
                                      )
                                    }
                                  />
                                )}
                              >
                                {data.nottouchedClaimscount}
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div style={{ marginRight: "368px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick1}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </ModalFooter>
        </Modal>
      )}
      {this.state.allocatemodal2 && (
        <Modal
          // // style={{ width: "30%" }}
          isOpen={this.state.allocatemodal2}
          onHide={this.toggle2}
          onClose={this.handleClose}
          size="lg"
          centered
        >
          <ModalHeader toggle={this.toggle2}>Allocated</ModalHeader>
          <ModalBody>
            <Row style={{ margin: "0px" }}>
              <div style={{ width: "100%" }}>
                <Table
                  className="StandardTable"
                  bordered
                  style={{
                    marginLeft: "3%",
                    width: "99%",
                    marginTop: "4%",
                  }}
                >
                  <thead>
                    <tr align="center">
                      <th>Username</th>
                      <th>No of Claims</th>
                      <th>Completed</th>
                      <th>Not Touched</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.ivrclaim &&
                      this.state.ivrclaim.map((data, index) => {
                        return (
                          <tr>
                            <td>{data.username}</td>
                            <td>
                              <Link
                                to={{
                                  pathname:
                                    "/Transaction/ClaimManagement/Claimtablenottouchweb1",
                                }}
                                onClick={() => (
                                  <Claimtablenottouchweb1
                                    value={
                                      sessionStorage.setItem(
                                        "username",
                                        data.username
                                      ) ||
                                      sessionStorage.setItem(
                                        "filteredclaims",
                                        data.filteredClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "completedclaims",
                                        data.completedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "nottouch",
                                        data.nottouchedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "checkfiltertable",
                                        true
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimtype",
                                        "ivr"
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimpageno",
                                        this.state.currentpage3
                                      )
                                    }
                                  />
                                )}
                              >
                                {data.filteredClaimscount}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={{
                                  pathname:
                                    "/Transaction/ClaimManagement/Claimtablenottouchweb2",
                                }}
                                onClick={() => (
                                  <Claimtablenottouchweb2
                                    value={
                                      sessionStorage.setItem(
                                        "username",
                                        data.username
                                      ) ||
                                      sessionStorage.setItem(
                                        "filteredclaims",
                                        data.filteredClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "completedclaims",
                                        data.completedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "nottouch",
                                        data.nottouchedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "Checkcompletetable",
                                        true
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimtype",
                                        "ivr"
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimpageno",
                                        this.state.currentpage3
                                      )
                                    }
                                  />
                                )}
                              >
                                {data.completedClaimscount}
                              </Link>
                            </td>

                            <td>
                              <Link
                                to={{
                                  pathname:
                                    "/Transaction/ClaimManagement/Claimtablenottouchweb3",
                                }}
                                onClick={() => (
                                  <Claimtablenottouchweb3
                                    value={
                                      sessionStorage.setItem(
                                        "username",
                                        data.username
                                      ) ||
                                      sessionStorage.setItem(
                                        "filteredclaims",
                                        data.filteredClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "completedclaims",
                                        data.completedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "nottouch",
                                        data.nottouchedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "nottouchtable",
                                        true
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimtype",
                                        "ivr"
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimpageno",
                                        this.state.currentpage3
                                      )
                                    }
                                  />
                                )}
                              >
                                {data.nottouchedClaimscount}
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div style={{ marginRight: "368px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount3}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick3}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </ModalFooter>
        </Modal>
      )}
      {this.state.allocatemodal3 && (
        <Modal
          // // style={{ width: "30%" }}
          isOpen={this.state.allocatemodal3}
          onHide={this.toggle3}
          onClose={this.handleClose}
          size="lg"
          centered
        >
          <ModalHeader toggle={this.toggle3}>Allocated</ModalHeader>
          <ModalBody>
            <Row style={{ margin: "0px" }}>
              <div style={{ width: "100%" }}>
                <Table
                  className="StandardTable"
                  bordered
                  style={{
                    marginLeft: "3%",
                    width: "99%",
                    marginTop: "4%",
                  }}
                >
                  <thead>
                    <tr align="center">
                      <th>Username</th>
                      <th>No of Claims</th>
                      <th>Completed</th>
                      <th>Not Touched</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.manualclaim &&
                      this.state.manualclaim.map((data, index) => {
                        return (
                          <tr>
                            <td>{data.username}</td>
                            <td>
                              <Link
                                to={{
                                  pathname:
                                    "/Transaction/ClaimManagement/Claimtablenottouchweb1",
                                }}
                                onClick={() => (
                                  <Claimtablenottouchweb1
                                    value={
                                      sessionStorage.setItem(
                                        "username",
                                        data.username
                                      ) ||
                                      sessionStorage.setItem(
                                        "filteredclaims",
                                        data.filteredClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "completedclaims",
                                        data.completedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "nottouch",
                                        data.nottouchedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "checkfiltertable",
                                        true
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimtype",
                                        "manual"
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimpageno",
                                        this.state.currentpage2
                                      )
                                    }
                                  />
                                )}
                              >
                                {data.filteredClaimscount}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={{
                                  pathname:
                                    "/Transaction/ClaimManagement/Claimtablenottouchweb2",
                                }}
                                onClick={() => (
                                  <Claimtablenottouchweb2
                                    value={
                                      sessionStorage.setItem(
                                        "username",
                                        data.username
                                      ) ||
                                      sessionStorage.setItem(
                                        "filteredclaims",
                                        data.filteredClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "completedclaims",
                                        data.completedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "nottouch",
                                        data.nottouchedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "Checkcompletetable",
                                        true
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimtype",
                                        "manual"
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimpageno",
                                        this.state.currentpage2
                                      )
                                    }
                                  />
                                )}
                              >
                                {data.completedClaimscount}
                              </Link>
                            </td>

                            <td>
                              <Link
                                to={{
                                  pathname:
                                    "/Transaction/ClaimManagement/Claimtablenottouchweb3",
                                }}
                                onClick={() => (
                                  <Claimtablenottouchweb3
                                    value={
                                      sessionStorage.setItem(
                                        "username",
                                        data.username
                                      ) ||
                                      sessionStorage.setItem(
                                        "filteredclaims",
                                        data.filteredClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "completedclaims",
                                        data.completedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "nottouch",
                                        data.nottouchedClaimscount
                                      ) ||
                                      sessionStorage.setItem(
                                        "nottouchtable",
                                        true
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimtype",
                                        "manual"
                                      ) ||
                                      sessionStorage.setItem(
                                        "claimpageno",
                                        this.state.currentpage2
                                      )
                                    }
                                  />
                                )}
                              >
                                {data.nottouchedClaimscount}
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div style={{ marginRight: "368px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount2}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick2}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </ModalFooter>
        </Modal>
      )}
      </div>
 
    );
  }
}
export default ClaimSubCategoryDetails;
