import "../../Styles/denialprediction.css";
import {  Row, Col } from "react-bootstrap";
import {
  RadialBarChart,
  RadialBar,
  ResponsiveContainer,
  Legend,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
} from "recharts";
import { FcComboChart } from "react-icons/fc";
import { FcPaid } from "react-icons/fc";
import { FcOk } from "react-icons/fc";
import { FcRedo } from "react-icons/fc";
import Newsidebar from "../Dashboard/Newsidebar";
 
//Area chart fill by value
const data__2 = [
  {
    name: "AR",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Charge",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Coding",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
];

//Line chart with multiple series values
const series = [
  {
    name: "Paid",
    data: [
      { category: "A", value: Math.random() },
      { category: "B", value: Math.random() },
      { category: "C", value: Math.random() },
    ],
  },
  {
    name: "Avoidable Denial",
    data: [
      { category: "B", value: Math.random() },
      { category: "C", value: Math.random() },
      { category: "D", value: Math.random() },
    ],
  },
  {
    name: "Unavoidable Denial",
    data: [
      { category: "C", value: Math.random() },
      { category: "D", value: Math.random() },
      { category: "E", value: Math.random() },
    ],
  },
];

//Line charts values
const data_1 = [
  { name: "Jan", uv: 4000 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr" },
  { name: "May", uv: 1890 },
  { name: "June", uv: 2390 },
  { name: "Jul", uv: 3490 },
];

//radialBar charts values
const style = {
  top: "50%",
  right: 0,
  marginLeft: "250px",
  transform: "translate(0, -50%)",
  lineHeight: "24px",
};
const data = [
  {
    // name: '18-24',
    uv: 31.47,
    pv: 2400,
    fill: "#8884d8",
  },
  {
    // name: '25-29',
    uv: 26.69,
    pv: 4567,
    fill: "#83a6ed",
  },
  {
    //name: '30-34',
    uv: 15.69,
    pv: 1398,
    fill: "#8dd1e1",
  },
  {
    //name: '50+',
    uv: 2.63,
    pv: 4800,
    fill: "#d0ed57",
  },
];

function DenialPrediction() {
  return (
    <div>
    <Newsidebar name="Over All Prediction" />
    <div className="align-row">
    <div className="align-column denial__head">
      <div className="wrapper">
        <div className="grid__one">
          <Row>
            <Col>
              <h3
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingTop: "1%",
                  fontSize: "14px",
                }}
              >
                Prediction Volume Trend{" "}
              </h3>
            </Col>
            <hr style={{ width: "100%", marginLeft: "-1px" }} />
          </Row>
          <ResponsiveContainer width="80%" height="80%">
            <RadialBarChart
              cx="50%"
              cy="50%"
              innerRadius="10%"
              outerRadius="80%"
              barSize={10}
              data={data}
            >
              <RadialBar
                minAngle={15}
                // label={{ position: 'insideStart', fill: '#fff' }}
                background
                clockWise
                dataKey="uv"
              />
              <Legend
                iconSize={10}
                layout="vertical"
                verticalAlign="middle"
                wrapperStyle={style}
              />
            </RadialBarChart>
          </ResponsiveContainer>
        </div>
        <div className="grid__two">
          <div className="card__1">
            <Row>
              <Col
                style={{ float: "left", fontSize: "13px", color: "white" }}
              >
                <Row style={{ paddingTop: "2%", marginLeft: "5px" }}>
                  Total LineItems
                </Row>
                <Row style={{ paddingTop: "4%", marginLeft: "5px" }}>
                  175930
                </Row>
              </Col>
              <Col style={{ float: "right", fontSize: "50px" }} md={3}>
                <FcComboChart className="icon__one" />
              </Col>
            </Row>
          </div>
          <div className="card__2">
            <Row>
              <Col
                style={{ float: "left", fontSize: "13px", color: "white" }}
              >
                <Row style={{ paddingTop: "2%", marginLeft: "5px" }}>
                  PAID
                </Row>
                <Row style={{ paddingTop: "4%", marginLeft: "5px" }}>
                  160004
                </Row>
              </Col>
              <Col style={{ float: "right", fontSize: "50px" }} md={3}>
                <FcPaid className="icon__one" />
              </Col>
            </Row>
          </div>
          <div className="card__3">
            <Row>
              <Col
                style={{ float: "left", fontSize: "13px", color: "white" }}
              >
                <Row style={{ paddingTop: "2%", marginLeft: "5px" }}>
                  Denial Predicted
                </Row>
                <Row style={{ paddingTop: "4%", marginLeft: "5px" }}>
                  160004
                </Row>
              </Col>
              <Col style={{ float: "right", fontSize: "50px" }} md={3}>
                <FcRedo className="icon__one" />
              </Col>
            </Row>
          </div>
          <div className="card__4">
            <Row>
              <Col
                style={{ float: "left", fontSize: "13px", color: "white" }}
              >
                <Row style={{ paddingTop: "2%", marginLeft: "5px" }}>
                  Denial Prevented
                </Row>
                <Row style={{ paddingTop: "4%", marginLeft: "5px" }}>
                  13690
                </Row>
              </Col>
              <Col style={{ float: "right", fontSize: "50px" }} md={3}>
                <FcOk className="icon__one" />
              </Col>
            </Row>
          </div>
        </div>
        <div className="grid__three scroll" style={{ fontSize: "20px" }}>
          <p>
            Healthcare organizations have tried to address the revenue leakage
            issues that denied claims cause with varying success, as
            technology and process-based solutions seem to solve only part of
            the problem. And as the rapidly changing healthcare industry gets
            even more complex, denied claims can have multiple causes — given
            the ever-increasing number of insurance plans and products
            available — making it more difficult than ever to remediate
            denials. Whats more, an increase in denied claims means higher
            costs for patients. The constant rework of denied claims not only
            distracts you from improving patient care but can also hamper your
            revenue stream. You need a trusted provider to help you resolve
            accounts and refile denied claims.Our Denial Management AI
            Platform transforms denial management end to end and helps speed
            up the process. It uses machine learning and intelligent
            automation to simplify the denial management process. This
            intelligent platform actively learns, constantly adapting and
            evolving — far beyond human thinking and capabilities. It can help
            you: • Discover insights using advanced machine learning
            algorithms, deep learning neural networks, and prescriptive and
            predictive modeling, and then apply those insights to your core
            business processes Build and optimize prediction models using an
            intuitive, userfriendly interface for reinforcement learning and
            self-guidancealue or age) — in real time. The suite uses a
            predefined configuration that automatically uploads claims and
            then routes them based on denial type and age, enabling faster
            turnaround of denial follow-up actions. As part of accounts
            receivable operations management,DBPMS has built-in dashboards to
            view denials by dollar value, aging days, payer and other
            variables.{" "}
          </p>
        </div>
        <div className="grid__four">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart width={10} height={300}>
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis
                dataKey="category"
                type="category"
                allowDuplicatedCategory={false}
              />
              <YAxis dataKey="value" />
              <Line
                type="monotone"
                dataKey="totalcount"
                stroke="#e6005c"
                activeDot={{ r: 8 }}
              />
              <Tooltip />
              <Legend />
              {series.map((s) => (
                <Line
                  dataKey="value"
                  data={s.data}
                  name={s.name}
                  key={s.name}
                  stroke="#e6005c"
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="grid__five">
          <Row>
            <Col>
              <h3
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingTop: "1%",
                  fontSize: "14px",
                }}
              >
                Prediction Volume Trend{" "}
              </h3>
            </Col>
            <hr style={{ width: "100%", marginLeft: "-1px" }} />
          </Row>
          <div className="cap_1">
            <div className="cap_2 circle">
              <h3>Jan</h3>
            </div>
            <div className="cap_2 circle">
              <h3>Feb</h3>
            </div>
            <div className="cap_2 circle">
              <h3>Mar</h3>
            </div>
          </div>
        </div>
        <div className="grid__six">
          <Row>
            <Col>
              <h3
                style={{
                  fontWeight: "bold",
                  float: "left",
                  paddingTop: "1%",
                  fontSize: "14px",
                }}
              >
                Revenue Cycle Duration{" "}
              </h3>
            </Col>
            <Col>
              <h3
                style={{
                  fontWeight: "bold",
                  float: "right",
                  paddingTop: "1%",
                  paddingRight: "1%",
                  fontSize: "14px",
                }}
              >
                breakup
              </h3>
            </Col>
          </Row>
          {/* <ResponsiveContainer width="100%" height="100%"> */}
          <AreaChart
            width={650}
            height={400}
            data={data__2}
            margin={{
              top: 60,
              right: 35,
              left: 40,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#fa3c68" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#fa3c68" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#11b8f5" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#11b8f5" stopOpacity={0} />
              </linearGradient>
            </defs>
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="name" height={90} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}}/>
            <YAxis />
            <Tooltip />
            {/* <CartesianGrid opacity={0.5} vertical={false} /> */}

            <Area
              type="monotone"
              dataKey="uv"
              stroke="#fa3c68"
              fillOpacity={1}
              fill="url(#colorUv)"
              name="Class"
            />
            <Area
              type="monotone"
              dataKey="pv"
              stroke="#11b8f5"
              fillOpacity={1}
              fill="url(#colorPv)"
              name="Amount"
            />
          </AreaChart>
          {/* </ResponsiveContainer> */}
        </div>

        <div className="grid__seven">
          <h3
            style={{
              fontWeight: "bold",
              textAlign: "left",
              paddingTop: "1%",
              fontSize: "14px",
            }}
          >
            Prediction Forcasting-Avoidable denial{" "}
          </h3>
          {/* <ResponsiveContainer width="100%" height={200}> */}
          <LineChart
            width={675}
            height={400}
            data={data_1}
            // backgroundColor={{'color':rgba(255, 206, 86, 0.2)}}
            // borderColor={{'color':rgba(255, 206, 86, 1)}}
            margin={{
              top: 65,
              right: 35,
              left: 40,
              bottom: 0,
            }}
          >
            <Line
              type="monotone"
              dataKey="totalcount"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="name" height={90} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}}/>
            <YAxis domain={["auto", "auto"]} />
            <Tooltip />
            <Line
              connectNulls
              type="monotone"
              dataKey="uv"
              stroke="#82ca9d"
              name="Amount"
            />
          </LineChart>
          {/* </ResponsiveContainer> */}
        </div>
      </div>
    </div>
  </div>
    </div>

  );
}

export default DenialPrediction;
