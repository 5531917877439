import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import "../AI/denialDashboard.css";
import { Row, Col } from "reactstrap"; 
import DenialCategoryChart from "./DenialDashboardCharts/denialCategoryChart";
import DenialPayerChart from "./DenialDashboardCharts/denialPayerChart";
import DenialPredictionChart from "./DenialDashboardCharts/denialPredictionTrendChart";
import TopImpactorChart from "./DenialDashboardCharts/topImpactorChart";
import TableData from "./DenialDashboardCharts/tabledata";
import "./DenialDashboardCharts/tabledata.css";
import TopImpactorTable from "./DenialDashboardCharts/TopImpactor";
import "../../Styles/CollectionPredictionchanged.css";
export class DenialPredictionDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cptcode: "$",
      age: "$",
    };
  }
  render() {
    return (
      <div className="align-row" style={{ overflow: "hidden" }}>
        <Newsidebar name="Denial Dashboard" />
        <div className="align-column">

          <div className="denial-card">
            <div>Denial Prediction Dashboard</div>
          </div>
          <div className="denial-db-grid1">
            <div className="denialcell1" style={{ backgroundColor: "#ff0000" }}>
              <div className="content__db__pre">
                <p>$111,422</p>
                <p>Denial Charges $</p>
              </div>
            </div>
            <div className="denialcell2" style={{ backgroundColor: "#ff1a1a" }}>
              <div className="content__db__pre">
                <p>#1165</p>
                <p>Denial CPT #</p>
              </div>
            </div>
            <div className="denialcell3" style={{ backgroundColor: "#ff3333" }}>
              <div className="content__db__pre">
                <p>2.17%</p>
                <p>Denial Rate %</p>
              </div>
            </div>
            <div className="denialcell5" style={{ backgroundColor: "#ff4d4d" }}>
              <div className="content__db__pre">
                <p>90%</p>
                <p>ML Precisiion</p>
              </div>
            </div>
            <div className="denialcell6" style={{ backgroundColor: "#ff6666" }}>
              <div className="content__db__pre">
                <p>89%</p>
                <p>ML Recall</p>
              </div>
            </div>
            <div className="denialcell7" style={{ backgroundColor: "#ff8080" }}>
              <div className="content__db__pre">
                <p>80%</p>
                <p>ML Accuracy</p>
              </div>
            </div>
          </div>
          <div className="denial-second-grid">
            <div className="denial-second1">
              <Row>
                <Row>
                  <h6
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",

                      fontFamily: "Poppins",
                    }}
                  >
                    Denial By Payer
                  </h6>
                </Row>
                <hr style={{ width: "95%", marginLeft: "3%" }} />
              </Row>

              <DenialPayerChart />
            </div>
            <div className="denial-second2">
              <Row>
                <Row>
                  <h6
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontFamily: "Poppins",
                    }}
                  >
                    Denial By Category
                  </h6>
                </Row>
                <hr style={{ width: "95%", marginLeft: "3%" }} />
                <DenialCategoryChart></DenialCategoryChart>
              </Row>
            </div>
            <div className="denial-second3">
              <Row>
                <Col md="6">
                  <h6
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      marginLeft: "15px",

                      fontFamily: "Poppins",
                    }}
                  >
                    Historical Denial Prediction Trend
                  </h6>
                </Col>
                <Col>
                  <div style={{ paddingTop: "1%", marginLeft: "25px" }}>
                    <select
                      style={{
                        width: "50%",
                        height: "85%",
                        borderBlockColor: "gray",
                        borderRadius: "4px",
                      }}
                      onChange={(e) => this.setState({ age: e.target.value })}
                      value={this.state.age}
                    >
                      <option value="$">Charge</option>
                      <option value="#">Rate</option>
                    </select>
                  </div>
                </Col>
                <hr style={{ width: "95%", marginLeft: "8%" }} />
              </Row>

              <DenialPredictionChart
                data={this.state.age}
              ></DenialPredictionChart>
            </div>
          </div>

          <div className="denial-topimpactor">
            <div>
              <TopImpactorTable></TopImpactorTable>
            </div>
            <div className="denialheading-last">
              <div className="denialgrid-last1">
                <Row>
                  <h6
                    style={{
                      fontWeight: "bold",
                      marginLeft: "-3%",
                    }}
                  >
                    Top Impactor
                  </h6>
                  <hr style={{ width: "95%", marginLeft: "8%" }} />
                </Row>
                <TableData></TableData>
              </div>
              <div className="denialgrid-last2">
                <Row>
                  <Col md="6">
                    <h6
                      style={{
                        fontWeight: "bold",
                        marginLeft: "-3%",
                      }}
                    >
                      Top Impactor
                    </h6>
                  </Col>
                  <Col>
                    <select
                      style={{
                        width: "50%",
                        height: "78%",
                        borderBlockColor: "gray",
                        borderRadius: "4px",
                      }}
                      onChange={(e) =>
                        this.setState({ cptcode: e.target.value })
                      }
                      value={this.state.cptcode}
                    >
                      <option value="$">Amount</option>
                      <option value="#">Count</option>
                    </select>
                  </Col>
                  <hr style={{ width: "95%", marginLeft: "3%" }} />
                </Row>

                <TopImpactorChart data={this.state.cptcode}></TopImpactorChart>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DenialPredictionDashboard;
