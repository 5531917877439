import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import actioncode from "../../Files/actioncode.xlsx";
import "../../Styles/configure.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col, Form, Label, Input, Button, FormGroup } from "reactstrap";
import { Table } from "react-bootstrap";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import { ip, secretkey } from "../../Api";
import aes256 from "aes256";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import upload from "../../Images/upload.png";
import excel from "../../Images/excel.png";
import { IoMdClose } from "react-icons/io";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import ReactPaginate from "react-paginate";
import { notification, Space } from "antd";
import loading from "../../Images/loader.gif";
export const FollowupRun = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
export class ActionCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createmodal: false,
      searchtext1: "",
      searchdataresponse1: [],
      searchclaimdata1: [],
      statuslist: [],
      query1: [],
      addnewmodal: false,
      opendropdown: false,
      editmodal: false,
      deletemodal: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role_type: sessionStorage.getItem("role_type"),
      client: sessionStorage.getItem("clientid"),
      clientname: sessionStorage.getItem("clientname"),
      clientslist: [],
      status: "",
      followuprunupdate: "",
      followuprun: "",
      sub: [],
      bulkmodal: false,
      status1: "",
      selectedfile: "",
      uploading: false,
      offset: 0,
      currentpage: 0,
      perPage: 10,
      Desc: "",
      DescErr: "",
      updateDesc: "",
      updateDescErr: "",
      updatestatus: "",
      _id: null,
      pageCountweb: "",
      perPageweb: 10,
      offsetweb: 0,
      syncvalue:"",
      updatesyncvalue:"",
    };
    this.fileInput = React.createRef();
    this.onFileChange = this.onFileChange.bind(this);
    this.clearFile = this.clearFile.bind(this);
  }
  bulkmodal = () => {
    this.setState({
      bulkmodal: !this.state.bulkmodal,
    });
  };
  openmodal = () => {
    this.setState({ addnewmodal: !this.state.addnewmodal });
  };

  openeditmodal = () => {
    this.setState({ editmodal: !this.state.editmodal });
  };

  opendeletemodal = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };

  imageHandler = (e) => {
    this.setState({ selectedfile: e.target.files[0] });
  };

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  addClaimSubCategory = () => {
    const isValid = this.addValidate();
    if (isValid) {
      this.setState({ createmodal: !this.state.createmodal });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };

      const value = {
        Description: this.state.Desc,
        Status: this.state.status,
        Followup_Run: this.state.followuprun,
        clientId: parseInt(sessionStorage.getItem("clientid")),
        apiSync: this.state.syncvalue,
      };

       axios
        .post(`${ip}/actioncode/create`, value, { headers: headers })
        .then((res) => {
          if (res) {
            this.setState({ addnewmodal: !this.state.addnewmodal });
            this.setState({ createmodal: !this.state.createmodal });
            this.getClaimSubcategory();
            toast.success("Action Code created", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            this.getClaimSubcategory();
            this.setState({ addnewmodal: !this.state.addnewmodal });
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getClaimSubcategory();
      }
    );
  };
  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        if (res.data.clientonboard.length !== 0) {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        } else {
          this.setState({
            clientslist: [],
          });
        }
      })
      .catch((err) => {});
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.filtersearch1();
      }
    );
  };
  editAction = () => {
    const valid = this.editValidate();
    if (valid) {
      this.setState({ createmodal: !this.state.createmodal });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };

      const value1 = {
        Description: this.state.updateDesc,
        Status: this.state.updatestatus,
        Followup_Run: this.state.followuprunupdate,
        clientId: parseInt(sessionStorage.getItem("clientid")),
        apiSync: this.state.updatesyncvalue,
      };
       axios
        .put(`${ip}/actioncode/${this.state._id}`, value1, { headers: headers })
        .then((res) => {
          if (res) {
            this.setState({
              editmodal: !this.state.editmodal,
              createmodal: !this.state.createmodal,
            });
            this.getClaimSubcategory();
            toast.info("Action Code updated", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            this.getClaimSubcategory();
            this.setState({ editmodal: !this.state.editmodal });
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };
  getClaimSubcategory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      url = `${ip}/actioncode/all?clientId=${this.state.client}&pageno=${this.state.currentpage}`;
    } else {
      url = `${ip}/actioncode/all?pageno=${this.state.currentpage}`;
    }
     axios
      .get(url, { headers: headers })
      .then((res) => {
        let actioncodeData;
        actioncodeData = aes256.decrypt(`${secretkey}`, res.data.actioncode);
        const decryptedactioncodeData = JSON.parse(actioncodeData);
        this.setState({
          pageCount: Math.ceil(res.data.count / this.state.perPage),
          sub: decryptedactioncodeData,
        });
      })
      .catch((err) => {});
  };
  deleteActionCode = () => {
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .delete(`${ip}/actioncode/${this.state._id}`, { headers: headers })
      .then((res) => {
        if (res) {
          this.setState({ deletemodal: !this.state.deletemodal });
          this.setState({ createmodal: !this.state.createmodal });
          this.getClaimSubcategory();
          toast.error("Action Code deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.getClaimSubcategory();
          this.setState({ deletemodal: !this.state.deletemodal });
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  onFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };
  clearFile() {
    this.setState({ file: null });
  }
  onFileUpload = async (e) => {
    if (!this.state.file) {
      // Display an error message or handle the validation as needed
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    this.setState({ fileUploaded: !this.state.fileUploaded });
    e.preventDefault();
    const value = new FormData();
    value.append("file", this.fileInput.current.files[0]);
    value.append("clientids", JSON.stringify(this.state.client));
    const headers = {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    await axios({
      method: "post",
      url: `${ip}/actioncode/bulk/upload`,
      data: value,
      headers: headers,
    })
      .then((response) => {
        //handle success
        if (response.status === 200) {
          this.setState({ fileUploaded: !this.state.fileUploaded });
          toast.success("File uploaded successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
          this.getClaimSubcategory();
        }
      })
      .catch((err) => {
        //handle error
        this.setState({ fileUploaded: !this.state.fileUploaded });
        if (err.response) {
          if (err.response.status === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 400) {
            toast.error("Please include all the fields in excel data", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  getquery = () => {
    var query_o;
    let query = [];
    if (this.state.status1.length !== 0) {
      query_o = `Description=${this.state.status1}`;
      query.push(query_o);
    }
    if (query.length === 1) {
      this.state.query1.push(`${query[0]}`);
    }
  };
  filtersearch1 = (e) => {
    // e.preventDefault();
    this.setState({ query1: [] });
    this.getquery();
    let filtereddata = [];
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.state.query1.length !== 0) {
      let url;
      if (this.state.client) {
        url = `${ip}/actioncode/advancedmultiplefilter?${this.state.query1}&clientId=${this.state.client}`;
      } else {
        url = `${ip}/actioncode/advancedmultiplefilter?${this.state.query1}`;
      }
       axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          let filteractionData;
          filteractionData = aes256.decrypt(`${secretkey}`, res.data.claims);
          const decryptedfilteractionData = JSON.parse(filteractionData); 
          filtereddata = decryptedfilteractionData;
          if (filtereddata.length !== 0) {
            this.setState({ searchtext1: 1 });
            const slice = filtereddata.slice(
              this.state.offsetweb,
              this.state.offsetweb + this.state.perPageweb
            );
            this.setState({
              searchdataresponse1: filtereddata,
              pageCountweb: Math.ceil(
                filtereddata.length / this.state.perPageweb
              ),
              searchclaimdata1: slice,
            });
          } else if (filtereddata.length === 0) {
            this.setState({
              searchtext1: 1,
              searchdataresponse1: [],
              searchclaimdata1: [],
            });
          }
        })
        .catch((err) => {});
    } else {
      alert("Select data to search");
    }
  };
  addValidate = () => {
    let hasErr = "true";
    let DescErr = "";

    if (this.state.Desc === "") {
      DescErr = "This field is required";
      this.setState({ DescErr });
      hasErr = false;
    }
    return hasErr;
  };
  editValidate = () => {
    let updatehasErr = "true";
    let updateDescErr = "";

    if (this.state.updateDesc === "") {
      updateDescErr = "This field is required";
      this.setState({ updateDescErr });
      updatehasErr = false;
    }
    return updatehasErr;
  };
  componentDidMount() {
    
    this.getClaimSubcategory();
    this.getclients();
  }
  toggle = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };
  reset = (e) => {
    // e.preventDefault();
    this.setState({
      updateDescErr: "",
      DescErr: "",
      claimfield1: "",
      claimvalue1: "",
      status1: "",
      payervalue1: [],
      Desc: "",
      status: "",
      followuprun: "",
      cptvalue1: [],
      searchdataresponse1: [],
      searchclaimdata1: [],
      searchtext1: "",
    });
  };
  render() {
    return (
      <div>
      <Newsidebar name="Action Codes" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <div className="align-column">
        <br/>
          {this.state.role_type === "Admin" && (
            <div
              className="btn-group"
              role="group"
              style={{ marginRight: "5%", marginLeft: "65%" }}
            >
              <button
                type="button"
                className="btn"
                style={{ backgroundColor: "#041c3c", color: "#fff" , fontSize:"13px"}}
                onClick={this.openmodal}
              >
                Add Action code
              </button>
              <button
                type="button"
                className="btn"
                onClick={this.bulkmodal}
                style={{ backgroundColor: "#7DD657", color: "#fff", fontSize:"13px" }}
              >
                Bulk Upload
              </button>
              <button
                type="button"
                className="btn"
                style={{ backgroundColor: "#041c3c", color: "#fff" }}
              >
                <Link
                  style={{ textDecoration: "none", color: "#fff" , fontSize:"13px"}}
                  to={actioncode}
                  download="actioncode.csv"
                  target="_blank"
                >
                  {" "}
                  Sample File
                </Link>
              </button>
            </div>
          )}
          <br />
          {this.state.role_type === "SuperAdmin" ? (
            <ButtonDropdown
              isOpen={this.state.opendropdown}
              toggle={this.toggle}
              style={{ width: "12%", marginLeft: "56px", position: "inherit" }}
            >
              <DropdownToggle caret>
                {this.state.clientname ? this.state.clientname : "All"}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() =>
                    this.setState({ clientname: "", client: "" }, () =>
                      this.getClaimSubcategory()
                    )
                  }
                >
                  All
                </DropdownItem>
                {this.state.clientslist.map((e) => {
                  return (
                    <DropdownItem
                      id={e.clientId}
                      key={e.clientId}
                      onClick={(e) => {
                        this.setState(
                          {
                            client: e.target.id,
                            clientname: e.target.innerText,
                          },
                          () => this.getClaimSubcategory()
                        );
                      }}
                    >
                      {e.client}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </ButtonDropdown>
          ) : null}
          <br />
          <Form
            style={{
              border: "3px solid #FBFBFB",
              borderRadius: "16px",
              padding: "4px",
              background: "#FBFBFB",
            }}
          >
            <Row>
              <Col md="2"></Col>
              <Col md="6">
                <FormGroup>
                  <Label>Description</Label>
                  <Input
                    value={this.state.status1}
                    onChange={(e) => {
                      this.setState({ status1: e.target.value });
                    }}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="2">
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "#89CFF0", color: "#fff" }}
                    onClick={(e) => {
                      this.setState({ tabname: "web" }, () => {
                        this.filtersearch1(e);
                      });
                    }}
                  >
                    Search
                  </button>
                  <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "#7DD657", color: "#fff" }}
                    onClick={(e) => {
                      this.reset(e);
                    }}
                  >
                    <IoIosRefresh
                      style={{
                        color: "blue",
                        fontSize: "25px",
                      }}
                    ></IoIosRefresh>
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
          <br />
          <ToastContainer></ToastContainer>
          {this.state.sub.length === 0 ? (
            <div className="bot-ui">
              <div className="bot-head">
                <div className="eyes-container">
                  <div className="to-left eye"></div>
                  <div className="to-right eye"></div>
                </div>
              </div>
              <div className="bot-body">
                <div className="analyzer"></div>
              </div>
              <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                No Data Found
              </p>
            </div>
          ) : (
            <div>
              {this.state.searchtext1 === "" ? (
                <div style={{ marginLeft: "3%", marginRight: "3%" }}>
                  <Table bordered
                  className="StandardTable"
                  >
                    <thead>
                      <tr>
                        <th>Description</th>
                        {this.state.role_type === "SuperAdmin" && (
                          <th>Client Id</th>
                        )}
                        <th>Status</th>
                        <th>FollowupRun</th>
                        <th>ApiSync</th>

                        {this.state.role_type === "Admin" && <th>Action</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.sub.map((data, index) => (
                        <tr>
                          <td>{data.Description}</td>
                          {this.state.role_type === "SuperAdmin" && (
                            <td>{data.clientId}</td>
                          )}
                          <td>{data.Status}</td>
                          <td>{data.Followup_Run}</td>
                          <td>{data.apiSync}</td>

                          {this.state.role_type === "Admin" && (
                            <td>
                              <button
                                className="Edit"
                                onClick={() => {
                                  this.openeditmodal();
                                  this.setState({
                                    _id: data._id,
                                    updateDesc: data.Description,
                                    updatestatus: data.Status,
                                    followuprunupdate: data.Followup_Run,
                                    updatesyncvalue: data.apiSync
                                  });
                                }}
                              >
                                Edit
                              </button>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <button
                                className="Delete"
                                onClick={() => {
                                  this.opendeletemodal();
                                  this.setState({ _id: data._id });
                                }}
                              >
                                Delete
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <div>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  {this.state.searchdataresponse1.length === 0 ? (
                    <div className="bot-ui">
                      <div className="bot-head">
                        <div className="eyes-container">
                          <div className="to-left eye"></div>
                          <div className="to-right eye"></div>
                        </div>
                      </div>
                      <div className="bot-body">
                        <div className="analyzer"></div>
                      </div>
                      <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                        No Data Found
                      </p>
                    </div>
                  ) : (
                    <div style={{ marginLeft: "3%", marginRight: "3%" }}>
                      <Table bordered
                      className="StandardTable"
                      >
                        <thead>
                          <tr>
                            <th>Description</th>
                            {this.state.role_type === "SuperAdmin" && (
                              <th>Client Id</th>
                            )}
                            <th>Status</th>
                            <th>FollowupRun</th>
                            <th>ApiSync</th>
    
                            {this.state.role_type === "Admin" && <th>Action</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.searchdataresponse1.map((data, index) => (
                            <tr>
                              <td>{data.Description}</td>
                              {this.state.role_type === "SuperAdmin" && (
                                <td>{data.clientId}</td>
                              )}
                              <td>{data.Status}</td>
                              <td>{data.Followup_Run}</td>
                              <td>{data.apiSync}</td>
                              {this.state.role_type === "Admin" && (
                                <td>
                                  <button
                                    className="Edit"
                                    onClick={() => {
                                      this.openeditmodal();
                                      this.setState({
                                        _id: data._id,
                                        updateDesc: data.Description,
                                        updatestatus: data.Status,
                                        followuprunupdate: data.Followup_Run,
                                        updatesyncvalue: data.apiSync
                                      });
                                    }}
                                  >
                                    Edit
                                  </button>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <button
                                    className="Delete"
                                    onClick={() => {
                                      this.opendeletemodal();
                                      this.setState({ _id: data._id });
                                    }}
                                  >
                                    Delete
                                  </button>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCountweb}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick1}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <Modal
          show={this.state.editmodal}
          onHide={this.openeditmodal}
          onExit={this.reset}
          centered
        >
          <Modal.Header closeButton closeLabel="">
            <Modal.Title>Edit Action Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Label>Description</Label>
                  <Input
                    value={this.state.updateDesc}
                    onChange={(e) =>
                      this.setState({
                        updateDesc: e.target.value,
                        updateDescErr: "",
                      })
                    }
                  ></Input>
                  <div style={{ fontSize: 16, color: "red" }}>
                    {this.state.updateDescErr}
                  </div>
                </Col>
                <Col>
                  <Label for="select">Status</Label>
                  <Input
                    type="select"
                    name="select"
                    className="form-control form-select"
                    value={this.state.updatestatus}
                    onChange={(e) =>
                      this.setState({ updatestatus: e.target.value })
                    }
                  >
                    <option
                      value="Active"
                      selected={this.state.updatestatus === "Active"}
                    >
                      Active
                    </option>
                    <option
                      value="Inactive"
                      selected={this.state.updatestatus === "Inactive"}
                    >
                      Inactive
                    </option>
                  </Input>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="select" className="required">
                      FollowupRun
                    </Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control form-select"
                      value={this.state.followuprunupdate}
                      onChange={(e) =>
                        this.setState({ followuprunupdate: e.target.value })
                      }
                    >
                    <option value="" hidden selected="">--select--</option>
                      <option
                        value="Yes"
                        selected={this.state.followuprunupdate === "Yes"}
                      >
                        Yes
                      </option>
                      <option
                        value="No"
                        selected={this.state.followuprunupdate === "No"}
                      >
                        No
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                <Label>Sync this action field</Label>
                <Input
                 type="select"
                  value={this.state.updatesyncvalue}
                  onChange={(e) =>
                    this.setState({ updatesyncvalue : e.target.value })
                  }
                >
                <option value="Yes">Yes</option>
                <option  value="No" >No</option>
                </Input>
              </Col>
              </Row>
              <br />
              
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.openeditmodal();
                this.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={() => {
                this.editAction();
              }}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.deletemodal}
          onHide={this.opendeletemodal}
          centered
        >
          <Modal.Header closeButton closeLabel="">
            <Modal.Title>Delete Claim SubCategory</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to delete this claim subcategory?</Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.opendeletemodal();
                //   this.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={() => {
                this.deleteActionCode();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.createmodal} centered>
          <Modal.Body>
            <img
              src={loading}
              style={{ width: "200px", height: "200px", marginLeft: "30%" }}
            />
          </Modal.Body>
        </Modal>
        {this.state.bulkmodal && (
          <Modal
            show={this.state.bulkmodal}
            onHide={this.bulkmodal}
            // onExit={this.reloadpage}
            centered
            size="xl"
          >
            <Modal.Header closeLabel="" closeButton onClick={this.bulkmodal}>
              Bulk Upload
            </Modal.Header>
            <Modal.Body
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
            <input
              type="file"
              id="file"
              ref={this.fileInput}
              onChange={this.onFileChange}
              style={{ display: "none" }}
              accept=".xlsx"
            />
            {this.state.file ? (
              <div className="file-card-upload">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "5px" }}
                  >
                    <div className="file-card" style={{ position: "relative" }}>
                      <span>
                        <img
                          src={excel}
                          alt="excel"
                          style={{ width: "30px", height: "30px" }}
                        />
                      </span>
                      <div className="file-info">
                        <div style={{ flex: 1 }}>
                          <h6>{this.state.file.name}</h6>
                        </div>
                      </div>
                      <button
                        className="close-icon"
                        onClick={this.clearFile} // Clear file function
                      >
                        <IoMdClose />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="file-btn"
                onClick={() => this.fileInput.current.click()}
              >
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <img
                    src={upload}
                    alt="upload"
                    style={{ width: "90px", height: "90px" }}
                  />
                  <div>
                    <p style={{ margin: 0 }}>Click file to this area to upload</p>
                    <span style={{ color: "GrayText", fontSize: "11px" }}>
                      The given input should be in .xlsx format
                    </span>
                  </div>
                </div>
              </div>
            )}
              <br />
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{
                  border: "1px solid gray",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={this.bulkmodal}
              >
                Cancel
              </Button>
              <Button
                style={{
                  border: "1px solid gray",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={this.onFileUpload}
                className="uploadcss"
              >
                Upload
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <Modal
          show={this.state.addnewmodal}
          onHide={this.openmodal}
          onExit={this.reset}
          centered
        >
          <Modal.Header closeButton closeLabel="">
            <Modal.Title>Add Action Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Label className="required"> Description</Label>
                  <Input
                    value={this.state.Desc}
                    onChange={(e) =>
                      this.setState({ Desc: e.target.value, DescErr: "" })
                    }
                  />
                  <div style={{ fontSize: 16, color: "red" }}>
                    {this.state.DescErr}
                  </div>
                </Col>
                <Col>
                  <Label for="select" className="required">
                    Status
                  </Label>
                  <Input
                    type="select"
                    name="select"
                    className="form-control form-select"
                    value={this.state.status}
                    onChange={(e) => this.setState({ status: e.target.value })}
                  >
                  <option value="" hidden>--select--</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </Input>
                </Col>
              </Row>
              <br />

              <Row>
                <Col>
                  <FormGroup>
                    <Label for="select" className="required">
                      FollowupRun
                    </Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control form-select"
                      value={this.state.followuprun}
                      onChange={(e) =>
                        this.setState({ followuprun: e.target.value })
                      }
                    >
                      <option value="">--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                <Col>
                  <Label>Sync this action field</Label>
                  <Input
                   type="select"
                    value={this.state.syncvalue}
                    onChange={(e) =>
                      this.setState({ syncvalue : e.target.value })
                    }
                  >
                  <option hidden value="">--Select--</option>
                  <option value="Yes">Yes</option>
                  <option  value="No" >No</option>
                  </Input>
                </Col>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.openmodal();
                this.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={() => {
                this.addClaimSubCategory();
              }}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      </div>
    );
  }
}

export default ActionCode;
