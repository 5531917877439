import  { Component } from "react";
import { Table, Row, Col } from "reactstrap";
import { Spinner } from "react-bootstrap";
import { ip } from "../../../Api";
import axios from "axios";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import {
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";

class DailyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingdailyreport: true,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      currentpage4: 0,
      perPage4: 10,
      dailyreport: [],
      reports: [],
      loading: true,
      download: false,
      buttonsize: 0,
      countofarlog: "",
    };
  }

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getdownloadbutton = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    let filename;
    let date1 = moment().subtract(7, "days").format().substring(0, 16);
    let date2 = moment().format().substring(0, 16);
    if (this.props.startdate && this.props.enddate) {
      filename = `Daily_Report_${this.props.startdate}_${this.props.enddate}`;
      url = `${ip}/report/dailyreport?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}&button=${this.state.buttonsize}`;
    } else {
      filename = `Daily_Report_${moment(date1).format("MM/DD/YYYY")}_${moment(date2).format("MM/DD/YYYY")}`;
      url = `${ip}/report/dailyreport?clientId=${this.state.client}&start_date=${date1}&end_date=${date2}&button=${this.state.buttonsize}`;
    }
    fetch(url, { headers: headers }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", filename);
        a.click();
        URL.revokeObjectURL(a);
        this.setState({
          loading: false,
        });
      });
    });
  };
  increment = () => {
    if (this.state.buttonsize < this.state.countofarlog - 1) {
      this.setState({ buttonsize: this.state.buttonsize + 1 });

      if (this.state.buttonsize - 1 < 0) {
        alert("No data here so please click next arrow");
        this.state.buttonsize = 0;
      }
    }

  };

  decrement = () => {
    this.setState({ buttonsize: this.state.buttonsize - 1 });

    if (this.state.buttonsize - 1 < 0) {
      alert("No data here so please click next arrow");
      this.state.buttonsize = 0;
    }

  };
  getcount = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/report/getcounts?clientId=${this.state.client}&feature=dailyproductionreport&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
    } else {
      url = `${ip}/report/getcounts?clientId=${this.state.client}&feature=dailyproductionreport`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          countofarlog: Math.ceil(res.data.count / 500),
        });
      });
  };
  downloaddata = () => {
    const { download } = this.state;
    this.setState({ download: !download });
  };
  // getReports = () => {
  //   this.setState({ loading: true });
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${this.state.accesstoken}`,
  //   };
  //   let url;
  //   if (this.props.startdate && this.props.enddate) {
  //     url = `${ip}/report/dailyreport?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}`;
  //   } else {
  //     url = `${ip}/report/dailyreport?clientId=${this.state.client}`;
  //   }
  //   const res = axios
  //     .get(url, {
  //       headers: headers,
  //     })
  //     .then((res) => {
  //       this.setState({
  //         reports: res.data.data,
  //         loading: false,
  //       });
  //     });
  // };

  getDailyreports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    let date1 = moment().subtract(7, "days").format().substring(0, 16);
    let date2 = moment().format().substring(0, 16);
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/report/dailyreport?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}&pageno=${this.state.currentpage4}`;
    } else {
      url = `${ip}/report/dailyreport?clientId=${this.state.client}&pageno=${this.state.currentpage4}&start_date=${date1}&end_date=${date2}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pageCount4: Math.ceil(res.data.count / this.state.perPage4),
          dailyreport: res.data.data,
          fetchingdailyreport: false,
        });
      });
  };
  handlePageClick4 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage4;

    this.setState(
      {
        currentpage4: selectedPage,
        offset4: offset,
      },
      () => {
        this.getDailyreports();
      }
    );
  };
  componentDidMount() {
    
    this.getDailyreports();
    this.getcount();
  }
  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.startdate === prevProps.startdate &&
        this.props.enddate === prevProps.enddate
      )
    ) {
      this.getDailyreports();
    }
  }
  render() {
    return this.state.fetchingdailyreport ? (
      <div>
        <p
          style={{
            fontSize: "23px",
            marginTop: "10px",
            color: "#000",
          }}
        >
          <span>Loading...</span>
          <span>Please Wait...</span>
        </p>
        <div className="boxes body__loader dribbble">
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    ) : this.state.dailyreport.length === 0 || this.state.dailyreport === null ? (
        <p style={{ fontSize: "20px", fontStyle: "italic", marginTop:"25%" }}>
          No Data Found
        </p>
    ) : (
      <div>
        <div>
          <button
            style={{
              boxShadow: "inset 0px 1px 0px 0px #E184F3",
              background: "black",
              backgroundColor: "black",
              borderRadius: "6px",
              border: "1px solid black",
              display: "inline-block",
              cursor: "pointer",
              color: "#FFFFFF",
              fontSize: "15px",
              fontWeight: "bold",
              padding: "6px 24px",
              textDecoration: "none",
              textShadow: "0px 1px 0px #9B14B3",
              float: "right",
            }}
            onClick={(e) => {
              this.downloaddata();
              this.getdownloadbutton();
            }}
          >
            Generate Report
          </button>
        </div><br/>
        {this.state.download && (
          <Modal
            style={{ width: "30%" }}
            isOpen={this.state.download}
            onHide={this.downloaddata}
            onExit={this.reset}
            size="md"
            centered
          >
            <ModalHeader toggle={this.downloaddata}>Download</ModalHeader>
            <ModalBody
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
              <Row style={{ margin: "0px" }}>
                <Col>
                  <div id="container">
                    {this.state.loading ? (
                      <BsFillArrowLeftCircleFill
                        style={{ fontSize: "183%" }}
                        disabled
                        onClick={(e) => {
                          this.decrement();
                        }}
                      >
                        left
                      </BsFillArrowLeftCircleFill>
                    ) : (
                      <BsFillArrowLeftCircleFill
                        style={{ fontSize: "183%" }}
                        onClick={(e) => {
                          this.decrement();
                        }}
                      >
                        left
                      </BsFillArrowLeftCircleFill>
                    )}
                    {this.state.loading ? (
                      <button
                        style={{
                          // boxShadow: "inset 0px 1px 0px 0px #e184f3",
                          background: "green",
                          backgroundColor: "green",
                          borderRadius: "6px",
                          border: "3px solid green",
                          display: "inline-block",
                          cursor: "pointer",
                          color: "#ffffff",
                          fontSize: "15px",
                          fontWeight: "bold",
                          padding: "-4px 24px",
                          textDecoration: "none",
                          width: "45%",
                          whiteSpace: "nowrap",
                          marginLeft: "20%",
                          textShadow: "0px 1px 0px #9b14b3",
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp;&nbsp;Loading data...{this.state.buttonsize + 1}
                      </button>
                    ) : (
                      <button
                        style={{
                          // boxShadow: "inset 0px 1px 0px 0px #e184f3",
                          background: "Black",
                          backgroundColor: "Black",
                          borderRadius: "6px",
                          border: "3px solid Black",
                          display: "inline-block",
                          cursor: "pointer",
                          color: "#ffffff",
                          fontSize: "15px",
                          fontWeight: "bold",
                          padding: "-4px 24px",
                          textDecoration: "none",
                          width: "42%",
                          marginLeft: "20%",
                          textShadow: "0px 1px 0px #9b14b3",
                        }}
                        onClick={(e) => {
                          this.getdownloadbutton();
                        }}
                      >
                        Report {this.state.buttonsize + 1}
                      </button>
                    )}

                    {this.state.loading ? (
                      <BsFillArrowRightCircleFill
                        disabled
                        style={{ marginLeft: "49px", fontSize: "183%" }}
                        onClick={(e) => {
                          this.increment();
                        }}
                      >
                        right
                      </BsFillArrowRightCircleFill>
                    ) : (
                      <BsFillArrowRightCircleFill
                        style={{ marginLeft: "49px", fontSize: "183%" }}
                        onClick={(e) => {
                          this.increment();
                        }}
                      >
                        right
                      </BsFillArrowRightCircleFill>
                    )}
                  </div>
                </Col>
                <br />
                <br />
              </Row>

              <br />
            </ModalBody>
          </Modal>
        )}
        <br />
        <div style={{ width: "100%" }}>
          <Table
            className="StandardTable"
            bordered
            responsive
            style={{
              width: "99%",
            }}
          >
            <thead>
              <tr>
                <th>ClaimID</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Worked(MM)</th>
                <th>OverallClaimStatus</th>
                <th>Client</th>
                <th>Location</th>
                <th>Account#</th>
                <th>Physician</th>
                <th>Claim Status</th>
                <th>DOS</th>
                <th>Insurance</th>
                <th>CPT</th>
                <th>Balance</th>
                <th>Status Code</th>
                <th>Action Code</th>
                <th>Worked Date</th>
                <th>Follow Up Date</th>
                <th>Touch</th>
                <th>Type</th>
                <th>Username</th>
                <th>AR Notes</th>
              </tr>
            </thead>
            <tbody>
              {this.state.dailyreport &&
                this.state.dailyreport.map((data, index) => {
                  return (
                    <tr>
                      <td>{data.ClaimID}</td>
                      <td>{data.startTime.split('T')[1].split('.')[0]}</td>
                      <td>{data.endTime.split('T')[1].split('.')[0]}</td>
                      <td>{data.workedMinutes}</td>
                      <td>{data.OverallClaimStatus}</td>
                      <td>{data.clientId}</td>
                      <td>{data.Location}</td>
                      <td>{data.patientAccountNumber}</td>
                      <td>{data.Physician}</td>
                      <td>{data.ClaimStatus}</td>
                      <td>{data.DateOfService}</td>
                      <td>{data.InsuranceName}</td>
                      <td>{data.CPTCode}</td>
                      <td>{data.TotalBalanceAmount}</td>
                      <td>{data.statusCode}</td>
                      <td>{data.Actioncode}</td>
                      <td>{data.workedDate}</td>
                      <td>{data.FollowupDate}</td>
                      <td>{data.Touch}</td>
                      <td>{data.type}</td>
                      <td>{data.UserName}</td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {data.cptNotes}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <br />
        <div>
          <ReactPaginate
            previousLabel={<IoIosArrowBack />}
            nextLabel={<IoIosArrowForward />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount4}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick4}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    );
  }
}
export default DailyReport;
