import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";
import { ip } from "../Api";
import "../.././Styles/LoadingSettings.scss";

// AccessRoute component handles role-based access control for routes
const AccessRoute = ({ component: Component, role, ...rest }) => {
  const [rolenames, setRolenames] = useState([]);
  const [access, setAccess] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null); // State to handle errors

  // Fetch roles from the API
  const getrole = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("accesstoken")}`,
    };

    // Check if roles are already cached
    const cachedRoles = sessionStorage.getItem("getRolenamesdata");
    if (cachedRoles) {
      setRolenames(JSON.parse(cachedRoles));
      setAccess(true);
      setLoaded(true);
      return;
    }

    try {
      // Fetch roles from the API
      const res = await axios.get(`${ip}/role/all`, { headers: headers });
      const fetchedRolenames = res.data.role.map((data) => data.role_name);
      sessionStorage.setItem("getRolenamesdata", JSON.stringify(fetchedRolenames));
      setRolenames(fetchedRolenames);
      setAccess(true);
    } catch (err) {
      console.error(err);
      setError("Failed to load roles. Please try again later."); // Set error state
    } finally {
      setLoaded(true); // Ensure loaded is set to true even if there's an error
    }
  };

  useEffect(() => {
    getrole();
  }, []);

  // Show loading indicator while data is being fetched
  if (!loaded) {
    return (
      <div className="loader-container">
        <div className="loader">
          <div className="loader_overlay"></div>
          <div className="loader_cogs">
            <div className="loader_cogs__top">
              <div className="top_part"></div>
              <div className="top_part"></div>
              <div className="top_part"></div>
              <div className="top_hole"></div>
            </div>
            <div className="loader_cogs__left">
              <div className="left_part"></div>
              <div className="left_part"></div>
              <div className="left_part"></div>
              <div className="left_hole"></div>
            </div>
            <div className="loader_cogs__bottom">
              <div className="bottom_part"></div>
              <div className="bottom_part"></div>
              <div className="bottom_part"></div>
              <div className="bottom_hole"></div>
            </div>
            <h3 className="loader_heading">Loading...</h3>
          </div>
        </div>
      </div>
    );
  }

  // Show error message if an error occurred
  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p> {/* Display error message */}
      </div>
    );
  }

  // Check if user has the required role and render the component or redirect
  const storedRole = sessionStorage.getItem("role_type");

  return (
    <Route
      {...rest}
      render={(props) =>
        rolenames.includes(storedRole) && access ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default AccessRoute;
