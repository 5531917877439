import { Component } from "react";
import axios from "axios";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  LabelList,
  YAxis,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ip } from "../../../Api";
const data1 = [
  {
    _id: "",
    totalamt: 800,
    totalclaims: 30,
    percent: 82,
  },
  {
    _id: "",
    totalamt: 800,
    totalclaims: 20,
    percent: 59,
  },
  {
    _id: "",
    totalamt: 400,
    totalclaims: 10,
    percent: 27,
  },
  {
    _id: "",
    totalamt: 200,
    totalclaims: 10,
    percent: 27,
  },
  {
    _id: "",
    totalamt: 200,
    totalclaims: 5,
    percent: 27,
  },
  {
    _id: "",
    totalamt: 200,
    totalclaims: 5,
    percent: 27,
  },
  {
    _id: "",
    totalamt: 200,
    totalclaims: 5,
    percent: 27,
  },
];
let tooltip;

const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: "#f5234a" }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};
const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: "#f5234a" }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};
class Ageingchart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agegrpwiseamount: [],
      agegrpwisecount: [],
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
    };
  }

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getinventory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.ageStart !== "" && this.props.ageEnd !== "") {
          url = `${ip}/inventory/agegrpwise?start_date=${this.props.ageStart}&end_date=${this.props.ageEnd}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/inventory/agegrpwise?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.ageStart !== "" && this.props.ageEnd !== "") {
          url = `${ip}/inventory/agegrpwise?start_date=${this.props.ageStart}&end_date=${this.props.ageEnd}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/inventory/agegrpwise?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.ageStart !== "" && this.props.ageEnd !== "") {
        url = `${ip}/inventory/agegrpwise?start_date=${this.props.ageStart}&end_date=${this.props.ageEnd}`;
      } else {
        url = `${ip}/inventory/agegrpwise`;
      }
    }

    const req = axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.inventory.length !== 0) {
          this.setState({ agegrpwiseamount: res.data.inventory });

          this.setState({ agegrpwisecount: res.data.inventory });
        } else {
          this.setState({ agegrpwiseamount: data1, agegrpwisecount: data1 });
        }
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
    this.getinventory();
  }
  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.ageStart === prevProps.ageStart &&
        this.props.ageEnd === prevProps.ageEnd
      )
    ) {
      this.getinventory();
    } else if (this.props.client !== prevProps.client) {
      this.getinventory();
    } else if (this.props.Location !== prevProps.Location) {
      this.getinventory();
    }
  }

  render() {
    return (
      <>
        {this.props.agegrpwise === "$" ? (
          <div onMouseOver={() => (tooltip = "totalamount")}>
            <ResponsiveContainer width={"99%"} height={400}>
              <ComposedChart
                width={400}
                height={400}
                data={this.state.agegrpwiseamount}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 0,
                  left: 20,
                }}
              >
                <XAxis dataKey={"_id"} height={120} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}} />
                <YAxis dataKey={"totalamount"} fontSize="10" />
                <Legend />
                <Tooltip content={<CustomTooltip />} />
                <defs>
                  <linearGradient
                    id="colorUv"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="100%"
                    spreadMethod="reflect"
                  >
                    <stop offset="0" stopColor="#f5234a" />
                    <stop offset="1" stopColor="#fa859a" />
                  </linearGradient>
                </defs>
                <Bar
                  name="Total Amount"
                  dataKey="totalamount"
                  onMouseOver={() => (tooltip = "totalamount")}
                  radius={[10, 10, 0, 0]}
                  barSize={30}
                  fill="url(#colorUv)"
                >
                  {/* <LabelList dataKey=" totalclaims" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
                </Bar>
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div onMouseOver={() => (tooltip = "totalcount")}>
            <ResponsiveContainer width={"99%"} height={400}>
              <ComposedChart
                width={400}
                height={400}
                data={this.state.agegrpwisecount}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 0,
                  left: 20,
                }}
              >
                <defs>
                  <linearGradient
                    id="colorUv"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="100%"
                    spreadMethod="reflect"
                  >
                    <stop offset="0" stopColor="#f5234a" />
                    <stop offset="1" stopColor="#fa859a" />
                  </linearGradient>
                </defs>
                <XAxis dataKey="_id" height={120} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}} />
                <YAxis dataKey={"totalcount"} fontSize="10" />
                <Tooltip content={<ClaimTooltip />} />
                <Legend></Legend>
                <Bar
                  name="Total Claims"
                  dataKey="totalcount"
                  radius={[10, 10, 0, 0]}
                  barSize={30}
                  fill="url(#colorUv)"
                >
                  {/* <LabelList dataKey=" totalclaims" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
                </Bar>
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        )}
      </>
    );
  }
}

export default Ageingchart;
