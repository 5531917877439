import React, { Component } from "react";
import { ip } from "../../../../Api";
import axios from "axios";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
} from "recharts";

const style = {
  top: "50%",
  right: 0,
  transform: "translate(0, -50%)",
  lineHeight: "24px",
};
const data2 = [
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
];
let tooltip;
const COLORS = ["#00BFFF", "#FF0080", "#8C7380", "#992600", "#008080"];
let url = null;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};
const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};
export default class PayerCategoryCharts extends Component {
  state = {
    dept_wise: [],
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
  };

  tooltipClaims = ({ active, payload }) => {
    if (!active || !tooltip) return null;
    for (const bar of payload)
      if (bar.dataKey === tooltip)
        return (
          <div className="custom-tooltip">
            {bar.payload._id}
            <br />
            <p style={{ color: bar.fill }}>
              {bar.name}:&nbsp;{bar.value}
            </p>
          </div>
        );
    return null;
  };

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.props.client) {
      if(this.props.Location){
      if (this.props.from && this.props.to) {
        url = `${ip}/outcome/payerwise?start_date=${this.props.from}&end_date=${this.props.to}&clientId=${this.props.client}&Location=${this.props.Location}`;
      } else {
        url = `${ip}/outcome/payerwise?clientId=${this.props.client}&Location=${this.props.Location}`;
      }}
      else{
        if (this.props.from && this.props.to) {
          url = `${ip}/outcome/payerwise?start_date=${this.props.from}&end_date=${this.props.to}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/outcome/payerwise?clientId=${this.props.client}`;
        } 
      }
    } else {
      if (this.props.from && this.props.to) {
        url = `${ip}/outcome/payerwise?start_date=${this.props.from}&end_date=${this.props.to}`;
      } else {
        url = `${ip}/outcome/payerwise`;
      }
    }
    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        let i;
        let data = [];
        if (req.data.payer_wise.denied.length !== 0) {
          for (i = 0; i < 5; i++) {
            data.push(req.data.payer_wise.denied[i]);
          }
          this.setState((this.state.dept_wise = data));
        } else {
          this.setState({
            dept_wise: data2,
          });
        }
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
    this.getDetails();
  }
  componentDidUpdate(prevProps) {
    if (
      !(this.props.from === prevProps.from && this.props.to === prevProps.to)
    ) {
      this.getDetails();
    } else if (this.props.client !== prevProps.client) {
      this.getDetails();
    }
    else if (this.props.Location !== prevProps.Location){
      this.getDetails();
    }
  }
  render() {
    return (
      <div>
        {
          this.props.payerwise === "$" && (
            <div onMouseOver={() => (tooltip = "totalAmount")}>
              <ComposedChart
                width={700}
                height={400}
                data={this.state.dept_wise}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <XAxis dataKey={"_id"} height={120} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}} />
                <YAxis dataKey={"totalAmount"} />
                <Tooltip content={<CustomTooltip />} />
                <Area
                  name="Total Amount"
                  type="monotone"
                  dataKey={"totalAmount"}
                  fill="#8884d8"
                  stroke="#8884d8"
                />
                <Bar dataKey={"totalAmount"} barSize={20} fill="#04C671" />
                <Line type="monotone" dataKey={"_id"} stroke="#ff7300" />
              </ComposedChart>
            </div>
          )
          //   <BarChart
          //   width={500}
          //   height={300}
          //   data={this.state.dept_wise}
          //   margin={{
          //     top: 5,
          //     right: 30,
          //     left: 20,
          //     bottom: 5,
          //   }}
          // >
          //   <XAxis dataKey={"_id"} angle="-20" interval={0} tick={{strokeWidth:0,fontSize:"12",top:100,textAnchor:"start",dy:6}}/>
          //   <YAxis dataKey={"totalAmount"}/>
          //   <Tooltip  cursor={{fill: 'transparent'}} />
          //   <Bar dataKey={"totalAmount"}   radius={[10, 0, 0, 10]} fill="#0000FF" barSize={20}/>
          // </BarChart>
        }
        {
          this.props.payerwise === "#" && (
            <div onMouseOver={() => (tooltip = "totalClaims")}>
              <ComposedChart
                width={700}
                height={400}
                data={this.state.dept_wise}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <XAxis dataKey={"_id"} height={120} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}} />
                <YAxis dataKey={"totalClaims"} />
                <Tooltip content={<ClaimTooltip />} />
                <Area
                  name="Total Claims"
                  type="monotone"
                  dataKey={"totalClaims"}
                  fill="#8884d8"
                  stroke="#8884d8"
                />
                <Line type="monotone" dataKey={"_id"} stroke="#ff7300" />
                <Bar dataKey={"totalClaims"} barSize={20} fill="#04C671" />
              </ComposedChart>
            </div>
          )
          //   <BarChart
          //   width={500}
          //   height={300}
          //   data={this.state.dept_wise}
          //   margin={{
          //     top: 5,
          //     right: 30,
          //     left: 20,
          //     bottom: 5,
          //   }}
          // >
          //   <XAxis dataKey={"_id"} angle="-20" interval={0} tick={{strokeWidth:0,fontSize:"12",top:100,textAnchor:"start",dy:6}}/>
          //   <YAxis dataKey={"totalClaims"}/>
          //   <Tooltip  cursor={{fill: 'transparent'}} />
          //   <Bar dataKey={"totalClaims"}  radius={[10, 0, 0, 10]} fill="#0000FF" barSize={20}/>

          // </BarChart>
        }
      </div>
    );
  }
}
