import { Component } from "react";
import "antd/dist/antd.css";
import React from "react";
import axios from "axios";
import { IoIosRefresh } from "react-icons/io";
import { Row, Col, Container, Input } from "reactstrap";
// import Newsidebar from "../Dashboard/DummySidebar";
import Newsidebar from "../Dashboard/Newsidebar";
import { Card } from "antd";
import { PDFExport } from "@progress/kendo-react-pdf";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { BiCloudDownload } from "react-icons/bi";
import jsPDF from "jspdf";
import "react-best-tabs/dist/index.css";
import "../../Styles/charts.css";
 
import UploadStatus from "./performanceCharts/UploadStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { ip } from "../../Api";
import moment from "moment";
import TestiMonials from "./Outcomes/TestiMonials/TestiMonials";
import WebChartStatus from "./performanceCharts/WebChartsStatus";
import { IoMdNotificationsOutline, IoMdGitPullRequest } from "react-icons/io";
import { MdPendingActions } from "react-icons/md";
import PdfDownload from "./pdfDownload";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { GiThrustBend } from "react-icons/gi";
import { RiSignalWifiErrorFill } from "react-icons/ri";
import { BsSearch } from "react-icons/bs";
import { MdOutlinePaid } from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import { DatePicker } from "antd";
import { Button, notification, Divider } from "antd";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import OutcomeCharts from "./Outcomes/TestiMonials/outcomecharts";
const ref = React.createRef();

const { RangePicker } = DatePicker;
const openNotificationWithIcon = (success) => {
  notification[success]({
    message: "Filtered Succesfully",
  });
};
const openDownload = (success) => {
  notification[success]({
    message: "File is being downloaded",
  });
};
const openNotificationWithIcon3 = (success) => {
  notification[success]({
    message: "Reset Successfully",
  });
};
const openNotificationWithIcon2 = (error) => {
  notification[error]({
    message: "*Please Remove the Dates",
  });
};
const openNotificationWithIcon1 = (error) => {
  notification[error]({
    message: "No Values Entered",
  });
};
let url = null;

class PerformanceDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      locationslist: [],
      clientslist: [],
      zeropaid: [],
      partialpaid: [],
      appealclaim: [],
      denialbytfl: [],
      appealondenial: "",
      arover90: "",
      arover120: "",
      arover1yr: "",
      datareportingack: "",
      name: "Web",
      name1: "Web",
      name2: "Denial",
      claimname: "Denied",
      ack: "",
      datareport: "",
      pendin: "",
      final: "",
      req: "",
      error: "",
      nisvalue: "",
      denial: "",
      paid: "",
      search: "",
      start: "",
      end: "",
      valueOfInput1: "",
      valueOfInput2: "",
      filterstatus: "custom",
      DenialClaims: "",
      PaidClaims: "",
      SearchesClaims: "",
      NISClaims: "",
      ACKClaims: "",
      PendingClaims: "",
      finalizedClaims: "",
      RequestClaims: "",
      ErrorClaims: "",
      startDate: "",
      start: "",
      end: "",
      action_wise_card: [],
      role_type: sessionStorage.getItem("role_type"),
      client: sessionStorage.getItem("clientid"),
      clientname: sessionStorage.getItem("clientname"),
      opendropdown: false,
      Location: "",
    };
    this.pdfRef = React.createRef();
  }

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  toggle = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };
  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        this.setState({
          clientslist: res.data.clientonboard,
        });
      })
      .catch((err) => {});
  };
handleChange =(selectedOptions)  =>{
    let Location = selectedOptions.map((data)=>{
          return data.Location
    })
    this.setState ({
      Location: Location[0],
    }, ()=> {
      this.getclients();
      this.getLocation();
      this.getDetailsCard();
    })
    }
  pdfGenerate = () => {
    const doc = new jsPDF();
    var html = document.getElementById("pdfvalue").innerHTML;
    doc.text(html, 10, 10);
    doc.save("a4.pdf");
  };

  getDetailsCard = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.state.client) {
      if (
        this.state.valueOfInput1.length !== 0 &&
        this.state.valueOfInput2.length !== 0
      ) {
        url = `${ip}/outcome/claimstatus?start_date=${this.state.valueOfInput1}&end_date=${this.state.valueOfInput2}&clientId=${this.state.client}&Location=${this.state.Location}`;
      } else {
        url = `${ip}/outcome/claimstatus?clientId=${this.state.client}&Location=${this.state.Location}`;
      }
    } else {
      if (
        this.state.valueOfInput1.length !== 0 &&
        this.state.valueOfInput2.length !== 0
      ) {
        url = `${ip}/outcome/claimstatus?start_date=${this.state.valueOfInput1}&end_date=${this.state.valueOfInput2}`;
      } else {
        url = `${ip}/outcome/claimstatus`;
      }
    }
    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        if (req.data.action_wise.denied.length !== 0) {
          this.setState({ denial: req.data.action_wise.denied[0].totalAmount });
          this.setState({
            DenialClaims: req.data.action_wise.denied[0].totalClaims,
          });
        } else {
          this.setState({
            denial: 0,
            DenialClaims: 0,
          });
        }
        if (req.data.action_wise.paid.length == 0) {
          this.setState({ paid: 0, PaidClaims: 0 });
        } else {
          this.setState({ paid: req.data.action_wise.paid[0].totalAmount });
          this.setState({
            PaidClaims: req.data.action_wise.paid[0].totalClaims,
          });
        }
        if (req.data.action_wise.nis.length === 0) {
          this.setState({
            nisvalue: 0,
            NISClaims: 0,
          });
        } else {
          this.setState({ nisvalue: req.data.action_wise.nis[0].totalAmount });
          this.setState({ NISClaims: req.data.action_wise.nis[0].totalClaims });
        }
        if (req.data.action_wise.acknowledgement.length === 0) {
          this.setState({ ack: 0, ACKClaims: 0 });
        } else {
          this.setState({
            ack: req.data.action_wise.acknowledgement[0].totalAmount,
          });
          this.setState({
            ACKClaims: req.data.action_wise.acknowledgement[0].totalClaims,
          });
        }
        if (req.data.action_wise.pending.length === 0) {
          this.setState({ pending: 0, PendingClaims: 0 });
        } else {
          this.setState({
            pending: req.data.action_wise.pending[0].totalAmount,
          });
          this.setState({
            PendingClaims: req.data.action_wise.pending[0].totalClaims,
          });
        }
        if (req.data.action_wise.finalized.length === 0) {
          this.setState({ final: 0, finalizedClaims: 0 });
        } else {
          this.setState({
            final: req.data.action_wise.finalized[0].totalAmount,
          });
          this.setState({
            finalizedClaims: req.data.action_wise.finalized[0].totalClaims,
          });
        }
        if (req.data.action_wise.request.length === 0) {
          this.setState({ req: 0, RequestClaims: 0 });
        } else {
          this.setState({ req: req.data.action_wise.request[0].totalAmount });
        }
        if (req.data.action_wise.searches.length === 0) {
          this.setState({ search: 0, SearchesClaims: 0 });
        } else {
          this.setState({
            search: req.data.action_wise.searches[0].totalAmount,
          });
          this.setState({
            SearchesClaims: req.data.action_wise.searches[0].totalClaims,
          });
        }
        if (req.data.action_wise.error.length === 0) {
          this.setState({ error: 0, ErrorClaims: 0 });
        } else {
          this.setState({ error: req.data.action_wise.error[0].totalAmount });
          this.setState({
            ErrorClaims: req.data.action_wise.error[0].totalClaims,
          });
        }
        if (req.data.action_wise.datareportingack.length === 0) {
          this.setState({ datareportingack: 0 });
        } else {
          this.setState({
            datareportingack:
              req.data.action_wise.datareportingack[0].totalAmount,
          });
        }
      })
      .catch((err) => {});
  };
  getLocation = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      url = `${ip}/location/all/list?clientId=${this.state.client}`;
    } else {
      url = `${ip}/location/all/list`;
    }
     axios
      .get(url, { headers: headers })
      .then((res) => {
        let data = res.data.location;
        data = data.filter((item) => {
          return item.clientId == this.state.client;
        });
        data.sort((a, b) => {
          return a.Location < b.Location ? -1 : 1;
        });

        this.setState({ locationslist: data });
      })

      .catch((err) => {});
  };
  valuedate = (option, e) => {
    if (option === "yesterday") {
      this.setState({
        valueOfInput1: e.target.value,
        valueOfInput2: moment().subtract(1, "days").format("MM-DD-YYYY"),
        filterstatus: option,
      });
    } else {
      this.setState({
        valueOfInput1: e.target.value,
        valueOfInput2: moment().format("MM-DD-YYYY"),
        filterstatus: option,
      });
    }
  };
  getpercent_of_appealondenial = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const req = axios
      .get(`${ip}/outcome/appealondenial`, { headers: headers })
      .then((req) => {
        this.setState({ appealondenial: req.data.appealondenial });
      })
      .catch((err) => {});
  };

  getpercent_account_receivables = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      url = `${ip}/outcome/ar?clientId=${this.state.client}&Location=Texas`;
    } else {
      url = `${ip}/outcome/ar`;
    }
    const req = axios
      .get(url, {
        headers: headers,
      })
      .then((req) => {
        this.setState({ arover90: req.data.account_receivables[0] });
        this.setState({ arover120: req.data.account_receivables[1] });
        this.setState({ arover1yr: req.data.account_receivables[2] });
      })
      .catch((err) => {});
  };

  
  componentDidMount() {
    
    this.getclients();
    this.getDetailsCard();
    this.getLocation();
  }

  togglecheckoutcome = () => {
    this.setState({ check: !this.state.check });
  };
  render() {
    return (
      <div>
      <Newsidebar name="Outcomes" />
      <div className="align-row" style={{ overflow: "hidden" }}>

        <div className="align-column">
          <div
            forcePageBreak=".page-break"
            ref={this.pdfRef}
            className="align-row items"
          ></div>
          <br />
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
            <div className="row">
            <div className="col-md-3 mb-3">
            {this.state.role_type === "SuperAdmin" && (
              <ButtonDropdown
                isOpen={this.state.opendropdown}
                toggle={this.toggle}
                style={{
                  width: "12%",
                  position: "inherit",
                  float: "left",
                }}
              >
                <DropdownToggle caret>
                  {this.state.clientname ? this.state.clientname : "All"}
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem
                    onClick={() =>
                      this.setState({ client: "", clientname: "" }, () => {
                        this.getLocation();
                      })
                    }
                  >
                    All
                  </DropdownItem>
                  {this.state.clientslist.map((e) => {
                    return (
                      <DropdownItem
                        id={e.clientId}
                        key={e.clientId}
                        onClick={(e) =>
                          this.setState(
                            {
                              client: e.target.id,
                              clientname: e.target.innerText,
                            },
                            () => {
                              this.getLocation();
                            }
                          )
                        }
                      >
                        {e.client}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </ButtonDropdown>
            )} 
            {this.state.client && (
              <Typeahead
            id="outcome-typeahead"
            clearButton={true}
            onChange={this.handleChange}
            options={this.state.locationslist}
            labelKey={option => `${option.Location}`}
            placeholder="All Location"
            selected={this.state.selected}
          /> 
            )}   
            </div>
            <div className="col-md-4 mb-3" style={{display:"flex", flexDirection:"row"}}>
            <Input
            type="select"
            style={{ width: "40%"}}
            className="form-control form-select"
            onChange={(e) => {
              const index = e.target.selectedIndex;
              const el = e.target.childNodes[index];
              const option = el.getAttribute("title");
              this.valuedate(option, e);
            }}
          >
            <option value="" title="custom">
              Custom
            </option>
            <option value={moment().format("MM-DD-YYYY")} title="">
              Today
            </option>
            <option
              value={moment().subtract(1, "days").format("MM-DD-YYYY")}
              title="yesterday"
            >
              Yesterday
            </option>
            <option
              value={moment().subtract(3, "days").format("MM-DD-YYYY")}
              title=""
            >
              Last 3 days
            </option>
            <option
              value={moment().subtract(7, "days").format("MM-DD-YYYY")}
              title=""
            >
              Last 7 days
            </option>
            <option
              value={moment().subtract(30, "days").format("MM-DD-YYYY")}
              title=""
            >
              Last 30 days
            </option>
            <option
              value={moment().subtract(365, "days").format("MM-DD-YYYY")}
              title=""
            >
              Last 365 days
            </option>
          </Input> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {this.state.filterstatus === "custom" && (
            <RangePicker
              format="MM-DD-YYYY"
              onChange={(dates, datestring) => {
                this.setState({
                  valueOfInput1: datestring[0],
                  valueOfInput2: datestring[1],
                });
              }}
              height="50%"
              width="200%"
              style={{ borderColor: "gray" }}
            />
          )} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button
          className="export-btn"
          onClick={() => {
            openDownload("success");
            if (this.pdfRef.current) {
              this.pdfRef.current.save();
            }
          }}
        >
          <BiCloudDownload style={{ width: "30px", height: "30px" }} />
        </button> 
            </div>
            </div>
            </div>
            <br/>
          <PDFExport
            forcePageBreak=".page-break"
            ref={this.pdfRef}
            fileName="Outcomes.pdf"
          >
            <div className="outcome_grid1">
              <TestiMonials
                testStart={this.state.valueOfInput1}
                testEnd={this.state.valueOfInput2}
                client={this.state.client}
                Location={this.state.Location}
              ></TestiMonials>
            </div>
            <div className="outcome_container">

              <div className="outcome_grid2">
                <OutcomeCharts
                  testStart={this.state.valueOfInput1}
                  testEnd={this.state.valueOfInput2}
                  client={this.state.client}
                  Location={this.state.Location}
                />
              </div>

              <div className="outcome_grid4">
                <Row>
                  <Row>
                    <Row>
                      <Row style={{ marginLeft: "5%" }}>
                        <Col>
                          <h6
                            style={{
                              fontWeight: "bold",
                              marginLeft: "-8%",
                              float: "left",
                            }}
                          ></h6>
                        </Col>
                      </Row>
                    </Row>
                  </Row>

                  <Row style={{ marginTop: "1%" }}>
                    <UploadStatus
                      togglecheckoutcome={this.togglecheckoutcome}
                      checking={this.state.check}
                      uploadStart={this.state.valueOfInput1}
                      uploadEnd={this.state.valueOfInput2}
                      data={this.state.name}
                      client={this.state.client}
                      Location={this.state.Location}
                    ></UploadStatus>
                  </Row>
                </Row>
              </div>

              <div className="outcome_grid5">
                <Row>
                  <Row>
                    <Row>
                      <Row style={{ marginLeft: "5%" }}>
                        <Col>
                          <h6
                            style={{
                              fontWeight: "bold",
                              marginLeft: "-8%",
                              float: "left",
                            }}
                          ></h6>
                        </Col>

                        <Col>
                          <div
                            style={{
                              marginLeft: "60%",
                              marginTop: "-1%",
                              alignItems: "left",
                            }}
                          >
                            <select
                              style={{
                                width: "80%",
                                height: "30%",
                                borderBlockColor: "gray",
                                borderRadius: "4px",
                              }}
                              onChange={(e) =>
                                this.setState({ name2: e.target.value })
                              }
                              value={this.state.name2}
                            >
                              <option value="Denial">Denial</option>
                              <option value="Paid">Paid</option>
                              <option value="NIS">Not In System</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </Row>
                  </Row>
                  <br />

                  <hr style={{ width: "100%", marginLeft: "-12px" }} />
                </Row>
                <Row>
                  {this.state.name1 === "Web" && (
                    <WebChartStatus
                      data1={this.state.name1}
                      data2={this.state.name2}
                      startdate={this.state.valueOfInput1}
                      endDate={this.state.valueOfInput2}
                      checking={this.state.check}
                      togglecheckoutcome={this.togglecheckoutcome}
                      client={this.state.client}
                      Location={this.state.Location}
                    />
                  )}
                </Row>
              </div>
            </div>
          </PDFExport>
          <div></div>
        </div>
      </div>
      </div>

    );
  }
}
export default PerformanceDashboard;
