import { React, Component } from "react";
import "../../Styles/TeamPerformances.css";
import { Row, Col } from "reactstrap";
import "../../Styles/performances.css";
import axios from "axios";
import { ip, secretkey } from "../../Api";
import aes256 from "aes256";
import Agecharts from "./PerformanceCharts/Agecharts";
import AvgTouches from "./PerformanceCharts/AvgTouches";
import { BsCalendarCheckFill } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { FaRegWindowClose } from "react-icons/fa";
import { HiOutlineCollection } from "react-icons/hi";
import { BiDollarCircle } from "react-icons/bi";
import "react-circular-progressbar/dist/styles.css";
import ChangingProgressProvider from "./PerformanceCharts/ChangingProgressProvider";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
const triangleCOLORS = [
  "rgb(255,99,132)",
  "rgb(54,162,235",
  "rgb(75,192,192)",
  "rgb(255,159,64)",
];
const pieCOLORS = ["#0088FE", "#FFBB28"];

let url, url1, url2, url4;
const getPath = (x, y, width, height) => `M${x},${y + height}
    C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
  x + width / 2
}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
  x + width
}, ${y + height}
    Z`;

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
let paid1 = 0;
let worked1 = 0;
let denied1 = 0;

export class PerfomanceComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role_type: sessionStorage.getItem("role_type"),
      firstname: sessionStorage.getItem("firstname"),
      lastname: sessionStorage.getItem("lastname"),
      css_name: "",
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      clientname: sessionStorage.getItem("clientname"),
      user_id: sessionStorage.getItem("user_id"),
      touchwise: [],
      name__user: "$",
      date: "Oct 13",
      start: "",
      end: "",
      outcomes__alloc: [],
      outcomes__notwork: [],
      outcomes__total: [],
      outcomes__value: [],
      outcomes__worked: "",
      outcomes__role: [],
      paid: "",
      paidamount: "",
      deniedamount: "",
      denied: "",
      niscount: "",
      nisamount: "",
      ack: "",
      ackamount: "",
      nis: [],
      inprocess: [],
      paidperc: "",
      deniedperc: "",
      allocated: "",
      alloworked: "",
      productivity: "",
      uncountable: "",
      uncountableMins:"",
      target: "",
      check: false,
      workedMins: "",
    };
  }
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  toggleCheck = () => {
    this.setState({ check: !this.state.check });
  };
  getUserRole = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/userdashboard/userdetails?userid=${this.state.user_id}&start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
    } else {
      url = `${ip}/userdashboard/userdetails?userid=${this.state.user_id}&clientId=${this.props.client}`;
    }
    const req = axios
      .get(url, {
        headers: headers,
      })
      .then((req) => {
        let userData;
        userData = aes256.decrypt(`${secretkey}`, req.data.userdetails);
        const decrypteduserdata = JSON.parse(userData); 
        this.setState({ outcomes__role: decrypteduserdata });
      })
      .catch((err) => {});
  };
  getTarget = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url4;
    if (this.props.startdate && this.props.enddate) {
      url4 = `${ip}/userdashboard/target?userid=${this.state.user_id}&clientId=${this.props.client}&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
    } else {
      url4 = `${ip}/userdashboard/target?userid=${this.state.user_id}&clientId=${this.props.client}`;
    }
    const req = axios
      .get(url4, {
        headers: headers,
      })
      .then((req) => {
        let targetData;
        targetData = aes256.decrypt(`${secretkey}`, req.data.claimdetails);
        const decryptedtargetdata = JSON.parse(targetData); 
        if (req.data.count.target !== 0) {
          this.setState({ target: req.data.count.target });
        } else {
          this.setState({ target: 0 });
        }
      })
      .catch((err) => {});
  };
  getClaims = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let name = this.state.firstname + " " + this.state.lastname;
    if (this.props.startdate && this.props.enddate) {
      url1 = `${ip}/userdashboard/claimsdetails?claimunder=${name}&start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
    } else {
      url1 = `${ip}/userdashboard/claimsdetails?claimunder=${name}&clientId=${this.props.client}`;
    }
    const req = axios
      .get(url1, { headers: headers })
      .then((req) => {
        let claimsData;
        claimsData = aes256.decrypt(`${secretkey}`, req.data.claimdetails);
        const decryptedclaimsdata = JSON.parse(claimsData); 
        if (decryptedclaimsdata.claimsallocated.length !== 0) {
          this.setState({
            allocated: decryptedclaimsdata.claimsallocated[0].totalcount,
          });
        } else {
          this.setState({
            allocated: 0,
          });
        }
        if (req.data.claimdetails.uncountable.length !== 0) {
          this.setState({
            uncountable: req.data.claimdetails.uncountable[0].totalcount,
          });
        } else {
          this.setState({
            uncountable: 0,
          });
        }

        this.setState({
          outcomes__alloc: req.data.claimdetails.claimsallocated,
        });

        if (req.data.claimdetails.claimsworked !== 0) {
          this.setState({
            outcomes__worked: req.data.claimdetails.claimsworked[0].totalcount,
          });
        } else {
          this.setState({ outcomes__worked: 0 });
        }

        worked1 = this.state.outcomes__worked;
        if (this.state.target !== 0) {
          this.setState({
            productivity: ((worked1 / this.state.target) * 100).toFixed(0),
          });
        } else {
          this.setState({ productivity: 1 });
        }
        this.setState({
          outcomes__notwork: req.data.claimdetails.claimsnotworked,
        });
        this.setState({
          outcomes__value: req.data.claimdetails.workedclaimsamount,
        });
        this.setState({
          outcomes__total: req.data.claimdetails.totalcollected,
        });
        this.setState({
          workedMins: req.data.claimdetails.workedMinutes,
          uncountableMins: req.data.claimdetails.uncountableMinutes,
        });
      })

      .catch((err) => {});
  };
  getOutcomes = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let name = this.state.firstname + " " + this.state.lastname;
    if (this.props.startdate && this.props.enddate) {
      url2 = `${ip}/userdashboard/outcome?claimunder=${name}&start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
    } else {
      url2 = `${ip}/userdashboard/outcome?claimunder=${name}&clientId=${this.props.client}`;
    }
    const req = axios
      .get(url2, { headers: headers })
      .then((req) => {
        if (req.data.outcome.paid.length !== 0) {
          this.setState({
            paid: req.data.outcome.paid[0].totalcount,
            paidamount: req.data.outcome.paid[0].totalamount,
          });
        } else {
          this.setState({ paidamount: 0, paid: 0 });
        }
        if (req.data.outcome.denied.length !== 0) {
          this.setState({
            deniedamount: req.data.outcome.denied[0].totalamount,
            denied: req.data.outcome.denied[0].totalcount.toFixed(0),
          });
        } else {
          this.setState({ denied: 0, deniedamount: 0 });
        }
        denied1 = this.state.denied;

        paid1 = this.state.paid;
        if (worked1 !== 0) {
          this.setState({
            paidperc: ((paid1 / worked1) * 100).toFixed(0),
            deniedperc: ((denied1 / worked1) * 100).toFixed(0),
          });
        } else {
          this.setState({ paidperc: 0, deniedperc: 0 });
        }
        if (req.data.outcome.nis.length !== 0) {
          this.setState({
            niscount: req.data.outcome.nis[0].totalcount,
            nisamount: req.data.outcome.nis[0].totalamount,
          });
        } else {
          this.setState({ nisamount: 0, niscount: 0 });
        }

        if (req.data.outcome.inprocess.length !== 0) {
          this.setState({
            ack: req.data.outcome.inprocess[0].totalcount,
            ackamount: req.data.outcome.inprocess[0].totalamount
              .toFixed(0)
              .toLocaleString(),
          });
        } else {
          this.setState({ ack: 0, ackamount: 0 });
        }
        this.setState({ nis: req.data.outcome.nis });
        this.setState({ inprocess: req.data.outcome.inprocess });
      })
      .catch((err) => {});
  };
  getperformance = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const req = axios
      .get(`${ip}/outcome`, { headers: headers })
      .then((req) => {
        this.setState({ touchwise: req.data.outcome.touchwise });
      })
      .catch((err) => {});
  };
  getcss = () => {
    if (this.state.role_type === "Team Member") {
      this.setState({ css_name: "align-col-actions" });
    } else {
      this.setState({ css_name: "align-column" });
    }
  };

  componentDidMount() {
    this.getcss();
    this.getperformance();
    this.getUserRole();
    this.getClaims();
    this.getTarget();
    this.getOutcomes();
  }

  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.startdate === prevProps.startdate &&
        this.props.enddate === prevProps.enddate
      )
    ) {
      this.getcss();
      this.getperformance();
      this.getUserRole();
      this.getClaims();
      this.getTarget();
      this.getOutcomes();
    } else if (this.props.client !== prevProps.client) {
      this.getcss();
      this.getperformance();
      this.getUserRole();
      this.getClaims();
      this.getTarget();
      this.getOutcomes();
    }
  }

  render() {
    return (
      <div className="user__performance__wrapper">
        <div className="user__performance__wrapper__one">
          <div className="header__user__wrapper">
              <Row>
                <Col>
                  <div className="p-2 col-md-12 offset-md-0">
                    <div className="bg-white shadow mb-5 bg-body rounded-3 border-start border-0 border-3 border-danger card">
                      <div className="card-body p-3 position-relative">
                        <div className="float-start ps-2  position-absolute top-0 start-0">
                          <span
                            style={{ fontSize: "22px", fontWeight: "bold" }}
                          >
                            {this.state.allocated}
                          </span>
                        </div>
                      </div>
                      <br />
                      <div className="card-footer text-muted bg-transparent border-0 text-start">
                        <div className="float-end text-center lh-1">
                          <span
                            style={{
                              color: "grey",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Allocated
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="p-2 col-md-12 offset-md-0">
                    <div className="bg-white shadow mb-5 bg-body rounded-3 border-start border-0 border-3 border-danger card">
                      <div className="card-body p-3 position-relative">
                        <div className="float-start ps-2  position-absolute top-0 start-0">
                          <span
                            style={{ fontSize: "22px", fontWeight: "bold" }}
                          >
                            {this.state.outcomes__worked}
                          </span>
                        </div>
                        <div className="float-end ps-2  position-absolute top-0 end-0">
                        <span
                          style={{ fontSize: "22px", fontWeight: "bold" }}
                        >
                          {this.state.workedMins}
                        </span>
                      </div>
                      </div>
                      <br />
                      <div className="card-footer text-muted bg-transparent border-0 text-start">
                        <div className="float-end text-center lh-1">
                          <span
                            style={{
                              color: "grey",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Countable
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                <div className="p-2 col-md-12 offset-md-0">
                  <div className="bg-white shadow mb-5 bg-body rounded-3 border-start border-0 border-3 border-danger card">
                    <div className="card-body p-3 position-relative">
                      <div className="float-start ps-2  position-absolute top-0 start-0">
                        <span
                          style={{ fontSize: "22px", fontWeight: "bold" }}
                        >
                          {this.state.target}
                        </span>
                      </div>
                    </div>
                    <br />
                    <div className="card-footer text-muted bg-transparent border-0 text-start">
                      <div className="float-end text-center lh-1">
                        <span
                          style={{
                            color: "grey",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Target
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              </Row>
              <Row>
                <Col>
                  <div className="p-2 col-md-12 offset-md-0">
                    <div className="bg-white shadow mb-5 bg-body rounded-3 border-start border-0 border-3 border-danger card">
                      <div className="card-body p-3 position-relative">
                        <div className="float-start ps-2  position-absolute top-0 start-0">
                          <span
                            style={{ fontSize: "22px", fontWeight: "bold" }}
                          >
                            {this.state.productivity} %
                          </span>
                        </div>
                      
                      </div>
                      <br />
                      <div className="card-footer text-muted bg-transparent border-0 text-start">
                        <div className="float-end text-center lh-1">
                          <span
                            style={{
                              color: "grey",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Productivity
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="p-2 col-md-12 offset-md-0">
                    <div className="bg-white shadow mb-5 bg-body rounded-3 border-start border-0 border-3 border-danger card">
                      <div className="card-body p-3 position-relative">
                        <div className="float-start ps-2  position-absolute top-0 start-0">
                          <span
                            style={{ fontSize: "22px", fontWeight: "bold" }}
                          >
                            0
                          </span>
                        </div>
                      </div>
                      <br />
                      <div className="card-footer text-muted bg-transparent border-0 text-start">
                        <div className="float-end text-center lh-1">
                          <span
                            style={{
                              color: "grey",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Absent
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="p-2 col-md-12 offset-md-0">
                    <div className="bg-white shadow mb-5 bg-body rounded-3 border-start border-0 border-3 border-danger card">
                      <div className="card-body p-3 position-relative">
                        <div className="float-start ps-2  position-absolute top-0 start-0">
                          <span
                            style={{ fontSize: "22px", fontWeight: "bold" }}
                          >
                            {this.state.uncountable}
                          </span>
                        </div>
                        <div className="float-end ps-2  position-absolute top-0 end-0">
                        <span
                          style={{ fontSize: "22px", fontWeight: "bold" }}
                        >
                          {this.state.uncountableMins}
                        </span>
                      </div>
                      </div>
                      <br />
                      <div className="card-footer text-muted bg-transparent border-0 text-start">
                        <div className="float-end text-center lh-1">
                          <span
                            style={{
                              color: "grey",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Uncountable
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    Outcomes
                  </h3>
                </Col>
                <Col>
                  <select
                    style={{
                      width: "30%",
                      height: "100%",
                      borderBlockColor: "gray",
                      borderRadius: "3px",
                      float: "right",
                      marginTop: "-3px",
                    }}
                    onChange={(e) =>
                      this.setState({ name__user: e.target.value })
                    }
                    value={this.state.name__user}
                  >
                    <option value="$">Charges</option>
                    <option value="#">Claims</option>
                  </select>
                </Col>
                <hr style={{ width: "95%", left:"0" }} />
              </Row>
              {this.state.name__user === "$" && (
                <div className="card__container__user">
                  <div className="card__inner__user">
                    <div className="circle__card__user">
                      <h3>Paid</h3>
                    </div>

                    <div className="content__card__user">
                      <p>${this.state.paidamount.toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="card__inner__user">
                    <div className="circle__card__user">
                      <h3>Denied</h3>
                    </div>

                    <div className="content__card__user">
                      <p>${this.state.deniedamount.toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="card__inner__user">
                    <div className="circle__card__user">
                      <h3>Not In System</h3>
                    </div>

                    <div className="content__card__user">
                      <p>${this.state.nisamount.toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="card__inner__user">
                    <div className="circle__card__user">
                      <h3>Ack</h3>
                    </div>

                    <div className="content__card__user">
                      <p>${this.state.ackamount.toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              )}
              {this.state.name__user === "#" && (
                <div className="card__container__user">
                  <div className="card__inner__user">
                    <div className="circle__card__user">
                      <h3>Paid</h3>
                    </div>

                    <div className="content__card1__user">
                      <p>{this.state.paid}</p>
                    </div>
                  </div>
                  <div className="card__inner__user">
                    <div className="circle__card__user">
                      <h3>Denied</h3>
                    </div>

                    <div className="content__card1__user">
                      <p>{this.state.denied}</p>
                    </div>
                  </div>
                  <div className="card__inner__user">
                    <div className="circle__card__user">
                      <h3>Not In System</h3>
                    </div>

                    <div className="content__card1__user">
                      <p>{this.state.niscount}</p>
                    </div>
                  </div>
                  <div className="card__inner__user">
                    <div className="circle__card__user">
                      <h3>Ack</h3>
                    </div>

                    <div className="content__card1__user">
                      <p>{this.state.ack}</p>
                    </div>
                  </div>
                </div>
              )}
          </div>
          <div className="user__details__grid">        
            <div className="claim__user roles-container">
              <div className="course">
                <BsCalendarCheckFill
                  style={{
                    width: "35px",
                    height: "35px",
                    padding: "10px",
                    borderRadius: "10px",
                    float: "right",
                    color: "#3944bc",
                    backgroundColor: "#faf6f3",
                  }}
                />
              </div>
              <div className="course1" style={{color:"black",fontWeight: "600"}}>
                Claims Allocated: &nbsp;&nbsp;
                {this.state.outcomes__alloc.map((data, index) => (
                  <span style={{ fontWeight: "600" }}>{data.totalcount}</span>
                ))}
              </div>
            </div>
            <div className="work__user roles-container">
              <div className="course">
                <FaCheckCircle
                  style={{
                    width: "35px",
                    height: "35px",
                    padding: "10px",
                    borderRadius: "10px",
                    float: "right",
                    color: "#3944bc",
                    backgroundColor: "#faf6f3",
                  }}
                />
              </div>
              <div className="course1" style={{color:"black",fontWeight: "600"}}>
                Claims Worked: &nbsp;&nbsp;
                <span style={{ fontWeight: "600" }}>
                  {this.state.outcomes__worked}
                </span>
              </div>
            </div>
            <div className="not__user roles-container">
              <div className="course">
                <FaRegWindowClose
                  style={{
                    width: "35px",
                    height: "35px",
                    padding: "10px",
                    borderRadius: "10px",
                    float: "right",
                    color: "#3944bc",
                    backgroundColor: "#faf6f3",
                  }}
                />
              </div>
              <div className="course1" style={{color:"black",fontWeight: "600"}}>
                Claims Not Worked: &nbsp;&nbsp;
                {this.state.outcomes__notwork.map((data, index) => (
                  <span>{data.totalcount}</span>
                ))}
              </div>
            </div>
            <div className="value__user roles-container">
              <div className="course">
                <BiDollarCircle
                  style={{
                    width: "35px",
                    height: "35px",
                    padding: "10px",
                    borderRadius: "10px",
                    float: "right",
                    color: "#3944bc",
                    backgroundColor: "#faf6f3",
                  }}
                />
              </div>
              <div className="course1" style={{color:"black",fontWeight: "600"}}>
                Value Worked: &nbsp;&nbsp;
                {this.state.outcomes__value.map((data, index) => (
                  <span>${data.totalamount.toLocaleString()}</span>
                ))}
              </div>
            </div>
            <div className="total__user roles-container">
              <div className="course">
                <HiOutlineCollection
                  style={{
                    width: "35px",
                    height: "35px",
                    padding: "10px",
                    borderRadius: "10px",
                    float: "right",
                    color: "#3944bc",
                    backgroundColor: "#faf6f3",
                  }}
                  className="total__collected"
                />
              </div>
              <div className="course1" style={{color:"black",fontWeight: "600"}}>
                Total Collected: &nbsp;&nbsp;
                {this.state.outcomes__total.map((data, index) => (
                  <span>${data.totalamount.toLocaleString()}</span>
                ))}
              </div>
            </div>
          </div>
          <div className="inner__header__wrapper">
       
          <div className="chart__user">
          <p className="chart_title">Paid %</p>
          <div className="chart_content">
            <ChangingProgressProvider values={[0, 20, 80]}>
              {(value) => (
                <div style={{ width: "100px", height: "100px" }}>
                <CircularProgressbar
                  value={this.state.paidperc}
                  text={`${this.state.paidperc}%`}
                  circleRatio={0.75}
                  strokeWidth={6}
                  styles={buildStyles({
                    rotation: 1 / 2 + 1 / 8,
                    strokeLinecap: "butt",
                    trailColor: "#041c3c",
                    textSize: "13px",
                    pathTransitionDuration: 0.5,
                    pathColor: `rgba(0, 229, 59, 0.69)`,
                    textColor: "#041c3c",
                    backgroundColor: "#3e98c7",
                  })}
                />
              </div>
              )}
            </ChangingProgressProvider>
            </div>
          </div>
            <div className="chart__user">
              <p className="chart_title">Denied %</p>
              <div
              className="chart_content"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ChangingProgressProvider values={[0, 20, 80]}>
                  {(value) => (
                    <div style={{ width: "100px", height: "100px" }}>
                      <CircularProgressbar
                        value={this.state.deniedperc}
                        text={`${this.state.deniedperc}%`}
                        circleRatio={0.75}
                        strokeWidth={6}
                        styles={buildStyles({
                          rotation: 1 / 2 + 1 / 8,
                          strokeLinecap: "butt",
                          trailColor: "#041c3c",
                          textSize: "18px",
                          pathTransitionDuration: 0.5,
                          pathColor: `rgba(255, 13, 0, 0.8)`,
                          textColor: "#041c3c",
                          backgroundColor: "#3e98c7",
                        })}
                      />
                    </div>
                  )}
                </ChangingProgressProvider>
              </div>
            </div>
            <div className="chart__user">
              <p className="chart_title">Productivity %</p>
              <div
              className="chart_content"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ChangingProgressProvider values={[0, 20, 80]}>
                  {(value) => (
                    <div style={{ width: "100px", height: "100px" }}>
                      <CircularProgressbar
                        value={this.state.productivity}
                        text={`${this.state.productivity}%`}
                        circleRatio={0.75}
                        strokeWidth={6}
                        styles={buildStyles({
                          rotation: 1 / 2 + 1 / 8,
                          strokeLinecap: "butt",
                          trailColor: "#041c3c",
                          textSize: "18px",
                          pathTransitionDuration: 0.5,
                          pathColor: `rgba(0, 255, 113, 1)`,
                          textColor: "#041c3c",
                          backgroundColor: "#3e98c7",
                        })}
                      />
                    </div>
                  )}
                </ChangingProgressProvider>
              </div>
            </div>
          </div>
        </div>
        <div className="user__performance__wrapper__three">
          <div>
            <Row>
              <h6
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingTop: "1%",
                  fontSize: "13px",
                }}
              >
                Worked Claims - Aging Contribution
              </h6>
              <hr style={{width:"96.5%"}}/>
            </Row>
            <Row>
              <Agecharts
                start={this.props.startdate}
                end={this.props.enddate}
              />
            </Row>
          </div>
          <div>
            <Row>
              <h6
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingTop: "1%",
                  fontSize: "14px",
                }}
              >
                Avg Touches for Resolution
              </h6>
              <hr style={{width:"96%"}} />
            </Row>
            <Row>
              <AvgTouches
                start={this.props.startdate}
                end={this.props.enddate}
                client={this.props.client}
              />
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default PerfomanceComponent;
