import React, { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import axios from "axios";
import fileformat from "../../Files/Automation-Sample.xlsx";
import File from "../../Images/folder__file.jpg";
import "../../Styles/data.css";
import "../../Styles/Data.scss";
import { FcCheckmark } from "react-icons/fc";
import Newsidebar from "../Dashboard/Newsidebar";
import { MdOutlineFileUpload } from "react-icons/md";
import CO from "../../Images/imag.jpeg";
import arrow from "../../Images/arrow.gif";
import setting from "../../Images/setting.gif";
import { AiOutlineDownload } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import excel from "../../Images/excel.png";
import Database from "../../Images/Database.png";
import { HiOutlineCollection } from "react-icons/hi";
import { FcDoNotInsert } from "react-icons/fc";
import { BiAddToQueue } from "react-icons/bi";
import { HiOutlineDuplicate } from "react-icons/hi";
import { BiFolderPlus } from "react-icons/bi";
import { VscServerProcess } from "react-icons/vsc";
import { GiDuration } from "react-icons/gi";
import { testUploadIp } from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdRestartAlt } from "react-icons/md";
import { useSocket } from "../Context/socketContext";
const Data = () => {
  const [upload, setUpload] = useState([]);

  const [accesstoken, setAccessToken] = useState(
    sessionStorage.getItem("accesstoken")
  );
  const [client, setClient] = useState(sessionStorage.getItem("clientid"));
  const inputRef = useRef();

  const [fadeClass, setFadeClass] = useState("");
  const {
    setFileName,
    progress,
    setProgress,
    setNotInserted,
    setInserted,
    setTotalCount,
    setDuration,
    roomId,
    setRoomId,
    setDuplicate,
    setQueuing,
    setProgressCount,
    fileName,
    inserted,
    notInserted,
    totalCount,
    duplicate,
    process_count,
    duration,
    queuing,
    uploadStatus,
    setUploadStatus,
    socket,
    uploadSocket,
    isCardVisible,
    setIsCardVisible,
    fileMeta,
    setFileMeta,
    selectedFile,
    setSelectedFile,
  } = useSocket();


  useEffect(() => {
    // Initial call
    getFiles();
    // Load fileMeta and uploadStatus from sessionStorage on component mount
    const storedUploadStatus = sessionStorage.getItem("uploadStatus");
    const storedFileMeta = JSON.parse(sessionStorage.getItem("fileMeta"));
    const storedClientId = parseInt(storedFileMeta?.clientId);

    if (storedClientId === client &&storedFileMeta && storedUploadStatus) {
      setFileMeta(storedFileMeta); // Set file metadata
      setUploadStatus(storedUploadStatus);
      setIsCardVisible(storedUploadStatus !== "select");
    }
  }, [setFileName, setUploadStatus]);

  useEffect(() => {
    if (selectedFile) {
      sessionStorage.setItem("uploadStatus", uploadStatus);
    }
  }, [selectedFile, uploadStatus]);
  useEffect(() => {
    if (uploadStatus === "uploading" || uploadStatus === "done") {
      setFadeClass("fade-in");
    }
  }, [uploadStatus]);

  //funtion to trigger input dialog
  const onChooseFile = () => {
    inputRef.current.click();
  };
  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      try {
        const file = event.target.files[0];
        setSelectedFile(file);
        setFileMeta({ name: file.name, size: file.size, type: file.type });
        // Generate roomId based on the file name and set it in SocketProvider
        const newRoomId = `room_${file.name}`;
        setRoomId(newRoomId);
        uploadSocket.emit("joinRoom", { newRoomId });
        sessionStorage.setItem(
          "fileMeta",
          JSON.stringify({ name: file.name, size: file.size, type: file.type })
        );
        sessionStorage.setItem("roomId", newRoomId);
      } catch (error) {
        console.error("Error handling file:", error);
      }
    }
  };
  const clearFileInput = () => {
    inputRef.current.value = "";
    setSelectedFile(null);
    setFileMeta(null);
    setUploadStatus("select");
    setIsCardVisible(false);
    setProgress(0);
    setNotInserted(0);
    setInserted(0);
    setDuplicate(0);
    setTotalCount(0);
    setDuration(0);
    setFileName("");
    setQueuing(0);
    setProgressCount(0);
    setFadeClass("fade-out");
    sessionStorage.removeItem("fileMeta");
    sessionStorage.removeItem("uploadStatus");
  };

  const handleUpload = async () => {
    if (uploadStatus === "done") {
      clearFileInput();
      return;
    }
    try {
      setUploadStatus("uploading");
      setIsCardVisible(true);
      const fileSize = selectedFile.size;
      console.log(`File size: ${fileSize} bytes`);

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("roomId", roomId);
      formData.append("clientids", parseInt(client));
      const headers = {
        "Content-Type": `multipart/form-data`,
        Authorization: `Bearer ${accesstoken}`,
      };

      const response = await axios.post(`${testUploadIp}/upload/claim/bulk`, formData, {
        headers,
        // This is a upload progress bar when a real time communication is established then implement this logic it will work onUploadProgress: (progressEvent) => {
        //   const percentCompleted = Math.round(
        //     (progressEvent.loaded * 100) / progressEvent.total
        //   );
        //   setProgress(percentCompleted);
        //   console.log(`Upload progress: ${percentCompleted}%`);
        //   console.log(
        //     `Uploaded: ${progressEvent.loaded} of ${progressEvent.total} bytes`
        //   );
        // },
      });

      if (response.status === 200) {
        setUploadStatus("done");
        setIsCardVisible(false);
        toast.success("Claims inserted successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });

        // Call getFiles to update the file list
        getFiles();
      }
    } catch (err) {
      setUploadStatus("select");
      handleUploadError(err);
    }
  };
  // Function to handle server restart
  const handleRestart = async () => {
    toast.info("Restarting the server..."); // Display an info toast initially
    try {
      // Make the API call to restart the server
      const response = await axios.post(`${testUploadIp}/api/restart-server`);

      if (response.status === 201) {
        toast.success("Server restarted successfully!");
      } else {
        toast.error("Failed to restart the server.");
      }
    } catch (error) {
      console.error("Error restarting the server:", error);
      toast.error("Error restarting the server.");
    }
  };
  const handleUploadError = (err) => {
    const toastOptions = {
      position: "top-right",
      autoClose: false,
      closeOnClick: true,
      pauseOnHover: true,
      dSampleraggable: true,
      closeButton: true,
    };

    if (err.response) {
      if (err.response.status === 500) {
        toast.error("Server Error", toastOptions);
      } else if (err.response.status === 401) {
        toast.error("Please reload and try again", toastOptions);
      } else if (err.response.status === 400) {
        toast.error(
          "Please include all fields in the Excel data",
          toastOptions
        );
      } else {
        toast.error("An unknown error occurred", toastOptions);
      }
    }
  };
  const getFiles = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    try {
      const response = await axios.get(
        `${testUploadIp}/upload/getuploaddetails?clientId=${client}`,
        { headers }
      );
      const sortedData = response.data.data.sort(
        (a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt)
      );
      setUpload(sortedData);
    } catch (err) {
      console.error("Error fetching files:", err);
    }
  };
  const downloadData = (objectId) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };

    let filename = `Not_Inserted_Claims`;
    let url = `${testUploadIp}/upload/getuploaddetails?clientId=${client}&button=0&objectid=${objectId}`;

    fetch(url, { headers })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => {
        console.error("Error downloading data:", err);
      });
  };
  const downloadCombinedData = (objectId) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };

    let filename = `Combined_Report`;
    let url = `${testUploadIp}/report/combainedreportforuploads?clientId=${client}&button=0&id=${objectId}`;

    fetch(url, { headers })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => {
        console.error("Error downloading data:", err);
      });
  };
  return (
    <div>
      <Newsidebar name="Data Upload" progress={progress} />
      <div className="align-row">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="align-row items">
          <div
            className={`fileUpload__container ${
              isCardVisible ? "show-data-up" : "hide-data-up"
            }`}
          >
            <div
              className={`upload_container ${
                isCardVisible
                  ? "slide-left"
                  : uploadStatus === "done"
                  ? "slide-center"
                  : ""
              }`}
            >
              <input
                ref={inputRef}
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              {fileMeta ? (
                <div className="file-card-upload">
                  <div className="file-card">
                    <span>
                      <img
                        src={excel}
                        alt="excel"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </span>
                    <div className="file-info">
                      <div style={{ flex: 1 }}>
                        <h6>
                          {fileMeta.name}
                        </h6>
                        <div
                          className="progress-bar1"
                          style={{ width: `${progress}%` }}
                        />
                      </div>
                      {uploadStatus === "select" ? (
                        <button onClick={clearFileInput}>
                          <span className="close-icon">
                            <IoMdClose />
                          </span>
                        </button>
                      ) : (
                        <div className="check-circle">
                          {uploadStatus === "uploading" ? (
                            `${progress}%`
                          ) : uploadStatus === "done" ? (
                            <span>
                              <FcCheckmark style={{ fontSize: "20px" }} />
                            </span>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>

                  <button className="upload_button" onClick={handleUpload}>
                    {uploadStatus === "select" || uploadStatus === "uploading"
                      ? "Upload"
                      : "Done"}
                  </button>
                </div>
              ) : (
                <button className="file-btn" onClick={onChooseFile}>
                  <span className="file-circle">
                    <MdOutlineFileUpload />
                  </span>
                  Click file to this area to upload <br />
                  <span style={{ color: "GrayText", fontSize: "11px" }}>
                    The given input should be in .xlsx format
                  </span>
                </button>
              )}
            </div>
            <div
              className={`card__change ${
                isCardVisible
                  ? "show-card"
                  : uploadStatus === "done"
                  ? "hide-upload-card"
                  : "hide-upload-card"
              }`}
            >
              <div className="card__uploading">
                <div>
                  <span className="msg">File Upload</span>
                  <div className="row__data">
                    <div className="column__data">
                      <img
                        height="60px"
                        width="60px"
                        src={File}
                        style={{ marginRight: "2%" }}
                        alt="notfound"
                      ></img>
                    </div>
                    <div className="column__data">
                      <img
                        src={arrow}
                        style={{
                          height: "70px",
                          width: "80px",
                          top: "4px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="box__one arrow-right">
                    2500++ Insurance Data Source
                  </div>
                </div>
                <div>
                  <img
                    height="110px"
                    width="365px"
                    src={CO}
                    alt="notfound"
                  ></img>
                  <span className="msg-one">
                    Fetching the claim status and payment details from the
                    insurance.
                  </span>
                </div>
                <div>
                  <span className="msg">
                    OBO Algorithms, preparing insights, dashboards, patterns
                  </span>

                  <div>
                    <img
                      height="155px"
                      width="150px"
                      src={setting}
                      alt="notfound"
                      style={{ marginBottom: "-10px" }}
                    ></img>
                  </div>
                </div>
                <div>
                  <span className="msg">
                    OBO NLP algorithm evaluating the status
                    <br />
                    to prepare the suggested action and automating the notation
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <img
                        height="70px"
                        width="70px"
                        src={Database}
                        alt="notfound"
                      />
                    </div>
                    <div className="animation-data">
                      <div className="item-data">
                        <div className="line-data"></div>
                        <div className="dot-data"></div>
                        <div className="circle-data"></div>
                      </div>
                      <div className="item-data">
                        <div className="line-data"></div>
                        <div className="dot-data"></div>
                        <div className="circle-data"></div>
                      </div>
                      <div className="item-data">
                        <div className="line-data"></div>
                        <div className="dot-data"></div>
                        <div className="circle-data"></div>
                      </div>
                      <div className="item-data">
                        <div className="line-data"></div>
                        <div className="dot-data"></div>
                        <div className="circle-data"></div>
                      </div>
                      <div className="item-data -type2-data">
                        <div className="line-data"></div>
                        <div className="dot-data"></div>
                        <div className="circle-data"></div>
                      </div>
                      <div className="item-data -type2-data">
                        <div className="line-data"></div>
                        <div className="dot-data"></div>
                        <div className="circle-data"></div>
                      </div>
                      <div className="item-data -type2-data">
                        <div className="line-data"></div>
                        <div className="dot-data"></div>
                        <div className="circle-data"></div>
                      </div>
                      <div className="item-data -type2-data">
                        <div className="line-data"></div>
                        <div className="dot-data"></div>
                        <div className="circle-data"></div>
                      </div>
                      <div className="center-data">
                        <div className="circle-data"></div>
                        <div className="circle-data"></div>
                        <div className="circle-data"></div>
                      </div>
                    </div>

                    <div>
                      <img
                        height="70px"
                        width="70px"
                        src={Database}
                        alt="notfound"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`current_status ${
            isCardVisible ? fadeClass : "hide-upload-status"
          }`}
        >
          <div className="split-layout">
            <div className="split-layout__item">
              <span>File Name</span>
              <p>{fileName}</p>
            </div>

            <div className="split-layout__divider">
              <div className="split-layout__rule"></div>
              <div className="data_icon">
                <HiOutlineCollection
                  style={{
                    width: "35px",
                    height: "35px",
                    padding: "10px",
                    borderRadius: "10px",
                    float: "right",
                    color: "#3944bc",
                    backgroundColor: "#faf6f3",
                  }}
                />
              </div>
              <div className="split-layout__rule"></div>
            </div>

            <div className="split-layout__item">
              <span>Inserted</span>
              <p>{inserted}</p>
            </div>
            <div className="split-layout__divider">
              <div className="split-layout__rule"></div>
              <div className="data_icon">
                <FcDoNotInsert
                  style={{
                    width: "35px",
                    height: "35px",
                    padding: "10px",
                    borderRadius: "10px",
                    float: "right",
                    color: "#3944bc",
                    backgroundColor: "#faf6f3",
                  }}
                />
              </div>
              <div className="split-layout__rule"></div>
            </div>
            <div className="split-layout__item">
              <span>Not Inserted</span>
              <p>{notInserted}</p>
            </div>
            <div className="split-layout__divider">
              <div className="split-layout__rule"></div>
              <div className="data_icon">
                <BiAddToQueue
                  style={{
                    width: "35px",
                    height: "35px",
                    padding: "10px",
                    borderRadius: "10px",
                    float: "right",
                    color: "#3944bc",
                    backgroundColor: "#faf6f3",
                  }}
                />
              </div>
              <div className="split-layout__rule"></div>
            </div>
            <div className="split-layout__item">
              <span>Queuing</span>
              <p>{queuing}</p>
            </div>
            <div className="split-layout__divider">
              <div className="split-layout__rule"></div>
              <div className="data_icon">
                <HiOutlineDuplicate
                  style={{
                    width: "35px",
                    height: "35px",
                    padding: "10px",
                    borderRadius: "10px",
                    float: "right",
                    color: "#3944bc",
                    backgroundColor: "#faf6f3",
                  }}
                />
              </div>
              <div className="split-layout__rule"></div>
            </div>
            <div className="split-layout__item">
              <span>Duplicate</span>
              <p>{duplicate}</p>
            </div>
            <div className="split-layout__divider">
              <div className="split-layout__rule"></div>
              <div className="data_icon">
                <BiFolderPlus
                  style={{
                    width: "35px",
                    height: "35px",
                    padding: "10px",
                    borderRadius: "10px",
                    float: "right",
                    color: "#3944bc",
                    backgroundColor: "#faf6f3",
                  }}
                />
              </div>
              <div className="split-layout__rule"></div>
            </div>
            <div className="split-layout__item">
              <span>Total Count</span>
              <p>{totalCount}</p>
            </div>
            <div className="split-layout__divider">
              <div className="split-layout__rule"></div>
              <div className="data_icon">
                <VscServerProcess
                  style={{
                    width: "35px",
                    height: "35px",
                    padding: "10px",
                    borderRadius: "10px",
                    float: "right",
                    color: "#3944bc",
                    backgroundColor: "#faf6f3",
                  }}
                />
              </div>
              <div className="split-layout__rule"></div>
            </div>
            <div className="split-layout__item">
              <span>Processed Count</span>
              <p>{process_count}</p>
            </div>
            <div className="split-layout__divider">
              <div className="split-layout__rule"></div>
              <div className="data_icon">
                <GiDuration
                  style={{
                    width: "35px",
                    height: "35px",
                    padding: "10px",
                    borderRadius: "10px",
                    float: "right",
                    color: "#3944bc",
                    backgroundColor: "#faf6f3",
                  }}
                />
              </div>
              <div className="split-layout__rule"></div>
            </div>
            <div className="split-layout__item">
              <span>Duration</span>
              <p>{duration}</p>
            </div>
          </div>
        </div>
        <div className="upload_container1">
          <div className="upload_head">
            <div className="vertical" />
            <span style={{ fontWeight: "800", fontSize: "18px" }}>
              Bulk Upload History
            </span>
          </div>
          <div
            className="btn-group"
            role="group"
            style={{ boxShadow: "0px 0px 6px rgb(124 119 119 / 50%)" }}
          >
            <a
              style={{ textDecoration: "none", color: "white" }}
              href={fileformat}
              download="claimdata.xlsx"
              target="_blank"
            >
              <button type="button" className="btn btn-danger">
                <AiOutlineDownload style={{ fontSize: "15px" }} />
                &nbsp;&nbsp;Sample
              </button>
            </a>
            <button type="button" className="btn" onClick={handleRestart}>
              <MdRestartAlt style={{ fontSize: "16px" }} />
              &nbsp;&nbsp; Restart
            </button>
          </div>
        </div>
        <hr style={{ margin: "1%" }} />
        <div className="table-wrapper">
          <table bordered="true">
            <thead>
              <tr className="sticky_upload">
                <th className="sticky_upload">File Name</th>
                <th className="sticky_upload">Created AT</th>
                <th className="sticky_upload">Processed Time</th>
                <th className="sticky_upload">Machine</th>
                <th className="sticky_upload">Request Type</th>
                <th className="sticky_upload">Success Record</th>
                <th className="sticky_upload">Failed Record</th>
                <th className="sticky_upload">Duplicate Record</th>
                <th className="sticky_upload">Queuing</th>
                <th className="sticky_upload">Progress</th>
                <th className="sticky_upload">Action</th>
              </tr>
            </thead>
            <tbody>
              {upload.map((data, index) => (
                <tr key={index}>
                  <td
                    style={{
                      color: "#28a745",
                      fontWeight: "500",
                    }}
                  >
                    {data.filename}
                  </td>
                  <td>{data.CreatedAt}</td>
                  <td>{data.processedTime}</td>
                  <td>
                    {data.channel ? (
                      <button
                        style={{
                          color: "#39739d",
                          backgroundColor: "#e1ecf4",
                          border: "1px solid #7aa7c7",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {data.channel}
                      </button>
                    ) : (
                      <button
                        style={{
                          color: "#39739d",
                          backgroundColor: "#e1ecf4",
                          border: "1px solid #7aa7c7",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {"-"}
                      </button>
                    )}
                  </td>
                  <td>
                    {data.requestType ? (
                      <button
                        className="request_btn"
                        style={{
                          color: "#7469B6",
                          backgroundColor: "#FFE6E6",
                          border: "1px solid #7469B6",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {data.requestType}
                      </button>
                    ) : (
                      <button
                        className="request_btn"
                        style={{
                          color: "#7469B6",
                          backgroundColor: "#FFE6E6",
                          border: "1px solid #7469B6",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {"-"}
                      </button>
                    )}
                  </td>
                  <td>
                    {data.successRecord ? (
                      <button
                        style={{
                          color: "#588247",
                          backgroundColor: "#e1fdd5",
                          border: "1px solid #72a45d",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {data.successRecord}
                      </button>
                    ) : (
                      <button
                        style={{
                          color: "#588247",
                          backgroundColor: "#e1fdd5",
                          border: "1px solid #72a45d",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        0
                      </button>
                    )}
                  </td>
                  <td>
                    {data.failedRecord ? (
                      <button
                        style={{
                          color: "#00ABB3",
                          backgroundColor: "#EFFFFD",
                          border: "1px solid #7aa7c7",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {data.failedRecord}
                      </button>
                    ) : (
                      <button
                        style={{
                          color: "#00ABB3",
                          backgroundColor: "#EFFFFD",
                          border: "1px solid #7aa7c7",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        0
                      </button>
                    )}
                  </td>
                  <td>
                    {data.duplicate ? (
                      <button
                        style={{
                          color: "#820000",
                          backgroundColor: "#FEE0C0",
                          border: "1px solid #FF7C7C",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {data.duplicate}
                      </button>
                    ) : (
                      <button
                        style={{
                          color: "#820000",
                          backgroundColor: "#FEE0C0",
                          border: "1px solid #FF7C7C",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        0
                      </button>
                    )}
                  </td>
                  <td>
                    {data.queuing ? (
                      <button
                        style={{
                          color: "#7743DB",
                          backgroundColor: "#FFF6F6",
                          border: "1px solid #C3ACD0",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {data.queuing}
                      </button>
                    ) : (
                      <button
                        style={{
                          color: "#7743DB",
                          backgroundColor: "#FFF6F6",
                          border: "1px solid #C3ACD0",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        0
                      </button>
                    )}
                  </td>
                  <td>
                    {data.ProcessedCount_TotalRecords ? (
                      <button
                        style={{
                          color: "#39739d",
                          backgroundColor: "#e1ecf4",
                          border: "1px solid #7aa7c7",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {data.ProcessedCount_TotalRecords}
                      </button>
                    ) : (
                      <button
                        style={{
                          color: "#39739d",
                          backgroundColor: "#e1ecf4",
                          border: "1px solid #7aa7c7",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        0
                      </button>
                    )}
                  </td>
                  <td className="sticky_upload">
                    <div
                      className="btn-group"
                      role="group"
                      style={{
                        boxShadow: "0px 0px 6px rgb(124 119 119 / 50%)",
                      }}
                    >
                      <button
                        onClick={() => downloadData(data.id)}
                        className="btn btn-danger"
                        style={{ fontSize: "10px" }}
                      >
                        <AiOutlineDownload style={{ fontSize: "14px" }} />
                        &nbsp;&nbsp;Not Inserted
                      </button>
                      <button
                        onClick={() => downloadCombinedData(data.id)}
                        className="btn"
                        style={{
                          background: "#FFF1DB",
                          fontSize: "10px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <AiOutlineDownload style={{ fontSize: "14px" }} />
                        &nbsp;&nbsp;Combined Report
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Data;
