// src/socket.js
import { io } from 'socket.io-client';
import { checkHealth, ip } from './Api';


const SOCKET_URL = ip; // Your socket server URL

export const socket = io(SOCKET_URL, {
  reconnection: false, // Disable auto-reconnection to handle it manually
});

export const listenToSocketEvents = (onError) => {
  // Log connection established
  socket.on('connect', () => {
    console.log('Socket connection established');
  });

  // Log connection error
  socket.on('error', async (error) => {
    console.error('Socket connection error:', error);
    onError('Socket connection failed.');
    
    try {
      const healthStatus = await checkHealth();
      if (healthStatus.status === 'up') {
        console.log('Server is up, but socket connection failed.');
      } else {
        console.log('Server is down.');
      }
    } catch (e) {
      console.error('Unable to check server health:', e);
    }
  });

  // Log connection timeout
  socket.on('connect_timeout', (timeout) => {
    console.error('Socket connection timeout:', timeout);
  });

  // Log disconnect
  socket.on('disconnect', (reason) => {
    console.log('Socket disconnected. Reason:', reason);
  });
};

export const disconnectSocket = () => {
  socket.disconnect();
};
