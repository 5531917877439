import { React, Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import axios from "axios";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import { Table } from "react-bootstrap";
import "../../Styles/role.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { ip } from ".././Api";
class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      addnewmodal: false,
      successmodal: false,
      updatemodal: false,
      deletemodal: false,
      dataforupdate: null,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role: [],
      role_name: "",
      role_status: "",
      update_management_access: "",
      update_transaction_access: "",
      update_data_access: "",
      update_ai_bot_access: "",
      update_performance_access: "",
      update_action_access: "",
      update_admin_access: "",
      update_configure_access: "",
      update_allocation_access: "",
      update_role_access: "",
      update_workqueue_access: "",
      update_eligibility_access: "",
      singleid: "",
      management_access: false,
      Inventory_Analysis: false,
      outcomes: false,
      Actions: false,
      IndustryStandards: false,
      UserPerformanceDashboard: false,
      trendsandpatterns: false,
      Reports: false,
      ClaimQuickCheck: false,
      transaction_access: false,
      KeyIndicators: false,
      denialmanagement: false,
      claimmanagement: false,
      automationtrack: false,
      data_access: false,
      Databasesync: false,
      Apisync: false,
      Datauploadfile: false,
      Datadeletefile: false,
      Dataconversionocr: false,
      Datacleaning: false,
      Datamigration: false,
      ai_bot_access: false,
      collectionprediction: false,
      Denialprediction: false,
      Overallprediction: false,
      AiAssitant: false,
      workqueue: false,
      allocation_access: false,
      Allocated: false,
      UnAllocated: false,
      RejectionAndDenials: false,
      configure: false,
      usermanagement: false,
      useronboard: false,
      Roles: false,
      eligibility: false,
      eligibilitydataupload: false,
      eligibilityquickcheck: false,
      UploadAuth: false,
      PriorAuthTracker: false,
      Performances: false,
      Completed: false,
      Inprogress: false,
      Denial: false,
      NeedToCall: false,
      Pending: false,
      workqueue1: false,
      Rejection1: false,
      ClaimTracker: false,
      openClaims: false,
      closedClaims: false,
      Update_management_access: "",
      Update_Inventory_Analysis: "",
      Update_outcomes: "",
      Update_Actions: "",
      Update_IndustryStandards: "",
      Update_UserPerformanceDashboard: "",
      Update_trendsandpatterns: "",
      Update_Reports: "",
      Update_ClaimQuickCheck: "",
      Update_transaction_access: "",
      Update_KeyIndicators: "",
      Update_denialmanagement: "",
      Update_claimmanagement: "",
      Update_automationtrack: "",
      Update_data_access: "",
      Update_Databasesync: "",
      Update_Apisync: "",
      Update_Datauploadfile: "",
      Update_Datadeletefile: "",
      Update_Dataconversionocr: "",
      Update_Datacleaning: "",
      Update_Datamigration: "",
      Update_ai_bot_access: "",
      Update_collectionprediction: "",
      Update_Denialprediction: "",
      Update_Overallprediction: "",
      Update_AiAssitant: "",
      Update_workqueue: "",
      Update_allocation: "",
      Update_Allocated: "",
      Update_UnAllocated: "",
      Update_payercategory: "",
      Update_RejectionAndDenials: "",
      Update_configure: "",
      Update_usermanagement: "",
      Update_useronboard: "",
      Update_Roles: "",
      Update_eligibility: "",
      Update_eligibilitydataupload: "",
      Update_eligibilityquickcheck: "",
      Update_UploadAuth: "",
      Update_PriorAuthTracker: "",
      Update_Performances: "",
      Update_Completed: "",
      Update_Inprogress: "",
      Update_Denial: "",
      Update_NeedToCall: "",
      Update_Pending: "",
      Update_workqueue1: "",
      Update_Rejection1: "",
      Update_ClaimTracker: "",
      Update_openClaims: "",
      Update_closedClaims: "",
      eligibilityTracker: false,
      Update_eligibilitytracker: "",
      claimwiseReports: false,
      cptwiseReports: false,
      dailyprodReports: false,
      aglogReports: false,
      openclaimsReports: false,
      claimwiseprodReports: false,
      closedReports: false,
      CombinedReports: false,
      Update_claimwiseReports: "",
      Update_cptwiseReports: "",
      Update_dailyprodReports: "",
      Update_aglogReports: "",
      Update_openclaimsReports: "",
      Update_claimwiseprodReports: "",
      Update_closedReports: "",
      Update_CombinedReports: "",
      Update_username: "",
      payercategory: false,
      payermaster: false,
      primarypayer: false,
      cpt: false,
      modifiers: false,
      aging: false,
      denialmaster: false,
      billingprovider: false,
      userDetails: false,
      AdvBillingProvider: false,
      claimstatus: false,
      claimcategory: false,
      claimsubcategory: false,
      actioncode: false,
      actionstatuscode: false,
      clientnotes: false,
      location: false,
      ICD: false,
      RefProvider: false,
      GeneralSettings: false,
      providerdetails: false,
      notetypes: false,
      target: false,
      Update_target: "",
      Update_payermaster: "",
      Update_primarypayer: "",
      Update_cpt: "",
      Update_modifiers: "",
      Update_aging: "",
      Update_denialmaster: "",
      Update_billingprovider: "",
      Update_userDetails: "",
      Update_AdvBillingProvider: "",
      Update_claimstatus: "",
      Update_claimcategory: "",
      Update_claimsubcategory: "",
      Update_actioncode: "",
      Update_actionstatuscode: "",
      Update_clientnotes: "",
      Update_location: "",
      Update_ICD: "",
      Update_RefProvider: "",
      Update_GeneralSettings: "",
      Update_providerdetails: "",
      Update_notetypes: "",
      arlogwiseReports:false,
    };
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  //toggle modal for update
  toggleupdate = () => {
    this.setState({ updatemodal: !this.state.updatemodal });
  };
  openmodal = () => {
    this.setState({ addnewmodal: !this.state.addnewmodal });
  };
  // fuction for prepopulate the particular data
  settingupdatestate = function () {
    this.setState(
      {
        Update_management_access: this.state.dataforupdate.management,
        Update_transaction_access: this.state.dataforupdate.transaction,
        Update_data_access: this.state.dataforupdate.data,
        Update_ai_bot_access: this.state.dataforupdate.AIPrediction,
        Update_performance_access: this.state.dataforupdate.performance,
        Update_action_access: this.state.dataforupdate.action,
        Update_admin_access: this.state.dataforupdate.admin,
        Update_configure_access: this.state.dataforupdate.configure,
        Update_allocation_access: this.state.dataforupdate.allocation_access,
        Update_role_access: this.state.dataforupdate.role,
        singleid: this.state.dataforupdate._id,
        Update_workqueue_access: this.state.dataforupdate.workqueue,
        Update_eligibility_access: this.state.dataforupdate.eligibility,
        Update_Inventory_Analysis: this.state.dataforupdate.inventory,
        Update_outcomes: this.state.dataforupdate.outcome,
        Update_Actions: this.state.dataforupdate.action,
        Update_IndustryStandards: this.state.dataforupdate.industryStandards,
        Update_UserPerformanceDashboard:
          this.state.dataforupdate.adminUserperformanceDashboard,
        Update_trendsandpatterns: this.state.dataforupdate.trendsAndPatterns,
        Update_Reports: this.state.dataforupdate.reports,
        Update_ClaimQuickCheck: this.state.dataforupdate.claimQuickCheck,
        Update_transaction_access: this.state.dataforupdate.claims,
        Update_KeyIndicators: this.state.dataforupdate.keyIndicators,
        Update_denialmanagement: this.state.dataforupdate.denialManagement,
        Update_claimmanagement: this.state.dataforupdate.claimManagement,
        Update_automationtrack: this.state.dataforupdate.automationTracker,
        Update_data_access: this.state.dataforupdate.dataProcessing,
        Update_Databasesync: this.state.dataforupdate.databaseSync,
        Update_Apisync: this.state.dataforupdate.apiSync,
        Update_Datauploadfile: this.state.dataforupdate.dataUploadFile,
        Update_Datadeletefile: this.state.dataforupdate.dataDeleteFile,
        Update_Dataconversionocr: this.state.dataforupdate.dataConversionOcr,
        Update_Datacleaning: this.state.dataforupdate.dataCleaning,
        Update_Datamigration: this.state.dataforupdate.dataMigration,
        Update_collectionprediction:
          this.state.dataforupdate.collectionPredictionDashboard,
        Update_Denialprediction:
          this.state.dataforupdate.denialPredictionDashboard,
        Update_Overallprediction: this.state.dataforupdate.overallPrediction,
        Update_AiAssitant: this.state.dataforupdate.AIAssistant,
        Update_workqueue: this.state.dataforupdate.workqueue,
        Update_allocation: this.state.dataforupdate.allocation,
        Update_Allocated: this.state.dataforupdate.allocated,
        Update_UnAllocated: this.state.dataforupdate.unallocated,
        Update_RejectionAndDenials:
          this.state.dataforupdate.rejectionAndDenials,
        Update_configure: this.state.dataforupdate.configure,
        Update_usermanagement: this.state.dataforupdate.userManagement,
        Update_useronboard: this.state.dataforupdate.useronboard,
        Update_Roles: this.state.dataforupdate.role,
        Update_eligibility: this.state.dataforupdate.eligibility,
        Update_eligibilitydataupload:
          this.state.dataforupdate.eligibilityDataupload,
        Update_eligibilitytracker: this.state.dataforupdate.eligibilityTracker,
        Update_eligibilityquickcheck:
          this.state.dataforupdate.eligibilityQuickcheck,
        Update_UploadAuth:
          this.state.dataforupdate.eligibilityUploadAuthorization,
        Update_PriorAuthTracker:
          this.state.dataforupdate.eligibilityPriorauthTracker,
        Update_Performances: this.state.dataforupdate.userPerformanceDashboard,
        Update_Completed: this.state.dataforupdate.completed,
        Update_Inprogress: this.state.dataforupdate.inprogress,
        Update_Denial: this.state.dataforupdate.denial,
        Update_NeedToCall: this.state.dataforupdate.needToCall,
        Update_Pending: this.state.dataforupdate.pending,
        Update_workqueue1: this.state.dataforupdate.userWorkqueue,
        Update_Rejection1: this.state.dataforupdate.userRejectionAndDenials,
        Update_ClaimTracker: this.state.dataforupdate.claimTracker,
        Update_openClaims: this.state.dataforupdate.claimtrackerOpenclaims,
        Update_closedClaims: this.state.dataforupdate.claimtrackerClosedclaims,
        Update_claimwiseReports: this.state.dataforupdate.claimwiseReport,
        Update_cptwiseReports: this.state.dataforupdate.cptwiseReport,
        Update_dailyprodReports: this.state.dataforupdate.dailyProductionReport,
        Update_aglogReports: this.state.dataforupdate.arlogwiseReport,
        Update_openclaimsReports: this.state.dataforupdate.openClaimsReport,
        Update_claimwiseprodReports:
          this.state.dataforupdate.claimwiseProductionReport,
        Update_closedReports: this.state.dataforupdate.closedCPT,
        Update_CombinedReports: this.state.dataforupdate.combinedReport,
        Update_username: this.state.dataforupdate.role_name,
        Update_payercategory: this.state.dataforupdate.configurePayerCategory,
        Update_payermaster: this.state.dataforupdate.configurePayerMaster,
        Update_primarypayer: this.state.dataforupdate.configurePrimaryPayer,
        Update_cpt: this.state.dataforupdate.configureCpt,
        Update_modifiers: this.state.dataforupdate.configureModifiers,
        Update_aging: this.state.dataforupdate.configureAging,
        Update_denialmaster: this.state.dataforupdate.configureDenialMaster,
        Update_billingprovider:
          this.state.dataforupdate.configureBillingProvider,
        Update_userDetails: this.state.dataforupdate.configureUserDetails,
        Update_AdvBillingProvider:
          this.state.dataforupdate.configureAdvancedBillingProvider,
        Update_claimstatus: this.state.dataforupdate.configureClaimStatus,
        Update_claimcategory: this.state.dataforupdate.configureClaimCategory,
        Update_claimsubcategory:
          this.state.dataforupdate.configureClaimSubCategory,
        Update_actioncode: this.state.dataforupdate.configureActionCodes,
        Update_actionstatuscode:
          this.state.dataforupdate.configureActionStatusCodes,
        Update_clientnotes: this.state.dataforupdate.configureClientNotes,
        Update_location: this.state.dataforupdate.configureLocation,
        Update_ICD: this.state.dataforupdate.configureIcd,
        Update_RefProvider: this.state.dataforupdate.configureReferralProvider,
        Update_GeneralSettings:
          this.state.dataforupdate.configureGeneralSettings,
        Update_providerdetails:
          this.state.dataforupdate.configureReferralProvider,
        Update_notetypes: this.state.dataforupdate.configureNotesTypes,
        Update_target: this.state.dataforupdate.target,
      },
      (res) => {}
    );
  };
  successbox = () => {
    this.setState({
      successmodal: !this.state.successmodal,
    });
  };
  putrequests = async (dataid) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const data = {
      inventory: this.state.Update_Inventory_Analysis,
      outcome: this.state.Update_outcomes,
      industryStandards: this.state.Update_IndustryStandards,
      trendsAndPatterns: this.state.Update_trendsandpatterns,
      adminUserperformanceDashboard: this.state.Update_UserPerformanceDashboard,
      action: this.state.Update_Actions,
      reports: this.state.Update_Reports,
      configure: this.state.Update_configure,
      role: this.state.Update_Roles,
      useronboard: this.state.Update_useronboard,
      workqueue: this.state.Update_workqueue,
      keyIndicators: this.state.Update_KeyIndicators,
      denialManagement: this.state.Update_denialmanagement,
      claimManagement: this.state.Update_claimmanagement,
      automationTracker: this.state.Update_automationtrack,
      databaseSync: this.state.Update_Databasesync,
      apiSync: this.state.Update_Apisync,
      dataUploadFile: this.state.Update_Datauploadfile,
      dataDeleteFile: this.state.Update_Datadeletefile,
      dataConversionOcr: this.state.Update_Dataconversionocr,
      dataCleaning: this.state.Update_Datacleaning,
      dataMigration: this.state.Update_Datamigration,
      collectionPredictionDashboard: this.state.Update_collectionprediction,
      denialPredictionDashboard: this.state.Update_Denialprediction,
      overallPrediction: this.state.Update_Overallprediction,
      AIAssistant: this.state.Update_AiAssitant,
      allocated: this.state.Update_Allocated,
      unallocated: this.state.Update_UnAllocated,
      rejectionAndDenials: this.state.Update_RejectionAndDenials,
      eligibilityDataupload: this.state.Update_eligibilitydataupload,
      eligibilityTracker: this.state.Update_eligibilitytracker,
      eligibilityQuickcheck: this.state.Update_eligibilityquickcheck,
      eligibilityUploadAuthorization: this.state.Update_UploadAuth,
      eligibilityPriorauthTracker: this.state.Update_PriorAuthTracker,
      userPerformanceDashboard: this.state.Update_Performances,
      completed: this.state.Update_Completed,
      inprogress: this.state.Update_Inprogress,
      denial: this.state.Update_Denial,
      needToCall: this.state.Update_NeedToCall,
      pending: this.state.Update_Pending,
      userWorkqueue: this.state.Update_workqueue1,
      userRejectionAndDenials: this.state.Update_Rejection1,
      claimtrackerOpenclaims: this.state.Update_openClaims,
      claimtrackerClosedclaims: this.state.Update_closedClaims,
      management: this.state.Update_management_access,
      claims: this.state.Update_transaction_access,
      dataProcessing: this.state.Update_data_access,
      AIPrediction: this.state.Update_ai_bot_access,
      allocation_access: this.state.Update_allocation,
      userManagement: this.state.Update_usermanagement,
      eligibility: this.state.Update_eligibility,
      claimTracker: this.state.Update_ClaimTracker,
      claimQuickCheck: this.state.Update_ClaimQuickCheck,
      claimwiseReport: this.state.Update_claimwiseReports,
      cptwiseReport: this.state.Update_cptwiseReports,
      arlogwiseReport: this.state.Update_aglogReports,
      dailyProductionReport: this.state.Update_dailyprodReports,
      openClaimsReport: this.state.Update_openclaimsReports,
      claimwiseProductionReport: this.state.Update_claimwiseprodReports,
      closedCPT: this.state.Update_closedReports,
      combinedReport: this.state.Update_CombinedReports,
      configurePayerCategory: this.state.Update_payercategory,
      configurePayerMaster: this.state.Update_payermaster,
      configurePrimaryPayer: this.state.Update_primarypayer,
      configureCpt: this.state.Update_cpt,
      configureModifiers: this.state.Update_modifiers,
      configureAging: this.state.Update_aging,
      configureDenialMaster: this.state.Update_denialmaster,
      configureBillingProvider: this.state.Update_billingprovider,
      configureUserDetails: this.state.Update_userDetails,
      configureAdvancedBillingProvider: this.state.Update_AdvBillingProvider,
      configureClaimStatus: this.state.Update_claimstatus,
      configureClaimCategory: this.state.Update_claimcategory,
      configureClaimSubCategory: this.state.Update_claimsubcategory,
      configureActionCodes: this.state.Update_actioncode,
      configureActionStatusCodes: this.state.Update_actionstatuscode,
      configureClientNotes: this.state.Update_clientnotes,
      configureLocation: this.state.Update_location,
      configureIcd: this.state.Update_ICD,
      configureReferralProvider: this.state.Update_RefProvider,
      configureGeneralSettings: this.state.Update_GeneralSettings,
      configureProviderDetails: this.state.Update_providerdetails,
      configureNotesTypes: this.state.Update_notetypes,
      target: this.state.Update_target,
    };

    const res = await axios
      .put(`${ip}/role/${dataid}`, data, {
        headers: headers,
      })
      .then((res) => {
        this.successbox();
        this.toggleupdate();
        this.getusers();
      })
      .catch((err) => {});
  };
  addRole = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    const value = {
      role_name: this.state.role_name,
      status: this.state.role_status,
      inventory: this.state.Inventory_Analysis,
      outcome: this.state.outcomes,
      industryStandards: this.state.IndustryStandards,
      trendsAndPatterns: this.state.trendsandpatterns,
      adminUserperformanceDashboard: this.state.UserPerformanceDashboard,
      action: this.state.Actions,
      reports: this.state.Reports,
      configure: this.state.configure,
      role: this.state.Roles,
      useronboard: this.state.useronboard,
      workqueue: this.state.workqueue,
      keyIndicators: this.state.KeyIndicators,
      denialManagement: this.state.denialmanagement,
      claimManagement: this.state.claimmanagement,
      automationTracker: this.state.automationtrack,
      databaseSync: this.state.Databasesync,
      apiSync: this.state.Apisync,
      dataUploadFile: this.state.Datauploadfile,
      dataDeleteFile: this.state.Datadeletefile,
      dataConversionOcr: this.state.Dataconversionocr,
      dataCleaning: this.state.Datacleaning,
      dataMigration: this.state.Datamigration,
      collectionPredictionDashboard: this.state.collectionprediction,
      denialPredictionDashboard: this.state.Denialprediction,
      overallPrediction: this.state.Overallprediction,
      AIAssistant: this.state.AiAssitant,
      allocated: this.state.Allocated,
      unallocated: this.state.UnAllocated,
      rejectionAndDenials: this.state.RejectionAndDenials,
      eligibilityDataupload: this.state.eligibilitydataupload,
      eligibilityTracker: this.state.eligibilityTracker,
      eligibilityQuickcheck: this.state.eligibilityquickcheck,
      eligibilityUploadAuthorization: this.state.UploadAuth,
      eligibilityPriorauthTracker: this.state.PriorAuthTracker,
      userPerformanceDashboard: this.state.Performances,
      completed: this.state.Completed,
      inprogress: this.state.Inprogress,
      denial: this.state.Denial,
      needToCall: this.state.NeedToCall,
      pending: this.state.Pending,
      userWorkqueue: this.state.workqueue1,
      userRejectionAndDenials: this.state.Rejection1,
      claimtrackerOpenclaims: this.state.openClaims,
      claimtrackerClosedclaims: this.state.closedClaims,
      management: this.state.management_access,
      claims: this.state.transaction_access,
      dataProcessing: this.state.data_access,
      AIPrediction: this.state.ai_bot_access,
      allocation_access: this.state.allocation_access,
      userManagement: this.state.usermanagement,
      eligibility: this.state.eligibility,
      claimTracker: this.state.ClaimTracker,
      claimQuickCheck: this.state.ClaimQuickCheck,
      claimwiseReport: this.state.claimwiseReports,
      cptwiseReport: this.state.cptwiseReports,
      arlogwiseReport: this.state.arlogwiseReports,
      dailyProductionReport: this.state.dailyprodReports,
      openClaimsReport: this.state.openclaimsReports,
      claimwiseProductionReport: this.state.claimwiseprodReports,
      closedCPT: this.state.closedReports,
      combinedReport: this.state.CombinedReports,
      configurePayerCategory: this.state.payercategory,
      configurePayerMaster: this.state.payermaster,
      configurePrimaryPayer: this.state.primarypayer,
      configureCpt: this.state.cpt,
      configureModifiers: this.state.modifiers,
      configureAging: this.state.aging,
      configureDenialMaster: this.state.denialmaster,
      configureBillingProvider: this.state.billingprovider,
      configureUserDetails: this.state.userDetails,
      configureAdvancedBillingProvider: this.state.AdvBillingProvider,
      configureClaimStatus: this.state.claimstatus,
      configureClaimCategory: this.state.claimcategory,
      configureClaimSubCategory: this.state.claimsubcategory,
      configureActionCodes: this.state.actioncode,
      configureActionStatusCodes: this.state.actionstatuscode,
      configureClientNotes: this.state.clientnotes,
      configureLocation: this.state.location,
      configureIcd: this.state.ICD,
      configureReferralProvider: this.state.RefProvider,
      configureGeneralSettings: this.state.GeneralSettings,
      configureProviderDetails: this.state.providerdetails,
      configureNotesTypes: this.state.notetypes,
      target: this.state.target,
    };

    axios
      .post(`${ip}/role/create`, value, { headers: headers })
      .then((res) => {
        if (res) {
          this.setState({ addnewmodal: !this.state.addnewmodal });
          this.getusers();
          toast.success("Role successfully created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  //delete call
  deleterequests = async (DataID) => {
    //api call to delete requests
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const req = axios
      .delete(`${ip}/role/${DataID}`, {
        headers: headers,
      })

      .then((req) => {
        if (req) {
          this.getusers();
          toast.error("Role successfully deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.getadvancedbillingprovider();
          this.toggledelete();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  //getusers
  getusers = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/role/all`, { headers: headers })
      .then((res) => {
        this.setState({
          role: res.data.role,
        });
      })
      .catch((err) => {});
  };
  reset = () => {
    this.setState({
      rolename: "",
      status: "Active",
      management_access: false,
      transaction_access: false,
      data_access: false,
      ai_bot_access: false,
      performance_access: false,
      action_access: false,
      admin_access: false,
      configure_access: false,
      role_access: false,
      allocation_access: false,
      rolenameErr: "",
      statusErr: "",
    });
  };
  //handle select/deselect options for edit modal and create modal
  handleChildCheckboxChange = (name, parentName) => {
    this.setState(
      (prevState) => ({
        [name]: !prevState[name],
      }),
      () => this.updateParentCheckbox(parentName)
    );
  };
  //all the menu and menu items are listed here 
  updateParentCheckbox = (parentName) => {
    if (parentName === "Update_management_access") {
      const {
        Update_Inventory_Analysis,
        Update_outcomes,
        Update_Actions,
        Update_IndustryStandards,
        Update_UserPerformanceDashboard,
        Update_trendsandpatterns,
        Update_ClaimQuickCheck,
        Update_Reports,
        Update_claimwiseReports,
        Update_cptwiseReports,
        Update_aglogReports,
        Update_dailyprodReports,
        Update_openclaimsReports,
        Update_claimwiseprodReports,
        Update_closedReports,
        Update_CombinedReports,
      } = this.state;

      const anyChildSelected =
        Update_Inventory_Analysis ||
        Update_outcomes ||
        Update_Actions ||
        Update_IndustryStandards ||
        Update_UserPerformanceDashboard ||
        Update_trendsandpatterns ||
        Update_ClaimQuickCheck ||
        Update_Reports ||
        Update_claimwiseReports ||
        Update_cptwiseReports ||
        Update_aglogReports ||
        Update_dailyprodReports ||
        Update_openclaimsReports ||
        Update_claimwiseprodReports ||
        Update_closedReports ||
        Update_CombinedReports;

      this.setState({
        Update_management_access: anyChildSelected,
      });
    } else if (parentName === "Update_transaction_access") {
      const {
        Update_KeyIndicators,
        Update_denialmanagement,
        Update_claimmanagement,
        Update_automationtrack,
      } = this.state;

      const anyChildSelected =
        Update_KeyIndicators ||
        Update_denialmanagement ||
        Update_claimmanagement ||
        Update_automationtrack;

      this.setState({
        Update_transaction_access: anyChildSelected,
      });
    } else if (parentName === "Update_data_access") {
      const {
        Update_Databasesync,
        Update_Apisync,
        Update_Datauploadfile,
        Update_Datadeletefile,
        Update_Dataconversionocr,
        Update_Datacleaning,
        Update_Datamigration,
      } = this.state;

      const anyChildSelected =
        Update_Databasesync ||
        Update_Apisync ||
        Update_Datauploadfile ||
        Update_Datadeletefile ||
        Update_Dataconversionocr ||
        Update_Datacleaning ||
        Update_Datamigration;

      this.setState({
        Update_data_access: anyChildSelected,
      });
    } else if (parentName === "Update_ai_bot_access") {
      const {
        Update_collectionprediction,
        Update_Denialprediction,
        Update_Overallprediction,
        Update_AiAssitant,
      } = this.state;

      const anyChildSelected =
        Update_collectionprediction ||
        Update_Denialprediction ||
        Update_Overallprediction ||
        Update_AiAssitant;

      this.setState({
        Update_ai_bot_access: anyChildSelected,
      });
    } else if (parentName === "Update_allocation") {
      const {
        Update_Allocated,
        Update_RejectionAndDenials,
        Update_UnAllocated,
      } = this.state;

      const anyChildSelected =
        Update_Allocated || Update_RejectionAndDenials || Update_UnAllocated;

      this.setState({
        Update_allocation: anyChildSelected,
      });
    } else if (parentName === "Update_configure") {
      const {
        Update_payercategory,
        Update_payermaster,
        Update_primarypayer,
        Update_cpt,
        Update_modifiers,
        Update_aging,
        Update_claimsubcategory,
        Update_actioncode,
        Update_actionstatuscode,
        Update_clientnotes,
        Update_location,
        Update_denialmaster,
        Update_billingprovider,
        Update_userDetails,
        Update_AdvBillingProvider,
        Update_claimstatus,
        Update_claimcategory,
        Update_ICD,
        Update_RefProvider,
        Update_GeneralSettings,
        Update_providerdetails,
        Update_notetypes,
      } = this.state;

      const anyChildSelected =
      Update_payercategory ||
      Update_payermaster ||
      Update_primarypayer||
      Update_cpt||
      Update_modifiers||
      Update_aging||
      Update_claimsubcategory||
      Update_actioncode||
      Update_actionstatuscode ||
      Update_clientnotes||
      Update_location||
      Update_denialmaster||
      Update_billingprovider||
      Update_userDetails||
      Update_AdvBillingProvider||
      Update_claimstatus||
      Update_claimcategory||
      Update_ICD||
      Update_RefProvider||
      Update_GeneralSettings||
      Update_providerdetails||
      Update_notetypes;

      this.setState({
        Update_configure: anyChildSelected,
      });
    } else if (parentName === "Update_usermanagement") {
      const {
        Update_useronboard,
        Update_Roles,
        Update_target,
      } = this.state;

      const anyChildSelected =
      Update_useronboard||
        Update_Roles||
        Update_target;

      this.setState({
        Update_usermanagement: anyChildSelected,
      });
    } else if (parentName === "Update_eligibility") {
      const {
        Update_eligibilitydataupload,
        Update_eligibilitytracker,
        Update_eligibilityquickcheck,
        Update_PriorAuthTracker,
        Update_UploadAuth
      } = this.state;

      const anyChildSelected =
      Update_eligibilitydataupload ||
      Update_eligibilitytracker ||
      Update_eligibilityquickcheck ||
      Update_PriorAuthTracker ||
      Update_UploadAuth;

      this.setState({
        Update_eligibility: anyChildSelected,
      });
    } else if (parentName === "ai_bot_access") {
      const {
        collectionprediction,
        Denialprediction,
        Overallprediction,
        AiAssitant,
      } = this.state;

      const anyChildSelected =
        collectionprediction ||
        Denialprediction ||
        Overallprediction ||
        AiAssitant;

      this.setState({
        ai_bot_access: anyChildSelected,
      });
    } else if (parentName === "allocation_access") {
      const {
        Allocated,
        RejectionAndDenials,
        UnAllocated,
      } = this.state;

      const anyChildSelected =
        Allocated || RejectionAndDenials || UnAllocated;

      this.setState({
        allocation_access: anyChildSelected,
      });
    } else if (parentName === "configure") {
      const {
        payercategory,
        payermaster,
        primarypayer,
        cpt,
        modifiers,
        aging,
        claimsubcategory,
        actioncode,
        actionstatuscode,
        clientnotes,
        location,
        denialmaster,
        billingprovider,
        userDetails,
        AdvBillingProvider,
        claimstatus,
        claimcategory,
        ICD,
        RefProvider,
        GeneralSettings,
        providerdetails,
        notetypes,
      } = this.state;

      const anyChildSelected =
      payercategory ||
      payermaster ||
      primarypayer||
      cpt||
      modifiers||
      aging||
      claimsubcategory||
      actioncode||
      actionstatuscode ||
      clientnotes||
      location||
      denialmaster||
      billingprovider||
      userDetails||
      AdvBillingProvider||
      claimstatus||
      claimcategory||
      ICD||
      RefProvider||
      GeneralSettings||
      providerdetails||
      notetypes;

      this.setState({
        configure: anyChildSelected,
      });
    } else if (parentName === "usermanagement"){
      const {
        useronboard,
        Roles,
        target,
      } = this.state;

      const anyChildSelected =
        useronboard||
        Roles||
        target;

      this.setState({
        usermanagement: anyChildSelected,
      });
    } else if (parentName === "eligibility") {
      const {
        eligibilitydataupload,
        eligibilitytracker,
        eligibilityquickcheck,
        PriorAuthTracker,
        UploadAuth
      } = this.state;

      const anyChildSelected =
      eligibilitydataupload ||
      eligibilitytracker ||
      eligibilityquickcheck ||
      PriorAuthTracker ||
      UploadAuth;

      this.setState({
        eligibility: anyChildSelected,
      });
    } else if (parentName === "management_access") {
      const {
        Inventory_Analysis,
        outcomes,
        Actions,
        IndustryStandards,
        UserPerformanceDashboard,
        trendsandpatterns,
        ClaimQuickCheck,
        Reports,
        claimwiseReports,
        cptwiseReports,
        aglogReports,
        dailyprodReports,
        openclaimsReports,
        claimwiseprodReports,
        closedReports,
        CombinedReports,
      } = this.state;

      const anyChildSelected =
        Inventory_Analysis ||
        outcomes ||
        Actions ||
        IndustryStandards ||
        UserPerformanceDashboard ||
        trendsandpatterns ||
        ClaimQuickCheck ||
        Reports ||
        claimwiseReports ||
        cptwiseReports ||
        aglogReports ||
        dailyprodReports ||
        openclaimsReports ||
        claimwiseprodReports ||
        closedReports ||
        CombinedReports;
      this.setState({
        management_access: anyChildSelected,
      });
    } else if (parentName === "transaction_access") {
      const {
        KeyIndicators,
        denialmanagement,
        claimmanagement,
        automationtrack,
      } = this.state;

      const anyChildSelected =
        KeyIndicators ||
        denialmanagement ||
        claimmanagement ||
        automationtrack;

      this.setState({
        transaction_access: anyChildSelected,
      });
    } else if (parentName === "data_access") {
      const {
        Databasesync,
        Apisync,
        Datauploadfile,
        Datadeletefile,
        Dataconversionocr,
        Datacleaning,
        Datamigration,
      } = this.state;

      const anyChildSelected =
        Databasesync ||
        Apisync ||
        Datauploadfile ||
        Datadeletefile ||
        Dataconversionocr ||
        Datacleaning ||
        Datamigration;

      this.setState({
        data_access: anyChildSelected,
      });
    }
  };
  handleParentCheckboxChange = (parentName, childNames) => {
    this.setState((prevState) => {
      const newState = !prevState[parentName];
      const updatedState = {
        [parentName]: newState,
      };
      childNames.forEach((childName) => {
        updatedState[childName] = newState;
      });
      return updatedState;
    });
  };

  componentDidMount() {
    this.getusers();
  }

  render() {
    return (
      <div>
        <Newsidebar name="Roles" />
        <div className="align-row" style={{ overflow: "hidden" }}>
          <div className="align-column">
            <ToastContainer></ToastContainer>
            <br></br>
            <div>
              <Row></Row>
            </div>
            <button
              type="button"
              class="btn"
              style={{
                backgroundColor: "#041c3c",
                color: "#fff",
                fontSize: "12px",
                marginRight: "5%",
                marginLeft: "65%",
              }}
              onClick={this.openmodal}
            >
              Add Role
            </button>
            <br></br>
            <div
              style={{
                height: "100%",
                width: "100%",
                marginLeft: "2%",
                marginTop: "1%",
              }}
            >
              <Table bordered className="StandardTable">
                <thead>
                  <tr>
                    <th>Role Name</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.role &&
                    this.state.role.map((data, index) => (
                      <tr key={data._id}>
                        <td component="th" scope="row">
                          {data.role_name}
                        </td>
                        <td>{data.status}</td>
                        <td>{data.createdAt.substring(0, 10)}</td>
                        <td>
                          <button
                            className="Edit"
                            onClick={() => {
                              this.setState({ dataforupdate: data }, () => {
                                this.settingupdatestate();
                                this.toggleupdate();
                              });
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="Edit"
                            onClick={() => {
                              this.deleterequests(data._id);
                            }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>

            {this.state.updatemodal && this.state.dataforupdate && (
              <Modal
                size="xl"
                isOpen={this.state.updatemodal}
                onHide={this.toggleupdate}
                onExit={this.reset}
                centered
              >
                <ModalHeader toggle={this.toggleupdate}>
                  Update{" "}
                  <span style={{ fontWeight: "bolder", color: "#041c3c" }}>
                    {this.state.Update_username}
                  </span>
                </ModalHeader>
                <ModalBody
                  style={{
                    "max-height": "calc(100vh - 210px)",
                    "overflow-y": "auto",
                  }}
                >
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Role</Label>
                          <p style={{fontWeight:"800"}}>Dashboard-1</p>
                          <Row style={{ paddingTop: "2%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  name="Update_management_access"
                                  id="Update_management_access"
                                  checked={this.state.Update_management_access}
                                  value={this.state.Update_management_access}
                                  onChange={() =>
                                    this.handleParentCheckboxChange(
                                      "Update_management_access",
                                      [
                                        "Update_Inventory_Analysis",
                                        "Update_outcomes",
                                        "Update_Actions",
                                        "Update_IndustryStandards",
                                        "Update_UserPerformanceDashboard",
                                        "Update_trendsandpatterns",
                                        "Update_ClaimQuickCheck",
                                        "Update_Reports",
                                        "Update_claimwiseReports",
                                        "Update_cptwiseReports",
                                        "Update_aglogReports",
                                        "Update_dailyprodReports",
                                        "Update_openclaimsReports",
                                        "Update_claimwiseprodReports",
                                        "Update_closedReports",
                                        "Update_CombinedReports",
                                      ]
                                    )
                                  }
                                />
                                <Label
                                  for="Update_management_access"
                                  style={{ marginLeft: "2%" }}
                                >
                                  Management
                                </Label>
                              </CardHeader>
                              <CardBody>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_Inventory_Analysis"
                                      id="Update_Inventory_Analysis"
                                      checked={
                                        this.state.Update_Inventory_Analysis
                                      }
                                      value={
                                        this.state.Update_Inventory_Analysis
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_Inventory_Analysis",
                                          "Update_management_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_Inventory_Analysis"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Inventory Analysis
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_outcomes"
                                      id="Update_outcomes"
                                      checked={this.state.Update_outcomes}
                                      value={this.state.Update_outcomes}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_outcomes",
                                          "Update_management_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_outcomes"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Outcomes
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_Actions"
                                      id="Update_Actions"
                                      checked={this.state.Update_Actions}
                                      value={this.state.Update_Actions}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_Actions",
                                          "Update_management_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_Actions"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Actions
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_IndustryStandards"
                                      id="Update_IndustryStandards"
                                      checked={
                                        this.state.Update_IndustryStandards
                                      }
                                      value={
                                        this.state.Update_IndustryStandards
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_IndustryStandards",
                                          "Update_management_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_IndustryStandards"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Industry Standards
                                    </Label>
                                  </Col>
                                </Row>
                                <br />
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_UserPerformanceDashboard"
                                      id="Update_UserPerformanceDashboard"
                                      checked={
                                        this.state
                                          .Update_UserPerformanceDashboard
                                      }
                                      value={
                                        this.state
                                          .Update_UserPerformanceDashboard
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_UserPerformanceDashboard",
                                          "Update_management_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_UserPerformanceDashboard"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      User Dashboard
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_trendsandpatterns"
                                      id="Update_trendsandpatterns"
                                      checked={
                                        this.state.Update_trendsandpatterns
                                      }
                                      value={
                                        this.state.Update_trendsandpatterns
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_trendsandpatterns",
                                          "Update_management_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_trendsandpatterns"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Trends & Patterns
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_ClaimQuickCheck"
                                      id="Update_ClaimQuickCheck"
                                      checked={
                                        this.state.Update_ClaimQuickCheck
                                      }
                                      value={this.state.Update_ClaimQuickCheck}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_ClaimQuickCheck",
                                          "Update_management_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_ClaimQuickCheck"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Claim Quick Check
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_Reports"
                                      id="Update_Reports"
                                      checked={this.state.Update_Reports}
                                      value={this.state.Update_Reports}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_Reports",
                                          "Update_management_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_Reports"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Reports
                                    </Label>
                                  </Col>
                                  {this.state.Update_Reports && (
                                    <div>
                                      <Row
                                        style={{
                                          marginTop: "2%",
                                          marginLeft: "4%",
                                        }}
                                      >
                                        <Col>
                                          <Input
                                            type="checkbox"
                                            name="Update_claimwiseReports"
                                            id="Update_claimwiseReports"
                                            checked={
                                              this.state.Update_claimwiseReports
                                            }
                                            value={
                                              this.state.Update_claimwiseReports
                                            }
                                            onChange={() =>
                                              this.handleChildCheckboxChange(
                                                "Update_claimwiseReports",
                                                "Update_management_access"
                                              )
                                            }
                                          />
                                          <Label
                                            for="Update_claimwiseReports"
                                            style={{ marginLeft: "2%" }}
                                          >
                                            Claimwise
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Input
                                            type="checkbox"
                                            name="Update_cptwiseReports"
                                            id="Update_cptwiseReports"
                                            checked={
                                              this.state.Update_cptwiseReports
                                            }
                                            value={
                                              this.state.Update_cptwiseReports
                                            }
                                            onChange={() =>
                                              this.handleChildCheckboxChange(
                                                "Update_cptwiseReports",
                                                "Update_management_access"
                                              )
                                            }
                                          />
                                          <Label
                                            for="Update_cptwiseReports"
                                            style={{ marginLeft: "2%" }}
                                          >
                                            CPTwise
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Input
                                            type="checkbox"
                                            name="Update_aglogReports"
                                            id="Update_aglogReports"
                                            checked={
                                              this.state.Update_aglogReports
                                            }
                                            value={
                                              this.state.Update_aglogReports
                                            }
                                            onChange={() =>
                                              this.handleChildCheckboxChange(
                                                "Update_aglogReports",
                                                "Update_management_access"
                                              )
                                            }
                                          />
                                          <Label
                                            for="aglogReports"
                                            style={{ marginLeft: "2%" }}
                                          >
                                            Arlogwise
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Input
                                            type="checkbox"
                                            name="Update_dailyprodReports"
                                            id="Update_dailyprodReports"
                                            checked={
                                              this.state.Update_dailyprodReports
                                            }
                                            value={
                                              this.state.Update_dailyprodReports
                                            }
                                            onChange={() =>
                                              this.handleChildCheckboxChange(
                                                "Update_dailyprodReports",
                                                "Update_management_access"
                                              )
                                            }
                                          />
                                          <Label
                                            for="Update_dailyprodReports"
                                            style={{ marginLeft: "2%" }}
                                          >
                                            Daily Production
                                          </Label>
                                        </Col>
                                      </Row>
                                      <Row
                                        style={{
                                          marginTop: "2%",
                                          marginLeft: "4%",
                                        }}
                                      >
                                        <Col>
                                          <Input
                                            type="checkbox"
                                            name="Update_openclaimsReports"
                                            id="Update_openclaimsReports"
                                            checked={
                                              this.state
                                                .Update_openclaimsReports
                                            }
                                            value={
                                              this.state
                                                .Update_openclaimsReports
                                            }
                                            onChange={() =>
                                              this.handleChildCheckboxChange(
                                                "Update_openclaimsReports",
                                                "Update_management_access"
                                              )
                                            }
                                          />
                                          <Label
                                            for="openclaimsReports"
                                            style={{ marginLeft: "2%" }}
                                          >
                                            Open Claims
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Input
                                            type="checkbox"
                                            name="Update_claimwiseprodReports"
                                            id="Update_claimwiseprodReports"
                                            checked={
                                              this.state
                                                .Update_claimwiseprodReports
                                            }
                                            value={
                                              this.state
                                                .Update_claimwiseprodReports
                                            }
                                            onChange={() =>
                                              this.handleChildCheckboxChange(
                                                "Update_claimwiseprodReports",
                                                "Update_management_access"
                                              )
                                            }
                                          />
                                          <Label
                                            for="Update_claimwiseprodReports"
                                            style={{ marginLeft: "2%" }}
                                          >
                                            Claimwise Production
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Input
                                            type="checkbox"
                                            name="Update_closedReports"
                                            id="Update_closedReports"
                                            checked={
                                              this.state.Update_closedReports
                                            }
                                            value={
                                              this.state.Update_closedReports
                                            }
                                            onChange={() =>
                                              this.handleChildCheckboxChange(
                                                "Update_closedReports",
                                                "Update_management_access"
                                              )
                                            }
                                          />
                                          <Label
                                            for="Update_closedReports"
                                            style={{ marginLeft: "2%" }}
                                          >
                                            Closed
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Input
                                            type="checkbox"
                                            name="Update_combinedReports"
                                            id="Update_combinedReports"
                                            checked={
                                              this.state.Update_CombinedReports
                                            }
                                            value={
                                              this.state.Update_CombinedReports
                                            }
                                            onChange={() =>
                                              this.handleChildCheckboxChange(
                                                "Update_CombinedReports",
                                                "Update_management_access"
                                              )
                                            }
                                          />
                                          <Label
                                            for="Update_CombinedReports"
                                            style={{ marginLeft: "2%" }}
                                          >
                                            Combined
                                          </Label>
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                          <Row style={{ paddingTop: "3%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  name="Update_transaction_access"
                                  id="Update_transaction_access"
                                  checked={this.state.Update_transaction_access}
                                  value={this.state.Update_transaction_access}
                                  onChange={() =>
                                    this.handleParentCheckboxChange(
                                      "Update_transaction_access",
                                      [
                                        "Update_KeyIndicators",
                                        "Update_denialmanagement",
                                        "Update_claimmanagement",
                                        "Update_automationtrack",
                                      ]
                                    )
                                  }
                                />
                                <Label
                                  for="Update_transaction_access"
                                  style={{ marginLeft: "2%" }}
                                >
                                  Claims
                                </Label>
                              </CardHeader>
                              <CardBody>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_KeyIndicators"
                                      id="Update_KeyIndicators"
                                      checked={this.state.Update_KeyIndicators}
                                      value={this.state.Update_KeyIndicators}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_KeyIndicators",
                                          "Update_transaction_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_KeyIndicators"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Key Indicators
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_denialmanagement"
                                      id="Update_denialmanagement"
                                      checked={
                                        this.state.Update_denialmanagement
                                      }
                                      value={this.state.Update_denialmanagement}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_denialmanagement",
                                          "Update_transaction_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="denialmanagement"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Denial Management
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_claimmanagement"
                                      id="Update_claimmanagement"
                                      checked={
                                        this.state.Update_claimmanagement
                                      }
                                      value={this.state.Update_claimmanagement}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_claimmanagement",
                                          "Update_transaction_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_claimmanagement"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Claim Management
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_automationtrack"
                                      id="Update_automationtrack"
                                      checked={
                                        this.state.Update_automationtrack
                                      }
                                      value={this.state.Update_automationtrack}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_automationtrack",
                                          "Update_transaction_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_automationtrack"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Automation Track
                                    </Label>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                          <Row style={{ paddingTop: "3%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  name="Update_data_access"
                                  id="Update_data_access"
                                  checked={this.state.Update_data_access}
                                  value={this.state.Update_data_access}
                                  onChange={() =>
                                    this.handleParentCheckboxChange(
                                      "Update_data_access",
                                      [
                                        "Update_Databasesync",
                                        "Update_Apisync",
                                        "Update_Datauploadfile",
                                        "Update_Datadeletefile",
                                        "Update_Dataconversionocr",
                                        "Update_Datacleaning",
                                        "Update_Datamigration",
                                      ]
                                    )
                                  }
                                />
                                <Label
                                  for="Update_data_access"
                                  style={{ marginLeft: "2%" }}
                                >
                                  Data Processing
                                </Label>
                              </CardHeader>
                              <CardBody>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_Databasesync"
                                      id="Update_Databasesync"
                                      checked={this.state.Update_Databasesync}
                                      value={this.state.Update_Databasesync}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_Databasesync",
                                          "Update_data_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_Databasesync"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Database Sync
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_Apisync"
                                      id="Update_Apisync"
                                      checked={this.state.Update_Apisync}
                                      value={this.state.Update_Apisync}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_Apisync",
                                          "Update_data_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_Apisync"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      API Sync
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_Datauploadfile"
                                      id="Update_Datauploadfile"
                                      checked={this.state.Update_Datauploadfile}
                                      value={this.state.Update_Datauploadfile}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_Datauploadfile",
                                          "Update_data_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_Datauploadfile"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Data Upload File
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_Datadeletefile"
                                      id="Update_Datadeletefile"
                                      checked={this.state.Update_Datadeletefile}
                                      value={this.state.Update_Datadeletefile}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_Datadeletefile",
                                          "Update_data_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_Datadeletefile"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Data Delete File
                                    </Label>
                                  </Col>
                                </Row>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_Dataconversionocr"
                                      id="Update_Dataconversionocr"
                                      checked={
                                        this.state.Update_Dataconversionocr
                                      }
                                      value={
                                        this.state.Update_Dataconversionocr
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_Dataconversionocr",
                                          "Update_data_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_Dataconversionocr"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Data Conversion OCR
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_Datacleaning"
                                      id="Update_Datacleaning"
                                      checked={this.state.Update_Datacleaning}
                                      value={this.state.Update_Datacleaning}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_Datacleaning",
                                          "Update_data_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_Datacleaning"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Data Cleaning
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_Datamigration"
                                      id="Update_Datamigration"
                                      checked={this.state.Update_Datamigration}
                                      value={this.state.Update_Datamigration}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_Datamigration",
                                          "Update_data_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_Datamigration"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Data Migration
                                    </Label>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                          <Row style={{ paddingTop: "3%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "#fff",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  name="Update_ai_bot_access"
                                  id="Update_ai_bot_access"
                                  checked={this.state.Update_ai_bot_access}
                                  value={this.state.Update_ai_bot_access}
                                  onChange={() =>
                                    this.handleParentCheckboxChange(
                                      "Update_ai_bot_access",
                                      [
                                        "Update_collectionprediction",
                                        "Update_Denialprediction",
                                        "Update_Overallprediction",
                                        "Update_AiAssitant",
                                      ]
                                    )
                                  }
                                />
                                <Label
                                  for="Update_ai_bot_access"
                                  style={{ marginLeft: "2%" }}
                                >
                                  AI Prediction
                                </Label>
                              </CardHeader>
                              <CardBody>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_collectionprediction"
                                      id="Update_collectionprediction"
                                      checked={
                                        this.state.Update_collectionprediction
                                      }
                                      value={
                                        this.state.Update_collectionprediction
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_collectionprediction",
                                          "Update_ai_bot_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_collectionprediction"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Collection Prediction Dashboard
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_Denialprediction"
                                      id="Update_Denialprediction"
                                      checked={
                                        this.state.Update_Denialprediction
                                      }
                                      value={this.state.Update_Denialprediction}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_Denialprediction",
                                          "Update_ai_bot_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_Denialprediction"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Denial Prediction Dashboard
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_Overallprediction"
                                      id="Update_Overallprediction"
                                      checked={
                                        this.state.Update_Overallprediction
                                      }
                                      value={
                                        this.state.Update_Overallprediction
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_Overallprediction",
                                          "Update_ai_bot_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_Overallprediction"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Overall Prediction
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_AiAssitant"
                                      id="Update_AiAssitant"
                                      checked={this.state.Update_AiAssitant}
                                      value={this.state.Update_AiAssitant}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_AiAssitant",
                                          "Update_ai_bot_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_AiAssitant"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      AI Assistant
                                    </Label>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                          <Row style={{ paddingTop: "3%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "#fff",
                                }}
                              >
                                Work Queue Access
                              </CardHeader>
                              <CardBody>
                                <Input
                                  type="checkbox"
                                  name="Update_workqueue"
                                  id="Update_workqueue"
                                  checked={this.state.Update_workqueue}
                                  value={this.state.Update_workqueue}
                                  onChange={() =>
                                    this.setState({
                                      Update_workqueue:
                                        !this.state.Update_workqueue,
                                    })
                                  }
                                />
                                <Label
                                  for="Update_workqueue"
                                  style={{ marginLeft: "2%" }}
                                >
                                  Work Queue
                                </Label>
                              </CardBody>
                            </Card>
                          </Row>
                          <Row style={{ paddingTop: "3%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "#fff",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  name="Update_allocation"
                                  id="Update_allocation"
                                  checked={this.state.Update_allocation}
                                  value={this.state.Update_allocation}
                                  onChange={() =>
                                    this.handleParentCheckboxChange(
                                      "Update_allocation",
                                      [
                                        "Update_Allocated",
                                        "Update_RejectionAndDenials",
                                        "Update_UnAllocated",
                                      ]
                                    )
                                  }
                                />
                                <Label
                                  for="Update_allocation"
                                  style={{ marginLeft: "2%" }}
                                >
                                  Allocation
                                </Label>
                              </CardHeader>
                              <CardBody>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_Allocated"
                                      id="Update_Allocated"
                                      checked={this.state.Update_Allocated}
                                      value={this.state.Update_Allocated}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_Allocated",
                                          "Update_allocation"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_Allocated"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Allocated
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_UnAllocated"
                                      id="Update_UnAllocated"
                                      checked={this.state.Update_UnAllocated}
                                      value={this.state.Update_UnAllocated}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_UnAllocated",
                                          "Update_allocation"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_UnAllocated"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      UnAllocated
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_RejectionAndDenials"
                                      id="Update_RejectionAndDenials"
                                      checked={
                                        this.state.Update_RejectionAndDenials
                                      }
                                      value={
                                        this.state.Update_RejectionAndDenials
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_UnAllocated",
                                          "Update_allocation"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_RejectionAndDenials"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Rejection And Denials
                                    </Label>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                          <Row style={{ paddingTop: "3%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "#fff",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  name="Update_configure"
                                  id="Update_configure"
                                  checked={this.state.Update_configure}
                                  value={this.state.Update_configure}
                                  onChange={() =>
                                    this.handleParentCheckboxChange(
                                      "Update_configure",
                                      [
                                        "Update_payercategory",
                                        "Update_payermaster",
                                        "Update_primarypayer",
                                        "Update_cpt",
                                        "Update_modifiers",
                                        "Update_aging",
                                        "Update_claimsubcategory",
                                        "Update_actioncode",
                                        "Update_actionstatuscode",
                                        "Update_clientnotes",
                                        "Update_location",
                                        "Update_denialmaster",
                                        "Update_billingprovider",
                                        "Update_userDetails",
                                        "Update_AdvBillingProvider",
                                        "Update_claimstatus",
                                        "Update_claimcategory",
                                        "Update_ICD",
                                        "Update_RefProvider",
                                        "Update_GeneralSettings",
                                        "Update_providerdetails",
                                        "Update_notetypes",
                                      ]
                                    )
                                  }
                                />
                                <Label
                                  for="Update_configure"
                                  style={{ marginLeft: "2%" }}
                                >
                                  Configure
                                </Label>
                              </CardHeader>
                              <CardBody>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_payercategory"
                                      id="Update_payercategory"
                                      checked={
                                        this.state.Update_payercategory
                                      }
                                      value={
                                        this.state.Update_payercategory
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_payercategory",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_payercategory"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Payer Category
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_payermaster"
                                      id="Update_payermaster"
                                      checked={
                                        this.state.Update_payermaster
                                      }
                                      value={
                                        this.state.Update_payermaster
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_payermaster",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_payermaster"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Payer Master
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_primarypayer"
                                      id="Update_primarypayer"
                                      checked={
                                        this.state.Update_primarypayer
                                      }
                                      value={
                                        this.state.Update_primarypayer
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_primarypayer",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_primarypayer"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Primary Payer
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_cpt"
                                      id="Update_cpt"
                                      checked={
                                        this.state.Update_cpt
                                      }
                                      value={
                                        this.state.Update_cpt
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_cpt",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_cpt"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      CPT
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_modifiers"
                                      id="Update_modifiers"
                                      checked={
                                        this.state.Update_modifiers
                                      }
                                      value={
                                        this.state.Update_modifiers
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_modifiers",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_modifiers"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Modifiers
                                    </Label>
                                  </Col>
                                </Row>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_Aging"
                                      id="Update_Aging"
                                      checked={
                                        this.state.Update_aging
                                      }
                                      value={
                                        this.state.Update_aging
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_aging",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_aging"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Aging
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_claimsubcategory"
                                      id="Update_claimsubcategory"
                                      checked={
                                        this.state.Update_claimsubcategory
                                      }
                                      value={
                                        this.state.Update_claimsubcategory
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_claimsubcategory",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_claimsubcategory"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Claim Subcategory
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_actioncode"
                                      id="Update_actioncode"
                                      checked={
                                        this.state.Update_actioncode
                                      }
                                      value={
                                        this.state.Update_actioncode
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_actioncode",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_actioncode"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Action code
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_actionstatuscode"
                                      id="Update_actionstatuscode"
                                      checked={
                                        this.state.Update_actionstatuscode
                                      }
                                      value={
                                        this.state.Update_actionstatuscode
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_actionstatuscode",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_actionstatuscode"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Action Status Code
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_clientnotes"
                                      id="Update_clientnotes"
                                      checked={
                                        this.state.Update_clientnotes
                                      }
                                      value={
                                        this.state.Update_clientnotes
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_clientnotes",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_clientnotes"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Client Notes
                                    </Label>
                                  </Col>
                                </Row>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="lUpdate_ocation"
                                      id="Update_location"
                                      checked={
                                        this.state.Update_location
                                      }
                                      value={
                                        this.state.Update_location
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_location",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_location"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Location
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_denialmaster"
                                      id="Update_denialmaster"
                                      checked={
                                        this.state.Update_denialmaster
                                      }
                                      value={
                                        this.state.Update_denialmaster
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_denialmaster",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_denialmaster"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Denial Master
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_billingprovider"
                                      id="Update_billingprovider"
                                      checked={
                                        this.state.Update_billingprovider
                                      }
                                      value={
                                        this.state.Update_billingprovider
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_billingprovider",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_billingprovider"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Update_Billing Provider
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_userDetails"
                                      id="Update_userDetails"
                                      checked={
                                        this.state.Update_userDetails
                                      }
                                      value={
                                        this.state.Update_userDetails
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_userDetails",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_userDetails"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      User Details
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_AdvBillingProvider"
                                      id="Update_AdvBillingProvider"
                                      checked={
                                        this.state.Update_AdvBillingProvider
                                      }
                                      value={
                                        this.state.Update_AdvBillingProvider
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_AdvBillingProvider",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_AdvBillingProvider"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Adv Billing Provider
                                    </Label>
                                  </Col>
                                </Row>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_claimstatus"
                                      id="Update_claimstatus"
                                      checked={
                                        this.state.Update_claimstatus
                                      }
                                      value={
                                        this.state.Update_claimstatus
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_claimstatus",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_claimstatus"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Claim Status
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_claimcategory"
                                      id="Update_claimcategory"
                                      
                                      checked={
                                        this.state.Update_claimcategory
                                      }
                                      value={
                                        this.state.Update_claimcategory
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_claimcategory",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_claimcategory"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Claim Category
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_ICD"
                                      id="Update_ICD"
                                      checked={
                                        this.state.Update_ICD
                                      }
                                      value={
                                        this.state.Update_ICD
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_ICD",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_ICD"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      ICD
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_RefProvider"
                                      id="Update_RefProvider"
                                      checked={
                                        this.state.Update_RefProvider
                                      }
                                      value={
                                        this.state.Update_RefProvider
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_RefProvider",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_RefProvider"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Refferal Provider
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_GeneralSettings"
                                      id="Update_GeneralSettings"
                                      checked={
                                        this.state.Update_GeneralSettings
                                      }
                                      value={
                                        this.state.Update_GeneralSettings
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_GeneralSettings",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_GeneralSettings"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      General Settings
                                    </Label>
                                  </Col>
                                </Row>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_providerdetails"
                                      id="Update_providerdetails"
                                      checked={
                                        this.state.Update_providerdetails
                                      }
                                      value={
                                        this.state.Update_providerdetails
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_providerdetails",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="providerdetails"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Provider Details
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_notetypes"
                                      id="Update_notetypes"
                                      checked={
                                        this.state.Update_notetypes
                                      }
                                      value={
                                        this.state.Update_notetypes
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_notetypes",
                                          "Update_configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_notetypes"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Note Types
                                    </Label>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                          <Row style={{ paddingTop: "3%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "#fff",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  name="Update_usermanagement"
                                  id="Update_usermanagement"
                                  checked={this.state.Update_usermanagement}
                                  value={this.state.Update_usermanagement}
                                  onChange={() =>
                                    this.handleParentCheckboxChange(
                                      "Update_usermanagement",
                                      [
                                        "Update_useronboard",
                                        "Update_Roles",
                                        "Update_target",
                                      ]
                                    )
                                  }
                                />
                                <Label
                                  for="Update_usermanagement"
                                  style={{ marginLeft: "2%" }}
                                >
                                  User Management
                                </Label>
                              </CardHeader>
                              <CardBody>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_useronboard"
                                      id="Update_useronboard"
                                      checked={
                                        this.state.Update_useronboard
                                      }
                                      value={
                                        this.state.Update_useronboard
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_useronboard",
                                          "Update_usermanagement"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_useronboard"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      User on Board
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_Roles"
                                      id="Update_Roles"
                                      checked={
                                        this.state.Update_Roles
                                      }
                                      value={
                                        this.state.Update_Roles
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_Roles",
                                          "Update_usermanagement"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_Roles"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Roles
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_target"
                                      id="Update_target"
                                      checked={
                                        this.state.Update_target
                                      }
                                      value={
                                        this.state.Update_target
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_target",
                                          "Update_usermanagement"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_target"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Target
                                    </Label>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                          <Row style={{ paddingTop: "3%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "#fff",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  name="Update_eligibility"
                                  id="Update_eligibility"
                                  checked={this.state.Update_eligibility}
                                  value={this.state.Update_eligibility}
                                  onChange={() =>
                                    this.handleParentCheckboxChange(
                                      "Update_eligibility",
                                      [
                                        "Update_eligibilitydataupload",
                                        "Update_eligibilitytracker",
                                        "Update_eligibilityquickcheck",
                                        "Update_PriorAuthTracker",
                                        "Update_UploadAuth",
                                      ]
                                    )
                                  }
                                />
                                <Label
                                  for="Update_eligibility"
                                  style={{ marginLeft: "2%" }}
                                >
                                  Eligibility
                                </Label>
                              </CardHeader>
                              <CardBody>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_eligibilitydataupload"
                                      id="Update_eligibilitydataupload"
                                      checked={
                                        this.state.Update_eligibilitydataupload
                                      }
                                      value={
                                        this.state.Update_eligibilitydataupload
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_eligibilitydataupload",
                                          "Update_eligibility"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_eligibilitydataupload"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Data upload
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_eligibilitytracker"
                                      id="Update_eligibilitytracker"
                                      checked={
                                        this.state.Update_eligibilitytracker
                                      }
                                      value={
                                        this.state.Update_eligibilitytracker
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_eligibilitytracker",
                                          "Update_eligibility"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_eligibilitytracker"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Eligibility Tracker
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_eligibilityquickcheck"
                                      id="Update_eligibilityquickcheck"
                                      checked={
                                        this.state.Update_eligibilityquickcheck
                                      }
                                      value={
                                        this.state.Update_eligibilityquickcheck
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_eligibilityquickcheck",
                                          "Update_eligibility"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_eligibilityquickcheck"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Eligibility Quick Check
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_UploadAuth"
                                      id="Update_UploadAuth"
                                      checked={
                                        this.state.Update_UploadAuth
                                      }
                                      value={
                                        this.state.Update_UploadAuth
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_UploadAuth",
                                          "Update_eligibility"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_UploadAuth"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Upload Authorization
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Update_PriorAuthTracker"
                                      id="Update_PriorAuthTracker"
                                      checked={
                                        this.state.Update_PriorAuthTracker
                                      }
                                      value={
                                        this.state.Update_PriorAuthTracker
                                      }
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Update_PriorAuthTracker",
                                          "Update_eligibility"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Update_PriorAuthTracker"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Prior Auth Tracker
                                    </Label>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                          <br />
                          <p style={{fontWeight:"800"}}>Dashboard-2</p>
                          <Card
                            style={{
                              backgroundColor: "#FAF9F6",
                              padding: "15px",
                              marginLeft: "2%",
                            }}
                          >
                            <CardBody>
                              <Row style={{ paddingTop: "1%" }}>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="Update_Performances"
                                    id="Update_Performances"
                                    checked={this.state.Update_Performances}
                                    onChange={() =>
                                      this.setState({
                                        Update_Performances:
                                          !this.state.Update_Performances,
                                      })
                                    }
                                  />
                                  <Label
                                    for="Update_Performances"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Performances
                                  </Label>
                                </Col>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="Update_Completed"
                                    id="Update_Completed"
                                    checked={this.state.Update_Completed}
                                    onChange={() =>
                                      this.setState({
                                        Update_Completed:
                                          !this.state.Update_Completed,
                                      })
                                    }
                                  />
                                  <Label
                                    for="Update_Completed"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Completed
                                  </Label>
                                </Col>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="Update_Inprogress"
                                    id="Update_Inprogress"
                                    checked={this.state.Update_Inprogress}
                                    onChange={() =>
                                      this.setState({
                                        Update_Inprogress:
                                          !this.state.Update_Inprogress,
                                      })
                                    }
                                  />
                                  <Label
                                    for="Update_Inprogress"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Inprogress
                                  </Label>
                                </Col>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="Update_Denial"
                                    id="Update_Denial"
                                    checked={this.state.Update_Denial}
                                    onChange={() =>
                                      this.setState({
                                        Update_Denial:
                                          !this.state.Update_Denial,
                                      })
                                    }
                                  />
                                  <Label
                                    for="Update_Denial"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Denial
                                  </Label>
                                </Col>
                              </Row>
                              <Row style={{ paddingTop: "3%" }}>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="Update_NeedToCall"
                                    id="Update_NeedToCall"
                                    checked={this.state.Update_NeedToCall}
                                    onChange={() =>
                                      this.setState({
                                        Update_NeedToCall:
                                          !this.state.Update_NeedToCall,
                                      })
                                    }
                                  />
                                  <Label
                                    for="Update_NeedToCall"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Need To Call
                                  </Label>
                                </Col>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="Update_Pending"
                                    id="Update_Pending"
                                    checked={this.state.Update_Pending}
                                    onChange={() =>
                                      this.setState({
                                        Update_Pending:
                                          !this.state.Update_Pending,
                                      })
                                    }
                                  />
                                  <Label
                                    for="Update_Pending"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Pending
                                  </Label>
                                </Col>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="Update_workqueue1"
                                    id="Update_workqueue1"
                                    checked={this.state.Update_workqueue1}
                                    onChange={() =>
                                      this.setState({
                                        Update_workqueue1:
                                          !this.state.Update_workqueue1,
                                      })
                                    }
                                  />
                                  <Label
                                    for="Update_workqueue1"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Work Queue
                                  </Label>
                                </Col>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="Update_Rejection1"
                                    id="Update_Rejection1"
                                    checked={this.state.Update_Rejection1}
                                    onChange={() =>
                                      this.setState({
                                        Update_Rejection1:
                                          !this.state.Update_Rejection1,
                                      })
                                    }
                                  />
                                  <Label
                                    for="Update_Rejection1"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Rejection & Denials
                                  </Label>
                                </Col>
                              </Row>
                              <Row style={{ paddingTop: "3%" }}>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="Update_ClaimTracker"
                                    id="Update_ClaimTracker"
                                    checked={this.state.Update_ClaimTracker}
                                    onChange={() =>
                                      this.setState({
                                        Update_ClaimTracker:
                                          !this.state.Update_ClaimTracker,
                                      })
                                    }
                                  />
                                  <Label
                                    for="Update_ClaimTracker"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Claim Tracker
                                  </Label>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button
                    style={{
                      border: "1px solid grey",
                      color: "black",
                      backgroundColor: "#fff",
                    }}
                    onClick={() => {
                      this.toggleupdate();
                      this.reset();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ backgroundColor: "black", color: "white" }}
                    onClick={() => {
                      this.putrequests(this.state.singleid);
                    }}
                  >
                    Update
                  </Button>
                </ModalFooter>
              </Modal>
            )}
            {this.state.addnewmodal && (
              <Modal
                size="xl"
                isOpen={this.state.addnewmodal}
                onHide={this.openmodal}
                onExit={this.reset}
                centered
              >
                <ModalHeader toggle={this.openmodal}>Create User</ModalHeader>
                <ModalBody
                  style={{
                    "max-height": "calc(100vh - 210px)",
                    "overflow-y": "auto",
                  }}
                >
                  <Form>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <Label>Role name</Label>
                            <Input
                              value={this.state.role_name}
                              onChange={(e) =>
                                this.setState({ role_name: e.target.value })
                              }
                            />
                          </Col>
                          <Col>
                            <Label>Status</Label>
                            <Input
                              type="select"
                              name="select"
                              className="form-control form-select"
                              value={this.state.role_status}
                              onChange={(e) =>
                                this.setState({ role_status: e.target.value })
                              }
                            >
                              <option value="" hidden selected="">
                                --select--
                              </option>
                              <option
                                value="Active"
                                selected={this.state.role_status === "Yes"}
                              >
                                Active
                              </option>
                              <option
                                value="Inactive"
                                selected={this.state.role_status === "No"}
                              >
                                Inactive
                              </option>
                            </Input>
                          </Col>
                        </Row>
                        <br />
                        <FormGroup>
                          <Label>Role</Label>
                          <p style={{fontWeight:"800"}}>Dashboard-1</p>
                          <Row style={{ paddingTop: "2%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  name="management_access"
                                  id="management_access"
                                  checked={this.state.management_access}
                                  value={this.state.management_access}
                                  onChange={() =>
                                    this.handleParentCheckboxChange(
                                      "management_access",
                                      [
                                        "Inventory_Analysis",
                                        "outcomes",
                                        "Actions",
                                        "IndustryStandards",
                                        "UserPerformanceDashboard",
                                        "trendsandpatterns",
                                        "ClaimQuickCheck",
                                        "Reports",
                                        "claimwiseReports",
                                        "cptwiseReports",
                                        "arlogwiseReports",
                                        "dailyprodReports",
                                        "openclaimsReports",
                                        "claimwiseprodReports",
                                        "closedReports",
                                        "CombinedReports"
                                      ]
                                    )}
                                />
                                <Label
                                  for="management_access"
                                  style={{ marginLeft: "2%" }}
                                >
                                  Management
                                </Label>
                              </CardHeader>
                              <CardBody>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Inventory_Analysis"
                                      id="Inventory_Analysis"
                                      checked={this.state.Inventory_Analysis}
                                      value={this.state.Inventory_Analysis}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Inventory_Analysis",
                                          "management_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Inventory_Analysis"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Inventory Analysis
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="outcomes"
                                      id="outcomes"
                                      checked={this.state.outcomes}
                                      value={this.state.outcomes}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "outcomes",
                                          "management_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="outcomes"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Outcomes
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Actions"
                                      id="Actions"
                                      checked={this.state.Actions}
                                      value={this.state.Actions}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Actions",
                                          "management_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Actions"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Actions
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="IndustryStandards"
                                      id="IndustryStandards"
                                      checked={this.state.IndustryStandards}
                                      value={this.state.IndustryStandards}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "IndustryStandards",
                                          "management_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="IndustryStandards"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Industry Standards
                                    </Label>
                                  </Col>
                                </Row>
                                <br />
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="UserPerformanceDashboard"
                                      id="UserPerformanceDashboard"
                                      checked={this.state.UserPerformanceDashboard}
                                      value={this.state.UserPerformanceDashboard}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "UserPerformanceDashboard",
                                          "management_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="UserPerformanceDashboard"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      User Dashboard
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="trendsandpatterns"
                                      id="trendsandpatterns"
                                      checked={this.state.trendsandpatterns}
                                      value={this.state.trendsandpatterns}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "trendsandpatterns",
                                          "management_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="trendsandpatterns"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Trends & Patterns
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="ClaimQuickCheck"
                                      id="ClaimQuickCheck"
                                      checked={this.state.ClaimQuickCheck}
                                      value={this.state.ClaimQuickCheck}
                                      onChange={this.handleCheckboxChange}
                                    />
                                    <Label
                                      for="ClaimQuickCheck"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Claim Quick Check
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Reports"
                                      id="Reports"
                                      checked={this.state.Reports}
                                      value={this.state.Reports}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Reports",
                                          "management_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Reports"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Reports
                                    </Label>
                                  </Col>
                                </Row>
                                {this.state.Reports && (
                                  <div>
                                    <Row
                                      style={{
                                        marginTop: "2%",
                                        marginLeft: "4%",
                                      }}
                                    >
                                      <Col>
                                        <Input
                                          type="checkbox"
                                          name="claimwiseReports"
                                          id="claimwiseReports"
                                          checked={this.state.claimwiseReports}
                                      value={this.state.claimwiseReports}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "claimwiseReports",
                                          "management_access"
                                        )
                                      }
                                        />
                                        <Label
                                          for="claimwiseReports"
                                          style={{ marginLeft: "2%" }}
                                        >
                                          Claimwise
                                        </Label>
                                      </Col>
                                      <Col>
                                        <Input
                                          type="checkbox"
                                          name="cptwiseReports"
                                          id="cptwiseReports"
                                          checked={this.state.cptwiseReports}
                                          value={this.state.cptwiseReports}
                                          onChange={() =>
                                            this.handleChildCheckboxChange(
                                              "cptwiseReports",
                                              "management_access"
                                            )
                                          }
                                        />
                                        <Label
                                          for="cptwiseReports"
                                          style={{ marginLeft: "2%" }}
                                        >
                                          Cptwise
                                        </Label>
                                      </Col>
                                      <Col>
                                        <Input
                                          type="checkbox"
                                          name="arlogReports"
                                          id="arlogReports"
                                          checked={this.state.arlogwiseReports}
                                          value={this.state.arlogwiseReports}
                                          onChange={() =>
                                            this.handleChildCheckboxChange(
                                              "arlogwiseReports",
                                              "management_access"
                                            )
                                          }
                                        />
                                        <Label
                                          for="arlogReports"
                                          style={{ marginLeft: "2%" }}
                                        >
                                          Arlogwise
                                        </Label>
                                      </Col>
                                      <Col>
                                        <Input
                                          type="checkbox"
                                          name="dailyprodReports"
                                          id="dailyprodReports"
                                          checked={this.state.dailyprodReports}
                                          value={this.state.dailyprodReports}
                                          onChange={() =>
                                            this.handleChildCheckboxChange(
                                              "dailyprodReports",
                                              "management_access"
                                            )
                                          }
                                        />
                                        <Label
                                          for="dailyprodReports"
                                          style={{ marginLeft: "2%" }}
                                        >
                                          Daily Production
                                        </Label>
                                      </Col>
                                    </Row>
                                    <Row
                                      style={{
                                        marginTop: "2%",
                                        marginLeft: "4%",
                                      }}
                                    >
                                      <Col>
                                        <Input
                                          type="checkbox"
                                          name="openclaimsReports"
                                          id="openclaimsReports"
                                          checked={this.state.openclaimsReports}
                                          value={this.state.openclaimsReports}
                                          onChange={() =>
                                            this.handleChildCheckboxChange(
                                              "openclaimsReports",
                                              "management_access"
                                            )
                                          }
                                        />
                                        <Label
                                          for="openclaimsReports"
                                          style={{ marginLeft: "2%" }}
                                        >
                                          Open Claims
                                        </Label>
                                      </Col>
                                      <Col>
                                        <Input
                                          type="checkbox"
                                          name="claimwiseprodReports"
                                          id="claimwiseprodReports"
                                          checked={this.state.claimwiseprodReports}
                                          value={this.state.claimwiseprodReports}
                                          onChange={() =>
                                            this.handleChildCheckboxChange(
                                              "claimwiseprodReports",
                                              "management_access"
                                            )
                                          }
                                        />
                                        <Label
                                          for="claimwiseprodReports"
                                          style={{ marginLeft: "2%" }}
                                        >
                                          Claimwise Production
                                        </Label>
                                      </Col>
                                      <Col>
                                        <Input
                                          type="checkbox"
                                          name="closedReports"
                                          id="closedReports"
                                          checked={this.state.closedReports}
                                          value={this.state.closedReports}
                                          onChange={() =>
                                            this.handleChildCheckboxChange(
                                              "closedReports",
                                              "management_access"
                                            )
                                          }
                                        />
                                        <Label
                                          for="closedReports"
                                          style={{ marginLeft: "2%" }}
                                        >
                                          Closed
                                        </Label>
                                      </Col>
                                      <Col>
                                        <Input
                                          type="checkbox"
                                          name="CombinedReports"
                                          id="combinedReports"
                                          checked={this.state.CombinedReports}
                                          value={this.state.CombinedReports}
                                          onChange={() =>
                                            this.handleChildCheckboxChange(
                                              "CombinedReports",
                                              "management_access"
                                            )
                                          }
                                        />
                                        <Label
                                          for="CombinedReports"
                                          style={{ marginLeft: "2%" }}
                                        >
                                          Combined
                                        </Label>
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </CardBody>
                            </Card>
                          </Row>
                          <Row style={{ paddingTop: "3%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  name="transaction_access"
                                  id="transaction_access"
                                  checked={this.state.transaction_access}
                                  value={this.state.transaction_access}
                                  onChange={() =>
                                    this.handleParentCheckboxChange(
                                      "transaction_access",
                                      [
                                        "KeyIndicators",
                                        "denialmanagement",
                                        "claimmanagement",
                                        "automationtrack",
                                      ]
                                    )}
                                />
                                <Label
                                  for="transaction_access"
                                  style={{ marginLeft: "2%" }}
                                >
                                  Claims
                                </Label>
                              </CardHeader>
                              <CardBody>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="KeyIndicators"
                                      id="KeyIndicators"
                                      checked={this.state.KeyIndicators}
                                      value={this.state.KeyIndicators}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "KeyIndicators",
                                          "transaction_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="KeyIndicators"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Key Indicators
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="denialmanagement"
                                      id="denialmanagement"
                                      checked={this.state.denialmanagement}
                                      value={this.state.denialmanagement}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "denialmanagement",
                                          "transaction_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="denialmanagement"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Denial Management
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="claimmanagement"
                                      id="claimmanagement"
                                      checked={this.state.claimmanagement}
                                      value={this.state.claimmanagement}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "claimmanagement",
                                          "transaction_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="claimmanagement"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Claim Management
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="automationtrack"
                                      id="automationtrack"
                                      checked={this.state.automationtrack}
                                      value={this.state.automationtrack}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "automationtrack",
                                          "transaction_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="automationtrack"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Automation Track
                                    </Label>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                          <Row style={{ paddingTop: "3%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  name="data_access"
                                  id="data_access"
                                  checked={this.state.data_access}
                                  value={this.state.data_access}
                                  onChange={() =>
                                    this.handleParentCheckboxChange(
                                      "data_access",
                                      [
                                        "Databasesync",
                                        "Apisync",
                                        "Datauploadfile",
                                        "Datadeletefile",
                                        "Dataconversionocr",
                                        "Datacleaning",
                                        "Datamigration",
                                      ]
                                    )}
                                />
                                <Label
                                  for="data_access"
                                  style={{ marginLeft: "2%" }}
                                >
                                  Data Processing
                                </Label>
                              </CardHeader>
                              <CardBody>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Databasesync"
                                      id="Databasesync"
                                      checked={this.state.Databasesync}
                                      value={this.state.Databasesync}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Databasesync",
                                          "data_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Databasesync"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Database Sync
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Apisync"
                                      id="Apisync"
                                      checked={this.state.Apisync}
                                      value={this.state.Apisync}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Apisync",
                                          "data_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Apisync"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      API Sync
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Datauploadfile"
                                      id="Datauploadfile"
                                      checked={this.state.Datauploadfile}
                                      value={this.state.Datauploadfile}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Datauploadfile",
                                          "data_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Datauploadfile"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Data Upload File
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Datadeletefile"
                                      id="Datadeletefile"
                                      checked={this.state.Datadeletefile}
                                      value={this.state.Datadeletefile}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Datadeletefile",
                                          "data_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Datadeletefile"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Data Delete File
                                    </Label>
                                  </Col>
                                </Row>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Dataconversionocr"
                                      id="Dataconversionocr"
                                      checked={this.state.Dataconversionocr}
                                      value={this.state.Dataconversionocr}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Dataconversionocr",
                                          "data_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Dataconversionocr"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Data Conversion OCR
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Datacleaning"
                                      id="Datacleaning"
                                      checked={this.state.Datacleaning}
                                      value={this.state.Datacleaning}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Datacleaning",
                                          "data_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Datacleaning"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Data Cleaning
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Datamigration"
                                      id="Datamigration"
                                      checked={this.state.Datamigration}
                                      value={this.state.Datamigration}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Datamigration",
                                          "data_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Datamigration"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Data Migration
                                    </Label>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                          <Row style={{ paddingTop: "3%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "white",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  name="ai_bot_access"
                                  id="ai_bot_access"
                                  checked={this.state.ai_bot_access}
                                  value={this.state.ai_bot_access}
                                  onChange={() =>
                                    this.handleParentCheckboxChange(
                                      "ai_bot_access",
                                      [
                                        "collectionprediction",
                                        "Denialprediction",
                                        "Overallprediction",
                                        "AiAssitant",
                                      ]
                                    )}
                                />
                                <Label
                                  for="ai_bot_access"
                                  style={{ marginLeft: "2%" }}
                                >
                                  AI Prediction
                                </Label>
                              </CardHeader>
                              <CardBody>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="collectionprediction"
                                      id="collectionprediction"
                                      checked={this.state.collectionprediction}
                                      value={this.state.collectionprediction}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "collectionprediction",
                                          "ai_bot_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="collectionprediction"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Collection Prediction Dashboard
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Denialprediction"
                                      id="Denialprediction"
                                      checked={this.state.Denialprediction}
                                      value={this.state.Denialprediction}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Denialprediction",
                                          "ai_bot_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Denialprediction"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Denial Prediction Dashboard
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Overallprediction"
                                      id="Overallprediction"
                                      checked={this.state.Overallprediction}
                                      value={this.state.Overallprediction}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Overallprediction",
                                          "ai_bot_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Overallprediction"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Overall Prediction
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="AiAssitant"
                                      id="AiAssitant"
                                      checked={this.state.AiAssitant}
                                      value={this.state.AiAssitant}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "AiAssitant",
                                          "ai_bot_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="AiAssitant"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      AI Assistant
                                    </Label>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                          <Row style={{ paddingTop: "3%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "#fff",
                                }}
                              >
                                Work Queue Access
                              </CardHeader>
                              <CardBody>
                                <Input
                                  type="checkbox"
                                  name="workqueue"
                                  id="workqueue"
                                  checked={this.state.workqueue}
                                  value={this.state.workqueue}
                                  onChange={() =>
                                    this.setState({
                                      workqueue: !this.state.workqueue,
                                    })
                                  }
                                />
                                <Label
                                  for="workqueue"
                                  style={{ marginLeft: "2%" }}
                                >
                                  Work Queue
                                </Label>
                              </CardBody>
                            </Card>
                          </Row>
                          <Row style={{ paddingTop: "3%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "#fff",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  name="allocation_access"
                                  id="allocation_access"
                                  checked={this.state.allocation_access}
                                  value={this.state.allocation_access}
                                  onChange={() =>
                                    this.handleParentCheckboxChange(
                                      "allocation_access",
                                      [
                                        "Allocated",
                                        "RejectionAndDenials",
                                        "UnAllocated",
                                      ]
                                    )
                                  }
                                />
                                <Label
                                  for="allocation_access"
                                  style={{ marginLeft: "2%" }}
                                >
                                  Allocation
                                </Label>
                              </CardHeader>
                              <CardBody>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Allocated"
                                      id="Allocated"
                                      checked={this.state.Allocated}
                                      value={this.state.Allocated}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Allocated",
                                          "allocation_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Allocated"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Allocated
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="UnAllocated"
                                      id="UnAllocated"
                                      checked={this.state.UnAllocated}
                                      value={this.state.UnAllocated}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "UnAllocated",
                                          "allocation_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="UnAllocated"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      UnAllocated
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="RejectionAndDenials"
                                      id="RejectionAndDenials"
                                      checked={this.state.RejectionAndDenials}
                                      value={this.state.RejectionAndDenials}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "RejectionAndDenials",
                                          "allocation_access"
                                        )
                                      }
                                    />
                                    <Label
                                      for="RejectionAndDenials"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Rejection And Denials
                                    </Label>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                          <Row style={{ paddingTop: "3%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "#fff",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  name="configure"
                                  id="configure"
                                  checked={this.state.configure}
                                  value={this.state.configure}
                                  onChange={() =>
                                    this.handleParentCheckboxChange(
                                      "configure",
                                      [
                                        "payercategory",
                                        "payermaster",
                                        "primarypayer",
                                        "cpt",
                                        "modifiers",
                                        "aging",
                                        "claimsubcategory",
                                        "actioncode",
                                        "actionstatuscode",
                                        "clientnotes",
                                        "location",
                                        "denialmaster",
                                        "billingprovider",
                                        "userDetails",
                                        "AdvBillingProvider",
                                        "claimstatus",
                                        "claimcategory",
                                        "ICD",
                                        "RefProvider",
                                        "GeneralSettings",
                                        "providerdetails",
                                        "notetypes",
                                      ]
                                    )
                                  }
                                />
                                <Label
                                  for="configure"
                                  style={{ marginLeft: "2%" }}
                                >
                                  Configure
                                </Label>
                              </CardHeader>
                              <CardBody>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="payercategory"
                                      id="payercategory"
                                      checked={this.state.payercategory}
                                      value={this.state.payercategory}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "payercategory",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="payercategory"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Payer Category
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="payermaster"
                                      id="payermaster"
                                      checked={this.state.payermaster}
                                      value={this.state.payermaster}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "payermaster",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="payermaster"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Payer Master
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="primarypayer"
                                      id="primarypayer"
                                      checked={this.state.primarypayer}
                                      value={this.state.primarypayer}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "primarypayer",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="primarypayer"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Primary Payer
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="cpt"
                                      id="cpt"
                                      checked={this.state.cpt}
                                      value={this.state.cpt}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "cpt",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="cpt"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      CPT
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="modifiers"
                                      id="modifiers"
                                      checked={this.state.modifiers}
                                      value={this.state.modifiers}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "modifiers",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="modifiers"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Modifiers
                                    </Label>
                                  </Col>
                                </Row>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="aging"
                                      id="aging"
                                      checked={this.state.aging}
                                      value={this.state.aging}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "aging",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="aging"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Aging
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="claimsubcategory"
                                      id="claimsubcategory"
                                      checked={this.state.claimsubcategory}
                                      value={this.state.claimsubcategory}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "claimsubcategory",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="claimsubcategory"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Claim Subcategory
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="actioncode"
                                      id="actioncode"
                                      checked={this.state.actioncode}
                                      value={this.state.actioncode}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "actioncode",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="actioncode"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Action code
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="actionstatuscode"
                                      id="actionstatuscode"
                                      checked={this.state.actionstatuscode}
                                      value={this.state.actionstatuscode}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "actionstatuscode",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="actionstatuscode"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Action Status Code
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="clientnotes"
                                      id="clientnotes"
                                      checked={this.state.clientnotes}
                                      value={this.state.clientnotes}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "clientnotes",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="clientnotes"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Client Notes
                                    </Label>
                                  </Col>
                                </Row>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="location"
                                      id="location"
                                      checked={this.state.location}
                                      value={this.state.location}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "location",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="location"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Location
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="denialmaster"
                                      id="denialmaster"
                                      checked={this.state.denialmaster}
                                      value={this.state.denialmaster}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "denialmaster",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="denialmaster"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Denial Master
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="billingprovider"
                                      id="billingprovider"
                                      checked={this.state.billingprovider}
                                      value={this.state.billingprovider}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "billingprovider",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="billingprovider"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Billing Provider
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="userDetails"
                                      id="userDetails"
                                      checked={this.state.userDetails}
                                      value={this.state.userDetails}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "userDetails",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="userDetails"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      User Details
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="AdvBillingProvider"
                                      id="AdvBillingProvider"
                                      checked={this.state.AdvBillingProvider}
                                      value={this.state.AdvBillingProvider}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "AdvBillingProvider",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="AdvBillingProvider"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Adv Billing Provider
                                    </Label>
                                  </Col>
                                </Row>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="claimstatus"
                                      id="claimstatus"
                                      checked={this.state.claimstatus}
                                      value={this.state.claimstatus}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "claimstatus",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="claimstatus"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Claim Status
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="claimcategory"
                                      id="claimcategory"
                                      checked={this.state.claimcategory}
                                      value={this.state.claimcategory}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "claimcategory",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="claimcategory"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Claim Category
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="ICD"
                                      id="ICD"
                                      checked={this.state.ICD}
                                      value={this.state.ICD}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "ICD",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="ICD"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      ICD
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="RefProvider"
                                      id="RefProvider"
                                      checked={this.state.RefProvider}
                                      value={this.state.RefProvider}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "RefProvider",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="RefProvider"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Refferal Provider
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="GeneralSettings"
                                      id="GeneralSettings"
                                      checked={this.state.GeneralSettings}
                                      value={this.state.GeneralSettings}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "GeneralSettings",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="GeneralSettings"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      General Settings
                                    </Label>
                                  </Col>
                                </Row>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="providerdetails"
                                      id="providerdetails"
                                      checked={this.state.providerdetails}
                                      value={this.state.providerdetails}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "providerdetails",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="providerdetails"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Provider Details
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="notetypes"
                                      id="notetypes"
                                      checked={this.state.notetypes}
                                      value={this.state.notetypes}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "notetypes",
                                          "configure"
                                        )
                                      }
                                    />
                                    <Label
                                      for="notetypes"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Note Types
                                    </Label>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                          <Row style={{ paddingTop: "3%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "#fff",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  name="usermanagement"
                                  id="usermanagement"
                                  checked={this.state.usermanagement}
                                  value={this.state.usermanagement}
                                  onChange={() =>
                                    this.handleParentCheckboxChange(
                                      "usermanagement",
                                      [
                                        "useronboard",
                                        "Roles",
                                        "target",
                                      ]
                                    )}
                                />
                                <Label
                                  for="usermanagement"
                                  style={{ marginLeft: "2%" }}
                                >
                                  User Management
                                </Label>
                              </CardHeader>
                              <CardBody>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="useronboard"
                                      id="useronboard"
                                      checked={this.state.useronboard}
                                      value={this.state.useronboard}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "useronboard",
                                          "usermanagement"
                                        )
                                      }
                                    />
                                    <Label
                                      for="useronboard"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      User on Board
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="Roles"
                                      id="Roles"
                                      checked={this.state.Roles}
                                      value={this.state.Roles}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "Roles",
                                          "usermanagement"
                                        )
                                      }
                                    />
                                    <Label
                                      for="Roles"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Roles
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="target"
                                      id="target"
                                      checked={this.state.target}
                                      value={this.state.target}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "target",
                                          "usermanagement"
                                        )
                                      }
                                    />
                                    <Label
                                      for="target"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Target
                                    </Label>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                          <Row style={{ paddingTop: "3%" }}>
                            <Card
                              style={{
                                backgroundColor: "#FAF9F6",
                                padding: "15px",
                                marginLeft: "2%",
                              }}
                            >
                              <CardHeader
                                style={{
                                  backgroundColor: "#041c3c",
                                  color: "#fff",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  name="eligibility"
                                  id="eligibility"
                                  checked={this.state.eligibility}
                                  value={this.state.eligibility}
                                  onChange={() =>
                                    this.handleParentCheckboxChange(
                                      "eligibility",
                                      [
                                        "eligibilitydataupload",
                                        "eligibilityTracker",
                                        "eligibilityquickcheck",
                                        "UploadAuth",
                                        "PriorAuthTracker"
                                      ]
                                    )
                                  }
                                />
                                <Label
                                  for="eligibility"
                                  style={{ marginLeft: "2%" }}
                                >
                                  Eligibility
                                </Label>
                              </CardHeader>
                              <CardBody>
                                <Row
                                  style={{ marginTop: "2%", marginLeft: "4%" }}
                                >
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="eligibilitydataupload"
                                      id="eligibilitydataupload"
                                      checked={this.state.eligibilitydataupload}
                                      value={this.state.eligibilitydataupload}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "eligibilitydataupload",
                                          "eligibility"
                                        )
                                      }
                                    />
                                    <Label
                                      for="eligibilitydataupload"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Data upload
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="eligibilityTracker"
                                      id="eligibilityTracker"
                                      checked={this.state.eligibilityTracker}
                                      value={this.state.eligibilityTracker}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "eligibilityTracker",
                                          "eligibility"
                                        )
                                      }
                                    />
                                    <Label
                                      for="eligibilityTracker"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Eligibility Tracker
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="eligibilityquickcheck"
                                      id="eligibilityquickcheck"
                                      checked={this.state.eligibilityquickcheck}
                                      value={this.state.eligibilityquickcheck}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "eligibilityquickcheck",
                                          "eligibility"
                                        )
                                      }
                                    />
                                    <Label
                                      for="eligibilityquickcheck"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Eligibility Quick Check
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="UploadAuth"
                                      id="UploadAuth"
                                      checked={this.state.UploadAuth}
                                      value={this.state.UploadAuth}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "UploadAuth",
                                          "eligibility"
                                        )
                                      }
                                    />
                                    <Label
                                      for="UploadAuth"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Upload Authorization
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      name="PriorAuthTracker"
                                      id="PriorAuthTracker"
                                      checked={this.state.PriorAuthTracker}
                                      value={this.state.PriorAuthTracker}
                                      onChange={() =>
                                        this.handleChildCheckboxChange(
                                          "PriorAuthTracker",
                                          "eligibility"
                                        )
                                      }
                                    />
                                    <Label
                                      for="PriorAuthTracker"
                                      style={{ marginLeft: "2%" }}
                                    >
                                      Prior Auth Tracker
                                    </Label>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                          <br />
                          <p style={{fontWeight:"800"}}>Dashboard-2</p>
                          <Card
                            style={{
                              backgroundColor: "#FAF9F6",
                              padding: "15px",
                              marginLeft: "2%",
                            }}
                          >
                            <CardBody>
                              <Row style={{ paddingTop: "1%" }}>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="Performances"
                                    id="Performances"
                                    checked={this.state.Performances}
                                    onChange={() =>
                                      this.setState({
                                        Performances: !this.state.Performances,
                                      })
                                    }
                                  />
                                  <Label
                                    for="Performances"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Performances
                                  </Label>
                                </Col>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="Completed"
                                    id="Completed"
                                    checked={this.state.Completed}
                                    onChange={() =>
                                      this.setState({
                                        Completed: !this.state.Completed,
                                      })
                                    }
                                  />
                                  <Label
                                    for="Completed"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Completed
                                  </Label>
                                </Col>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="Inprogress"
                                    id="Inprogress"
                                    checked={this.state.Inprogress}
                                    onChange={() =>
                                      this.setState({
                                        Inprogress: !this.state.Inprogress,
                                      })
                                    }
                                  />
                                  <Label
                                    for="Inprogress"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Inprogress
                                  </Label>
                                </Col>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="Denial"
                                    id="Denial"
                                    checked={this.state.Denial}
                                    onChange={() =>
                                      this.setState({
                                        Denial: !this.state.Denial,
                                      })
                                    }
                                  />
                                  <Label
                                    for="Denial"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Denial
                                  </Label>
                                </Col>
                              </Row>
                              <Row style={{ paddingTop: "3%" }}>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="NeedToCall"
                                    id="NeedToCall"
                                    checked={this.state.NeedToCall}
                                    onChange={() =>
                                      this.setState({
                                        NeedToCall: !this.state.NeedToCall,
                                      })
                                    }
                                  />
                                  <Label
                                    for="NeedToCall"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Need To Call
                                  </Label>
                                </Col>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="Pending"
                                    id="Pending"
                                    checked={this.state.Pending}
                                    onChange={() =>
                                      this.setState({
                                        Pending: !this.state.Pending,
                                      })
                                    }
                                  />
                                  <Label
                                    for="Pending"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Pending
                                  </Label>
                                </Col>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="workqueue1"
                                    id="workqueue1"
                                    checked={this.state.workqueue1}
                                    onChange={() =>
                                      this.setState({
                                        workqueue1: !this.state.workqueue1,
                                      })
                                    }
                                  />
                                  <Label
                                    for="workqueue1"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Work Queue
                                  </Label>
                                </Col>
                                <Col>
                                  <Input
                                    type="checkbox"
                                    name="Rejection1"
                                    id="Rejection1"
                                    checked={this.state.Rejection1}
                                    onChange={() =>
                                      this.setState({
                                        Rejection1: !this.state.Rejection1,
                                      })
                                    }
                                  />
                                  <Label
                                    for="Rejection1"
                                    style={{ marginLeft: "2%" }}
                                  >
                                    Rejection & Denials
                                  </Label>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                          <br/>
                          <Card
                          style={{
                            backgroundColor: "#FAF9F6",
                            padding: "15px",
                            marginLeft: "2%",
                          }}
                          >
                          <CardHeader
                          style={{
                            backgroundColor: "#041c3c",
                            color: "#fff",
                          }}
                          >
                          <Row>
                          <Col>
                            <Input
                              type="checkbox"
                              name="ClaimTracker"
                              id="ClaimTracker"
                              checked={this.state.ClaimTracker}
                              onChange={() =>
                                this.setState({
                                  ClaimTracker:
                                    !this.state.ClaimTracker,
                                })
                              }
                            />
                            <Label
                              for="ClaimTracker"
                              style={{ marginLeft: "2%" }}
                            >
                              Claim Tracker
                            </Label>
                          </Col>
                        </Row>
                          </CardHeader>
                          </Card>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button
                    style={{
                      border: "1px solid grey",
                      color: "black",
                      backgroundColor: "#fff",
                    }}
                    onClick={() => {
                      this.openmodal();
                      this.reset();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ backgroundColor: "black", color: "white" }}
                    onClick={() => {
                      this.addRole();
                    }}
                  >
                    Add
                  </Button>
                </ModalFooter>
              </Modal>
            )}
            <Modal
              isOpen={this.state.successmodal}
              onHide={this.successbox}
              centered
            >
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Role Access Updated!</Row>
                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid #268da5",
                    color: "#268da5",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.successbox}
                >
                  Cancel
                </Button>
                <Link to="/">
                  <Button style={{ backgroundColor: "#268da5" }}>
                    Redirect to Home Page
                  </Button>
                </Link>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
export default Roles;
