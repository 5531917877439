import React, { useState, useEffect } from "react";
import axios from "axios";
import { ip } from "../../../Api";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Table } from "reactstrap";

const UploadedClaims = (props) => {
  const [fetchingClaims, setFetchingClaims] = useState(true);
  const [refreshToken] = useState(sessionStorage.getItem("refreshtoken"));
  const [accessToken, setAccessToken] = useState(sessionStorage.getItem("accesstoken"));
  const [client] = useState(sessionStorage.getItem("clientid"));
  const [uploadedClaimsArray, setUploadedClaimsArray] = useState([]);
  const [currentpage1, setCurrentPage1] = useState(0);
  const [perPage1] = useState(10);
  const [pageCount1, setPageCount1] = useState(0);

  const handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    setCurrentPage1(selectedPage);
  };
  const getDownloadReports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    let url;
    if (props.startdate && props.enddate) {
      url = `${ip}/helper/uploaded/claims?clientId=${client}&startdate=${props.startdate}&enddate=${props.enddate}`;
    } else {
      url = `${ip}/helper/uploaded/claims?clientId=${client}`;
    }

    fetch(url, { headers: headers })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", "Uploaded Claims");
        a.click();
        URL.revokeObjectURL(a);
      })
      .catch((error) => console.error("Error downloading reports", error));
  };

  const getUploadedReports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    let url;
    if (props.startdate && props.enddate) {
      url = `${ip}/helper/uploaded/claims?clientId=${client}&startdate=${props.startdate}&enddate=${props.enddate}&pageno=${currentpage1}`;
    } else {
      url = `${ip}/helper/uploaded/claims?clientId=${client}&pageno=${currentpage1}`;
    }

    axios
      .get(url, { headers })
      .then((res) => {
        setPageCount1(Math.ceil(res.data.count / perPage1));
        setUploadedClaimsArray(res.data.data);
        setFetchingClaims(false);
      })
      .catch((error) => console.error("Error fetching uploaded reports", error));
  };

  useEffect(() => {
    getUploadedReports();
  }, [currentpage1, props.startdate, props.enddate]);

  return fetchingClaims ? (
    <div>
      <p
        style={{
          fontSize: "23px",
          marginTop: "10px",
          color: "#000",
        }}
      >
        <span>Loading...</span>
        <span>Please Wait...</span>
      </p>
      <div className="boxes body__loader dribbble">
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  ) : uploadedClaimsArray.length === 0 ? (
    <div className="bot-ui">
      <div className="bot-head">
        <div className="eyes-container">
          <div className="to-left eye"></div>
          <div className="to-right eye"></div>
        </div>
      </div>
      <div className="bot-body">
        <div className="analyzer"></div>
      </div>
      <p style={{ fontSize: "20px", fontStyle: "italic" }}>No Data Found</p>
    </div>
  ) : (
    <div>
      <div>
        <button
          style={{
            boxShadow: "inset 0px 1px 0px 0px #E184F3",
            backgroundColor: "black",
            borderRadius: "6px",
            border: "1px solid black",
            display: "inline-block",
            cursor: "pointer",
            color: "#FFFFFF",
            fontSize: "13px",
            fontWeight: "bold",
            padding: "3px 18px",
            textDecoration: "none",
            textShadow: "0px 1px 0px #9B14B3",
            float: "right",
          }}
          onClick={getDownloadReports}
        >
          Generate Report
        </button>
      </div>
      <br />
      <div style={{ width: "100%" }}>
        <Table
          className="StandardTable"
          bordered
          style={{
            marginLeft: "3%",
            width: "99%",
          }}
        >
          <thead>
            <tr align="center">
              <th>Client ID</th>
              <th>File name</th>
              <th>Inserted</th>
              <th>Not Inserted</th>
            </tr>
          </thead>
          <tbody>
            {uploadedClaimsArray &&
              uploadedClaimsArray.map((data, index) => (
                <tr key={index}>
                  <td>{data.clientId}</td>
                  <td>{data.filename}</td>
                  <td>{data.inserted}</td>
                  <td>{data.notinserted}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <br />
      <div>
        <ReactPaginate
          previousLabel={<IoIosArrowBack />}
          nextLabel={<IoIosArrowForward />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount1}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick1}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};

export default UploadedClaims;
