import { Component } from "react";
import axios from "axios";
import React from "react";
import {
  Area,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Bar,
  ComposedChart,
  LabelList,
  Label,
} from "recharts";
import { ip } from "../../../Api";
const data = [
  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },
  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },
  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },

  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },
  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },
];
let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: "#0033cc" }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};
const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: "#0033cc" }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};
class PripayerChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pripayamount: [],
      pripaycount: [],
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
    };
  }

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getinventory = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.pripayerStart !== "" && this.props.pripayerEnd !== "") {
          url = `${ip}/inventory/pripayer?start_date=${this.props.pripayerStart}&end_date=${this.props.pripayerEnd}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/inventory/pripayer?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.pripayerStart !== "" && this.props.pripayerEnd !== "") {
          url = `${ip}/inventory/pripayer?start_date=${this.props.pripayerStart}&end_date=${this.props.pripayerEnd}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/inventory/pripayer?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.pripayerStart !== "" && this.props.pripayerEnd !== "") {
        url = `${ip}/inventory/pripayer?start_date=${this.props.pripayerStart}&end_date=${this.props.pripayerEnd}`;
      } else {
        url = `${ip}/inventory/pripayer`;
      }
    }
    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        if (req.data.touch.length !== 0) {
          this.setState({ pripayamount: req.data.touch });

          this.setState({ pripaycount: req.data.touch });
        } else {
          this.setState({
            pripayamount: data,
            pripaycount: data,
          });
        }
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
    this.getinventory();
  }
  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.pripayerStart === prevProps.pripayerStart &&
        this.props.pripayerEnd === prevProps.pripayerEnd
      )
    ) {
      this.getinventory();
    } else if (this.props.client !== prevProps.client) {
      this.getinventory();
    } else if (this.props.Location !== prevProps.Location) {
      this.getinventory();
    }
  }

  render() {
    return (
      <div>
        {this.props.pripayamount === "$" && (
          <div onMouseOver={() => (tooltip = "totalamount")}>
            <ResponsiveContainer width={"95%"} height={400}>
              <ComposedChart
                width={450}
                height={350}
                data={this.state.pripayamount}
                stopColor="red"
                margin={{
                  top: 13,
                  right: 30,
                  left: 10,
                  bottom: 35,
                }}
              >
                <XAxis dataKey={"_id"} height={120} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}} />
                <YAxis dataKey={"totalamount"} fontSize="10" />
                <Tooltip content={<CustomTooltip />} />
                <defs>
                  <linearGradient
                    id="colorYv"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="100%"
                    spreadMethod="reflect"
                  >
                    <stop offset="0" stopColor="#0033cc" />
                    <stop offset="1" stopColor="#33ccff" />
                  </linearGradient>
                </defs>
                <Bar
                  name="Total Amount"
                  onMouseOver={() => (tooltip = "totalamount")}
                  dataKey="totalamount"
                  barSize={30}
                  fill="url(#colorYv)"
                >
                  {/* <LabelList dataKey="totalamt" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
                </Bar>
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        )}
        {this.props.pripayamount === "#" && (
          <div onMouseOver={() => (tooltip = "totalcount")}>
            <ResponsiveContainer width={"95%"} height={400}>
              <ComposedChart
                width={450}
                height={350}
                data={this.state.pripaycount}
                stopColor="red"
                margin={{
                  top: 13,
                  right: 30,
                  left: 10,
                  bottom: 35,
                }}
              >
                <XAxis dataKey={"_id"} height={120} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}} />
                {/* tick={{strokeWidth:0,fontSize:"12",top:400,textAnchor:"start",dy:6}}angle={"45"} */}
                <YAxis dataKey={"totalcount"} fontSize="10" />
                <Tooltip content={<ClaimTooltip />} />
                <defs>
                  <linearGradient
                    id="colorYv"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="100%"
                    spreadMethod="reflect"
                  >
                    <stop offset="0" stopColor="#0033cc" />
                    <stop offset="1" stopColor="#33ccff" />
                  </linearGradient>
                </defs>
                <Bar
                  name="Total Claims"
                  dataKey="totalcount"
                  barSize={30}
                  fill="url(#colorYv)"
                >
                  {/* <LabelList dataKey="totalclaims" type="number" position='top' fill='#7a051b' fontWeight='60' fontFamily='Poppins' /> */}
                </Bar>
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    );
  }
}
export default PripayerChart;
