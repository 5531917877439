import { React, Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import "../../Styles/TeamPerformances.css";
import "../../Styles/performances.css";
import { Input } from "reactstrap";
import moment from "moment";
import PerformanceComponent from "./PerfomanceComponent";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { FormControl } from "react-bootstrap";
import { IoIosRefresh } from "react-icons/io";

class Performances extends Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    this.state = {
      start: start,
      end: end,
      role_type: sessionStorage.getItem("role_type"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      UserId: sessionStorage.getItem("user_id"),
      clientname: sessionStorage.getItem("clientname"),
      touchwise: [],
      name__user: "$",
      date: "Oct 13",
      filterstatus: "custom",
      range: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      outcomes__alloc: [],
      outcomes__notwork: [],
      outcomes__total: [],
      outcomes__value: [],
      outcomes__worked: "",
      outcomes__role: [],
      paid: "",
      paidamount: "",
      deniedamount: "",
      denied: "",
      nis: [],
      inprocess: [],
      valueOfInput1: "",
      valueOfInput2: "",
      paidperc: "",
      deniedperc: "",
      allocated: "",
      alloworked: "",
      productivity: "",
      target: "",
      clientid: sessionStorage.getItem("clientid"),
      target: "",
      check: false,
      startdatetime: "",
      enddatetime: "",
    };
    this.applyCallback = this.applyCallback.bind(this);
  }
  applyCallback = (startDate, endDate) => {
    let start = moment(startDate._d).format().substring(0, 16);
    let end = moment(endDate._d).format().substring(0, 16);
    this.setState({
      startdatetime: start,
      enddatetime: end,
    });
  };
  //to renew accesstoken

  toggleCheck = () => {
    this.setState({ check: !this.state.check });
  };
  valuedate = (option, e) => {
    if(option === "yesterday") {
    this.setState({
      startdatetime : e.target.value,
      enddatetime : moment().subtract(1, "days").format("MM-DD-YYYY HH:mm"),
      filterstatus: option,
    });
  }
    else{
      this.setState({
        startdatetime : e.target.value,
        enddatetime : moment().format("MM-DD-YYYY HH:mm"),
        filterstatus: option,
      });
    }
  }
  render() {
    let value;
    let local = {
      format: "MM-DD-YYYY HH:mm",
      sundayFirst: false,
    };

    if (this.state.startdatetime && this.state.enddatetime) {
      value = `${moment(this.state.startdatetime.substring(
        0,
        16
      )).format("MM-DD-YYYY HH:mm")} ~ ${moment(this.state.enddatetime.substring(0, 16)).format("MM-DD-YYYY HH:mm")}`;
    } else {
      value = "Start Date ~ End Date";
    }
    return (
      <div>
      <Newsidebar name="Performance" />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Input
              type="select"
              style={{ width: "20%", margin:"1%" }}
              className="form-control form-select"
              onChange={(e) => {
                const index = e.target.selectedIndex;
                const el = e.target.childNodes[index];
                const option = el.getAttribute("title");
                this.valuedate(option,e)
                
              }}

            >
              <option value="" title="custom">
                Custom
              </option>
              <option value={moment().format("MM-DD-YYYY HH:mm")} title="">
                Today
              </option>
              <option
                value={moment().subtract(1, "days").format("MM-DD-YYYY HH:mm")}
                title="yesterday"
              >
                Yesterday
              </option>
              <option
                value={moment().subtract(3, "days").format("MM-DD-YYYY HH:mm")}
                title=""
              >
                Last 3 days
              </option>
              <option
                value={moment().subtract(7, "days").format("MM-DD-YYYY HH:mm")}
                title=""
              >
                Last 7 days
              </option>
              <option
                value={moment().subtract(30, "days").format("MM-DD-YYYY HH:mm")}
                title=""
              >
                Last 30 days
              </option>
              <option
                value={moment().subtract(365, "days").format("MM-DD-YYYY HH:mm")}
                title=""
              >
                Last 365 days
              </option>
            </Input>
            {this.state.filterstatus === "custom" && (
              <div
              style={{width:"30%", margin:"1%"}}>
              <DateTimeRangeContainer
              start={this.state.start}
              end={this.state.end}
              applyCallback={this.applyCallback}
              local={local}
            >
              <FormControl
                id="formControlsTextB"
                type="text"
                label="Text"
                placeholder="Select Date Range"
                // disabled={disabled}
                value={value}
              />
            </DateTimeRangeContainer>
            </div>
            )}
            <IoIosRefresh
            style={{
              color: "blue",
              width: "38px",
              marginTop:"1%",
              height: "38px",
              padding: "12px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
            }}
            onClick={(e) => {
              this.setState({ startdatetime: "", enddatetime: "" });
            }}
          ></IoIosRefresh>
          </div>
          <PerformanceComponent
            startdate={this.state.startdatetime}
            enddate={this.state.enddatetime}
            client={this.state.clientid}
          />
      </div>
    );
  }
}
export default Performances;
