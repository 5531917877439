import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import { Table } from "react-bootstrap";
import { FaRegEdit } from "react-icons/fa";
import { Row, Col, Form, Label, Input, Button, FormGroup } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MdOutlineDelete } from "react-icons/md";
import ReactPaginate from "react-paginate";
import "../../Styles/clientNotes.css";
import axios from "axios";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import loading from "../../Images/loader.gif";
import { ip } from "../../Api";

export default class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role_type: sessionStorage.getItem("role_type"),
      client: sessionStorage.getItem("clientid"),
      fetching: true,
      editmodal: false,
      deletemodal: false,
      addnewmodal: false,
      locations: [],
      updateLocations: "",
      updateClientId: "",
      updateStatus: "",
      id: "",
      clientid: sessionStorage.getItem("clientid"),
      clientName: sessionStorage.getItem("clientname"),
      location: "",
      status: "Active",
      clientslist: [],
      currentpage: 0,
      perPage: 10,
    };
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getLocation();
      }
    );
  };
  openmodal = () => {
    this.setState({ addnewmodal: !this.state.addnewmodal });
  };
  openeditmodal = () => {
    this.setState({ editmodal: !this.state.editmodal });
  };
  opendeletemodal = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  addLocation = async () => {
    this.openmodal();
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      clientId: this.state.clientid,
      Location: this.state.location,
      status: this.state.status,
    };
    const res = await axios
      .post(`${ip}/location/create`, value, { headers: headers })
      .then((res) => {
        if (res) {
          this.getLocation();
          this.setState({ createmodal: !this.state.createmodal });
          toast.success("Location successfully created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.getLocation();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  updateLocation = async () => {
    this.openeditmodal();
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      Location: this.state.updateLocations,
      status: this.state.updateStatus,
    };
    const res = await axios
      .put(`${ip}/location/${this.state.id}`, value, { headers: headers })
      .then((res) => {
        if (res) {
          this.getLocation();
          this.setState({ createmodal: !this.state.createmodal });
          // this.openeditmodal();
          toast.info("Location Updated", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          // this.openeditmodal();
          this.getLocation();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  deleteLocation = async () => {
    this.opendeletemodal();
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = await axios
      .delete(`${ip}/location/${this.state.id} `, { headers: headers })
      .then((res) => {
        if (res) {
          // this.opendeletemodal();
          this.getLocation();
          this.setState({ createmodal: !this.state.createmodal });
          toast.error("Location deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          // this.opendeletemodal();
          this.getLocation();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        this.setState({
          clientslist: res.data.clientonboard,
          // clientname: res.data.clientonboard[0].client,
        });
      })
      .catch((err) => {});
  };
  getLocation = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.client) {
      url = `${ip}/location/all?pageno=${this.state.currentpage}&clientId=${this.state.client}`;
    } else {
      url = `${ip}/location/all?pageno=${this.state.currentpage}`;
    }
    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          locations: res.data.location,
          pageCount: res.data.count / this.state.perPage,
          fetching: false,
        });
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
    this.getLocation();
    this.getclients();
  }
  render() {
    return (
      <div>
      <Newsidebar name="Location" />
      <div className="align-row" style={{overflow:"hidden"}}>
        <div className="align-column">
          <br />
          <ToastContainer></ToastContainer>
          <button
          onClick={this.openmodal}
          style={{
            borderRadius: "8px",
            color: "#000",
            padding:"5px",
            background: "White",
            fontWeight: "bold",
            float:"right",
            marginRight:"2%"
          }}
        >
          Add Location
        </button>
        <br/>
          <div className="location__table">
            {this.state.fetching ? (
              <div>
                <p
                  style={{ fontSize: "23px", marginTop: "25px", color: "#000" }}
                >
                  <span>Loading...</span>
                  <span>Please Wait...</span>
                </p>
                <div className="boxes body__loader dribbble">
                  <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            ) : this.state.locations.length === 0 ? (
              <div className="bot-ui">
                <div className="bot-head">
                  <div className="eyes-container">
                    <div className="to-left eye"></div>
                    <div className="to-right eye"></div>
                  </div>
                </div>
                <div className="bot-body">
                  <div className="analyzer"></div>
                </div>
                <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                  No Data Found
                </p>
              </div>
            ) : (
              <div>
                <Table responsive="md" bordered className="StandardTable">
                  <thead>
                    <tr>
                      <th>
                        Location
                        &nbsp;&nbsp;&nbsp;
                 
                      </th>
                      <th>
                        Client Id
                      </th>
                      <th>
                        Status
                      </th>
                      <th>
                        Edit
                      </th>
                      <th>
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.locations.map((data, index) => (
                      <tr>
                        <td
                          style={{
                            textAlign: "center",
                            
                          }}
                        >
                          {data.Location}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            
                          }}
                        >
                          {data.clientId}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            
                          }}
                        >
                          {data.status}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            fontSize: "20px",
                            
                            width: "15px",
                          }}
                        >
                          <button
                            style={{ border: "none" }}
                            onClick={() => {
                              this.setState({
                                id: data._id,
                                updateLocations: data.Location,
                                updateClientId: data.clientId,
                                updateStatus: data.status,
                              });
                              this.openeditmodal();
                            }}
                          >
                            <FaRegEdit />
                          </button>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            fontSize: "20px",
                            width: "15px",
                          }}
                        >
                          <button
                            style={{ border: "none" }}
                            onClick={() => {
                              this.setState({ id: data._id });
                              this.opendeletemodal();
                            }}
                          >
                            <MdOutlineDelete />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                  <ReactPaginate
                    previousLabel={<IoIosArrowBack />}
                    nextLabel={<IoIosArrowForward />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <Modal show={this.state.addnewmodal} onHide={this.openmodal} centered>
          <Modal.Header closeButton closeLabel="">
            <Modal.Title>Add Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Label className="required">Location</Label>
                  <Input
                    type="text"
                    value={this.state.location}
                    onChange={(e) =>
                      this.setState({ location: e.target.value })
                    }
                  ></Input>
                </Col>
              </Row>
              <br />
              <Row>
                {this.state.role_type === "SuperAdmin" && (
                  <Col>
                    <Label className="required">Client</Label>
                    <Input
                      type="select"
                      className="form-control form-select"
                      value={this.state.clientid}
                      onChange={(e) =>
                        this.setState({ clientid: e.target.value })
                      }
                    >
                      <option value="" hidden>
                        Select Clients...
                      </option>
                      {this.state.clientslist.map((data, index) => (
                        <option value={data.clientId}>{data.client}</option>
                      ))}
                    </Input>
                  </Col>
                )}
                {this.state.role_type === "Admin" && (
                  <Col>
                    <Label>Client</Label>
                    <Input
                      type="text"
                      disabled
                      value={this.state.clientName}
                    ></Input>
                  </Col>
                )}
              </Row>
              <br />
              <Row>
                <Col>
                  <Label for="select" className="required">
                    Status
                  </Label>
                  <Input
                    type="select"
                    name="select"
                    value={this.state.status}
                    onChange={(e) => this.setState({ status: e.target.value })}
                    className="form-control form-select"
                  >
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </Input>
                </Col>
              </Row>
              <br />
              <br />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.openmodal();
                //   this.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={this.addLocation}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.editmodal} onHide={this.openeditmodal} centered>
          <Modal.Header closeButton closeLabel="">
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <FormGroup>
                  <Label>Location</Label>
                  <Input
                    type="text"
                    // required
                    value={this.state.updateLocations}
                    onChange={(e) =>
                      this.setState({
                        updateLocations: e.target.value,
                      })
                    }
                  >
                    {this.state.updateLocations}
                  </Input>
                </FormGroup>
              </Row>
              <br />
              <Row>
                <FormGroup>
                  <Col>
                    <Label>Client Id</Label>
                    <Input
                      type="text"
                      value={this.state.updateClientId}
                      disabled
                    >
                      {this.state.updateClientId}
                    </Input>
                  </Col>
                </FormGroup>
              </Row>
              <br />
              <Row>
                <Col>
                  <Label for="select">Status</Label>
                  <Input
                    type="select"
                    name="select"
                    value={this.state.updateStatus}
                    onChange={(e) =>
                      this.setState({ updateStatus: e.target.value })
                    }
                    className="form-control form-select"
                  >
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </Input>
                </Col>
              </Row>
              <br />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.openeditmodal();
                //   this.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "#041c3c", color: "white" }}
              onClick={this.updateLocation}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.deletemodal}
          onHide={this.opendeletemodal}
          centered
        >
          <Modal.Header closeButton closeLabel="">
            <Modal.Title>Delete Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to delete this Location?</Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.opendeletemodal();
                //   this.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "#041c3c", color: "white" }}
              onClick={this.deleteLocation}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.createmodal} centered>
          <Modal.Body>
            <img
              src={loading}
              style={{ width: "200px", height: "200px", marginLeft: "30%" }}
            />
          </Modal.Body>
        </Modal>
      </div>
      </div>
    );
  }
}
