import { Component } from "react";
import {RadialBarChart,RadialBar ,Legend, Tooltip,Cell, Pie, PieChart} from "recharts";
const style = {
  top: '50%',
    marginLeft:"30%",
  transform: 'translate(0, -50%)',
  lineHeight: '13px',
  
};
const data=[
    {
  name:"99053",
  totalamount:"3000",
  totalcount:50,
  fill:"#ff3333"
    },
    {
      name:"99056",
      totalamount:"2000",
  totalcount:40,
  fill:"#992600"
    },
    {
      name:"99045",
      totalamount:"1500",
  totalcount:20,
  fill:"#8C7380"
    },
    {
      name:"99058",
      totalamount:"4000",
  totalcount:60,
  fill:"#FF0080"
    },
    {
      name:"98059",
      totalamount:"2500",
  totalcount:100,
  fill:"#00BFFF"
    }
  ];
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
   const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  class TopImpactorChart extends Component{
      render()
      {
          return(
              <div>
              
                  {this.props.data ==="$" &&  
                  <RadialBarChart   style={{top:30}}width={300}  
      height={250} innerRadius="10%" outerRadius="120%" barSize={10} data={data}  >
                <RadialBar
                 
                  minAngle={15}
                  // label={{ position: 'insideStart', fill: '#fff' }}
                  background
                  clockWise
                  
                  dataKey="totalamount"
                />
                <Tooltip/>
                <Legend fontSize={"2px"} iconSize={7} layout="horizontal" verticalAlign="middle" wrapperStyle={{style,left:10,top:250}} />
              </RadialBarChart>
              
     
    }

    {this.props.data==="#" && 
        <PieChart width={350} height={320} style={{top:10}}   >
          <Pie
 
            data={data}
            cx="50%"
            cy="50%"
             labelLine={false}
             label={renderCustomizedLabel}
            outerRadius={120}
            fill="#8884d8"
            dataKey="totalcount"
            
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend align="center"   iconSize={10} layout="horizontal" verticalAlign="bottom" wrapperStyle={{style, left:10,top:270}}></Legend>
        </PieChart>
      }
              </div>
          )
      }
  }
  export default TopImpactorChart;