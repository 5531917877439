import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import Badge from "@mui/material/Badge";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import Twilio from "../Twilio";
import {Table} from "react-bootstrap";
import "../../../Styles/loader.scss";
import { BiUpArrowAlt } from "react-icons/bi";
import { BiDownArrowAlt } from "react-icons/bi";
import {
  MdKeyboardArrowRight,
} from "react-icons/md";
import moment from "moment-timezone";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import SimpleMenu from "../AllocationCardComponents/MenuList";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ViewClaim from "./../AllocationCardComponents/ViewClaim";
import { ip } from "../../../Api";
import { IoIosRefresh } from "react-icons/io";
import Denialtableallocate1 from "./Denialtableallocate1";
import Denialtableallocate3 from "./Denialtableallocate3";
import Denialtableallocate2 from "./Denialtableallocate2";
import Viewmoreeligibility from "../../Eligibility/Viewmoreeligibility";
class DenialDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // value: sessionStorage.getItem("Allocatedenialtable"),
      value: this.props.location.state.current,
      pastvalue: this.props.location.state.past,
      pastvalue1: this.props.location.state.past1,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      querystartdate: sessionStorage.getItem("querystartdate"),
      queryenddate: sessionStorage.getItem("queryenddate"),

      modal: false,
      updatemodal: false,
      notestemplate: "",
      Showthetable: false,
      searchbyid: [],
      perPagesearchid: 10,
      offsetsearchid: 0,
      claims: [],
      searchid: sessionStorage.getItem("querysearchid"),
      querytouch: sessionStorage.getItem("querysubcategorytouch"),
      queryage: sessionStorage.getItem("querysubcategoryagegrp"),
      querycategory: sessionStorage.getItem("queryclaimiddenial"),
      claims: [],
      offset: 0,
      currentpage: 0,
      perPage: sessionStorage.getItem("Default_pagination"),
      allocatemodal1: false,
      pageCount1: 0,
      allocated: 0,
      allocatetable: [],
      allocatetable1: [],
      currentpage1: 0,
      perPage1: sessionStorage.getItem("Default_pagination"),
      currentpage2: 0,
      perPage2: sessionStorage.getItem("Default_pagination"),
      anchorEl: null,
      unallocated: 0,
      fetching: true,
      sortstate: "ASC",
      openicon: false,
      currentpagesort:0,
      sorttable: false, 
      sortdata:[],
      perPagesort:sessionStorage.getItem("Default_pagination"),     
      sortvalue:"",
      fieldvalue:"",
      offsetsort:0,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  handleClose = () => this.setState({ anchorEl: null });
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  updatetoggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getdata();
      }
    );
  };
  handlePagesearchid = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPagesearchid;

    this.setState(
      {
        currentpagesearch: selectedPage,
        offsetsearchid: offset,
      },
      () => {
        this.getclaimsbyid();
      }
    );
  };
  getclaimsbyid = async () => {
    //api call to get all user
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/denial/denialclaimid/search?ClaimID=${this.state.searchid}&Touch=${this.state.querytouch}&ClaimCategory=${this.state.querycategory}&AgeGrp=${this.state.queryage}`, {
        headers: headers,
      })
      .then((res) => {
        console.log("res get claims by id", res.data.data)
        this.setState({
          searchbyid: res.data.denial,
          Showthetable: true,
          // pageCountforsearch: Math.ceil(
          //   res.data.Claim.length / this.state.perPagesearchid
          // ),
        });
      });
  };

  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage1;

    this.setState(
      {
        currentpage1: selectedPage,
        offset1: offset,
      },
      () => {
        this.getallocateclaim();
      }
    );
  };
  handlePageClick2 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage2;

    this.setState(
      {
        currentpage2: selectedPage,
        offset2: offset,
      },
      () => {
        this.getunallocateclaim();
      }
    );
  };
  toggle1 = () => {
    this.setState(() => {
      this.setState({ allocatemodal1: !this.state.allocatemodal1 });
    });
  };
  getsorting = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryclient) {
      if (this.state.querystartdate && this.state.queryenddate) {
        if (this.state.queryLocation) {
          url = `${ip}/denial/claim/sort?${this.state.value}&clientId=${this.state.client}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpagesort}&Location=${this.state.queryLocation}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagesort}`;
        } else {
          url = `${ip}/denial/claim/sort?${this.state.value}clientId=${this.state.client}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpagesort}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagesort}`;
        }
      } else {
        if (this.state.queryLocation) {
          url = `${ip}/denial/claim/sort?${this.state.value}clientId=${this.state.client}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpagesort}&Location=${this.state.queryLocation}&count=${this.state.perPagesort}`;
        } else {
          url = `${ip}/denial/claim/sort?${this.state.value}clientId=${this.state.client}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpagesort}&count=${this.state.perPagesort}`;
        }
      }
    } else {
      if (this.state.querystartdate && this.state.queryenddate)
        url = `${ip}/denial/claim/sort?${this.state.value}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpagesort}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagesort}`;
      else
        url = `${ip}/denial/claim/sort?${this.state.value}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpagesort}&count=${this.state.perPagesort}`;
    }
     axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          pageCountsort: Math.ceil(
            res.data.claimcount / this.state.perPagesort
          ),
          sortdata: res.data.claims,
        });
      })
      .catch((err) => {});
  };
  handlePageClicksort = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPagesort;

    this.setState(
      {
        currentpagesort: selectedPage,
        offsetsort: offset,
      },
      () => {
        this.getsorting();
      }
    );
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  removedate = () => {
    sessionStorage.removeItem("querystartdate");
    sessionStorage.removeItem("queryenddate");
  };
  getdata = async () => {
    //this.setState({fetching:true});
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line

    let url;
    if (this.state.querystartdate && this.state.queryenddate) {
      url = `${ip}/denial/claim?${this.state.value}&pageno=${this.state.currentpage}&count=${this.state.perPage}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
    } else {
      url = `${ip}/denial/claim?${this.state.value}&pageno=${this.state.currentpage}&count=${this.state.perPage}`;
    }
     await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState(
          {
            pageCount: Math.ceil(res.data.count / this.state.perPage),
            claims: res.data.data,

            fetching: false,
            allocated: res.data.allocatedcount,
            unallocated: res.data.unallocatedcount,
          },
          () => {}
        );
        console.log(this.state.value, this.state.currentpage, "dennnnnn");
      })
      .catch((err) => {});
  };

  getallocateclaim = async () => {
    //this.setState({fetching:true});
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line

    let url;
    if (this.state.querystartdate && this.state.queryenddate) {
      url = `${ip}/denial/claim?${this.state.value}&allocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
    } else {
      url = `${ip}/denial/claim?${this.state.value}&allocatedpageno=${this.state.currentpage1}`;
    }
     await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pageCount1: Math.ceil(res.data.usercount / this.state.perPage1),

          allocatetable1: res.data.claimdetails,
        });
      })
      .catch((err) => {});
  };

  reset = (e) => {
    e.preventDefault();
    this.setState({
      perPage: 10,
    });
  };
  componentDidMount() {
    
    this.getallocateclaim();
    this.getdata();
    if (this.state.searchid !== "" || this.state.queryage !== "" || this.state.querytouch !== "" || this.state.querycategory !== "" ) {
      this.getclaimsbyid();
    }else{
    this.getdata();
    }
  }

  render() {
    return (
      <div>
      <Newsidebar>
      <p>
      <Link
        to="/denial-management"
        style={{ textDecoration: "none", color: "white" ,fontSize: "15px",
        fontWeight: 500}}
      >
        Denial Management
      </Link>
      &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
      <Link
        to={{
          pathname: "/denial/category",
          state: `${this.state.pastvalue1}`,
        }}
        style={{ textDecoration: "none", color: "white",fontSize: "15px",
        fontWeight: 500 }}
        onClick={this.removedate()}
      >
        Category
      </Link>
      &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Details
    </p>

      </Newsidebar>
      <div className="align-row" >
        <div className="align-column" style={{overflow:"hidden"}}>
        <br/>

        <div className="row" style={{justifyContent:"space-between"}}>
        <div className="col-md-4 mb-3">
   
        </div>
        <br/>
        <div className="col-md-4 mb-3" style={{ display: "flex" }}>
            <div class="select_drop">
              <select
                style={{height: "35px" }}
                value={this.state.perPage}
                onChange={(e) =>
                  this.setState(
                    {
                      perPage: e.target.value,
                      currentpage: 0,
                    },
                    () => {
                      this.getdata();
                    }
                  )
                }
              >
                <option value="select" hidden selected>
                  Select page count
                </option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
              </select>
            </div>
            <div>
              <Button
                style={{
                  border: "none",
                  color: "white",
                  background: "white",
                  fontWeight: "bold",
                }}
                onClick={(e) => {
                  this.reset(e);
                }}
              >
                <IoIosRefresh
                  style={{
                    color: "blue",
                    fontSize: "23px",
                  }}
                ></IoIosRefresh>
              </Button>
            </div>
        </div>
        </div>
          <div style={{ height: "100%", width: "100%" }}>
            {this.state.fetching ? (
              <div>
                <p
                  style={{ fontSize: "23px", marginTop: "10px", color: "#000" }}
                >
                  <span>Loading...</span>
                  <span>Please Wait...</span>
                </p>
                <div class="boxes body__loader dribbble">
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            ) : this.state.claims.length === 0 ? (
              <div class="bot-ui">
                <div class="bot-head">
                  <div class="eyes-container">
                    <div class="to-left eye"></div>
                    <div class="to-right eye"></div>
                  </div>
                </div>
                <div class="bot-body">
                  <div class="analyzer"></div>
                </div>
                <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                  No Data Found
                </p>
              </div>
            ) : (
              <div>
                {this.state.Showthetable === true ? null : (
                  <div style={{ float: "right", marginRight: "5%" }}>
                    <Badge
                      badgeContent={this.state.allocated}
                      color="primary"
                      showZero
                      max={this.state.pageCount * this.state.perPage}
                    >
                      <Button
                        style={{ backgroundColor: "#2e5a88", color: "white" }}
                        onClick={() => {
                          this.toggle1();
                          this.handleClose();
                        }}
                      >
                        Allocated
                      </Button>
                    </Badge>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Badge
                      badgeContent={this.state.unallocated}
                      color="primary"
                      showZero
                      max={this.state.pageCount * this.state.perPage}
                    >
                      <Button>
                        <Link
                          to="/Transaction/SubDenial/Denialunallocatedtable"
                          style={{
                            textDecoration: "none",
                            color: "whitesmoke",
                          }}
                          onClick={() =>
                            sessionStorage.setItem(
                              "Allocatedenialtable",
                              this.state.value
                            )
                          }
                        >
                          Unallocated
                        </Link>
                      </Button>
                    </Badge>
                  </div>
                )}
           
                {this.state.searchbyid.length !== 0 ? (
                  <div>
                    <div>
                      <Table
                        bordered
                        responsive
                        className="StandardTable"
                      >
                        <thead>
                          <tr align="center">
                            <th>Action</th>
                            <th style={{ whiteSpace: "nowrap" }}> Claim ID</th>
                            <th style={{ whiteSpace: "nowrap" }}> Location</th>
                            <th style={{ whiteSpace: "nowrap" }}>Account No</th>
                            <th style={{ whiteSpace: "nowrap" }}>DOS</th>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Patient Name
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Patient DOB
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>Plan Code</th>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Insurance Name
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Insurance No
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>Policy No</th>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Physician Name
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>Age</th>
                            <th style={{ whiteSpace: "nowrap" }}>Type</th>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Claim Status
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>
                              AllocatedTo
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {this.state.searchbyid &&
                            this.state.searchbyid.map((data, index) => {
                              return (
                                <tr>
                                  <td
                                    onClick={() => (
                                      (
                                        <ViewClaim
                                          value={
                                            sessionStorage.setItem(
                                              "Module",
                                              "Denial Management"
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimID",
                                              data.ClaimID
                                            ) ||
                                            sessionStorage.setItem(
                                              "ChartID",
                                              data.ChartID
                                            ) ||
                                            sessionStorage.setItem(
                                              "MRN",
                                              data.MRN
                                            ) ||
                                            sessionStorage.setItem(
                                              "Patient",
                                              data.Patient
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientDOB",
                                              data.PatientDOB
                                            ) ||
                                            sessionStorage.setItem(
                                              "Physician",
                                              data.Physician
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "Pripayer",
                                              data.Pripayer
                                            ) ||
                                            sessionStorage.setItem(
                                              "DateOfService",
                                              data.DateOfService
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDBID",
                                              data._id
                                            ) ||
                                            sessionStorage.setItem(
                                              "AllocatedTo",
                                              data.Claimunder
                                            )
                                          }
                                        />
                                      ),
                                      (
                                        <Viewmoreeligibility
                                        value={
                                          sessionStorage.setItem(
                                            "modulename",
                                            "Denial Management"
                                          ) ||
                                          sessionStorage.setItem(
                                            "Eligibilityclaimid",
                                            data.ClaimID
                                          )
                                        }
                                        />
                                      ),
                                      (
                                        <SimpleMenu
                                          value={
                                            sessionStorage.setItem(
                                              "modulename",
                                              "Claim Status Management"
                                            ) ||
                                            sessionStorage.setItem(
                                              "Eligibilityclaimid",
                                              data.ClaimID
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientFirstName",
                                              data.PatientFirstName
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientLastName",
                                              data.PatientLastName
                                            )
                                          }
                                        ></SimpleMenu>
                                      )
                                    )}
                                  >
                                    {" "}
                                    <SimpleMenu></SimpleMenu>
                                  </td>
                                  <td>{data.ClaimID}</td>
                                  <td>{data.Location}</td>
                                  <td>{data.patientAccountNumber}</td>
                                  <td>
                                    {data.DateOfService
                                      ? data.DateOfService
                                      : "-"}
                                  </td>

                                  <td>
                                    {data.PatientFirstName}&nbsp;
                                    {data.PatientLastName}
                                  </td>

                                  <td>
                                    {data.PatientDOB ? moment(data.PatientDOB).format("MM/DD/YYYY") : "-"}
                                  </td>
                                  <td>{data.PlanCode ? data.PlanCode : "-"}</td>
                                  <td>{data.Pripayer}</td>
                                  <td>
                                    <Row>
                                      <Col md="4">
                                        <Twilio value11={data.Pripayerphone} />
                                      </Col>
                                      <Col md="2">{data.Pripayerphone}</Col>
                                    </Row>
                                  </td>
                                  <td>{data.PrimaryPolicyNo}</td>
                                  <td>{data.Physician}</td>
                                  <td>{data.Age}</td>
                                  <td>{data.type}</td>
                                  <td>{data.ClaimStatus}</td>
                                  <td>{data.Claimunder}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                ) :
                this.state.claims.length !== 0 ?   
                (
                  <div>
                  <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                  <ReactPaginate
                    previousLabel={<IoIosArrowBack />}
                    nextLabel={<IoIosArrowForward />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    forcePage={this.state.currentpage}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
                  <Table
                  className="StandardTable"
                  bordered
                  responsive
                  >
                    <thead>
                      <tr align="center">
                        <th>Action</th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"ClaimID",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"ClaimID",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Claim Id
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"ClaimID",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"ClaimID",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false,sortvalue:"ascending", fieldvalue:"Location",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true,sortvalue:"ascending", fieldvalue:"Location",sorttable: true },()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Location
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false,sortvalue:"ascending", fieldvalue:"Location",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true,sortvalue:"ascending", fieldvalue:"Location",sorttable: true },()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false, fieldvalue:"patientAccountNumber", sortvalue:"ascending",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true , fieldvalue:"patientAccountNumber", sortvalue:"descending",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Account No
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false, fieldvalue:"patientAccountNumber", sortvalue:"ascending",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true , fieldvalue:"patientAccountNumber", sortvalue:"descending",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , sortvalue:"ascending", fieldvalue:"DateOfService",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, sortvalu:"descending", fieldvalue:"DateOfService",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          DOS
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , sortvalue:"ascending", fieldvalue:"DateOfService",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, sortvalu:"descending", fieldvalue:"DateOfService",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , sortvalue:"ascending", fieldvalue:"DOIAge",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, sortvalu:"descending", fieldvalue:"DOIAge",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          DOI Age
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , sortvalue:"ascending", fieldvalue:"DOIAge",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, sortvalu:"descending", fieldvalue:"DOIAge",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , sortvalue:"ascending", fieldvalue:"DOIAgeGrp",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, sortvalu:"descending", fieldvalue:"DOIAgeGrp",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          DOI Agegrp
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , sortvalue:"ascending", fieldvalue:"DOIAgeGrp",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, sortvalu:"descending", fieldvalue:"DOIAgeGrp",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , sortvalue:"ascending", fieldvalue:"PatientFirstName",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, sortvalu:"descending", fieldvalue:"PatientFirstName",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Patient Name
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , sortvalue:"ascending", fieldvalue:"PatientFirstName",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, sortvalu:"descending", fieldvalue:"PatientFirstName",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , sortvalue:"ascending", fieldvalue:"PatientDOB",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, sortvalu:"descending", fieldvalue:"PatientDOB",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Patient DOB
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , sortvalue:"ascending", fieldvalue:"PatientDOB",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, sortvalu:"descending", fieldvalue:"PatientDOB",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , sortvalue:"ascending", fieldvalue:"PlanCode",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, sortvalu:"descending", fieldvalue:"PlanCode",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Plan Code
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , sortvalue:"ascending", fieldvalue:"PlanCode",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, sortvalu:"descending", fieldvalue:"PlanCode",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"Pripayer" ,sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"Pripayer",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Insurance Name
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"Pripayer" ,sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"Pripayer",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"Age",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true , sortvalue:"descending", fieldvalue:"Age",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Age
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"Age",sorttable: true }, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true , sortvalue:"descending", fieldvalue:"Age",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"type", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"type", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Type
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"type", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"type", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"statusCode", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"statusCode", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Code
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"statusCode", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"statusCode", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"ClaimCategory", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"ClaimCategory", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Category
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"ClaimCategory", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"ClaimCategory", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"claimSubCategory", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"claimSubCategory", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Subcategory
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"claimSubCategory", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"claimSubCategory", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"Reason", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"Reason", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Code Description
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"Reason", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"Reason", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"Physician", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"Physician", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Physician
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"Physician", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"Physician", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() =>   {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"PrimaryPolicyNo", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"PrimaryPolicyNo", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Policy No
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"PrimaryPolicyNo", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"PrimaryPolicyNo", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"Claimunder", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"Claimunder", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Allocated To
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"Claimunder", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"Claimunder", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"ClaimStatus", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"ClaimStatus", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}}
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Claim Status
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false , fieldvalue:"ClaimStatus", sortvalue:"ascending",sorttable: true}, ()=> this.getsorting())
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true, fieldvalue:"ClaimStatus", sortvalue:"descending",sorttable: true  }, ()=> this.getsorting())
                              }
                            />
                          )}
                        </th>
                      </tr>
                    </thead>
                    {this.state.sorttable === true ?
                      (
                        <tbody>
                        {this.state.sortdata &&
                          this.state.sortdata.map((data, index) => (
                            <tr key={data._id}>
                              <td
                                onClick={() => (
                                  <ViewClaim
                                    value={
                                      sessionStorage.setItem(
                                        "Module",
                                        "Denial Management"
                                      ) ||
                                      sessionStorage.setItem(
                                        "ClaimID",
                                        data.ClaimID
                                      ) ||
                                      sessionStorage.setItem(
                                        "ChartID",
                                        data.ChartID
                                      ) ||
                                      sessionStorage.setItem("MRN", data.MRN) ||
                                      sessionStorage.setItem(
                                        "Patient",
                                        data.Patient
                                      ) ||
                                      sessionStorage.setItem(
                                        "PatientDOB",
                                        data.PatientDOB
                                      ) ||
                                      sessionStorage.setItem(
                                        "Physician",
                                        data.Physician
                                      ) ||
                                      sessionStorage.setItem(
                                        "ClaimDate",
                                        data.ClaimDate
                                      ) ||
                                      sessionStorage.setItem(
                                        "Pripayer",
                                        data.Pripayer
                                      ) ||
                                      sessionStorage.setItem(
                                        "DateOfService",
                                        data.DateOfService
                                      ) ||
                                      sessionStorage.setItem(
                                        "ClaimDate",
                                        data.ClaimDate
                                      ) ||
                                      sessionStorage.setItem(
                                        "ClaimDBID",
                                        data._id
                                      ) ||
                                      sessionStorage.setItem(
                                        "AllocatedTo",
                                        data.Claimunder
                                      )
                                    }
                                  />,
                                  (
                                    <Viewmoreeligibility
                                    value={
                                      sessionStorage.setItem(
                                        "modulename",
                                        "Denial Management"
                                      ) ||
                                      sessionStorage.setItem(
                                        "Eligibilityclaimid",
                                        data.ClaimID
                                      )
                                    }
                                    />
                                  )
                                )}
                              >
                                <SimpleMenu />
                              </td>
                              <td>{data.ClaimID}</td>
                              <td>{data.Location}</td>
                              <td>
                                {data.patientAccountNumber}
                              </td>
                              <td>
                                {data.DateOfService ? data.DateOfService : "-"}
                              </td>
                              <td>
                                {data.DOIAge ? data.DOIAge : "-"}
                              </td>
                              <td>
                                {data.DOIAgeGrp ? data.DOIAgeGrp : "-"}
                              </td>
                              <td>
                                {data.PatientFirstName}&nbsp;
                                {data.PatientLastName}
                              </td>
                              <td>
                                {data.PatientDOB ? moment(data.PatientDOB).format("MM/DD/YYYY") : "-"}
                              </td>
                              <td>
                                {data.PlanCode ? data.PlanCode : "-"}
                              </td>
                              <td>
                                {" "}
                                {data.InsuranceName ? data.InsuranceName : "-"}
                              </td>
                              <td>{data.Age}</td>
                              <td>{data.type}</td>
                              <td>{data.statusCode}</td>
                              <td>
                                {data.ClaimCategory}
                              </td>
                              <td>
                                {data.claimSubCategory}
                              </td>
                              <td>{data.Reason}</td>
                              <td>{data.Physician}</td>
                              <td>
                                {data.PrimaryPolicyNo}
                              </td>
                              <td>{data.Claimunder}</td>
                              <td>
                                {data.ClaimStatus}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      ):(
                        <tbody>
                        {this.state.claims &&
                          this.state.claims.map((data, index) => (
                            <tr key={data._id}>
                              <td
                                onClick={() => (
                                  <ViewClaim
                                    value={
                                      sessionStorage.setItem(
                                        "Module",
                                        "Denial Management"
                                      ) ||
                                      sessionStorage.setItem(
                                        "ClaimID",
                                        data.ClaimID
                                      ) ||
                                      sessionStorage.setItem(
                                        "ChartID",
                                        data.ChartID
                                      ) ||
                                      sessionStorage.setItem("MRN", data.MRN) ||
                                      sessionStorage.setItem(
                                        "Patient",
                                        data.Patient
                                      ) ||
                                      sessionStorage.setItem(
                                        "PatientDOB",
                                        data.PatientDOB
                                      ) ||
                                      sessionStorage.setItem(
                                        "Physician",
                                        data.Physician
                                      ) ||
                                      sessionStorage.setItem(
                                        "ClaimDate",
                                        data.ClaimDate
                                      ) ||
                                      sessionStorage.setItem(
                                        "Pripayer",
                                        data.Pripayer
                                      ) ||
                                      sessionStorage.setItem(
                                        "DateOfService",
                                        data.DateOfService
                                      ) ||
                                      sessionStorage.setItem(
                                        "ClaimDate",
                                        data.ClaimDate
                                      ) ||
                                      sessionStorage.setItem(
                                        "ClaimDBID",
                                        data._id
                                      ) ||
                                      sessionStorage.setItem(
                                        "AllocatedTo",
                                        data.Claimunder
                                      )
                                    }
                                  />,
                                  (
                                    <Viewmoreeligibility
                                    value={
                                      sessionStorage.setItem(
                                        "modulename",
                                        "Denial Management"
                                      ) ||
                                      sessionStorage.setItem(
                                        "Eligibilityclaimid",
                                        data.ClaimID
                                      )
                                    }
                                    />
                                  )
                                )}
                              >
                                <SimpleMenu />
                              </td>
                              <td>{data.ClaimID}</td>
                              <td>{data.Location}</td>
                              <td>
                                {data.patientAccountNumber}
                              </td>
                              <td>
                                {data.DateOfService ? data.DateOfService : "-"}
                              </td>
                              <td>
                                {data.DOIAge ? data.DOIAge : "-"}
                              </td>
                              <td>
                                {data.DOIAgeGrp ? data.DOIAgeGrp : "-"}
                              </td>
                              <td>
                                {data.PatientFirstName}&nbsp;
                                {data.PatientLastName}
                              </td>
                              <td>
                                {data.PatientDOB ? moment(data.PatientDOB).format("MM/DD/YYYY") : "-"}
                              </td>
                              <td>
                                {data.PlanCode ? data.PlanCode : "-"}
                              </td>
                              <td>
                                {" "}
                                {data.InsuranceName ? data.InsuranceName : "-"}
                              </td>
                              <td>{data.Age}</td>
                              <td>{data.type}</td>
                              <td>{data.statusCode}</td>
                              <td>
                                {data.ClaimCategory}
                              </td>
                              <td>
                                {data.claimSubCategory}
                              </td>
                              <td>{data.Reason}</td>
                              <td>{data.Physician}</td>
                              <td>
                                {data.PrimaryPolicyNo}
                              </td>
                              <td>{data.Claimunder}</td>
                              <td>
                                {data.ClaimStatus}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      )
                    }
                
                  </Table>
                {this.state.sorttable === true ?
                  <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                  <ReactPaginate
                    previousLabel={<IoIosArrowBack />}
                    nextLabel={<IoIosArrowForward />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCountsort}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClicksort}
                    forcePage={this.state.currentpage}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div> :
                <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                <ReactPaginate
                  previousLabel={<IoIosArrowBack />}
                  nextLabel={<IoIosArrowForward />}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  forcePage={this.state.currentpage}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
                }
             
                </div>
                ) : <p>No data found</p>
              }
              </div>
            )}
          </div>
          {this.state.allocatemodal1 && (
            <Modal
              // // style={{ width: "30%" }}
              isOpen={this.state.allocatemodal1}
              onHide={this.toggle1}
              onClose={this.handleClose}
              size="lg"
              centered
            >
              <ModalHeader toggle={this.toggle1}>Allocated</ModalHeader>
              <ModalBody>
                <Row style={{ margin: "0px" }}>
                  <div style={{ width: "100%" }}>
                    <Table
                      className="StandardTable"
                      bordered
                      style={{
                        marginLeft: "3%",
                        width: "99%",
                        marginTop: "4%",
                      }}
                    >
                      <thead
                        style={{ backgroundColor: "#041c3c", color: "white" }}
                      >
                        <tr align="center">
                          <th>Username</th>
                          <th>No of Claims</th>
                          <th>Completed</th>
                          <th>Not Touched</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.allocatetable1 &&
                          this.state.allocatetable1.map((data, index) => {
                            return (
                              <tr>
                                <td>{data.username}</td>

                                <td>
                                  <Link
                                    to={{
                                      pathname:
                                        "/Transaction/SubDenial/Denialtableallocate1",
                                    }}
                                    onClick={() => (
                                      <Denialtableallocate1
                                        value={
                                          sessionStorage.setItem(
                                            "username",
                                            data.username
                                          ) ||
                                          sessionStorage.setItem(
                                            "filteredclaims",
                                            data.filteredClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "completedclaims",
                                            data.completedClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouch",
                                            data.nottouchedClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "checkfiltertable",
                                            true
                                          ) ||
                                          sessionStorage.setItem(
                                            "Allocatedenialtable",
                                            this.state.value
                                          ) ||
                                          sessionStorage.setItem(
                                            "denialpageno",
                                            this.state.currentpage1
                                          )
                                        }
                                      />
                                    )}
                                  >
                                    {data.filteredClaimscount}
                                  </Link>
                                </td>

                                <td>
                                  <Link
                                    to={{
                                      pathname:
                                        "/Transaction/SubDenial/Denialtableallocate2",
                                    }}
                                    onClick={() => (
                                      <Denialtableallocate2
                                        value={
                                          sessionStorage.setItem(
                                            "username",
                                            data.username
                                          ) ||
                                          sessionStorage.setItem(
                                            "filteredclaims",
                                            data.filteredClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "completedclaims",
                                            data.completedClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouch",
                                            data.nottouchedClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "Checkcompletetable",
                                            true
                                          ) ||
                                          sessionStorage.setItem(
                                            "Allocatedenialtable",
                                            this.state.value
                                          ) ||
                                          sessionStorage.setItem(
                                            "denialpageno",
                                            this.state.currentpage1
                                          )
                                        }
                                      />
                                    )}
                                  >
                                    {data.completedClaimscount}
                                  </Link>
                                </td>

                                <td>
                                  <Link
                                    to={{
                                      pathname:
                                        "/Transaction/SubDenial/Denialtableallocate3",
                                    }}
                                    onClick={() => (
                                      <Denialtableallocate3
                                        value={
                                          sessionStorage.setItem(
                                            "username",
                                            data.username
                                          ) ||
                                          sessionStorage.setItem(
                                            "filteredclaims",
                                            data.filteredClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "completedclaims",
                                            data.completedClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouch",
                                            data.nottouchedClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouchtable",
                                            true
                                          ) ||
                                          sessionStorage.setItem(
                                            "Allocatedenialtable",
                                            this.state.value
                                          ) ||
                                          sessionStorage.setItem(
                                            "denialpageno",
                                            this.state.currentpage1
                                          )
                                        }
                                      />
                                    )}
                                  >
                                    {data.nottouchedClaimscount}
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Row>
              </ModalBody>
              <ModalFooter>
                <div style={{ marginRight: "368px" }}>
                  <ReactPaginate
                    previousLabel={<IoIosArrowBack />}
                    nextLabel={<IoIosArrowForward />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount1}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick1}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </ModalFooter>
            </Modal>
          )}

          {this.state.modal && (
            <Modal
              style={{ width: "30%" }}
              isOpen={this.state.modal}
              onHide={this.toggle}
              centered
            >
              <ModalBody>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label></Label>
                        <Input
                          type="text"
                          required
                          value={this.state.notestemplate}
                          onChange={(e) =>
                            this.setState({ notestemplate: e.target.value })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.firstnameErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.handleSubmit}
                >
                  save
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
      </div>
      </div>
    );
  }
}
export default DenialDetails;
