import axios from 'axios';
import io from 'socket.io-client';

// develop ip
export const ip = "https://devapirevshield.imagnumhealthcare.com";
export const secretkey = "aes_256_encryption_in_nestjs_sec";
export const restartStatus = async () => {
    try {
      const response = await axios.post(`${ip}/restart-status`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const checkHealth = async () => {
    try {
      const response = await axios.get(`${ip}/health`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const socket = io('https://devapirevshield.imagnumhealthcare.com');