import React, { Component } from "react";
import { ip } from "../../../Api";
import axios from "axios";
import {
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Legend,
  ComposedChart,
  Bar,
} from "recharts";
const data1 = [
  {
    _id: "",
    totalamount: 0,
  },
  {
    _id: "",
    totalamount: 0,
  },
  {
    _id: "",
    totalamount: 0,
  },

  {
    _id: "",
    totalamount: 0,
  },
  {
    _id: "",
    totalamount: 0,
  },
];

let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

class AverageTouches extends Component {
  constructor(props) {
    super(props);

    this.state = {
      touch: [],
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
    };
  }

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getTouches = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.touchStart !== "" && this.props.touchEnd !== "") {
          url = `${ip}/inventory/avgnooftouches?start_date=${this.props.touchStart}&end_date=${this.props.touchEnd}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/inventory/avgnooftouches?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.touchStart !== "" && this.props.touchEnd !== "") {
          url = `${ip}/inventory/avgnooftouches?start_date=${this.props.touchStart}&end_date=${this.props.touchEnd}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/inventory/avgnooftouches?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.touchStart !== "" && this.props.touchEnd !== "") {
        url = `${ip}/inventory/avgnooftouches?start_date=${this.props.touchStart}&end_date=${this.props.touchEnd}`;
      } else {
        url = `${ip}/inventory/avgnooftouches`;
      }
    }
    const req = await axios
      .get(url, { headers: headers })
      .then((req) => {
        if (req.data.touch.length !== 0) {
          this.setState({ touch: req.data.touch });
        } else {
          this.setState({
            touch: data1,
          });
        }
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
    this.getTouches();
  }
  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.touchStart === prevProps.touchStart &&
        this.props.touchEnd === prevProps.touchEnd
      )
    ) {
      this.getTouches();
    } else if (this.props.client !== prevProps.client) {
      this.getTouches();
    } else if (this.props.Location !== prevProps.Location) {
      this.getTouches();
    }
  }
  render() {
    return (
      <div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <ResponsiveContainer width="100%" height={300}>
      <ComposedChart
        width={450}
        height={350}
        data={this.state.touch}
        stopColor="red"
        margin={{
          top: 13,
          right: 30,
          left: 10,
          bottom: 5,
        }}
      >
        <XAxis dataKey={"_id"} interval={0} fontSize="10" />
        <YAxis dataKey={"totalamount"} name="$" fontSize="10" />
        <Tooltip content={<CustomTooltip />} />
        <defs>
        </defs>
        <Bar
          name="Total Amount"
          dataKey="totalamount"
          onMouseOver={() => (tooltip = "totalamount")}
          barSize={20}
          fill="#eb349b"
          radius={[100, -30, 0, 0]}
        >
        </Bar>
        <Tooltip></Tooltip>
      </ComposedChart>
    </ResponsiveContainer>
      </div>
    );
  }
}
export default AverageTouches;
