import React, { Component } from "react";
import axios from "axios";
import "../../Styles/configure.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import {  Row, Col } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Twilio from "../Transaction/Twilio";
import { ageOptions } from "./Agedata";
import { default as ReactSelect } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { components } from "react-select";
import { DatePicker } from "antd";
import { Button } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { Table } from "react-bootstrap";
import moment from "moment-timezone";
import ActionViewClaim from "./ActionsComponents/ActionViewClaim";
import ActionSimpleMenu from "./ActionsComponents/ActionMenuList.js";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { ip, secretkey } from "../../Api.js";
import aes256 from "aes256";
import noresult from "../../Images/searchresult.jpg";
import "../../Styles/loader.scss";
import { BiUpArrowAlt } from "react-icons/bi";
import { BiDownArrowAlt } from "react-icons/bi";
import Viewmoreeligibility from "../Eligibility/Viewmoreeligibility.js";
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const { RangePicker } = DatePicker;

class UserCompletedPage extends Component {
  constructor(props) {
    super(props);
    this.typeaheadRef = React.createRef();
    this.state = {
      spageCount:1,
      searchCompleted: false,
      optionsarray: [],
      claimfield1: "",
      statuslist:[],
      claimvalue1: "",
      claimtype: "",
      payervalue1: [],
      any: [],
      loading: true,
      searchclaimdata: [],
      searchdataresponse: [],
      searchtext: "",
      query: [],
      startDate: "",
      payercategories: [],
      fetching: true,
      formValues: [
        {
          claimfield1: "",
          claimvalue: "",
        },
      ],
      modal: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      firstname: sessionStorage.getItem("firstname"),
      lastname: sessionStorage.getItem("lastname"),
      apiusername: sessionStorage.getItem("apiusername"),
      claimsdata: [],
      ids: [],
      offset: 0,
      currentpage: 0,
      currentpagesearch: 0,
      perPage: sessionStorage.getItem("Default_pagination"),
      sperPage: sessionStorage.getItem("Default_pagination"),
      optionSelected: [],
      checkedAll: false,
      role_type: sessionStorage.getItem("role_type"),
      clientid: sessionStorage.getItem("clientid"),
      css_name: "",
      alldataresponse: [],
      claimstable: [],
      agegrplist: [
        "0-30days",
        "31-60days",
        "61-90days",
        "91-120days",
        "121-180days",
        "181-365days",
        "above 1year",
      ],
      agegrp: "",
      firstsearch1: false,
      sortstate: "ASC",
      openicon: false,
      valueOfInput1: "",
      valueOfInput2: "",
      filterstatus: "custom",
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleChangedate = this.handleChangedate.bind(this);
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getcompletedclaims();
      }
    );
  };
  handleChange = (selected) => {
    this.setState({
      optionSelected: selected,
    });
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.sperPage;

    this.setState(
      {
        currentpagesearch: selectedPage,
        offset: offset,
        firstsearch1: true,
      },
      (e) => {
        this.filtersearch(e);
      }
    );
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.setState({  firstsearch1: false, searchCompleted:true }, () => {
        this.filtersearch(e);
      });
    }
  };
  getpayercategory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(`${ip}/payercategory/all`, {
        headers: headers,
      })
      .then((res) => {
        let categoryData;
        categoryData = aes256.decrypt(`${secretkey}`, res.data.payer);
        const decryptedcategorydata = JSON.parse(categoryData);  
        let other = {
          category_name: "Others",
        };
        let data = decryptedcategorydata;
        data.push(other);
        this.setState({ payercategories: data });
      })
      .catch((err) => {});
  };
  getquery = () => {
    var query_o;
    let query = [];
    if (this.state.payervalue1.length !== 0) {
      if (this.state.payervalue1[0].category_name !== "") {
        query_o = `Payercategory=${this.state.payervalue1[0].category_name}`;
        query.push(query_o);
      }
    }
    if (this.state.claimtype) {
      query_o = `type=${this.state.claimtype}`;
      query.push(query_o);
    }
    if (this.state.optionSelected.length !== 0) {
      let agegrp = [];
      let temp;
      let agelist;
      this.state.optionSelected.map((ele) => {
        agegrp.push(ele.value);
      });
      agelist = agegrp.map((str) => '"' + str + '"');
      query_o = `AgeGrp=[${agelist}]`;
      query.push(query_o);
    }
    if (this.state.claimfield1 && this.state.claimvalue1 !== "") {
      query_o = `${this.state.claimfield1}=${this.state.claimvalue1}`;
      query.push(query_o);
    }
    if (query.length === 1) {
      this.state.query.push(`${query[0]}`);
    }
    if (query.length === 2) {
      this.state.query.push(`${query[0]}&${query[1]}`);
    }
    if (query.length === 3) {
      this.state.query.push(`${query[0]}&${query[1]}&${query[2]}`);
    }
    if (query.length === 4) {
      this.state.query.push(`${query[0]}&${query[1]}&${query[2]}&${query[3]}`);
    }
  };
  filtersearch = (e) => {
    if (this.state.firstsearch1 && e) {
      e.preventDefault();
    }
  
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.state.searchCompleted=== true &&this.state.role_type === "Team Member") {
      if (this.state.formValues.some(formValue => formValue.claimfield1 !== "" && formValue.claimvalue !== "")) {
        let url;
        let name = this.state.firstname + " " + this.state.lastname;
        url = `${ip}/userdashboard/completed/advancedmultiplefilter?pageno=${this.state.currentpagesearch}&count=${this.state.sperPage}&UserId=${name}&OverallServiceLevelStatus=Completed&clientId=${this.state.clientid}`;
  
        const searchCriteria = {};
  
        this.state.formValues.forEach((formValue) => {
          if (formValue.claimfield1 && formValue.claimvalue) {
            searchCriteria[formValue.claimfield1] = formValue.claimvalue;
          }
        });
  
        // Check if formValues has ClaimStatus
        const claimStatusFormValue = this.state.formValues.find(
          (formValue) => formValue.claimfield1 === "ClaimStatus"
        );
  
        if (claimStatusFormValue && claimStatusFormValue.claimvalue) {
          const userValues = claimStatusFormValue.claimvalue.map(
            (option) => option.value
          );
          let statuslist = userValues.map((str) => '"' + str + '"');
          searchCriteria[claimStatusFormValue.claimfield1] = `[${statuslist.join(", ")}]`;
        }
  
        this.state.formValues.forEach((formValue) => {
          if (formValue.claimfield1 === "type") {
            searchCriteria[formValue.claimfield1] = formValue.claimvalue;
          }
        });
  
        this.state.formValues.forEach((formValue) => {
          if (formValue.claimfield1 === "AgeGrp" && formValue.claimvalue.length > 0) {
            const userValues = formValue.claimvalue.map((option) => option.value);
            let agegrplist = userValues.map((str) => '"' + str + '"');
            searchCriteria[formValue.claimfield1] = `[${agegrplist.join(", ")}]`;
          }
        });
  
        const searchCriteriaQueryString = Object.keys(searchCriteria)
          .map((key) => `${key}=${searchCriteria[key]}`)
          .join("&");
  
        if (searchCriteriaQueryString) {
          url += `&${searchCriteriaQueryString}`;
        }
  
         axios
          .get(url, {
            headers: headers,
          })
          .then((res) => {
            if (res && this.state.searchCompleted=== true && this.state.spageCount === 1) {
              toast.success("Searching completed!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
  
            if (res.data.claims.length !== 0) {
              this.setState({ searchtext: 1 });
              this.setState({
                searchdataresponse: res.data.claims,
                spageCount: Math.ceil(res.data.claimcount / this.state.sperPage),
              });
            } else if (res.data.claims.length === 0) {
              this.setState({
                searchtext: 1,
                searchdataresponse: [],
                searchclaimdata: [],
              });
            }
  
            this.setState({
              searchCompleted: true,
            });
          });
      } else {
        alert("Select data to search");
      }
    }
  };
  
  addFormField() {
    this.setState({
      formValues: [
        ...this.state.formValues,
        { claimfield1: "", claimvalue: "" },
      ],
    });
  }
  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }
  resetcount = (e) => {
    this.setState(
      {
        perPage: 10,
      },
      () => this.getcompletedclaims()
    );
  };
  //getcompletedclaims
  reset = (e) => {
    e.preventDefault();
    if(this.state.firstsearch1 === true){
      toast.info("All values displayed now!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    if (this.typeaheadRef.current) {
      this.typeaheadRef.current.clear(); // Use the ref to clear the Typeahead input
    }

    this.setState(
      {
        spageCount:1,
        searchCompleted: false,
        formValues: [],
        optionSelected: [],
        claimfield1: "",
        claimvalue1: "",
        claimtype: "",
        status1: [],
        payervalue1: [],
        cptvalue1: [],
        searchdataresponse: [],
        searchresultresponse1: [],
        searchclaimdata: [],
        searchtext: "",
        currentpage: 0,
        agegrp: "",
        currentpagesearch: 0,
        sperPage: 10,
      },
      () => {
        this.addFormField()
        this.getcompletedclaims()
      }
    );
  };
  handleChangedate(date) {
    this.setState(
      {
        startDate: date,
      },
      () => this.getcompletedclaims()
    );
  }
  resetDate = () => {
    this.setState(
      {
        startDate: "",
      },
      () => this.getcompletedclaims()
    );
  };
  getcompletedclaims = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    let name = this.state.firstname + " " + this.state.lastname;
    if (this.state.valueOfInput1 && this.state.valueOfInput2) {
      url = `${ip}/claim/user/completedclaims?userid=${name}&clientId=${this.state.clientid}&pageno=${this.state.currentpage}&count=${this.state.perPage}&startdate=${this.state.valueOfInput1}&enddate=${this.state.valueOfInput2}`;
    } else {
      url = `${ip}/claim/user/completedclaims?userid=${name}&clientId=${this.state.clientid}&pageno=${this.state.currentpage}&count=${this.state.perPage}`;
    }
     axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pageCount: Math.ceil(res.data.count / this.state.perPage),
          claimsdata: res.data.Claim,
          fetching: false,
        });
      })
      .catch((err) => {});
  };
  getcompletedclaimsreport = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    let name = this.state.firstname + " " + this.state.lastname;
    let cid = this.state.clientid ? this.state.clientid : "";
    if (this.state.valueOfInput1 && this.state.valueOfInput2) {
      url = `${ip}/claim/user/completedclaims?userid=${name}&clientId=${cid}&startdate=${this.state.valueOfInput1}&enddate=${this.state.valueOfInput2}`;
    } else {
      url = `${ip}/claim/user/completedclaims?userid=${name}&clientId=${cid}`;
    }
    
    fetch(url, { headers: headers })
      .then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.setAttribute("download", "Completed Claims");
          a.click();
          URL.revokeObjectURL(a);
        });
      })
      .catch((err) => {});
  };
  valuedate = (option, e) => {
    if (option === "yesterday") {
      this.setState(
        {
          valueOfInput1: e.target.value,
          valueOfInput2: moment().subtract(1, "days").format("MM-DD-YYYY"),
          filterstatus: option,
        },
        () => {
          this.getcompletedclaims();
          // this.getcompletedclaimsreport();
        }
      );
    } else {
      this.setState(
        {
          valueOfInput1: e.target.value,
          valueOfInput2: moment().format("MM-DD-YYYY"),
          filterstatus: option,
        },
        () => {
          this.getcompletedclaims();
          // this.getcompletedclaimsreport();
        }
      );
    }
  };

  getcss = () => {
    if (this.state.role_type === "Team Member") {
      this.setState({ css_name: "align-col-actions" });
    } else {
      this.setState({ css_name: "align-column" });
    }
  };
  sorting = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.claimsdata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ claimsdata: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.claimsdata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ claimsdata: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };
  sortingfilter = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.searchdataresponse].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ searchdataresponse: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.searchdataresponse].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ searchdataresponse: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };
  getClaimStatus = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

     await axios
      .get(`${ip}/claimstatus/all`, { headers: headers })
      .then((res) => {
        var data = res.data.claimstatus.map((e) => (
         {
          value: e.claimStatus,
          label: e.claimStatus,
        }));
        this.setState({
          statuslist: data,
        });
      })
      .catch((err) => {});
  };

  componentDidMount() {
    this.renewaccesstoken();
    this.getcompletedclaims();
    this.getpayercategory();
    this.getClaimStatus();
    // this.getcss();
    const options = [
      { value: "Location", label: "Location" },
      { value: "ClaimID", label: "Claim ID" },
      { value: "patientAccountNumber", label: "Account Number" },
      { value: "MRN", label: "Claim No" },
      { value: "PatientFirstName", label: "FirstName" },
      { value: "PatientLastName", label: "LastName" },
      { value: "Physician", label: "Physician" },
      { value: "Age", label: "Age" },
      { value: "PrimaryPolicyNo", label: "Policy No" },
      { value: "ClaimStatus", label: "Claim Status"},
      { value: "PatientDOB", label: "DOB" },
      { value: "PlanCode", label: "Plan Code" },
      { value: "DateOfService", label: "DOS" },
      { value: "InsuranceName", label: "Insurance Name" },
      { value: "type", label: "Type" },
      { value: "AgeGrp", label: "Age Grp" },
    ];
    this.setState({
      optionsarray: options,
    });
    let storedSearchCompletedInput = localStorage.getItem("storedClaimCompletedSearch");
    let storedSearchvalue = localStorage.getItem("storedCompletedSearchvalue");
    let storedSearchkey = localStorage.getItem("storedCompletedkey")
    let val = JSON.parse(storedSearchvalue)
    if (val === true) {
        this.setState({
        formValues: JSON.parse(storedSearchCompletedInput),
        firstsearch1: JSON.parse(storedSearchvalue),
         searchCompleted: JSON.parse(storedSearchkey)
          }, () => {
            this.filtersearch();
          })
    }
  }
  componentWillUnmount() {
    let Completedinput = JSON.stringify(this.state.formValues);
    localStorage.setItem("storedClaimCompletedSearch", Completedinput);
    let Completedinput1 = JSON.stringify(this.state.firstsearch1);
    localStorage.setItem("storedCompletedSearchvalue", Completedinput1);
    let Completedinput2 = JSON.stringify(this.state.searchCompleted)
    localStorage.setItem("storedCompletedkey", Completedinput2)
  }
  addsinglecheckboxid = (data, check) => {
    if (check) {
      this.setState({ ids: [...this.state.ids, data] }, () => {});
    } else {
      let arr = this.state.ids.slice();
      let val = arr.filter((id) => {
        return id != data;
      });
      this.setState({ ids: val }, () => {});
    }
  };

  checkBoxAll = () => {
    if (this.state.checkedAll === false) {
      let idvalue = this.state.alldataresponse.map((data) => data._id);
      this.setState(
        { checkedAll: !this.state.checkedAll, ids: idvalue },
        () => {}
      );
    } else {
      this.setState({ checkedAll: !this.state.checkedAll, ids: [] }, () => {});
    }
  };

  render() {
    return (
      <div>
      <Newsidebar name="List of Completed Claims" />
            <div className="align-row" style={{overflow:"hidden"}}>
        <ToastContainer></ToastContainer>
          <br/>
          <div className="search__grid__completed">
          <div>
            <Form>
              {this.state.formValues.map((formValue, index) => (
                <div key={index} className="search-field" style={{ borderRadius: "16px", padding: "4px", background: "#FBFBFB" }}>
                  <Row>
                    <Col md="5" className="d-flex justify-content-end">
                      <Typeahead
                        key={index}
                        id={`typeahead${index}`}
                        onKeyPress={this.handleKeyPress}
                        onKeyDown={this.handleKeyPress}
                        ref={this.typeaheadRef}
                        style={{ width: "100%" }}
                        options={this.state.optionsarray}
                        value={formValue.claimfield1}
                        selected={this.state.optionsarray.filter(option => option.value === formValue.claimfield1)}
                        labelKey={(option) => `${option.label}`}
                        placeholder="What are you looking for?..."
                        onChange={(selected) => {
                          const updatedFormValues = [...this.state.formValues];
                          if (selected && selected.length > 0 && selected[0] && selected[0].value) {
                            updatedFormValues[index].claimfield1 = selected[0].value;
                          } else {
                            updatedFormValues[index].claimfield1 = "Default";
                          }
                          this.setState({ formValues: updatedFormValues });
                        }}
                      />
                    </Col>
                    <Col md="5">
                      {formValue.claimfield1 === "ClaimStatus" ? (
                        <ReactSelect
                          onKeyPress={this.handleKeyPress}
                          onKeyDown={this.handleKeyPress}
                          options={this.state.statuslist}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{ Option }}
                          onChange={(selected) => {
                            const updatedFormValues = [...this.state.formValues];
                            updatedFormValues[index].claimvalue = selected || [];
                            this.setState({ formValues: updatedFormValues });
                          }}
                          allowSelectAll={true}
                          value={formValue.claimvalue}
                        />
                      ) : formValue.claimfield1 === "AgeGrp" ? (
                        <ReactSelect
                          onKeyPress={this.handleKeyPress}
                          onKeyDown={this.handleKeyPress}
                          options={ageOptions}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{ Option }}
                          onChange={(selected) => {
                            const updatedFormValues = [...this.state.formValues];
                            updatedFormValues[index].claimvalue = selected || [];
                            this.setState({ formValues: updatedFormValues });
                          }}
                          allowSelectAll={true}
                          value={formValue.claimvalue}
                        />
                      ) : formValue.claimfield1 === "type" ? (
                        <Typeahead
                          onKeyPress={this.handleKeyPress}
                          onKeyDown={this.handleKeyPress}
                          id="completeuser-typeahead"
                          clearButton={true}
                          options={["Web", "IVR", "Manual"]}
                          placeholder="Select Type..."
                          selected={formValue.claimvalue}
                          onChange={(selected) => {
                            const updatedFormValues = [...this.state.formValues];
                            updatedFormValues[index].claimvalue = selected || [];
                            this.setState({ formValues: updatedFormValues });
                          }}
                        />
                      ) : (
                        <Input
                          onKeyPress={this.handleKeyPress}
                          onKeyDown={this.handleKeyPress}
                          className="header__searchInput"
                          type="text"
                          placeholder="Enter text"
                          value={formValue.claimvalue}
                          onChange={(e) => {
                            const updatedFormValues = [...this.state.formValues];
                            updatedFormValues[index].claimvalue = e.target.value;
                            this.setState({ formValues: updatedFormValues });
                          }}
                        />
                      )}
                    </Col>
                    <Col md="2" className="d-flex justify-content-end">
                      <div className="button-group">
                        {index > 0 && (
                          <Button className="btn btn-danger float-right labelfontsize" onClick={() => this.removeFormFields(index)}>
                            <AiOutlineCloseCircle />
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </Form>
          </div>
          <div>
            <button
              type="button"
              className="btn"
              style={{ backgroundColor: "#89CFF0", color: "#fff" }}
              onClick={(e) => {
                this.setState({ firstsearch1: true, searchCompleted: true }, () => this.filtersearch(e));
              }}
            >
              <AiOutlineSearch />
            </button>
            <button
              type="button"
              className="btn"
              style={{ backgroundColor: "#7DD657", color: "#fff" }}
              onClick={() => this.addFormField()}
            >
              <AiOutlinePlusCircle />
            </button>
            <button
              type="button"
              className="btn"
              style={{ backgroundColor: "#041c3c", color: "#fff" }}
              onClick={(e) => this.reset(e)}
            >
              <IoIosRefresh style={{ color: "white" }} />
            </button>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Input
              type="select"
              style={{width:"43%"}}
              className="form-control form-select"
              onChange={(e) => {
                const index = e.target.selectedIndex;
                const el = e.target.childNodes[index];
                const option = el.getAttribute("title");
                this.valuedate(option, e);
              }}
            >
              <option value="" title="custom">Custom</option>
              <option value={moment().format("MM-DD-YYYY")} title="">Today</option>
              <option value={moment().subtract(1, "days").format("MM-DD-YYYY")} title="yesterday">Yesterday</option>
              <option value={moment().subtract(3, "days").format("MM-DD-YYYY")} title="">Last 3 days</option>
              <option value={moment().subtract(7, "days").format("MM-DD-YYYY")} title="">Last 7 days</option>
              <option value={moment().subtract(30, "days").format("MM-DD-YYYY")} title="">Last 30 days</option>
              <option value={moment().subtract(365, "days").format("MM-DD-YYYY")} title="">Last 365 days</option>
            </Input>
            {this.state.filterstatus === "custom" && (
              <RangePicker
                format="MM-DD-YYYY"
                onChange={(dates, datestring) => {
                  this.setState({ valueOfInput1: datestring[0], valueOfInput2: datestring[1] }, () => this.getcompletedclaims());
                }}
                style={{ borderColor: "gray", height:"35px" }}
              />
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="select_drop">
              <select
                style={{ height: "35px" }}
                defaultValue={this.state.perPage}
                onChange={(e) => this.setState({ perPage: e.target.value, currentpage: 0, sperPage: e.target.value, currentpagesearch: 0 }, () => this.getcompletedclaims())}
              >
                <option value="select" hidden selected>Select page count</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
              </select>
            </div>
            <div>
              <Button
                style={{ border: "none", color: "white", background: "white", fontWeight: "bold" }}
                onClick={(e) => this.resetcount(e)}
              >
                <IoIosRefresh style={{ color: "blue", fontSize: "23px" }} />
              </Button>
            </div>
            <div>
              <button
                style={{
                  boxShadow: "inset 0px 1px 0px 0px #e184f3",
                  background: "black",
                  backgroundColor: "black",
                  borderRadius: "6px",
                  border: "1px solid black",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "#ffffff",
                  fontSize: "13px",
                  fontWeight: "bold",
                  padding: "6px 24px",
                  textDecoration: "none",
                  textShadow: "0px 1px 0px #9b14b3",
                  whiteSpace: "nowrap",
                }}
                onClick={this.getcompletedclaimsreport}
              >
                Generate Report
              </button>
            </div>
          </div>
        </div>
        
        
          <div>
            {this.state.fetching ? (
              <div>
                <p
                  style={{ fontSize: "23px", marginTop: "25px", color: "#000" }}
                >
                  <span>Loading...</span>
                  <span>Please Wait...</span>
                </p>
                <div className="boxes body__loader dribbble">
                  <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            ) : 
            this.state.claimsdata.length === 0 ? (
              <div className="bot-ui">
                <div className="bot-head">
                  <div className="eyes-container">
                    <div className="to-left eye"></div>
                    <div className="to-right eye"></div>
                  </div>
                </div>
                <div className="bot-body">
                  <div className="analyzer"></div>
                </div>
                <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                  No Data Found
                </p>
              </div>
            ) : (
              <div className="table_view">
                {this.state.searchtext === "" ? (
                  <div id="actions">
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      forcePage={this.state.currentpage}
                    />
                  </div>
                  <div style={{ marginLeft: "3%", marginRight: "3%" }}>
                      <Table responsive bordered
                      className="StandardTable"
                      >
                        <thead>
                          <tr>
                            <th>Action</th>
                            <th
                              onClick={() => this.sorting("ClaimID")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Claim ID{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                              onClick={() => this.sorting("startTime")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Start Time{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                            onClick={() => this.sorting("endTime")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            End Time{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sorting("workedMinutes")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Worked Mins{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                            <th
                              onClick={() => this.sorting("Location")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Location{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.sorting("patientAccountNumber")
                              }
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Account No{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                              onClick={() => this.sorting("MRN")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                            Claim No
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                              onClick={() => this.sorting("DateOfService")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              DOS{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                              onClick={() => this.sorting("PatientFirstName")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Patient Name{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                              onClick={() => this.sorting("PatientDOB")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Patient DOB{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                              onClick={() => this.sorting("PlanCode")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              PlanCode{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                              onClick={() => this.sorting("Pripayer")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Insurance Name{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                              onClick={() => this.sorting("Pripayerphone")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Insurance No
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                              onClick={() => this.sorting("PrimaryPolicyNo")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Policy No{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                              onClick={() => this.sorting("Physician")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Physician Name{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                              onClick={() => this.sorting("Age")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Age{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                              onClick={() => this.sorting("AgeGrp")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Age Grp{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                              onClick={() => this.sorting("type")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Type{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                              onClick={() => this.sorting("ClaimStatus")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Claim Status{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.claimsdata &&
                            this.state.claimsdata.map((data, index) => (
                              <tr>
                                <td
                                  onClick={() => (
                                    (
                                      <ActionViewClaim
                                        value={
                                          sessionStorage.setItem(
                                            "ClaimID",
                                            data.ClaimID
                                          ) ||
                                          sessionStorage.setItem(
                                            "Module",
                                            "Completed"
                                          ) ||
                                          sessionStorage.setItem(
                                            "ChartID",
                                            data.ChartID
                                          ) ||
                                          sessionStorage.setItem(
                                            "MRN",
                                            data.MRN
                                          ) ||
                                          sessionStorage.setItem(
                                            "Patient",
                                            data.Patient
                                          ) ||
                                          sessionStorage.setItem(
                                            "PatientDOB",
                                            data.PatientDOB
                                          ) ||
                                          sessionStorage.setItem(
                                            "Physician",
                                            data.Physician
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimDate",
                                            data.ClaimDate
                                          ) ||
                                          sessionStorage.setItem(
                                            "Pripayer",
                                            data.Pripayer
                                          ) ||
                                          sessionStorage.setItem(
                                            "DateOfService",
                                            data.DateOfService
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimDate",
                                            data.ClaimDate
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimDBID",
                                            data._id
                                          ) ||
                                          sessionStorage.setItem(
                                            "AllocatedTo",
                                            data.Claimunder
                                          )
                                        }
                                      />
                                    ),(
                                      <Viewmoreeligibility
                                        value={
                                          sessionStorage.setItem(
                                            "modulename",
                                            "Completed"
                                          ) ||
                                          sessionStorage.setItem(
                                            "Eligibilityclaimid",
                                            data.ClaimID
                                          )
                                        }
                                      />
                                    ),
                                    (
                                      <ActionSimpleMenu
                                        value={
                                          sessionStorage.setItem(
                                            "ClaimID",
                                            data.ClaimID
                                          ) ||
                                          sessionStorage.setItem(
                                            "PatientFirstName",
                                            data.PatientFirstName
                                          ) ||
                                          sessionStorage.setItem(
                                            "PatientLastName",
                                            data.PatientLastName
                                          )
                                        }
                                      ></ActionSimpleMenu>
                                    )
                                  )}
                                >
                                  <ActionSimpleMenu />
                                </td>
                                <td>
                                  {data.ClaimID}
                                </td>
                                <td>{data.startTime ? data.startTime.split('T')[1].split('.')[0] : "-"}</td>
                                <td>{data.endTime ? data.endTime.split('T')[1].split('.')[0]: "-"}</td>
                                <td>
                                  {data.workedMinutes}
                                </td>
                                <td>
                                  {data.Location}
                                </td>
                                <td>
                                  {data.patientAccountNumber}
                                </td>
                                <td>{data.MRN}</td>
                                <td>
                                  {data.DateOfService}
                                </td>

                                <td>
                                  {data.PatientFirstName}&nbsp;
                                  {data.PatientLastName}
                                </td>
                                <td>
                                  {moment(data.PatientDOB).format("MM/DD/YYYY")}
                                </td>
                                <td>
                                  {data.PlanCode}
                                </td>
                                <td>
                                  {data.InsuranceName
                                    ? data.InsuranceName
                                    : "-"}
                                </td>
                                <td>
                                  <Row>
                                    <Col md="4">
                                      <Twilio value11={data.Pripayerphone} />
                                    </Col>
                                    <Col md="2">{data.Pripayerphone}</Col>
                                  </Row>
                                </td>
                                <td>
                                  {data.PrimaryPolicyNo}
                                </td>
                                <td>
                                  {data.Physician}
                                </td>
                                <td>{data.Age}</td>
                                <td>{data.AgeGrp}</td>
                                <td>{data.type}</td>

                                <td>
                                  {data.ClaimStatus}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      </div>
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.currentpage}
                      />
                    </div>
                  </div>
                ) : this.state.searchdataresponse.length == 0 ? (
                  <div>
                    <img src={noresult} />
                  </div>
                ) : (
                  <div id="actions">
                    <br />
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.spageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick1}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.currentpagesearch}
                      />
                    </div>
                  <div style={{ marginLeft: "3%", marginRight: "3%" }}>
                      <Table
                      bordered
                      responsive
                      className="StandardTable"
                        style={{ outline: "1px" }}
                      >
                      <thead>
                      <tr>
                        <th>Action</th>
                        <th
                          onClick={() => this.sorting("ClaimID")}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Claim ID{" "}
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false })
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true })
                              }
                            />
                          )}
                        </th>
                        <th
                              onClick={() => this.sorting("startTime")}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Start Time{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false })
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true })
                                  }
                                />
                              )}
                            </th>
                            <th
                            onClick={() => this.sorting("endTime")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            End Time{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sorting("workedMinutes")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Worked Mins{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                        <th
                          onClick={() => this.sorting("Location")}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Location{" "}
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false })
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true })
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.sorting("patientAccountNumber")
                          }
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Account No{" "}
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false })
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true })
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => this.sorting("MRN")}
                          style={{ whiteSpace: "nowrap" }}
                        >
                        Claim No
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false })
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true })
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => this.sorting("DateOfService")}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          DOS{" "}
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false })
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true })
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => this.sorting("PatientFirstName")}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Patient Name{" "}
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false })
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true })
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => this.sorting("PatientDOB")}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Patient DOB{" "}
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false })
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true })
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => this.sorting("PlanCode")}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          PlanCode{" "}
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false })
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true })
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => this.sorting("Pripayer")}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Insurance Name{" "}
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false })
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true })
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => this.sorting("Pripayerphone")}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Insurance No
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false })
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true })
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => this.sorting("PrimaryPolicyNo")}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Policy No{" "}
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false })
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true })
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => this.sorting("Physician")}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Physician Name{" "}
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false })
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true })
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => this.sorting("Age")}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Age{" "}
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false })
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true })
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => this.sorting("AgeGrp")}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Age Grp{" "}
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false })
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true })
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => this.sorting("type")}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Type{" "}
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false })
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true })
                              }
                            />
                          )}
                        </th>
                        <th
                          onClick={() => this.sorting("ClaimStatus")}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Claim Status{" "}
                          {this.state.openicon ? (
                            <BiUpArrowAlt
                              onClick={() =>
                                this.setState({ openicon: false })
                              }
                            />
                          ) : (
                            <BiDownArrowAlt
                              onClick={() =>
                                this.setState({ openicon: true })
                              }
                            />
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.searchdataresponse &&
                      this.state.searchdataresponse.map((data, index) => (
                        <tr>
                          <td
                            onClick={() => (
                              (
                                <ActionViewClaim
                                  value={
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "Module",
                                      "Completed"
                                    ) ||
                                    sessionStorage.setItem(
                                      "ChartID",
                                      data.ChartID
                                    ) ||
                                    sessionStorage.setItem(
                                      "MRN",
                                      data.MRN
                                    ) ||
                                    sessionStorage.setItem(
                                      "Patient",
                                      data.Patient
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientDOB",
                                      data.PatientDOB
                                    ) ||
                                    sessionStorage.setItem(
                                      "Physician",
                                      data.Physician
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "Pripayer",
                                      data.Pripayer
                                    ) ||
                                    sessionStorage.setItem(
                                      "DateOfService",
                                      data.DateOfService
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDate",
                                      data.ClaimDate
                                    ) ||
                                    sessionStorage.setItem(
                                      "ClaimDBID",
                                      data._id
                                    ) ||
                                    sessionStorage.setItem(
                                      "AllocatedTo",
                                      data.Claimunder
                                    )
                                  }
                                />
                              ),(
                                <Viewmoreeligibility
                                  value={
                                    sessionStorage.setItem(
                                      "modulename",
                                      "Completed"
                                    ) ||
                                    sessionStorage.setItem(
                                      "Eligibilityclaimid",
                                      data.ClaimID
                                    )
                                  }
                                />
                              ),
                              (
                                <ActionSimpleMenu
                                  value={
                                    sessionStorage.setItem(
                                      "ClaimID",
                                      data.ClaimID
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientFirstName",
                                      data.PatientFirstName
                                    ) ||
                                    sessionStorage.setItem(
                                      "PatientLastName",
                                      data.PatientLastName
                                    )
                                  }
                                ></ActionSimpleMenu>
                              )
                            )}
                          >
                            <ActionSimpleMenu />
                          </td>
                          <td>
                            {data.ClaimID}
                          </td>
                          <td>{data.startTime ? data.startTime.split('T')[1].split('.')[0] : "-"}</td>
                          <td>{data.endTime ? data.endTime.split('T')[1].split('.')[0]: "-"}</td>
                                <td>
                                  {data.workedMinutes}
                                </td>
                          <td>
                            {data.Location}
                          </td>
                          <td>
                            {data.patientAccountNumber}
                          </td>
                          <td>{data.MRN}</td>
                          <td>
                            {data.DateOfService}
                          </td>

                          <td>
                            {data.PatientFirstName}&nbsp;
                            {data.PatientLastName}
                          </td>
                          <td>
                          {moment(data.PatientDOB).format("MM/DD/YYYY")}
                          </td>
                          <td>
                            {data.PlanCode}
                          </td>
                          <td>
                            {data.InsuranceName
                              ? data.InsuranceName
                              : "-"}
                          </td>
                          <td>
                            <Row>
                              <Col md="4">
                                <Twilio value11={data.Pripayerphone} />
                              </Col>
                              <Col md="2">{data.Pripayerphone}</Col>
                            </Row>
                          </td>
                          <td>
                            {data.PrimaryPolicyNo}
                          </td>
                          <td>
                            {data.Physician}
                          </td>
                          <td>{data.Age}</td>
                          <td>{data.AgeGrp}</td>
                          <td>{data.type}</td>

                          <td>
                            {data.ClaimStatus}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                      </Table>
                      </div>
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.spageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick1}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.currentpagesearch}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
      </div>
      </div>
    );
  }
}

export default UserCompletedPage;
