import React, { Component } from "react";
import axios from "axios";
import "../../Styles/configure.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import loading from "../../Images/loader.gif";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import ReactPaginate from "react-paginate";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import {Table} from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { ip, secretkey } from "../../Api";
import aes256 from "aes256";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;
  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};
export default class RefProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      refdata: [],
      dataforupdate: null,
      modal: false,
      updatemodal: false,
      deletemodal: false,
      singleid: "",
      offset: 0,
      currentpage: 0,
      perPage: 10,
      createmodal: false,
      physicianfirstname:"",
      physicianlastname:"",
      prefix:"",
      npi:"",
      address:"",
      zipcode:"",
      city:"",
      state:"",
      country:"",
      phone1:"",
      phone2:"",
      fax:"",
      email:"",
      speciality:"",
      specialityarray:[],
      taxonomy:"",
      updatetaxonomy:"",
      status:"",
      updatephysician:"",
      updatephysicianfirstname:"",
      updatephysicianlastname:"",
      updateprefix:"",
      updatenpi:"",
      updateaddress:"",
      updatezipcode:"",
      updatecity:"",
      updatestate:"",
      updatecountry:"",
      updatephone1:"",
      updatephone2:"",
      updatefax:"",
      updateemail:"",
      updatespeciality:"",
      updatestatus:"",
      zipcodelist:[],
    };
    this.handleChangePhone = this.handleChangePhone.bind(this);
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal,
      prefix:"",
      physicianfirstname:"",
      physicianlastname:"",
      npi:"",
      address:"",
      zipcode:"",
      city:"",
      state:"",
      country:"",
      phone1:"",
      phone2:"",
      fax:"",
      email:"",
      status:"",
      speciality:"",
      taxonomy:"" 
    });
  };
  // toogle modal for delete
  toggledelete = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };
  //toggle modal for update
  toggleupdate = () => {
    this.setState({ updatemodal: !this.state.updatemodal });
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getRef();
      }
    );
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  //phone number validation
  handleChangePhone({ target: { value } }) {
    this.setState((prevState) => ({
      phone1: normalizeInput(value, prevState.phone1),
    }));
  }
  getRef= async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/referralprovider/all?pageno=${this.state.currentpage}`, {
        headers: headers,
      })
      .then((res) => {
        let refData;
        refData = aes256.decrypt(`${secretkey}`, res.data.Advanced_billingprovider);
        const decryptedrefData = JSON.parse(refData);  
        this.setState({
          pageCount: Math.ceil(res.data.count/ this.state.perPage),
          refdata: decryptedrefData,
        });
      })
      .catch((err) => {});
  };
  settingupdatestate = function () {
    this.setState({
      updatephysicianfirstname:this.state.dataforupdate.physicianfirstname,
      updatephysicianlastname: this.state.dataforupdate.physicianlastname,
      updateprefix: this.state.dataforupdate.prefix,
      updatenpi: this.state.dataforupdate.npi,
      singleid: this.state.dataforupdate._id,
      updateaddress:this.state.dataforupdate.address,
      updatezipcode: this.state.dataforupdate.zipCode,
      updatecity:this.state.dataforupdate.city,
      updatestate:this.state.dataforupdate.country,
      updatecountry:this.state.dataforupdate.country,
      updatephone1:this.state.dataforupdate.phone1,
      updatephone2:this.state.dataforupdate.phone2,
      updatefax:this.state.dataforupdate.fax,
      updateemail:this.state.dataforupdate.email,
      updatespeciality:this.state.dataforupdate.speciality,
      updatetaxonomy:this.state.dataforupdate.taxonomycode,
      updatestatus:this.state.dataforupdate.status      
    });
  };
  putrequests = async (dataid) => {
      this.setState({ createmodal: !this.state.createmodal });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        physician: this.state.updatephysician,
        physicianfirstname:this.state.updatephysicianfirstname,
        physicianlastname: this.state.updatephysicianlastname,
        prefix: this.state.updateprefix,
        npi: this.state.updatenpi,
        address: this.state.updateaddress,
        zipCode: this.state.updatezipcode,
        city: this.state.updatecity,
        state: this.state.updatestate,
        country: this.state.updatecountry,
        phone1:this.state.updatephone1,
        phone2:this.state.updatephone2,
        fax:this.state.updatefax,
        email:this.state.updateemail,
        speciality:this.state.updatespeciality,
        taxonomycode:this.state.updatetaxonomy,
        status:this.state.updatestatus,
      };
      // eslint-disable-next-line
      const res = await axios
        .put(`${ip}/referralprovider/${dataid}`, value, {
          headers: headers,
        })
        .then((res) => {
          this.toggleupdate();
          this.getRef();
          this.setState({ createmodal: !this.state.createmodal });
          toast.info("Refferal Provider updated", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          if (err.response) {
            this.getRef();
            this.toggleupdate();
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
    
  };
  deleterequests = async (DataID) => {
    //api call to delete requests
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const req = axios
      .delete(`${ip}/referralprovider/${DataID}`, {
        headers: headers,
      })
      .then((req) => {
        this.toggledelete();
        this.getRef();
        this.setState({ createmodal: !this.state.createmodal });
        toast.error("Refferal Provider deleted", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response) {
          this.getRef();
          this.toggledelete();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  handleSubmit = async () => {
      this.setState({ createmodal: !this.state.createmodal });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        // physician: this.state.physician,
        prefix: this.state.prefix,
        physicianfirstname: this.state.physicianfirstname,
        physicianlastname:this.state.physicianlastname,
        npi: this.state.npi,
        address: this.state.address,
        zipCode: this.state.zipcode,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        phone1:this.state.phone1,
        phone2:this.state.phone2,
        fax:this.state.fax,
        email:this.state.email,
        speciality:this.state.speciality,
        taxonomycode:this.state.taxonomy,
        status:this.state.status,
      };
      // eslint-disable-next-line
      const res = await axios
        .post(`${ip}/referralprovider/create`, value, {
          headers: headers,
        })
        .then((res) => {
          this.toggle();
          this.getRef();
          this.setState({ createmodal: !this.state.createmodal });
          toast.success("Refferal Provider created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          if (err.response) {
            this.getRef();
            this.toggle();
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
  };
  reset = () => {
    this.setState({
    physician:"",
    npi:"",
    address:"",
    zipcode:"",
    city:"",
    state:"",
    country:"",
    phone1:"",
    phone2:"",
    fax:"",
    email:"",
    speciality:"",
    status:"",
    });
  };
  getZipcode = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(
        `${ip}/zipcodes/all`,
        { headers: headers }
      )
      .then((res) => {
        
        this.setState({
        zipcodelist:res.data.zipcodes
        });
      })

      .catch((err) => {});
  };
  getZipcodeValues = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(
        `${ip}/zipcodes/all?zipcode=${this.state.zipcode}`,
        { headers: headers }
      )
      .then((res) => {
        this.setState({
        city:res.data.zipcodes[0].city,
        country:res.data.zipcodes[0].country,
        state:res.data.zipcodes[0].stateName,
        });
      })

      .catch((err) => {});
  };
  getspeciality = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      url = `${ip}/speciality/all?clientId=${this.state.client}`;
    }else{
      url = `${ip}/speciality/all`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        let specialityData;
        specialityData = aes256.decrypt(`${secretkey}`, res.data.speciality);
        const decryptedspecialitydata = JSON.parse(specialityData); 
        this.setState({
          specialityarray: decryptedspecialitydata,
        });
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
    this.getRef();
    this.getspeciality();
    this.getZipcode();
  }
  render() {
        return (
          <div>
        <Newsidebar name="Refferal Provider" />
      <div className="align-row" style={{overflow:"hidden"}}>
        <div className="align-column">
          <ToastContainer />
          <div className="align-row items" style={{  whiteSpace:"nowrap" }}>
            <button className="addNew" onClick={this.toggle}>
              Add New Referral Provider
            </button>
          </div>
          {this.state.refdata.length !== 0 ? (
            <div style={{ marginTop: "1%", marginLeft:"2%", marginRight:"4%" }}>
            <Table
            bordered
            className="StandardTable"
            responsive
            >
              <thead>
                <tr>
                  <th>Physician FirstName</th>
                  <th>Physician LastName</th>
                  <th>NPI</th>
                  <th>Address</th>
                  <th>Zipcode</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Country</th>
                  <th>Phone1</th>
                  <th>Phone2</th>
                  <th>Fax</th>
                  <th>Email</th>
                  <th>Speciality</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody >
                {this.state.refdata &&
                  this.state.refdata.map((data, index) => (
                    <tr key={data._id}>
                      <td component="th" scope="row">
                        {data.physicianfirstname}
                      </td>
                      <td component="th" scope="row">
                      {data.physicianlastname}
                    </td>
                      <td>{data.npi}</td>
                      <td>{data.address}</td>
                      <td>{data.zipCode}</td>
                      <td>{data.city}</td>
                      <td>{data.state}</td>
                      <td>{data.country}</td>
                      <td>{data.phone1}</td>
                      <td>{data.phone2}</td>
                      <td>{data.fax}</td>
                      <td>{data.email}</td>
                      <td>{data.speciality}</td>
                      <td>{data.status}</td>
                      <td>
                        <button
                          className="Edit"
                          onClick={() => {
                            this.setState({ dataforupdate: data }, () => {
                              this.settingupdatestate();
                              this.toggleupdate();
                            });
                          }}
                        >
                          Edit
                        </button>
                        &nbsp;
                        <button
                          className="Delete"
                          onClick={() => {
                            this.setState({ singleid: data._id }, () => {
                              this.toggledelete();
                            });
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                    // ))}
                  ))}
              </tbody>
            </Table>
          <div style={{ marginLeft: "3%", marginTop: "20px" }}>
            <ReactPaginate
              previousLabel={<IoIosArrowBack />}
              nextLabel={<IoIosArrowForward />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
          ):
          (<div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3 align="center">No data found</h3>
          </div>)
        }
     
          {this.state.modal && (
            <Modal
              style={{ width: "50%" }}
              isOpen={this.state.modal}
              onHide={this.toggle}
              onExit={this.reset}
              centered
              size="xl"
            >
              <ModalHeader toggle={this.toggle}>Create New Referral Provider</ModalHeader>
              <ModalBody
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
              >
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{whiteSpace:"nowrap"}}>Prefix</Label>
                        <Input
                          type="select"
                          value={this.state.prefix}
                          onChange={(e) =>
                            this.setState({
                                prefix: e.target.value,
                            })
                          }
                        >
                        <option value={""} hidden>Select</option>
                        <option value={"Dr"}>Dr.</option>
                        <option value={"Mr"}>Mr.</option>
                        <option value={"Mrs"}>Mrs.</option>
                        <option value={"Mrs"}>Miss.</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{whiteSpace:"nowrap"}}>Physician First Name</Label>
                        <Input
                          type="text"
                          value={this.state.physicianfirstname}
                          onChange={(e) =>
                            this.setState({
                                physicianfirstname: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{whiteSpace:"nowrap"}}>Physician Last Name</Label>
                        <Input
                          type="text"
                          value={this.state.physicianlastname}
                          onChange={(e) =>
                            this.setState({
                                physicianlastname: e.target.value,
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.codeErr}
                        </div>
                      </FormGroup>
                    </Col>
                    </Row>
                    <br/>    
                  <Row>
                  <Col>
                  <FormGroup>
                    <Label>NPI</Label>
                    <Input
                      type="text"
                      required
                      value={this.state.npi}
                      onChange={(e) =>
                        this.setState({
                          npi: e.target.value,
                        })
                      }
                    />
                    <div style={{ fontSize: 16, color: "red" }}>
                      {this.state.codeErr}
                    </div>
                  </FormGroup>
                </Col>
                </Row>
                  <br />
                  <Row>
                  <Col>
                  <FormGroup>
                    <Label>Address</Label>
                    <Input
                      type="text"
                      required
                      value={this.state.address}
                      onChange={(e) =>
                        this.setState({
                          address: e.target.value,
                        })
                      }
                    />
                    <div style={{ fontSize: 16, color: "red" }}>
                      {this.state.codeErr}
                    </div>
                  </FormGroup>
                </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Zip code</Label>
                        <Typeahead
                        id="typeahed1"
                        clearButton={true}
                        options={this.state.zipcodelist}
                        labelKey={(option) =>
                          (this.state.zipcode = `${option.zipCode}`)
                        }
                        placeholder="Select..."
                        onChange={(option) =>
                          this.setState(
                            {
                              zipcode: option.zipCode,
                              city:"",
                              state:"",
                              country:""
                            }, ()=> {this.getZipcodeValues()}
                          )
                        }
                      />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.descErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                    <FormGroup>
                      <Label>City</Label>
                      <Input
                        type="text"
                        required
                        value={this.state.city}
                        onChange={(e) =>
                          this.setState({ city: e.target.value, descErr: "" })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.descErr}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                  <FormGroup>
                    <Label>State</Label>
                    <Input
                      type="text"
                      required
                      value={this.state.state}
                      onChange={(e) =>
                        this.setState({ state: e.target.value, descErr: "" })
                      }
                    />
                    <div style={{ fontSize: 16, color: "red" }}>
                      {this.state.descErr}
                    </div>
                  </FormGroup>
                </Col>
                  </Row>
                  <br/>
                  <Row>
                  <Col>
                    <FormGroup>
                      <Label>Country</Label>
                      <Input
                        type="text"
                        required
                        value={this.state.country}
                        onChange={(e) =>
                          this.setState({ country: e.target.value, descErr: "" })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.descErr}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                  <FormGroup>
                    <Label>Phone1</Label>
                    <Input
                    type="text"
                    name="phone"
                    placeholder="(xxx) xxx-xxxx"
                    value={this.state.phone1}
                    onChange={this.handleChangePhone}
                  />
                  {this.state.error && (
                    <p className="error">{this.state.error}</p>
                  )}
                  </FormGroup>
                </Col>
                <Col>
                <FormGroup>
                  <Label style={{whiteSpace:"nowrap"}}>Phone2</Label>
                  <Input
                    type="text"
                    required
                    value={this.state.phone2}
                    onChange={(e) =>
                      this.setState({ phone2: e.target.value, descErr: "" })
                    }
                  />
                  <div style={{ fontSize: 16, color: "red" }}>
                    {this.state.descErr}
                  </div>
                </FormGroup>
              </Col>

                </Row>
                <br/>
                <Row>
                <Col>
                  <FormGroup>
                    <Label>Fax</Label>
                    <Input
                      type="text"
                      required
                      value={this.state.fax}
                      onChange={(e) =>
                        this.setState({ fax: e.target.value, descErr: "" })
                      }
                    />
                    <div style={{ fontSize: 16, color: "red" }}>
                      {this.state.descErr}
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    type="text"
                    required
                    value={this.state.email}
                    onChange={(e) =>
                      this.setState({ email: e.target.value, descErr: "" })
                    }
                  />
                  <div style={{ fontSize: 16, color: "red" }}>
                    {this.state.descErr}
                  </div>
                </FormGroup>
              </Col>
        
            <Col>
            <FormGroup>
              <Label style={{whiteSpace:"nowrap"}}>Status</Label>
              <Input
                type="select"
                required
                value={this.state.status}
                onChange={(e) =>
                  this.setState({ status: e.target.value, descErr: "" })
                }
              >
              <option value={""} hidden>Select Status</option>
              <option value={"Enabled"}>Enabled</option>
              <option value={"Disabled"}>Disabled</option>
              </Input>
              <div style={{ fontSize: 16, color: "red" }}>
                {this.state.descErr}
              </div>
            </FormGroup>
          </Col>

              </Row>
              <br/>
              <Row>
              <Col>
              <FormGroup>
                <Label style={{whiteSpace:"nowrap"}}>Speciality</Label>
                <Typeahead
                id="typeahed1"
                clearButton={true}
                defaultInputValue={this.state.speciality || ""}
                options={this.state.specialityarray}
                labelKey={(option) =>
                  (this.state.speciality = `${option.speciality}`)
                }
                onClick={(e) => {
                 this.setState({
                  speciality: e.target.value
                 })
                }}
                placeholder="Select speciality"
              />
              </FormGroup>
            </Col>
            <Col>
            <FormGroup>
              <Label style={{whiteSpace:"nowrap"}}>Taxonomy code</Label>
              <Input
                type="text"
                required
                value={this.state.taxonomy}
                onChange={(e) =>
                  this.setState({ taxonomy: e.target.value, descErr: "" })
                }
              />

            </FormGroup>
          </Col>
              </Row>
               <br/>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.handleSubmit}
                >
                  Create
                </Button>
              </ModalFooter>
            </Modal>
          )}
          {this.state.updatemodal && (
            <Modal
              style={{ width: "60%" }}
              isOpen={this.state.updatemodal}
              onHide={this.toggleupdate}
              onExit={this.reset}
              centered
              size="xl"
            >
              <ModalHeader toggle={this.toggleupdate}>Update Referral Provider</ModalHeader>
              <ModalBody
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
              >
              <Form>
              <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{whiteSpace:"nowrap"}}>Prefix</Label>
                        <Input
                          type="select"
                          value={this.state.updateprefix}
                          onChange={(e) =>
                            this.setState({
                                updateprefix: e.target.value,
                            })
                          }
                        >
                        <option value={""} hidden>Select</option>
                        <option value={"Mr"}>Mr.</option>
                        <option value={"Mrs"}>Mrs.</option>
                        <option value={"Mrs"}>Miss.</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{whiteSpace:"nowrap"}}>Physician First Name</Label>
                        <Input
                          type="text"
                          value={this.state.updatephysicianfirstname}
                          onChange={(e) =>
                            this.setState({
                                updatephysicianfirstname: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{whiteSpace:"nowrap"}}>Physician Last Name</Label>
                        <Input
                          type="text"
                          value={this.state.updatephysicianlastname}
                          onChange={(e) =>
                            this.setState({
                                updatephysicianlastname: e.target.value,
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.codeErr}
                        </div>
                      </FormGroup>
                    </Col>
                    </Row>
                    <br/>
                    <Row>
                    <Col>
                    <FormGroup>
                      <Label>NPI</Label>
                      <Input
                        type="text"
                        required
                        value={this.state.updatenpi}
                        onChange={(e) =>
                          this.setState({
                            updatenpi: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  </Row>
                    <br />
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Zip code</Label>
                    <Typeahead
                    id="typeahed1"
                    clearButton={true}
                    options={this.state.zipcodelist}
                    labelKey={(option) =>
                      (this.state.updatezipcode = `${option.zipCode}`)
                    }
                    placeholder="Select..."
                    onChange={(option) =>
                      this.setState(
                        {
                          updatezipcode: option.zipCode,
                          city:"",
                          state:"",
                          country:""
                        }, ()=> {this.getZipcodeValues()}
                      )
                    }
                  />
                    <div style={{ fontSize: 16, color: "red" }}>
                      {this.state.descErr}
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                <FormGroup>
                  <Label>City</Label>
                  <Input
                    type="text"
                    required
                    value={this.state.updatecity}
                    onChange={(e) =>
                      this.setState({ updatecity: e.target.value, descErr: "" })
                    }
                  />
                  <div style={{ fontSize: 16, color: "red" }}>
                    {this.state.descErr}
                  </div>
                </FormGroup>
              </Col>
              <Col>
              <FormGroup>
                <Label>State</Label>
                <Input
                  type="text"
                  required
                  value={this.state.updatestate}
                  onChange={(e) =>
                    this.setState({ updatestate: e.target.value, descErr: "" })
                  }
                />
                <div style={{ fontSize: 16, color: "red" }}>
                  {this.state.descErr}
                </div>
              </FormGroup>
            </Col>
              </Row>
              <br/>
              <Row>
              <Col>
                <FormGroup>
                  <Label>Country</Label>
                  <Input
                    type="text"
                    required
                    value={this.state.updatecountry}
                    onChange={(e) =>
                      this.setState({ updatecountry: e.target.value, descErr: "" })
                    }
                  />
                  <div style={{ fontSize: 16, color: "red" }}>
                    {this.state.descErr}
                  </div>
                </FormGroup>
              </Col>
              <Col>
              <FormGroup>
                <Label>Phone1</Label>
                <Input
                  type="text"
                  required
                  value={this.state.updatephone1}
                  onChange={(e) =>
                    this.setState({ updatephone1: e.target.value, descErr: "" })
                  }
                />
                <div style={{ fontSize: 16, color: "red" }}>
                  {this.state.descErr}
                </div>
              </FormGroup>
            </Col>
            <Col>
            <FormGroup>
              <Label style={{whiteSpace:"nowrap"}}>Phone2</Label>
              <Input
                type="text"
                required
                value={this.state.updatephone2}
                onChange={(e) =>
                  this.setState({ updatephone2: e.target.value, descErr: "" })
                }
              />
              <div style={{ fontSize: 16, color: "red" }}>
                {this.state.descErr}
              </div>
            </FormGroup>
          </Col>

            </Row>
            <br/>
            <Row>
            <Col>
              <FormGroup>
                <Label>Fax</Label>
                <Input
                  type="text"
                  required
                  value={this.state.updatefax}
                  onChange={(e) =>
                    this.setState({ updatefax: e.target.value, descErr: "" })
                  }
                />
                <div style={{ fontSize: 16, color: "red" }}>
                  {this.state.descErr}
                </div>
              </FormGroup>
            </Col>
            <Col>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="text"
                required
                value={this.state.updateemail}
                onChange={(e) =>
                  this.setState({ updateemail: e.target.value, descErr: "" })
                }
              />
              <div style={{ fontSize: 16, color: "red" }}>
                {this.state.descErr}
              </div>
            </FormGroup>
          </Col>
      
        <Col>
        <FormGroup>
          <Label style={{whiteSpace:"nowrap"}}>Status</Label>
          <Input
            type="select"
            required
            value={this.state.updatestatus}
            onChange={(e) =>
              this.setState({ updatestatus: e.target.value, descErr: "" })
            }
          >
          <option value={""} hidden>Select Status</option>
          <option value={"Enabled"}>Enabled</option>
          <option value={"Disabled"}>Disabled</option>
          </Input>
          <div style={{ fontSize: 16, color: "red" }}>
            {this.state.descErr}
          </div>
        </FormGroup>
      </Col>

          </Row>
          <br/>
          <Row>
          <Col>
          <FormGroup>
            <Label style={{whiteSpace:"nowrap"}}>Speciality</Label>
            <Typeahead
            id="typeahed1"
                clearButton={true}
                defaultInputValue={this.state.updatespeciality || ""}
                options={this.state.specialityarray}
                labelKey={(option) =>
                  (this.state.updatespeciality = `${option.speciality}`)
                }
                onClick={(e) => {
                 this.setState({
                  updatespeciality: e.target.value
                 })
                }}
                placeholder="Select speciality"
              />
            <div style={{ fontSize: 16, color: "red" }}>
              {this.state.descErr}
            </div>
          </FormGroup>
        </Col> 
        <Col>
        <FormGroup>
          <Label style={{whiteSpace:"nowrap"}}>Taxonomy code</Label>
          <Input
            type="text"
            required
            value={this.state.updatetaxonomy}
            onChange={(e) =>
              this.setState({ updatetaxonomy: e.target.value })
            }
          />

        </FormGroup>
      </Col>
          </Row>
           <br/>
            </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggleupdate();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    this.putrequests(this.state.singleid);
                  }}
                >
                  Update
                </Button>
              </ModalFooter>
            </Modal>
          )}
          {this.state.deletemodal && (
            <Modal
              isOpen={this.state.deletemodal}
              onHide={this.toggledelete}
              centered
            >
              <ModalHeader toggle={this.toggledelete}>
                Delete Referral Provider
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Do you want to delete this?</Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.toggledelete}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    this.deleterequests(this.state.singleid);
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
          <Modal isOpen={this.state.createmodal} centered>
            <ModalBody>
              <img
                src={loading}
                style={{ width: "200px", height: "200px", marginLeft: "30%" }}
              />
            </ModalBody>
          </Modal>
        </div>
      </div>
      </div>
    );
  }
}
