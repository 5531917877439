import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import { ip } from ".././Api";
import ReactPaginate from "react-paginate";
import { Spinner } from "react-bootstrap";
import CsvDownload from "react-json-to-csv";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { BiUpArrowAlt } from "react-icons/bi";
import { BiDownArrowAlt } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import Twilio from "../Transaction/Twilio";
import{Table} from "react-bootstrap";
import moment from "moment-timezone";
import ActionSimpleMenu from "./ActionsComponents/ActionMenuList.js";
import ActionViewClaim from "./ActionsComponents/ActionViewClaim";
import axios from "axios";
import { Modal } from "antd";
import { Button, Input } from "reactstrap";
import { Row, Col } from "react-bootstrap";
export default class ActionRejection extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      clientid: sessionStorage.getItem("clientid"),
      firstname: sessionStorage.getItem("firstname"),
      lastname: sessionStorage.getItem("lastname"),
      genderlist: ["Male", "Female"],
      taxid: "",
      locationlist: [],
      tablevalues: [],
      subAmt: [],
      LocationErr: "",
      accountnoErr: "",
      dosErr: "",
      pfirstnameErr: "",
      plastnameErr: "",
      plancodeErr: "",
      payeridErr: "",
      providercodeErr: "",
      orgaErr: "",
      taxidErr: "",
      policynoErr: "",
      providernamelistErr: "",
      npilistErr: "",
      dobErr: "",
      insurancenameErr: "",
      doiErr: "",
      doi: "",
      orgalist: [],
      cptlist: [],
      offset: 0,
      currentpage: 0,
      status: [],
      addModal: false,
      plancodelist: [],
      addtaxid: "",
      payeridlist: [],
      fetching: true,
      Type: "",
      dobdate: "",
      Module: "",
      phyname: "",
      any: [],
      policyno: "",
      Age: "",
      insurancename: "",
      npilist: "",
      date: "",
      providercodelist: [],
      providernamelist: "",
      addpayerid: "",
      addprovidercode: "",
      oragadata: "",
      loading: true,
      npiname: "",
      addnpiname: "",
      addorganame: "",
      locationname: "",
      gendername: "",
      datevalue: "",
      plancode: "",
      role_type: sessionStorage.getItem("role_type"),
      apiusername: sessionStorage.getItem("apiusername"),
      payerid: "",
      accountno: "",
      dos: "",
      pfirstname: "",
      statuslist: [],
      providername: "",
      plastname: "",
      perPage: 10,
      cpt: [],
      patientdob: "",
      policyno: "",
      cptmodal: false,
      sortstate: "ASC",
      openicon: false,
      formValues: [{ cpt: "", submitted_amount: "" }],
      submittedamount: [],
      cptvalues: [],
      timer: 0,
      intervalId: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.timerInterval = null;
  }
  handleChange(i, e) {
    let formValues = this.state.formValues;
    formValues[i][e.target.name] = e.target.value;
    this.setState({ formValues });
  }

  addFormFields() {
    this.setState({
      formValues: [...this.state.formValues, { cpt: "", submitted_amount: "" }],
    });
  }

  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }

  handleSubmit(event) {
    event.preventDefault();
    let cptvalue1 = this.state.formValues.map((data) => {
      return data.cpt;
    });
    let subvalue1 = this.state.formValues.map((data) => {
      return parseInt(data.submitted_amount);
    });
    this.setState(
      {
        cptvalues: cptvalue1,
        submittedamount: subvalue1,
      },
      () => this.addCpt()
    );
  }

  getClaimStatus = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

     await axios
      .get(`${ip}/claimstatus/all`, { headers: headers })
      .then((res) => {
        this.setState({
          statuslist: res.data.claimstatus,
        });
      })
      .catch((err) => {});
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getTabledata();
      }
    );
  };
  toggleaction = () => {
    this.setState({
      locationname: "",
      payerid: "",
      accountno: "",
      providernamelist: "",
      npilist: "",
      oragadata: "",
      taxid: "",
      addprovidercode: "",
      plancodedata: "",
      pfirstname: "",
      plastname: "",
      plancode: "",
      gendername: "",
      date: "",
      cpt: [],
      datevalue: "",
      patientdob: "",
      doi: "",
      insurancename: "",
      LocationErr: "",
      accountnoErr: "",
      pfirstnameErr: "",
      plastnameErr: "",
      genderErr: "",
      plancodeErr: "",
      payeridErr: "",
      orgaErr: "",
      taxidErr: "",
      policynoErr: "",
      providernamelistErr: "",
      npilistErr: "",
      dobErr: "",
      insurancenameErr: "",
      dosErr: "",
      genderErr: "",
      providercodeErr: "",
      doiErr: "",
    });
    this.setState(
      (prevState) => ({ addModal: !prevState.addModal }),
      () => {
        if (this.state.addModal) {
          this.startTimer();
        } else {
          this.stopTimer();
        }
      }
    );
  };
  togglecpt = () => {
    this.setState({
      cptmodal: !this.state.cptmodal,
      formValues: [{ cpt: "", submitted_amount: "" }],
    });
  };
  getLocation = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/location/all/list?clientId=${this.state.clientid}`, {
        headers: headers,
      })
      .then((res) => {
        let data1 = res.data.location.sort((a, b) => {
          return a.Location < b.Location ? -1 : 1;
        });
        this.setState({ locationlist: data1 });
      })
      .catch((err) => {});
  };
  getPlancode = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-View
     await axios
      .get(`${ip}/payermaster/all?clientId=${this.state.clientid}`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          plancodelist: res.data.payer,
        });
      })
      .catch((err) => {});
  };
  getCpt = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/claim/getcpts?clientId=${this.state.clientid}`, {
        headers: headers,
      })
      .then((res) => {
        let data = res.data.claims;
        let data1 = data.filter((item) => {
          return item.CPTCode;
        });
        this.setState({
          cptlist: data1,
        });
      })
      .catch((err) => {});
  };
  getPayerid = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(
        `${ip}/organizationdetails/getpayerid?clientId=${this.state.clientid}&plancode=${this.state.plancode}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState(
          {
            payeridlist: res.data.organizationdetails,
            payerid: res.data.organizationdetails[0].payer_id
              ? res.data.organizationdetails[0].payer_id
              : "-",
            typename: "",
          },
          () => this.getTypeName()
        );
      })
      .catch((err) => {});
  };
  getTypeName = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(
        `${ip}/organizationdetails/gettype?clientId=${this.state.clientid}&payerid=${this.state.payerid}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({ typename: res.data.organizationdetails[0].type });
      })
      .catch((err) => {});
  };
  getInsuranceName = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(
        `${ip}/organizationdetails/getpayerid?clientId=${this.state.clientid}&plancode=${this.state.plancode}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          insurancename: res.data.organizationdetails[0].CombinedInsurance,
        });
      })
      .catch((err) => {});
  };
  //This is function which will trigger to get the data of Plan code while giving insurance name
  getPayerName1 = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(
        `${ip}/organizationdetails/getpayerid?clientId=${this.state.clientid}&insuranceName=${this.state.insurancename}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          plancode: res.data.organizationdetails[0].PayerPlanCode,
        }, ()=> {
          this.getPayerid();
          this.getTypeName();
        });
      })
      .catch((err) => {});
  };
  getOrganisationName = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(
        `${ip}/organizationdetails/getorganizationname?clientId=${this.state.clientid}&Location=${this.state.locationname}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          orgalist: res.data.organizationdetails,
          oragadata: res.data.organizationdetails[0].OrganizationName,
          taxid: res.data.organizationdetails[0].TaxID,
        });
      })
      .catch((err) => {});
  };

  getcss = () => {
    if (this.state.role_type === "Team Member") {
      this.setState({ css_name: "align-col-actions" });
    } else {
      this.setState({ css_name: "align-column" });
    }
  };
  getNPI = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(
        `${ip}/organizationdetails/getnpi?clientId=${this.state.clientid}&providername=${this.state.providernamelist}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          npilist: res.data.organizationdetails[0].Npi,
        });
      })
      .catch((err) => {});
  };
  getProviderCode = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(
        `${ip}/organizationdetails/getprovidercode?clientId=${this.state.clientid}&Location=${this.state.locationname}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          providercodelist: res.data.organizationdetails,
        });
      })
      .catch((err) => {});
  };
  getProvidername = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(
        `${ip}/organizationdetails/getprovidername?clientId=${this.state.clientid}&Location=${this.state.locationname}&providercode=${this.state.addprovidercode}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          providernamelist: res.data.organizationdetails[0].ProviderName,
        });
        this.getNPI();
      })
      .catch((err) => {});
  };
  getTabledata = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let name = this.state.firstname + " " + this.state.lastname;
     await axios
      .get(
        `${ip}/claim/getdenialandrejectionclaims?userid=${name}&clientId=${this.state.clientid}&pageno=${this.state.currentpage}&count=${this.state.perPage}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          pageCount: Math.ceil(res.data.count / this.state.perPage),
          tablevalues: res.data.claims,
          fetching: false,
        });
      })
      .catch((err) => {});
  };
  getTablereport = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let name = this.state.firstname + " " + this.state.lastname;
     await axios
      .get(
        `${ip}/claim/getdenialandrejectionclaims?userid=${name}&clientId=${this.state.clientid}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          tablevalues: res.data.claims,
          fetching: false,
          loading: false,
        });
        res.data.claims.map((data, index) => {
          this.state.any[index] = {
            ClaimID: data.ClaimID,
            Location: data.Location,
            patientAccountNumber: data.patientAccountNumber,
            DateOfService: data.DateOfService,
            PatientFirstName: data.PatientFirstName,
            PatientLastName: data.PatientLastName,
            PatientDOB: data.PatientDOB,
            PlanCode: data.PlanCode,
            InsuranceName: data.InsuranceName,
            PrimaryPolicyNo: data.PrimaryPolicyNo,
            Pripayerphone: data.Pripayerphone,
            Physician: data.Physician,
            ClaimStatus: data.ClaimStatus,
            OverallClaimStatus: data.OverallClaimStatus,
            Claimunder: data.Claimunder,
            DOIAge: data.DOIAge,
            DOIAgeGrp: data.DOIAgeGrp,
            type: data.type,
            CreatedAt: data.CreatedAt,
          };
        });
      })
      .catch((err) => {});
  };
  resetCpt = () => {
    this.setState({
      cpt: [],
      subAmt: [],
    });
  };

  addCpt = () => {
    this.setState(
      {
        cpt: this.state.cptvalues,
        subAmt: this.state.submittedamount,
      },
      () => {
        this.addClaims();
      }
    );
    alert("cpt added successfully");
    this.setState({
      cptmodal: false,
    });
  };
  addClaims = async () => {
    const isValid = this.addClaimsValidate();
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      //   this.multiselectRef.current.state.selectedValues.map((data, index) =>
      //   this.state.cpt.push(data.CPTCode)
      // );
      let dosdate = moment(this.state.datevalue).format("MM/DD/YYYY");
      let dobdate = moment(this.state.patientdob).format("MM/DD/YYYY");
      let doidate = moment(this.state.doi).format("MM/DD/YYYY");
      const claimID = this.state.locationname + this.state.accountno + dosdate;
      const adddata = {
        ClaimID: this.state.locationname + this.state.accountno + dosdate,
        CPTCode: this.state.cpt,
        submittedAmount: this.state.subAmt,
        Location: this.state.locationname,
        patientAccountNumber: this.state.accountno,
        DateOfService: dosdate,
        PatientFirstName: this.state.pfirstname,
        PatientLastName: this.state.plastname,
        Gender: this.state.gendername,
        PlanCode: this.state.plancode,
        TaxID: this.state.taxid,
        ServiceProvider: this.state.addprovidercode,
        Npi: this.state.npilist,
        PayerID: this.state.payerid,
        clientId: parseInt(this.state.clientid),
        ServiceOrganizationName: this.state.oragadata,
        BillingOrganizationName: this.state.oragadata,
        Claimunder: this.state.firstname + " " + this.state.lastname,
        PatientDOB: dobdate,
        InsuranceName: this.state.insurancename,
        PrimaryPolicyNo: this.state.policyno,
        DOI: doidate,
        type: this.state.typename,
        Module: "RejectionandDenialclaims",
        // ClaimStatus:this.state.status,
        // type:this.state.Type,
        // InsuranceName:this.state.insurancename,
        // Age:parseInt(this.state.Age),
        // Physician:this.state.phyname,
        // PrimaryPolicyNo:this.state.policyno,
        // PatientDOB:this.state.patientdob,
      };
       await axios
        .post(`${ip}/claim/denialandrejectionclaim`, adddata, {
          headers: headers,
        })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ addModal: !this.state.addModal });
            toast.success("Rejection Claim Added Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
          this.getTabledata();
          this.resetCpt();
          // Navigate to /action-viewclaim with timer value
          this.props.history.push({
            pathname: '/action-view-claim',
            state: { 
            timerValue: this.state.timer, 
            claimID: claimID,
            modulename: "Rejection"
             } // pass the timer value
          });
        })
        .catch((err) => {
          if (err.res) {
            if (err.res.status === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.res.status === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.res.status === 400) {
              toast.error("Claim already exists", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };
  addClaimsValidate = () => {
    let hasErr = "true";
    let LocationErr = "";
    let accountnoErr = "";
    let dosErr = "";
    let pfirstnameErr = "";
    let plastnameErr = "";
    let genderErr = "";
    let plancodeErr = "";
    let payeridErr = "";
    let providercodeErr = "";
    let providernamelistErr = "";
    let orgaErr = "";
    let taxidErr = "";
    let policynoErr = "";
    let npilistErr = "";
    let dobErr = "";
    let insurancenameErr = "";
    let doiErr = "";
    if (this.state.locationname === "" || this.state.locationname === null) {
      LocationErr = "This field is required";
      this.setState({ LocationErr });
      hasErr = false;
    }
    if (this.state.accountno === "" || this.state.accountno === null) {
      accountnoErr = "This field is required";
      this.setState({ accountnoErr });
      hasErr = false;
    }
    if (this.state.datevalue === "" || this.state.datevalue === null) {
      dosErr = "This field is required";
      this.setState({ dosErr });
      hasErr = false;
    }
    if (this.state.pfirstname === "" || this.state.pfirstname === null) {
      pfirstnameErr = "This field is required";
      this.setState({ pfirstnameErr });
      hasErr = false;
    }
    if (this.state.plastname === "" || this.state.plastname === null) {
      plastnameErr = "This field is required";
      this.setState({ plastnameErr });
      hasErr = false;
    }
    if (this.state.gendername === "" || this.state.gendername === null) {
      genderErr = "This field is required";
      this.setState({ genderErr });
      hasErr = false;
    }
    if (this.state.plancode === "" || this.state.plancode === null) {
      plancodeErr = "This field is required";
      this.setState({ plancodeErr });
      hasErr = false;
    }
    if (this.state.payerid === "" || this.state.payerid === null) {
      payeridErr = "This field is required";
      this.setState({ payeridErr });
      hasErr = false;
    }
    if (
      this.state.addprovidercode === "" ||
      this.state.addprovidercode === null
    ) {
      providercodeErr = "This field is required";
      this.setState({ providercodeErr });
      hasErr = false;
    }
    if (this.state.oragadata === "" || this.state.oragadata === null) {
      orgaErr = "This field is required";
      this.setState({ orgaErr });
      hasErr = false;
    }
    if (this.state.taxid === "" || this.state.taxid === null) {
      taxidErr = "This field is required";
      this.setState({ taxidErr });
      hasErr = false;
    }
    if (this.state.policyno === "" || this.state.policyno === null) {
      policynoErr = "This field is required";
      this.setState({ policynoErr });
      hasErr = false;
    }
    if (
      this.state.providernamelist === "" ||
      this.state.providernamelist === null
    ) {
      providernamelistErr = "This field is required";
      this.setState({ providernamelistErr });
      hasErr = false;
    }
    if (this.state.npilist === "" || this.state.npilist === null) {
      npilistErr = "This field is required";
      this.setState({ npilistErr });
      hasErr = false;
    }
    if (this.state.patientdob === "" || this.state.patientdob === null) {
      dobErr = "This field is required";
      this.setState({ dobErr });
      hasErr = false;
    }
    if (this.state.insurancename === "" || this.state.insurancename === null) {
      insurancenameErr = "This field is required";
      this.setState({ insurancenameErr });
      hasErr = false;
    }
    if (this.state.doi === "" || this.state.doi === null) {
      doiErr = "This field is required";
      this.setState({ doiErr });
      hasErr = false;
    }
    return hasErr;
  };
  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  reset = (e) => {
    e.preventDefault();
    this.setState(
      {
        perPage: 10,
      },
      () => this.getTabledata()
    );
  };
  formatTimer = (seconds) => {
    const hh = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const mm = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const ss = String(seconds % 60).padStart(2, "0");
    return `${hh}:${mm}:${ss}`;
  };

  startTimer = () => {
    this.setState({ timer: 0 });
    this.timerInterval = setInterval(() => {
      this.setState((prevState) => ({ timer: prevState.timer + 1 }));
    }, 1000);
  };

  stopTimer = () => {
    clearInterval(this.timerInterval);
  };

  // Make sure to clear interval when component unmounts
  componentWillUnmount() {
    this.stopTimer();
  }

  componentDidMount() {    
    this.getLocation();
    this.getPlancode();
    this.getPayerid();
    this.getInsuranceName();
    this.getTypeName();
    this.getOrganisationName();
    this.getNPI();
    this.getClaimStatus();
    this.getProviderCode();
    this.getProvidername();
    this.getCpt();
    this.getTabledata();
    // this.getcss();
    this.getTablereport();
  }
  sorting = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.tablevalues].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ tablevalues: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.tablevalues].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ tablevalues: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };
  render() {
    return (
      <div>
      <Newsidebar name="Rejection & denials" />
      <div className="align-row" style={{overflow:"hidden", paddingTop:"10px"}}>
        <div className={this.state.css_name}>
          <ToastContainer></ToastContainer>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="select_drop">
              <select
                style={{ marginLeft: "30px", height: "35px" }}
                value={this.state.perPage}
                onChange={(e) =>
                  this.setState(
                    {
                      perPage: e.target.value,
                      currentpage: 0,
                    },
                    () => {
                      this.getTabledata();
                    }
                  )
                }
              >
                <option value="select" hidden selected>
                  Select page count
                </option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
              </select>
            </div>
            <div>
              <Button
                style={{
                  border: "none",
                  color: "white",
                  background: "white",
                  fontWeight: "bold",
                }}
                onClick={(e) => {
                  this.reset(e);
                }}
              >
                <IoIosRefresh
                  style={{
                    color: "blue",
                    fontSize: "23px",
                  }}
                ></IoIosRefresh>
              </Button>
            </div>
          </div>
        
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              type="button"
              className="btn"
              style={{
                backgroundColor: "#041c3c",
                color: "#fff",
                width: "fit-content",
                fontSize: "15px",
                fontWeight: "bold",
                marginRight: "10px",
              }}
              onClick={this.toggleaction}
            >
              Add Claims
            </button>
            {this.state.loading ? (
              <div>
                <button
                  style={{
                    background: "black",
                    backgroundColor: "#7DD657",
                    border: "1px solid green",
                    display: "inline-block",
                    color: "#FFFFFF",
                    fontSize: "15px",
                    fontWeight: "bold",
                    padding: "6px 24px",
                    textDecoration: "none",
                    cursor: "none",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Loading data...
                </button>
              </div>
            ) : (
              <CsvDownload
                data={this.state.any}
                filename="Rejection.csv"
                style={{
                  background: "#041c3c",
                  backgroundColor: "#7DD657",
                  border: "1px solid black",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "#FFFFFF",
                  fontSize: "15px",
                  fontWeight: "bold",
                  padding: "7px 7px",
                  textDecoration: "none",
                }}
              >
                Download JSON File
              </CsvDownload>
            )}
          </div>
        </div>
          {this.state.fetching ? (
            <div>
              <p style={{ fontSize: "23px", marginTop: "10px", color: "#000" }}>
                <span>Loading...</span>
                <span>Please Wait...</span>
              </p>
              <div class="boxes body__loader dribbble">
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          ) : this.state.tablevalues.length === 0 ? (
            /* <img src={nodata} alt="" className="nodata" /> */
            <div class="bot-ui">
              <div class="bot-head">
                <div class="eyes-container">
                  <div class="to-left eye"></div>
                  <div class="to-right eye"></div>
                </div>
              </div>
              <div class="bot-body">
                <div class="analyzer"></div>
              </div>
              <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                No Data Found
              </p>
            </div>
          ) : (
            <div id="actions">   
                      <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          forcePage={this.state.currentpage}
                        />
                      </div>
                        <Table
                        className="StandardTable" bordered responsive
                        >
                          <thead>
                            <tr>
                              <td>Action</td>
                              <td
                                onClick={() => this.sorting("ClaimID")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Claim ID
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </td>
                              <td
                                onClick={() => this.sorting("Location")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Location
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </td>
                              <td
                                onClick={() =>
                                  this.sorting("patientAccountNumber")
                                }
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Account No
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </td>
                              <td
                                onClick={() => this.sorting("DateOfService")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                DOS
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </td>
                              <td
                                onClick={() => this.sorting("PatientFirstName")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Patient Name
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </td>
                              <td
                                onClick={() => this.sorting("PatientDOB")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Patient DOB
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </td>
                              <td
                                onClick={() => this.sorting("PlanCode")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                PlanCode
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </td>
                              <td
                                onClick={() => this.sorting("Pripayer")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                InsuranceName
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </td>
                              <td
                                onClick={() => this.sorting("Pripayerphone")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Insurance No
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </td>
                              <td
                                onClick={() => this.sorting("PrimaryPolicyNo")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Policy No
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </td>
                              <td
                                onClick={() => this.sorting("Physician")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Physician Name
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </td>
                              <td
                                onClick={() => this.sorting("Age")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Age
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </td>
                              <td
                                onClick={() => this.sorting("type")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Type
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </td>
                              <td
                                onClick={() => this.sorting("Claimunder")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Allocated To
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </td>
                              <td
                                onClick={() => this.sorting("ClaimStatus")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Claim Status
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.tablevalues &&
                              this.state.tablevalues.map((data, index) => {
                                return (
                                  <tr>
                                    <td
                                      onClick={() => (
                                        (
                                          <ActionViewClaim
                                            value={
                                              sessionStorage.setItem(
                                                "Module",
                                                "Rejection"
                                              ) ||
                                              sessionStorage.setItem(
                                                "ClaimID",
                                                data.ClaimID
                                              ) ||
                                              sessionStorage.setItem(
                                                "ChartID",
                                                data.ChartID
                                              ) ||
                                              sessionStorage.setItem(
                                                "MRN",
                                                data.MRN
                                              ) ||
                                              sessionStorage.setItem(
                                                "Patient",
                                                data.Patient
                                              ) ||
                                              sessionStorage.setItem(
                                                "PatientDOB",
                                                data.PatientDOB
                                              ) ||
                                              sessionStorage.setItem(
                                                "Physician",
                                                data.Physician
                                              ) ||
                                              sessionStorage.setItem(
                                                "ClaimDate",
                                                data.ClaimDate
                                              ) ||
                                              sessionStorage.setItem(
                                                "Pripayer",
                                                data.Pripayer
                                              ) ||
                                              sessionStorage.setItem(
                                                "DateOfService",
                                                data.DateOfService
                                              ) ||
                                              sessionStorage.setItem(
                                                "ClaimDate",
                                                data.ClaimDate
                                              ) ||
                                              sessionStorage.setItem(
                                                "ClaimDBID",
                                                data._id
                                              ) ||
                                              sessionStorage.setItem(
                                                "AllocatedTo",
                                                data.Claimunder
                                              )
                                            }
                                          />
                                        ),
                                        (
                                          <ActionSimpleMenu
                                            value={
                                              sessionStorage.setItem(
                                                "ClaimID",
                                                data.ClaimID
                                              ) ||
                                              sessionStorage.setItem(
                                                "PatientFirstName",
                                                data.PatientFirstName
                                              ) ||
                                              sessionStorage.setItem(
                                                "PatientLastName",
                                                data.PatientLastName
                                              )
                                            }
                                          ></ActionSimpleMenu>
                                        )
                                      )}
                                    >
                                      {" "}
                                      <ActionSimpleMenu></ActionSimpleMenu>
                                    </td>
                                    <td>
                                      {data.ClaimID}
                                    </td>
                                    <td>
                                      {data.Location}
                                    </td>
                                    <td>
                                      {data.patientAccountNumber}
                                    </td>
                                    <td>
                                      {data.DateOfService
                                        ? data.DateOfService
                                        : "-"}
                                    </td>

                                    <td>
                                      {data.PatientFirstName}&nbsp;
                                      {data.PatientLastName}
                                    </td>

                                    <td>
                                      {data.PatientDOB ? moment(data.PatientDOB).format("MM/DD/YYYY") : "-"}
                                    </td>
                                    <td>
                                      {data.PlanCode ? data.PlanCode : "-"}
                                    </td>
                                    <td>
                                      {data.InsuranceName}
                                    </td>
                                    <td>
                                      <Row>
                                        <Col md="4">
                                          <Twilio
                                            value11={data.Pripayerphone}
                                          />
                                        </Col>
                                        <Col md="2">{data.Pripayerphone}</Col>
                                      </Row>
                                    </td>
                                    <td>
                                      {data.PrimaryPolicyNo}
                                    </td>
                                    <td>
                                      {data.Physician}
                                    </td>
                                    <td>
                                      {data.Age}
                                    </td>
                                    <td>
                                      {data.type}
                                    </td>
                                    <td>
                                      {data.Claimunder}
                                    </td>
                                    <td>
                                      {data.ClaimStatus}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.currentpage}
                      />
                    </div>
            </div>
          )}
          <div>
            <Modal
              visible={this.state.addModal}
              onCancel={() => this.setState({ addModal: !this.state.addModal })}
              onExit={this.reset}
              animation={false}
              centered
              width={1300}
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <span style={{ flex: 1, textAlign: "left" }}>
                    Add Claims
                  </span>
                  <span style={{ flex: 1, textAlign: "center" }}>
                    {this.formatTimer(this.state.timer)}
                  </span>
                </div>
              }
              footer={[
                <Row style={{ justifyContent: "center" }}>
                  <Col md="10" style={{ paddingLeft: "60%" }}>
                    <button
                      type="button"
                      class="btn"
                      style={{ backgroundColor: "#7DD657", color: "#fff" }}
                      onClick={this.togglecpt}
                    >
                      Add CPT
                    </button>
                    &nbsp; &nbsp;
                    <Button
                      style={{ background: "#07326c" }}
                      onClick={this.toggleaction}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>,
              ]}
            >
              <Row>
                <Col>
                  <Row style={{ paddingTop: "2%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left" }}
                      >
                        Claim Id
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="text"
                        value={
                          this.state.locationname +
                          this.state.accountno +
                          (this.state.datevalue
                            ? moment(this.state.datevalue).format("MM/DD/YYYY")
                            : "")
                        }
                      />
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left" }}
                      >
                        Location
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="select"
                        className="form-control form-select"
                        value={this.state.locationname}
                        onChange={(e) =>
                          this.setState(
                            {
                              locationname: e.target.value,
                              oragadata: "",
                              taxid: "",
                              LocationErr: "",
                            },
                            () => {
                              this.getOrganisationName();
                              this.getProviderCode();
                            }
                          )
                        }
                      >
                        <option value="" hidden>
                          Select Location...
                        </option>
                        {this.state.locationlist.map((data, index) => (
                          <option
                            value={data.Location}
                            selected={this.state.locationname === data.Location}
                          >
                            {data.Location}
                          </option>
                        ))}
                      </Input>
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.LocationErr}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left", whiteSpace: "nowrap" }}
                      >
                        Service Organization
                        <br />
                        &nbsp;&nbsp; Name
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.oragadata}
                        onChange={(e) =>
                          this.setState({
                            oragadata: e.target.value,
                            orgaErr: "",
                          })
                        }
                      ></Input>
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.orgaErr}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left", whiteSpace: "nowrap" }}
                      >
                        Billing Organization <br />
                        &nbsp;&nbsp; Name
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.oragadata}
                        onChange={(e) =>
                          this.setState({
                            oragadata: e.target.value,
                            orgaErr: "",
                          })
                        }
                      ></Input>
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.orgaErr}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left" }}
                      >
                        Tax ID
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.taxid}
                        onChange={(e) =>
                          this.setState({
                            addtaxid: e.target.value,
                            taxidErr: "",
                          })
                        }
                      ></Input>
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.taxidErr}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left", whiteSpace: "nowrap" }}
                      >
                        Provider Code
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="select"
                        className="form-control form-select"
                        value={this.state.addprovidercode}
                        onClick={this.getNPI}
                        onChange={(e) =>
                          this.setState(
                            {
                              addprovidercode: e.target.value,
                              providername: "",
                              providercodeErr: "",
                            },
                            () => {
                              this.getProvidername();
                              this.getNPI();
                            }
                          )
                        }
                      >
                        <option value="" hidden>
                          Select Provider Code...
                        </option>
                        {this.state.providercodelist.map((data, index) => (
                          <option
                            value={data.ProviderCode}
                            selected={
                              this.state.addprovidercode === data.ProviderCode
                            }
                          >
                            {data.ProviderCode}
                          </option>
                        ))}
                      </Input>
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.providercodeErr}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left", whiteSpace: "nowrap" }}
                      >
                        Rendering Provider <br />
                        &nbsp;&nbsp; NPI
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.npilist}
                        onChange={(e) =>
                          this.setState({
                            npilist: e.target.value,
                            npilistErr: "",
                          })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.npilistErr}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left", whiteSpace: "nowrap" }}
                      >
                        Rendering provider <br />
                        &nbsp;&nbsp; Name
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.providernamelist}
                        onClick={this.getNPI}
                        onChange={(e) =>
                          this.setState(
                            {
                              providernamelist: e.target.value,
                              npilist: "",
                              providernamelistErr: "",
                            },
                            () => {
                              this.getNPI();
                            }
                          )
                        }
                      />
                    </Col>
                    <div style={{ fontSize: 16, color: "red" }}>
                      {this.state.providernamelistErr}
                    </div>
                  </Row>
                </Col>
                <Col>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left" }}
                      >
                        Plan Code
                      </h5>
                    </Col>
                    <Col md="6">
                    {this.state.plancode !== "" ? (
                      <Input
                        type="text"
                        className="form-control"
                        defaultValue={this.state.plancode}
                        value={this.state.plancode}
                        onChange={(e) =>
                          this.setState({
                            plancode: e.target.value,
                            plancodeErr: "",
                          },() => {
                            this.getPayerid();
                            this.getTypeName();
                          }) 
                        }
                      ></Input>
                    ) : (
                        <Typeahead
                          ref={this.typeaheadRef}
                          options={this.state.plancodelist}
                          defaultInputValue={this.state.plancode}
                          labelKey={(option) => `${option.PayerPlanCode}` || ""}
                          placeholder="Select..."
                          onChange={(selected) => {
                            if (selected && selected.length > 0) {
                              let selected1 = selected[0].PayerPlanCode
                                ? selected[0].PayerPlanCode
                                : "";
                              this.setState(
                                {
                                  plancode: selected1,
                                  payerid: "",
                                  typename: "",
                                  plancodeErr: "",
                                },
                                () => {
                                  this.getPayerid();
                                  this.getInsuranceName();
                                  this.getTypeName();
                                }
                              );
                            } else {
                              this.setState({
                                plancode: "",
                                payerid: "",
                                insurancename:"",
                                typename: "",
                              });
                            }
                          }}
                        /> )}
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.plancodeErr}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left", whiteSpace: "nowrap" }}
                      >
                        Insurance Name
                      </h5>
                    </Col>
                    <Col md="6">
                    {this.state.insurancename !== "" ? (
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.insurancename}
                        onChange={(e) =>
                          this.setState({
                            insurancename: e.target.value,
                            insurancenameErr: "",
                          },
                          () => {
                            this.getPayerid();
                            this.getTypeName();
                          })
                        }
                      ></Input>
                    ) : (
                      <Typeahead
                        ref={this.typeaheadRef}
                        options={this.state.plancodelist}
                        defaultInputValue={this.state.insurancename}
                        labelKey={(option) => `${option.payerName}` || ""}
                        placeholder="Select..."
                        onChange={(selected) => {
                          if (selected && selected.length > 0) {
                            let selected1 = selected[0].payerName
                              ? selected[0].payerName
                              : "";
                            this.setState(
                              {
                                insurancename: selected1,
                                payerid: "",
                                typename: "",
                                insurancenameErr: "",
                              },
                              () => {
                                this.getPayerName1();
                              }
                            );
                          } else {
                            this.setState({
                              insurancename: "",
                            });
                          }
                        }}
                      />)}
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.insurancenameErr}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left" }}
                      >
                        Payer ID
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.payerid}
                        onChange={(e) =>
                          this.setState({
                            payerid: e.target.value,
                            payeridErr: "",
                          })
                        }
                      ></Input>
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.payeridErr}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left" }}
                      >
                        Type
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.typename}
                        onChange={(e) =>
                          this.setState({
                            typename: e.target.value,
                            typeErr: "",
                          })
                        }
                      ></Input>
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.typeErr}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left" }}
                      >
                        DOS
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="date"
                        value={this.state.datevalue}
                        onChange={(e) => {
                          this.setState({
                            datevalue: e.target.value,

                            dosErr: "",
                          });
                        }}
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.dosErr}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left" }}
                      >
                        DOI
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="date"
                        value={this.state.doi}
                        onChange={(e) => {
                          this.setState({ doi: e.target.value, doiErr: "" });
                        }}
                      ></Input>
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.doiErr}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left", whiteSpace: "nowrap" }}
                      >
                        Account Number
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="text"
                        value={this.state.accountno}
                        onChange={(e) =>
                          this.setState({
                            accountno: e.target.value,
                            accountnoErr: "",
                          })
                        }
                      ></Input>
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.accountnoErr}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left" }}
                      >
                        First Name
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="text"
                        value={this.state.pfirstname}
                        onChange={(e) =>
                          this.setState({
                            pfirstname: e.target.value,
                            pfirstnameErr: "",
                          })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.pfirstnameErr}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left" }}
                      >
                        Last Name
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="text"
                        value={this.state.plastname}
                        onChange={(e) =>
                          this.setState({
                            plastname: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left" }}
                      >
                        Gender
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        className="form-control form-select"
                        type="select"
                        value={this.state.gendername}
                        onChange={(e) => {
                          this.setState({
                            gendername: e.target.value,
                            genderErr: "",
                          });
                        }}
                      >
                        <option value="" hidden id="select-placeholder">
                          Select Gender
                        </option>
                        <option value="F">Female</option>
                        <option value="M">Male</option>
                      </Input>
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.genderErr}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left", whiteSpace: "nowrap" }}
                      >
                        Patient DOB
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="date"
                        value={this.state.patientdob}
                        onChange={(e) => {
                          const d = new Date();
                          this.setState({
                            patientdob: e.target.value,
                            dobErr: "",
                          });
                        }}
                      ></Input>
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.dobErr}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                    <Col md="6">
                      <h5
                        className="label required1"
                        style={{ textAlign: "left" }}
                      >
                        Policy No
                      </h5>
                    </Col>
                    <Col md="6">
                      <Input
                        type="text"
                        className="form-control"
                        value={this.state.policyno}
                        onChange={(e) =>
                          this.setState({
                            policyno: e.target.value,
                            policynoErr: "",
                          })
                        }
                      ></Input>
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.policynoErr}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <br />
            </Modal>
          </div>
          <Modal
            visible={this.state.cptmodal}
            onCancel={() =>
              this.setState({ cptmodal: !this.state.cptmodal }, () =>
                this.resetCpt()
              )
            }
            onExit={this.resetCpt}
            animation={false}
            centered
            width="70%"
            contentClassName="modal-open"
            title="Add CPT"
            footer={null}
          >
            <form onSubmit={this.handleSubmit}>
              {this.state.formValues.map((element, index) => (
                <div className="form-inline" key={index}>
                  <label>CPT</label>
                  <input
                    required
                    type="text"
                    name="cpt"
                    value={element.cpt || ""}
                    onChange={(e) => this.handleChange(index, e)}
                  />
                  <label>Submitted Amount</label>
                  <input
                    required
                    type="text"
                    name="submitted_amount"
                    value={element.submitted_amount || ""}
                    onChange={(e) => this.handleChange(index, e)}
                  />
                  {index ? (
                    <button
                      type="button"
                      className="button remove"
                      onClick={() => this.removeFormFields(index)}
                    >
                      Remove
                    </button>
                  ) : null}
                </div>
              ))}
              <div className="button-section">
                <button
                  className="button add"
                  type="button"
                  onClick={() => this.addFormFields()}
                >
                  Add
                </button>
                <button className="button submit" type="submit">
                  Submit & Save Claim
                </button>
              </div>
            </form>
          </Modal>
        </div>
      </div>
      </div>
    );
  }
}
