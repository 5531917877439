import React, { Component } from "react";
import axios from "axios";
import { ip } from "../../Api";
import ReactPaginate from "react-paginate";
import {
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import { Table } from "reactstrap";
import moment from "moment-timezone";

export class UploadedClaims extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingclaims: true,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      uploadedclaimsarray: [],
      allclaims: [],
      notesdata: [],
      currentpage1: 0,
      perPage1: 10,
      loading: true,
    };
  }
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage1;

    this.setState(
      {
        currentpage1: selectedPage,
        offset1: offset,
      },
      () => {
        this.getuploadedreports();
      }
    );
  };

  getdownloadreports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/uploaded/claims?clientId=${this.state.client}&startdate=${this.props.startdate}&enddate=${this.props.enddate}`;
    } else {
      url = `${ip}/helper/uploaded/claims?clientId=${this.state.client}`;
    }
    const res = axios;

    fetch(url, { headers: headers }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", "Uploaded Claims");
        a.click();
        URL.revokeObjectURL(a);
      });
    });
  };

  getuploadedreports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/uploaded/claims?clientId=${this.state.client}&startdate=${this.props.startdate}&enddate=${this.props.enddate}&pageno=${this.state.currentpage1}`;
    } else {
      url = `${ip}/helper/uploaded/claims?clientId=${this.state.client}&pageno=${this.state.currentpage1}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pageCount1: Math.ceil(res.data.count / this.state.perPage1),
          uploadedclaimsarray: res.data.data,
          fetchingclaims: false,
        });
      });
  };

  componentDidMount() {
    

    this.getuploadedreports();
  }

  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.startdate === prevProps.startdate &&
        this.props.enddate === prevProps.enddate
      )
    ) {
      this.getuploadedreports();
    }
  }

  render() {
    return this.state.fetchingclaims ? (
      <div>
        <p
          style={{
            fontSize: "23px",
            marginTop: "10px",
            color: "#000",
          }}
        >
          <span>Loading...</span>
          <span>Please Wait...</span>
        </p>
        <div class="boxes body__loader dribbble">
          <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    ) : this.state.uploadedclaimsarray.length === 0 ? (
      <div class="bot-ui">
        <div class="bot-head">
          <div class="eyes-container">
            <div class="to-left eye"></div>
            <div class="to-right eye"></div>
          </div>
        </div>
        <div class="bot-body">
          <div class="analyzer"></div>
        </div>
        <p style={{ fontSize: "20px", fontStyle: "italic" }}>No Data Found</p>
      </div>
    ) : (
      <div>
        <div>
          <button
            style={{
              boxShadow: "inset 0px 1px 0px 0px #E184F3",
              background: "black",
              backgroundColor: "black",
              borderRadius: "6px",
              border: "1px solid black",
              display: "inline-block",
              cursor: "pointer",
              color: "#FFFFFF",
              fontSize: "13px",
              fontWeight: "bold",
              padding: "3px 18px",
              textDecoration: "none",
              textShadow: "0px 1px 0px #9B14B3",
              float: "right",
            }}
            onClick={this.getdownloadreports}
          >
            Generate Report
          </button>
        </div>
        <br />
        <div style={{ width: "100%" }}>
          <Table
            className="StandardTable"
            bordered
            style={{
              marginLeft: "3%",
              width: "99%",
            }}
          >
            <thead>
              <tr align="center">
                <th>Client ID</th>
                <th>File name</th>
                <th>Inserted</th>
                <th>Not Inserted</th>
              </tr>
            </thead>
            <tbody>
              {this.state.uploadedclaimsarray &&
                this.state.uploadedclaimsarray.map((data, index) => {
                  return (
                    <tr>
                      <td>{data.clientId}</td>
                      <td>{data.filename}</td>
                      <td>{data.inserted}</td>
                      <td>{data.notinserted}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <br />
        <div>
          <ReactPaginate
            previousLabel={<IoIosArrowBack />}
            nextLabel={<IoIosArrowForward />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick1}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    );
  }
}

export default UploadedClaims;
