import { Component } from "react";
import axios from "axios";
import {
  RadialBarChart,
  RadialBar,
  Cell,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Legend,
  Tooltip,
  ComposedChart,
} from "recharts";
import { ip } from "../../../Api";
const style = {
  top: "50%",
  marginLeft: "38%",
  transform: "translate(0, -50%)",
  lineHeight: "24px",
};
// const data = [
//   { name: "Commercial", Totalamount: "1000", Totalclaim:"20",fill:"#00bfff" },
//   { name: "Medicare", Totalamount: "300", Totalclaim:"30",fill:"#ff0080" },
//   { name: "Medicaid", Totalamount: "300", Totalclaim:"25",fill:"#8c7380" },
//   { name: "Tricare", Totalamount: "200", Totalclaim:"15",fill:"#992600"},
//   { name: "BlueCross", Totalamount: "200", Totalclaim:"17",fill:"#008080" },
// ];
const COLORS = ["#00bfff", "#ff0080", "#8c7380", "#992600", "#008080"];

const data = [
  {
    name: "",
    totalamount: 0,
    totalcount: 0,
  },
  {
    name: "",
    totalamount: 0,
    totalcount: 0,
  },
  {
    name: "",
    totalamount: 0,
    totalcount: 0,
  },

  {
    name: "",
    totalamount: 0,
    totalcount: 0,
  },
  {
    name: "",
    totalamount: 0,
    totalcount: 0,
  },
];
let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};
const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};
class ARdepartChart2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payercategorywise: [],
      payercategorywisecount: [],
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
    };
  }

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getinventory = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.classStart !== "" && this.props.classEnd !== "") {
          url = `${ip}/inventory/payercategorywise?start_date=${this.props.classStart}&end_date=${this.props.classEnd}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/inventory/payercategorywise?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.classStart !== "" && this.props.classEnd !== "") {
          url = `${ip}/inventory/payercategorywise?start_date=${this.props.classStart}&end_date=${this.props.classEnd}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/inventory/payercategorywise?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.classStart !== "" && this.props.classEnd !== "") {
        url = `${ip}/inventory/payercategorywise?start_date=${this.props.classStart}&end_date=${this.props.classEnd}`;
      } else {
        url = `${ip}/inventory/payercategorywise`;
      }
    }

    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        if (req.data.inventory.length !== 0) {
          this.setState({ payercategorywise: req.data.inventory });

          this.setState({ payercategorywisecount: req.data.inventory });
        } else {
          this.setState({
            payercategorywise: data,
            payercategorywisecount: data,
          });
        }
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
    this.getinventory();
  }
  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.classStart === prevProps.classStart &&
        this.props.classEnd === prevProps.classEnd
      )
    ) {
      this.getinventory();
    } else if (this.props.client !== prevProps.client) {
      this.getinventory();
    } else if (this.props.Location !== prevProps.Location) {
      this.getinventory();
    }
  }

  render() {
    return (
      <div>
        {this.props.payercategorywise === "$" && (
          <div onMouseOver={() => (tooltip = "totalamount")}>
            <ResponsiveContainer width={"100%"} height={400}>
              <ComposedChart
                width={450}
                height={350}
                data={this.state.payercategorywise}
                stopColor="red"
                margin={{
                  top: 13,
                  right: 30,
                  left: 10,
                  bottom: 5,
                }}
              >
                <XAxis dataKey={"_id"} height={120} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}} />
                <YAxis dataKey={"totalamount"} fontSize="10" />
                <Tooltip content={<CustomTooltip />} />
                <defs>
                  <linearGradient
                    id="colorYv"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="100%"
                    spreadMethod="reflect"
                  >
                    <stop offset="0" stopColor="#0033cc" />
                    <stop offset="1" stopColor="#33ccff" />
                  </linearGradient>
                </defs>
                <Bar
                  name="Total Amount"
                  dataKey="totalamount"
                  barSize={15}
                  fill="#f5b942"
                  radius={[30, 30, 10, 10]}
                >
                  {/* <LabelList dataKey="totalamt" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
                </Bar>
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        )}
        {this.props.payercategorywise === "#" && (
          <div onMouseOver={() => (tooltip = "totalcount")}>
            <ResponsiveContainer width={"100%"} height={400}>
              <ComposedChart
                width={450}
                height={350}
                data={this.state.payercategorywisecount}
                stopColor="red"
                margin={{
                  top: 13,
                  right: 30,
                  left: 10,
                  bottom: 5,
                }}
              >
                <XAxis dataKey={"_id"} height={120} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}} />
                <YAxis dataKey={"totalcount"} fontSize="10" />
                <Tooltip content={<ClaimTooltip />} />
                <defs>
                  <linearGradient
                    id="colorYv"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="100%"
                    spreadMethod="reflect"
                  >
                    <stop offset="0" stopColor="#0033cc" />
                    <stop offset="1" stopColor="#33ccff" />
                  </linearGradient>
                </defs>
                <Bar
                  name="Total Claims"
                  dataKey="totalcount"
                  barSize={15}
                  fill="#f5b942"
                  radius={[30, 30, 10, 10]}
                >
                  {/* <LabelList dataKey="totalclaims" type="number" position='top' fill='#7a051b' fontWeight='60' fontFamily='Poppins' /> */}
                </Bar>
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    );
  }
}
export default ARdepartChart2;
