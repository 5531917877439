import React, { Component } from "react";
import "../../Styles/configure.css";
import Newsidebar from "../Dashboard/Newsidebar";
import "../../Styles/CollectionPredictionchanged.css";
import { Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { DatePicker } from "antd";
import {
  ComposedChart,
  LineChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  BarChart,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Treemap,
} from "recharts";
const { RangePicker } = DatePicker;
//Values for 3rd grid cell
const dataTree = [
  {
    name: "BCBS",
    children: [{ name: "BCBS-$27188", size: 29170 }],
  },
  {
    name: "MEDICAID",
    children: [{ name: "Medicaid-$21,327", size: 21327 }],
  },
  {
    name: "MEDICAID",
    children: [
      { name: "Medicare-$12,65", size: 12675 },
      { name: "UHC-$10,85", size: 18905 },
      { name: "Kaiser-$9,64", size: 9614 },
    ],
  },
  {
    name: "MEDI",
    children: [
      { name: "Commercial-$6,824", size: 6824 },
      { name: "Aetna-$6,599", size: 6599 },
      { name: "Cigna-$5,423", size: 5423 },
      { name: "Humana-$4,559", size: 4559 },
      { name: "VA-$1,595", size: 10089 },
    ],
  },
];
//Values for 5th grid cell
const collect_data1 = [
  {
    name: "2019-10",
    uv: 590,
    pv: 800,
    amt: 1400,
    cnt: 490,
  },
  {
    name: "2019-11",
    uv: 868,
    pv: 967,
    amt: 1506,
    cnt: 590,
  },
  {
    name: "2019-12",
    uv: 1397,
    pv: 1098,
    amt: 989,
    cnt: 350,
  },
  {
    name: "2020-01",
    uv: 1480,
    pv: 1200,
    amt: 1228,
    cnt: 480,
  },
  {
    name: "2020-02",
    uv: 1520,
    pv: 1108,
    amt: 1100,
    cnt: 460,
  },
  {
    name: "2020-03",
    uv: 1400,
    pv: 680,
    amt: 1700,
    cnt: 380,
  },
];
//Values for grid 9 line chart
const Line__data = [
  {
    name: "99285",
    "Product A": 3432,
    "Procuct B": 2342,
  },
  {
    name: "99053",
    "Product A": 2342,
    "Procuct B": 3246,
  },
  {
    name: "99284",
    "Product A": 4565,
    "Procuct B": 4556,
  },
  {
    name: "93010",
    "Product A": 6654,
    "Procuct B": 4465,
  },
  {
    name: "99220",
    "Product A": 8765,
    "Procuct B": 4553,
  },
];
//values for grid 4
const bar__data = [
  {
    name: "coding",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Billing",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Front End",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
];

//Values for grid 10
const data = [
  { name: "99053", value: 400 },
  { name: "93010", value: 300 },
  { name: "99285", value: 300 },
  { name: "99284", value: 200 },
  { name: "99220", value: 200 },
  { name: "27752", value: 300 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class CollectionPredictionDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: "",
      end: "",
      range: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
    };
  }
  render() {
    return (
      <div>
      <Newsidebar name="Collection Prediction Dashboard" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <div className="align-column">
          <div className="scroll__collect">
            <div className="collection-header1">
              <div style={{ color: "white" }}>
                Collection Prediction Dashboard
              </div>
            </div>
            {/* <div className="collection__db__container"> */}
            <div className="collection-db-grid">
              <div className="collection__db__cell1">
                <div className="content__db__pre">
                  <p>$312,345</p>
                  <p>Collection Charges $</p>
                </div>
              </div>
              <div className="collection__db__cell2">
                <div className="content__db__pre">
                  <p>#1897</p>
                  <p>Collection CPT #</p>
                </div>
              </div>
              <div className="collection__db__cell3">
                <div className="content__db__pre">
                  <p>5.5%</p>
                  <p>Collection Rate %</p>
                </div>
              </div>
              <div className="collection__db__cell4">
                <div className="content__db__pre">
                  <p>90%</p>
                  <p>ML Precisiion</p>
                </div>
              </div>
              <div className="collection__db__cell5">
                <div className="content__db__pre">
                  <p>89%</p>
                  <p>ML Recall</p>
                </div>
              </div>
              <div className="collection__db__cell6">
                <div className="content__db__pre">
                  <p>80%</p>
                  <p>ML Accuracy</p>
                </div>
              </div>
            </div>
            {/* </div> */}
            <div className="collection-db-container">
              <div className="collection-db-grid2">
                <div className="db__row1">
                  <RangePicker
                    height="50%"
                    width="200%"
                    style={{ borderColor: "gray" }}
                  />
                </div>
                {/* <div className="db__row2">
                            <Row style={{fontSize:"13px",marginLeft:'40px'}}>Month</Row>
                            <Row style={{fontSize:"13px"}}>Mar</Row>
                            </div> */}
                <div className="db__row3">
                  <Row style={{ fontSize: "13px", marginLeft: "40px" }}>
                    Period By
                  </Row>
                  <Row style={{ fontSize: "13px" }}>Posting Date</Row>
                </div>
                <div className="db__row4">
                  <Row style={{ fontSize: "13px", marginLeft: "40px" }}>
                    Provider
                  </Row>
                  <Row style={{ fontSize: "13px" }}>All</Row>
                </div>
                <div className="db__row5">
                  <Row style={{ fontSize: "13px", marginLeft: "40px" }}>
                    Payer
                  </Row>
                  <Row style={{ fontSize: "13px" }}>All</Row>
                </div>
              </div>
              <div className="collection-db-grid3">
                <Row>
                  <Col>
                    <h3
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        paddingTop: "1%",
                        fontSize: "14px",
                      }}
                    >
                      Collection by Payer{" "}
                    </h3>
                  </Col>
                  <hr style={{ width: "100vw", marginLeft: "-1px" }} />
                </Row>
                <ResponsiveContainer width={"99%"} height={450}>
                  <Treemap
                    className="treemap__collect"
                    data={dataTree}
                    children="name"
                    dataKey="size"
                    text="size"
                    ratio={4 / 3}
                    stroke="#fff"
                    fill="#002244"
                  ></Treemap>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="collection-db-container2">
              <div className="collection-db-grid4">
                <Row>
                  <Col>
                    <h3
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        paddingTop: "1%",
                        fontSize: "14px",
                      }}
                    >
                      Collection by Category{" "}
                    </h3>
                  </Col>
                  <hr style={{ width: "100%", marginLeft: "-1px" }} />
                </Row>
                <ResponsiveContainer width={"99%"} height={400}>
                  <BarChart
                    data={bar__data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 5,
                      bottom: 5,
                    }}
                  >
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis dataKey="name" height={90} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}} />
                    <YAxis />
                    <Tooltip cursor={{ fill: "transparent" }} />
                    <Legend />
                    <Bar
                      dataKey="pv"
                      fill="#002D62"
                      background={{ fill: "#eee" }}
                      name="Amount"
                    />
                    {/* <Bar dataKey="uv" fill="#0066b2" name="Class" /> */}
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="collection-db-grid5">
                <Row>
                  <Col>
                    <h3
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        paddingTop: "1%",
                        fontSize: "14px",
                      }}
                    >
                      Historical Collection Prediction Trend{" "}
                    </h3>
                  </Col>
                  <hr style={{ width: "100%", marginLeft: "-1px" }} />
                </Row>
                <ResponsiveContainer width={"99%"} height={400}>
                  <ComposedChart
                    width={650}
                    height={300}
                    data={collect_data1}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <XAxis dataKey="name" scale="band" height={90} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}}/>
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="uv"
                      barSize={20}
                      fill="#413ea0"
                      name="Collection Charges"
                    />
                    <Line
                      type="monotone"
                      dataKey="uv"
                      stroke="#ff7300"
                      name="Collection Rate"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="collection-header1">
              <div style={{ color: "white" }}>Top Impactors</div>
            </div>
            <div className="collection-db-container3">
              <div className="collection-db-grid6">
                <Table className="table__collect" striped bordered>
                  <thead>
                    <tr>
                      <th>Top Impactors</th>
                      <th>% of Total Collection</th>
                      <th>% of Total Count</th>
                      <th>Collection Charges</th>
                      <th>Count</th>
                      <th>ML Precision</th>
                      <th>ML Recall</th>
                      <th>ML Accuracy</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>99053</td>
                      <td>46.49%</td>
                      <td>51.80%</td>
                      <td style={{ textAlign: "center" }}>$47,717</td>
                      <td style={{ textAlign: "center" }}>591</td>
                      <td style={{ textAlign: "center" }}>91</td>
                      <td style={{ textAlign: "center" }}>87</td>
                      <td style={{ textAlign: "center" }}>79</td>
                    </tr>
                    <tr>
                      <td>93010</td>
                      <td>20.46%</td>
                      <td>42.77%</td>
                      <td style={{ textAlign: "center" }}>$21,004</td>
                      <td style={{ textAlign: "center" }}>488</td>
                      <td style={{ textAlign: "center" }}>96</td>
                      <td style={{ textAlign: "center" }}>93</td>
                      <td style={{ textAlign: "center" }}>86</td>
                    </tr>
                    <tr>
                      <td>99285</td>
                      <td>13.99%</td>
                      <td>1.75%</td>
                      <td style={{ textAlign: "center" }}>$14,360</td>
                      <td style={{ textAlign: "center" }}>20</td>
                      <td style={{ textAlign: "center" }}>92</td>
                      <td style={{ textAlign: "center" }}>83</td>
                      <td style={{ textAlign: "center" }}>79</td>
                    </tr>
                    <tr>
                      <td>99284</td>
                      <td>7.67%</td>
                      <td>1.40%</td>
                      <td style={{ textAlign: "center" }}>$7,877</td>
                      <td style={{ textAlign: "center" }}>16</td>
                      <td style={{ textAlign: "center" }}>93</td>
                      <td style={{ textAlign: "center" }}>89</td>
                      <td style={{ textAlign: "center" }}>82</td>
                    </tr>
                    <tr>
                      <td>99283</td>
                      <td>4.45%</td>
                      <td>1.49%</td>
                      <td style={{ textAlign: "center" }}>$4,572</td>
                      <td style={{ textAlign: "center" }}>17</td>
                      <td style={{ textAlign: "center" }}>94</td>
                      <td style={{ textAlign: "center" }}>89</td>
                      <td style={{ textAlign: "center" }}>85</td>
                    </tr>
                    <tr>
                      <td>76705</td>
                      <td>2.18%</td>
                      <td>0.44%</td>
                      <td style={{ textAlign: "center" }}>$2,235</td>
                      <td style={{ textAlign: "center" }}>5</td>
                      <td style={{ textAlign: "center" }}>95</td>
                      <td style={{ textAlign: "center" }}>92</td>
                      <td style={{ textAlign: "center" }}>89</td>
                    </tr>
                    <tr>
                      <td>27752</td>
                      <td>2.03%</td>
                      <td>0.09%</td>
                      <td style={{ textAlign: "center" }}>$2,086</td>
                      <td style={{ textAlign: "center" }}>1</td>
                      <td style={{ textAlign: "center" }}>92</td>
                      <td style={{ textAlign: "center" }}>83</td>
                      <td style={{ textAlign: "center" }}>80</td>
                    </tr>
                    <tr>
                      <td>99220</td>
                      <td>1.59%</td>
                      <td>0.19%</td>
                      <td style={{ textAlign: "center" }}>$1,632</td>
                      <td style={{ textAlign: "center" }}>2</td>
                      <td style={{ textAlign: "center" }}>93</td>
                      <td style={{ textAlign: "center" }}>88</td>
                      <td style={{ textAlign: "center" }}>85</td>
                    </tr>
                    <tr>
                      <td>99226</td>
                      <td>1.18%</td>
                      <td>0.09%</td>
                      <td style={{ textAlign: "center" }}>$1,164</td>
                      <td style={{ textAlign: "center" }}>1</td>
                      <td style={{ textAlign: "center" }}>92</td>
                      <td style={{ textAlign: "center" }}>90</td>
                      <td style={{ textAlign: "center" }}>83</td>
                    </tr>
                  </tbody>
                </Table>
                {/* <div className="db__cell8">
                                <div className="content__db__pre">
                                    <p>$312,345</p>
                                    <p>Collection Charges $</p>
                                </div>
                            </div>
                            <div className="db__cell9">
                                <div className="content__db__pre">
                                    <p>#1897</p>
                                    <p>Collection CPT #</p>
                                </div>
                            </div>
                            <div className="db__cell10">
                                <div className="content__db__pre">
                                    <p>5.5%</p>
                                    <p>Collection Rate %</p>
                                </div>
                            </div>
                            <div className="db__cell12">
                                <div className="content__db__pre">
                                    <p>90%</p>
                                    <p>ML Precisiion</p>
                                </div>
                            </div>
                            <div className="db__cell13">
                                <div className="content__db__pre">
                                    <p>89%</p>
                                    <p>ML Recall</p>
                                </div>
                            </div>
                            <div className="db__cell14">
                                <div className="content__db__pre">
                                    <p>80%</p>
                                    <p>ML Accuracy</p>
                                </div>
                            </div> */}
              </div>
            </div>
            <div className="collection-db-container4">
              <div className="collection-db-grid7">
                <div className="db__row6">
                  {/* <Row style={{fontSize:"13px",marginLeft:'40px'}}>Year</Row>
                                <Row style={{fontSize:"13px"}}>2020</Row> */}

                  <RangePicker
                    height="50%"
                    width="200%"
                    style={{ borderColor: "gray" }}
                  />
                </div>
                <div className="db__row7">
                  <Row style={{ fontSize: "13px", marginLeft: "40px" }}>
                    Month
                  </Row>
                  <Row style={{ fontSize: "13px" }}>Mar</Row>
                </div>
                <div className="db__row8">
                  <Row style={{ fontSize: "13px", marginLeft: "40px" }}>
                    Period By
                  </Row>
                  <Row style={{ fontSize: "13px" }}>Posting Date</Row>
                </div>
                <div className="db__row9">
                  <Row style={{ fontSize: "13px", marginLeft: "40px" }}>
                    Provider
                  </Row>
                  <Row style={{ fontSize: "13px" }}>All</Row>
                </div>
                <div className="db__row10">
                  <Row style={{ fontSize: "13px", marginLeft: "40px" }}>
                    Payer
                  </Row>
                  <Row style={{ fontSize: "13px" }}>All</Row>
                </div>
              </div>
              <div className="collection-db-grid8">
                <Row>
                  <Col>
                    <h3
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        paddingTop: "1%",
                        fontSize: "14px",
                      }}
                    >
                      Top Impactors-Collection Charges %{" "}
                    </h3>
                  </Col>
                  <hr style={{ width: "100%", marginLeft: "-1px" }} />
                </Row>
                <ResponsiveContainer width={"99%"} height={400}>
                  <LineChart data={Line__data}>
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis dataKey="name" height={90} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}}/>
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="Product A"
                      stroke="#0095FF"
                      name="Collection"
                    />
                    <Line
                      type="monotone"
                      dataKey="Procuct B"
                      stroke="#FF0000"
                      name="%"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="collection-db-grid9">
                <Row>
                  <Col>
                    <h3
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        paddingTop: "1%",
                        fontSize: "14px",
                      }}
                    >
                      Top Impactors-Collection Count %{" "}
                    </h3>
                  </Col>
                  <hr style={{ width: "100%", marginLeft: "-1px" }} />
                </Row>
                <ResponsiveContainer width={"99%"} height={400}>
                  <PieChart>
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default CollectionPredictionDashboard;
