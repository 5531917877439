import { PureComponent } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { ip } from "../../../Api";
let tooltip;
const getPath = (x, y, width, height) => `M${x},${y + height}
  C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
  x + width / 2
}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
  x + width
}, ${y + height}
  Z`;
const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};
const data = [
  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },
  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },
  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },

  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },
  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },
];
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};
const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};
class ARbyCPT extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cptwiseamount: [],
      cptwisecount: [],
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
    };
  }

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getinventory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/inventory/cptwise?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/inventory/cptwise?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate !== "" && this.props.enddate !== "") {
          url = `${ip}/inventory/cptwise?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/inventory/cptwise?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate !== "" && this.props.enddate !== "") {
        url = `${ip}/inventory/cptwise?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/inventory/cptwise`;
      }
    }
    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        if (req.data.inventory.length !== 0) {
          this.setState({ cptwiseamount: req.data.inventory });
          this.setState({ cptwisecount: req.data.inventorycount });
        } else {
          this.setState({
            cptwisecount: data,
            cptwiseamount: data,
          });
        }
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
    this.getinventory();
  }

  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.startdate === prevProps.startdate &&
        this.props.enddate === prevProps.enddate
      )
    ) {
      this.getinventory();
    } else if (this.props.client !== prevProps.client) {
      this.getinventory();
    } else if (this.props.Location !== prevProps.Location) {
      this.getinventory();
    }
    //  debugger;

    //  debugger;
  }

  render() {
    return (
      <div>
        {this.props.cptwise === "$" && (
          <div onMouseOver={() => (tooltip = "totalamount")}>
            <ResponsiveContainer width={"99%"} height={400}>
              <BarChart
                width={500}
                height={300}
                data={this.state.cptwiseamount}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis
                  dataKey="_id"
                  height={120}
                  interval={0}
                  angle={"-45"}
                  minTickGap={6}
                  tick={{
                    strokeWidth: 0,
                    fontSize: "11",
                    top: 400,
                    textAnchor: "end",
                    dy: 6,
                  }}
                />
                <YAxis dataKey={"totalamount"} hide />
                <Tooltip
                  content={<CustomTooltip />}
                  cursor={{ fill: "transparent" }}
                />
                <br />
                <Legend />
                <Bar
                  name="Total Amount"
                  dataKey={"totalamount"}
                  shape={<TriangleBar />}
                  radius={[16, 16, 16, 16]}
                  fill="#00a0fc"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}

        {this.props.cptwise === "#" && (
          <div onMouseOver={() => (tooltip = "totalcount")}>
            <ResponsiveContainer width={"99%"} height={400}>
              <BarChart
                width={500}
                height={300}
                data={this.state.cptwisecount}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis
                  dataKey="_id"
                  height={120}
                  interval={0}
                  angle={"-45"}
                  minTickGap={6}
                  tick={{
                    strokeWidth: 0,
                    fontSize: "11",
                    top: 400,
                    textAnchor: "end",
                    dy: 6,
                  }}
                />
                <YAxis dataKey="totalcount" hide />
                <Tooltip
                  cursor={{ fill: "transparent" }}
                  content={<ClaimTooltip />}
                ></Tooltip>{" "}
                <Legend />
                <Bar
                  name="Total Claims"
                  dataKey="totalcount"
                  shape={<TriangleBar />}
                  radius={[16, 16, 16, 16]}
                  fill="#00a0fc"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    );
  }
}
export default ARbyCPT;
